import React from 'react';
import Breadcrumb from '../commonComponent/common/breadcrumb';

const ApiManagement = () => {
    return (
        <>
            <Breadcrumb title="API Management" parent="Admin" />
            <div className='container-fluid'>
                {/*
                <div className='row'>
                    <div className='col-md-12'>
                        <h5>Centre Master</h5>
                    </div>
                </div>
                */}
                <form className="theme-form">

                   
                  
                </form>

            </div>
        </>
    )
}

export default ApiManagement;