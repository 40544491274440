import React from 'react';
import { Row, Col, Card } from "react-bootstrap";
import classes from './CartSummary.module.css';
import { useState, useEffect } from 'react';


const CartSummary = (props) => {
    const [cartactualtotal, setCartActualTotal] = useState(0);
    const [cartdiscounttotal, setCartDiscountTotal] = useState(0);
    const [itemcart, setItemCart] = useState([]);
    useEffect(() => {
        var cartarr = [];
        var cartpatientarr = [];
        var cartActualTotall = 0;
        var cartDiscountTotall = 0;
        cartarr = props.ItemDetails;
        cartpatientarr = props.PatientNames;
        for (var key in cartarr) {
            cartActualTotall += parseInt(cartarr[key].ActualPrice);
            cartDiscountTotall += parseInt(cartarr[key].DiscountPrice);
        }
        setItemCart([...new Map(cartarr.map((m) => [m.ItemMasterId, m.IsPackage, m])).values()]);
        setCartActualTotal(cartActualTotall);
        setCartDiscountTotal(cartDiscountTotall);
    }, [])

    const CallBackFunction = () => {
        { props.reviewstatus(false) }
        { props.statevalues([]) }
    }
    return (
        <>
            <Card>
                <Card.Header className={classes.bgTransparent}>
                    <p className='mb-0 fw-bold ft18'>Order Summary <i className="fa fa-edit" onClick={(e) => CallBackFunction()}></i></p>
                </Card.Header>
                <Card.Body>
                    <Row className='mb-3'>
                        <Col xs={7} className={classes.textDark}>
                            Subtotal (x{props.ItemDetails.length} Items)
                        </Col>
                        <Col xs={5} className="text-right">
                            <span className={classes.salePrice}>&#8377;{cartactualtotal}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={7} className={classes.textDiscount}>Discount</Col>
                        <Col xs={5} className="text-right">
                            <span className={classes.offPrice}>-&#8377;{cartactualtotal - cartdiscounttotal}</span>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <Row className='mb-3'>
                        <Col xs={7} className={classes.textTotal}>
                            Total amount
                        </Col>
                        <Col xs={5} className="text-right">
                            <span className={classes.priceTotal}>&#8377;{cartdiscounttotal}</span>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </>
    )
}

export default CartSummary