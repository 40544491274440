import React, { useState, useEffect, useRef } from 'react'
import SweetAlert from 'sweetalert2';
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import ConnectionInstance from '../../apisettings/ConnectionInstance';
import { Typeahead } from 'react-bootstrap-typeahead';
import DataTables from '../DataTable/DataTable';
import NoRecordFounds from '../DataTable/NoRecordFound';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolTip from '../ToolTip';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useHistory } from 'react-router';

var chkflag = '';
const SampleQuotation = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [region, setRegion] = useState([]);
    const [location, setLocation] = useState([]);
    const [center, setCenter] = useState([]);
    const [options, setOptions] = useState([]);
    const [itemsno, setItemSno] = useState("");
    const [table, setTable] = useState([]);
    const [viewpackageModal, setViewPackageModal] = useState();
    const [viewpanelModal, setViewPanelModal] = useState();
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [changeflag, setChangeFlag] = useState(false);
    const [viewpackageitems, setViewPackageItems] = useState([]);
    const [viewpanelitems, setViewPanelItems] = useState([]);
    const [totaltest, setTotalTest] = useState(0);
    const [totalamt, setTotalAmt] = useState(0);
    const multiple = false;
    const navigate = useHistory();
    let testreference = useRef();
    useEffect(() => {
        ClearStoredState();
        GetRegions();
    }, [])
    useEffect(() => {
        setTable(table.filter(item => item.ItemSNo !== chkflag))
        setChangeFlag(false); // this flag determines to delete a row from a grid
    }, [changeflag]);
    useEffect(() => {
        var storetotalamt = 0;
        setTotalTest(table.length);
        if (table == "") { setTotalAmt(0); }
        else { for (var key in table) { storetotalamt += parseInt(table[key].Amt); } }
        setTotalAmt(storetotalamt);
    }, [table]);
    const ClearStoredState = () => {
        localStorage.removeItem("samplerid");
        localStorage.removeItem("sampledid");
        localStorage.removeItem("samplecid");
        localStorage.removeItem("sampleitemmasterdetails");
    }
    const GetRegions = async () => {
        setIsLoading(true);
        let url = ConnectionInstance + 'cart/getcentreregions/0/0'
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                var glblregion = [];
                for (var key in data.Data) {
                    glblregion.push(<option key={data.Data[key].RegionId} value={data.Data[key].RegionId}>{data.Data[key].RegionName}</option>)
                }
                setRegion(glblregion);
            }
            else {
                setRegion([]);
                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                ErrorFunction(error.response.status);
            }
        });
    }
    const CallLocation = async (RegionId) => {
        setTable([]);
        if (RegionId === "0") {
            setLocation([]);
            setCenter([]);
            localStorage.setItem("samplerid", "0");
            localStorage.setItem("sampledid", "0");
            localStorage.setItem("samplecid", "0");
            setOptions([]);
            testreference.current.clear();
        }
        else {
            setLocation([]);
            setCenter([]);
            GetCities(RegionId);
        }
    }
    const GetCities = async (RegionId) => {
        setIsLoading(true);
        let url = ConnectionInstance + 'cart/getcentrelocation/0/0/' + RegionId
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                var glbldistrict = [];
                for (var key in data.Data) {
                    glbldistrict.push(<option key={data.Data[key].DistrictId} value={data.Data[key].DistrictId}>{data.Data[key].DistrictName}</option>)
                }
                setLocation(glbldistrict);
            }
            else {
                setLocation([]);
                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                ErrorFunction(error.response.status);
            }
        });
    }
    const CallCentresFunction = (LocationId) => {
        setTable([]);
        if (LocationId === "0") {
            setCenter([]);
            localStorage.setItem("samplecid", "0");
            setOptions([]);
            testreference.current.clear();
        }
        else {
            setCenter([]);
            GetCentreAgainstLocation(LocationId);
        }
    }
    const GetCentreAgainstLocation = async (LocationId) => {
        setIsLoading(true);
        let url = ConnectionInstance + 'cart/getcentreagainstlocation/0/0/' + LocationId
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                var glblcenter = [];
                for (var key in data.Data) {
                    glblcenter.push(<option key={data.Data[key].CentreId} value={data.Data[key].CentreId}>{data.Data[key].CentreName}</option>)
                }
                setCenter(glblcenter);
            }
            else {
                setCenter([]);
                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                ErrorFunction(error.response.status);
            }
        });
    }
    const ChangeCentre = async (CentreId) => {
        setTable([]);
        setOptions([]);
        testreference.current.clear();
        localStorage.setItem("samplerid", region.current.value);
        localStorage.setItem("sampledid", location.current.value);
        localStorage.setItem("samplecid", CentreId);
        localStorage.setItem("samplecentreid", CentreId);
    }
    const getitemlist = async (e, text) => {
        if (center.current.value === "0") {
            SweetAlert.fire("Please select center");
            testreference.current.clear();
            return false;
        }
        const requestOptions = {
            CentreId: localStorage.getItem("samplecid"), RateTypeId: "0", Item: e.target.value
        };
        let url = ConnectionInstance + 'master/getItemList'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data.Data;
            if (data === null) { } else { setOptions(data); }
        }).catch(function (error) { });
    }
    const CallBindGrid = async (itmsno, ispackage, ratetemplateid) => {
        if (itmsno == "") {
            SweetAlert.fire('Please add test');
            return false;
        }
        testreference.current.clear();
        for (var key in table) {
            if (table[key].ItemSNo == itmsno && table[key].IsPackage == ispackage) {
                SweetAlert.fire('Item already added');
                return false;
            }
        }
        setIsLoading(true);
        const requestOptions = {
            ItemTemplateSNo: ratetemplateid === undefined ? "0" : ratetemplateid, ItemSNo: itmsno, RateType: "0", CentreSNo: localStorage.getItem("samplecid"), RateTemplatesId: ratetemplateid === undefined ? "0" : ratetemplateid, IsPackage: ispackage
        };
        let url = ConnectionInstance + 'master/AddRegestrationTestItems'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                setIsLoading(false);
                if (data.Data.regestrationTestDetails != null) {
                    data.Data.AddedStatus == "-3000" ? SweetAlert.fire('Item already exists.') :
                        data.Data.AddedStatus == "-3001" ? SweetAlert.fire('Item already exists.') :
                            setTable([...table, ...data.Data.regestrationTestDetails])
                }
                else {
                    SweetAlert.fire('No item exists.')
                }
            }
            else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
    }
    const openModal = () => { setCrntPageNO(0); setViewPackageModal(!viewpackageModal); }
    const openPanelModal = () => { setCrntPageNO(0); setViewPanelModal(!viewpanelModal); }

    const popupPaginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.IsPackage !== "0" ? <ToolTip linkId={`Delete${rowData.ItemSNo}`} faName="fa fa-trash mr-2" title="Delete Package" id={`Delete${rowData.ItemSNo}`} placement="top" onClick={() => DisplayDeleteTest(rowData.ItemSNo)} /> :
                        <ToolTip linkId={`Delete${rowData.ItemSNo}`} faName="fa fa-trash mr-2" title="Delete Item" placement="top" id={`Delete${rowData.ItemSNo}`} onClick={() => DisplayDeleteTest(rowData.ItemSNo)} />
                }
                {
                    rowData.IsPackage !== "0" ? <ToolTip linkId={`ViewPack${rowData.ItemSNo}`} faName="fa fa-info-circle mr-2" title="View Package Items" placement="top" id={`ViewPack${rowData.ItemSNo}`} onClick={() => ViewPackageItems(rowData.ItemSNo)} /> :
                        null
                }
                {
                    rowData.IsPanel.toLowerCase() === "profile" ? <ToolTip linkId={`ViewPan${rowData.ItemSNo}`} faName="fa fa-info-circle mr-2" title="View Panel Items" placement="top" id={`ViewPan${rowData.InvestigationId}`} onClick={() => ViewPanelItems(rowData.InvestigationId)} /> :
                        null
                }
            </div>
        </React.Fragment>
    }
    const columns = [{
        dataField: '#',
        text: '#',
        editable: false,
        formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    },
    {
        dataField: 'Code',
        text: 'Code',
        editable: false
    },
    {
        dataField: 'Item',
        text: 'Item',
        editable: false
    },
    { dataField: 'Amt', text: 'MRP', editable: false },
    {
        dataField: 'Rate',
        text: 'Rate',
        editable: false
    },
    { dataField: 'DeliveryDate', text: 'Delivery Date', editable: false },
     { dataField: 'Precautions', text: 'Precautions', editable: false, },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        editable: false,
        formatter: actionFormatter
    }
    ];
    const popupidFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const popupviewColumns = [
        { dataField: '#', text: '#', editable: false, formatter: popupidFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'Code', text: 'Code', editable: false },
        { dataField: 'Item', text: 'Item', editable: false }
        // { dataField: 'PackageName', text: 'Package Name', editable: false }
    ];
    const popupviewpanelColumns = [
        { dataField: '#', text: '#', editable: false, formatter: popupidFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'Name', text: 'Item', editable: false }
    ];
    const DisplayDeleteTest = (ItemNo) => {
        SweetAlert.fire({
            title: 'You are going to delete an item. Are you sure?',
            text: "Once deleted, you will not be able to recover!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    DeleteTest(ItemNo);
                }
                else {
                    SweetAlert.fire(
                        'Your data is safe!'
                    )
                }
            })
    }
    const DeleteTest = async (ItemNo) => {
        setChangeFlag(true);
        chkflag = ItemNo;
        SweetAlert.fire(
            'Deleted!',
            'Data has been deleted.',
            'success')
    }
    const ViewPackageItems = async (PackageId) => {
        setIsLoading(true);
        const requestOptions = {
            ItemTemplateSNo: "0", Gender: "", Age: "", PackageId: PackageId, RateType: "0", CentreSNo: localStorage.getItem("samplecid")
        };
        try {
            let url = ConnectionInstance + 'master/PackageItems'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success) {
                    if (data.Data.regestrationTestDetails != null) {
                        setViewPackageItems(data.Data.regestrationTestDetails);
                        openModal();
                    }
                    else {
                        SweetAlert.fire("No items found");
                    }
                }
                else { setIsLoading(false); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const ViewPanelItems = async (InvestigationId) => {
        setIsLoading(true);
        const requestOptions = {
            InvestigationId: InvestigationId
        };
        try {
            let url = ConnectionInstance + 'master/PanelItems'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success) {
                    if (data.Data.regestrationTestDetails != null) {
                        setViewPanelItems(data.Data.regestrationTestDetails);
                        openPanelModal();
                    }
                    else {
                        SweetAlert.fire("No items found");
                    }
                }
                else { setIsLoading(false); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const BookAppointment = async () => {
        if (props.flag === "adminoutbound" ||props.flag === "useroutbound"|| props.flag === "inbound" ||props.flag=="adminviewassignedcalls"||
        props.flag=="adminviewreassignedcalls" || props.flag=="viewcalldataDD" ) {
            var cartarr = [];
            for (var key in table) {
                cartarr.push({
                    ItemMasterId: table[key].ItemSNo,
                    IsPackage: table[key].IsPackage === "True" ? "1" : table[key].IsPackage === "False" ? "0" : table[key].IsPackage
                })
            }
            localStorage.setItem('sampleitemmasterdetails', JSON.stringify(cartarr)); localStorage.setItem('sampleitemmasterdetails', JSON.stringify(cartarr));
           // alert(JSON.stringify(cartarr));
            cartarr = [];
            if (props.flag === "adminoutbound") {
                localStorage.setItem('UPageURL', "/admin/outbound/addassigncalls");
                localStorage.setItem('PageIdentify', "1");
            }
            else  if (props.flag === "useroutbound") {
                localStorage.setItem('UPageURL', "/user/outbound/addassigncalls");
                localStorage.setItem('PageIdentify', "2");
            }
            else if (props.flag === "inbound") {
                localStorage.setItem('UPageURL', "/inbound/addInboundcalls");
                localStorage.setItem('PageIdentify', "3");
            }
            else if (props.flag === "adminviewassignedcalls") {
                localStorage.setItem('UPageURL', "/admin/outbound/viewassignedcalls");
                localStorage.setItem('PageIdentify', "4");
            }
            else if (props.flag === "adminviewreassignedcalls") {
                localStorage.setItem('UPageURL', "/admin/outbound/viewreassignedcalls");
                localStorage.setItem('PageIdentify', "5");
            }
            else if (props.flag === "viewcalldataDD") {
                localStorage.setItem('UPageURL', "/searchdb/viewcalldatadetail");
                localStorage.setItem('PageIdentify', "6");
            }
            else {
                localStorage.setItem('PageIdentify', "0");
            }

            // navigate.push(`/inbound/appointments`);
           // navigate.push(`/inbound/bookappointments`);
           navigate.push(`/booking/appointments`);
        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Duplicate", text: "User already exist", icon: "error" }) }
        else if (ErrorCode === "0") { SweetAlert.fire({ title: "Location not found", text: "Please select location", icon: "error" }) }
        else if (ErrorCode === "-1997") { SweetAlert.fire({ title: "Empty", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-1998") { SweetAlert.fire({ title: "Some value is missing", text: "Check server and db logs", icon: "error" }) }
        else if (ErrorCode === "-1999") { SweetAlert.fire({ title: "Runtime Exception", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <Card.Header className='pb-0'>
                            <Card.Title>Sample Quotation</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Row className='justify-content-center'>
                                <Col md={4} lg={4}>
                                    <Form.Group>
                                        <Form.Label>Region <span className='text-red'>*</span></Form.Label>
                                        <Form.Control as="select" name="region" ref={region} onChange={(e) => CallLocation(e.target.value)}>
                                            <option key='0' value='0'>---Select---</option>
                                            {region}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={4} lg={4}>
                                    <Form.Group>
                                        <Form.Label>Location <span className='text-red'>*</span></Form.Label>
                                        <Form.Control as="select" name="location" ref={location} onChange={(e) => CallCentresFunction(e.target.value)}>
                                            <option key='0' value='0'>---Select---</option>
                                            {location}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={4} lg={4}>
                                    <Form.Group>
                                        <Form.Label>Center <span className='text-red'>*</span></Form.Label>
                                        <Form.Control as="select" name="center" ref={center} onChange={(e) => ChangeCentre(e.target.value)}>
                                            <option value='0'>---Select---</option>
                                            {center}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col md={12}>
                                    <Form.Group>
                                        <Form.Label>Search Test</Form.Label>
                                        <Typeahead
                                            id="basic-typeahead"
                                            labelKey="Item"
                                            multiple={multiple}
                                            options={options}
                                            placeholder="Choose a test..."
                                            ref={testreference}
                                            onInputChange={(text, e) => { getitemlist(e, text); }}
                                            onChange={(text) => {
                                                JSON.stringify(text) == "[]" ? setItemSno("") : setItemSno(text[0].SNo)
                                                CallBindGrid(text[0].SNo, text[0].IsPackage, text[0].RateTemplatesId)
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            {
                                table.length > 0 ?
                                    <>
                                        <div className='text-right'>
                                            <b>Total Test:</b> <span className='text-danger mr-2' name="totaltest">{totaltest}</span>
                                            <b>Total Amount:</b> <span className='text-danger mr-2' name="totalamount"><i className='fa fa-inr'></i>{totalamt}</span>
                                        </div>
                                        <div className="table-responsive mt15">
                                            <DataTables
                                                keyField='ItemSNo'
                                                tableData={table}
                                                columns={columns}
                                                noDataIndication={NoRecordFounds}
                                            />
                                        </div>
                                    </>
                                    :
                                    null
                            }
                            {
                                table.length > 0 ?
                                    <>
                                        <Form.Row>
                                            <Col md={12} className='text-center'>
                                                <Button variant='primary' type='submit' onClick={BookAppointment}>Make an appointment</Button>
                                            </Col>
                                        </Form.Row>
                                    </> : null
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {viewpackageModal &&
                <Modal isOpen={viewpackageModal} toggle={openModal} centered={true} size="lg">
                    <ModalHeader toggle={openModal}>Items</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <DataTables keyField='ItemSNo' tableData={viewpackageitems} columns={popupviewColumns} pagination={paginationFactory(popupPaginationOptions)} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            }
            {viewpanelModal &&
                <Modal isOpen={viewpanelModal} toggle={openPanelModal} centered={true} size="lg">
                    <ModalHeader toggle={openPanelModal}>Items</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <DataTables keyField='Id' tableData={viewpanelitems} columns={popupviewpanelColumns} pagination={paginationFactory(popupPaginationOptions)} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            }
        </>
    )
}

export default SampleQuotation