import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import React, { Fragment, useState, useEffect, useRef } from 'react'
import CallToolTip from '../UI/ToolTips/CallToolTip';
import Breadcrumb from '../commonComponent/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import InputGroup from 'react-bootstrap/InputGroup';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import ActiveToolTip from '../UI/ToolTips/ActiveToolTip';
import InactiveToolTip from '../UI/ToolTips/InactiveToolTip';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import { useFormik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import ConnectionInstance from '../apisettings/ConnectionInstance';
import { useHistory } from 'react-router';
import moment from 'moment';
let buttonclickStatus = false;
let GlobalPageNo=1;
let GlobalPageSizeNo=10;
const UntouchedInboundCall = () => {
    const[CCLoading,SetCCLoading]=useState(false);
    let fromDate = useRef();
    const [downloadvisible, setDownloadVisible] = useState(false);
    let toDate = useRef();
    const today = new Date();
    const date = today.setDate(today.getDate());
    const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
    const [defaultDate, setDefaultDate] = useState(defaultValue);
    const searchValue = useRef();
    const [TeamMData, SetTeamMData] = useState(0);
    const [CallCentreExecutiveList, setCallCentreExecutiveList] =useState(0);
    const [CallstatusDLL, SetCallstatusDLL] = useState(0);
    const [LeadSourceDDL, SetLeadSourceDDL] = useState(0);
     const[CallStatusIds,SetCallStatusIds]=useState("");
     const[CallSourceIds,SetCallSourceIds]=useState("");
     const[ExcutiveSelectionIds,SetExcutiveSelectionIds]=useState("");
     const[CallHistoryDatalistCSV,SetCallHistoryDatalistCSV]=useState([]);
    const navigate = useHistory();
    const [ModalBodyHtml, SetModalBodyHtml] = useState("");
    const [ErrorPopUp, setErrorPopUp] = useState(0);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [UserTypeId, setUserTypeId] = useState(localStorage.UserType == undefined ? "" : localStorage.UserType);
    const [CallExtensionId, setCallExtensionId] = useState(localStorage.ExtenNo == undefined ? "" : localStorage.ExtenNo);
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsInboundnotifiCallDlists: []

    });
    const [errorModal, setErrorModal] = useState(false);
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
    }
    
    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            asyncFunBindInboundnotifcationSearch(page, sizePerPage);
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            asyncFunBindInboundnotifcationSearch(page, sizePerPage);
        }
    };

    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        //console.log(cellData, rowData);
        return <React.Fragment>

<p>
     <span className='grdH3'>StartedAt:{(rowData.CallStartedAt!="")?(rowData.CallStartedAt):'N/A'}</span><br/>
     <span className='grdH3'>Duration: {(rowData.CallDuration!="")?(rowData.CallDuration):'N/A'}</span><br/>
      </p>
        </React.Fragment>
    }

    const CallQueueFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
       <span className='grdH3'><strong>Queue: {(rowData.FlowName!="")?(rowData.FlowName):'N/A'}</strong></span><br/>
       <span className='grdH3'>IVR: {(rowData.IVR!="")?(rowData.IVR):'N/A'}</span><br/>
       <span className='grdH3'>FLOW: {(rowData.FLOW!="")?(rowData.FLOW):'N/A'}</span><br/>
      </p>
        </div>
        </React.Fragment>
    }
    const ExecutiveActiveFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
     <span className='grdH3'>{(rowData.ExecutiveName!="")?(rowData.ExecutiveName):'N/A'}</span><br/>
     <span className='grdH3'>{(rowData.ExecutiveEmail!="")?(rowData.ExecutiveEmail):'N/A'}</span><br/>
      </p>
        </div>
        </React.Fragment>
    }

    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
          let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
          return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => {
            return { width: '6%'};
            }
    },
    {
        dataField: 'CallFrom',
        text: 'CallFrom'
    },
    {
        dataField: 'CallUpdate',
        text: 'Date'
    },
    {
        isDummyField: true,
        text: 'Executive',
        formatter: ExecutiveActiveFormatter

    }
    ,
    {
        text: 'Call Queue',
        formatter: CallQueueFormatter,
        editable: false 
    },
    {
        isDummyField: true,
        text: 'Call Time',
        formatter: actionFormatter

    }
    ];


    
   
       useEffect(() => {
        asyncFunCallExecutiveDDL();
        asyncFunBindInboundnotifcationSearch(1, 10);
    }, []);
    
    const DownloadHandler = () => { 
        if ((new Date(fromDate.current.value) > new Date(toDate.current.value)) || (new Date(toDate.current.value) < new Date(fromDate.current.value))) {

            SweetAlert.fire({ title: "Wrong Dates Selection", text: "From date always less than or equal to todate.", icon: 'warning' });
        }
        else {
            asyncFunBindInboundnotifcationDownload(1,10);
        }
        }
        const searchHandler = () => { 
            if ((new Date(fromDate.current.value) > new Date(toDate.current.value)) || (new Date(toDate.current.value) < new Date(fromDate.current.value))) {
    
                SweetAlert.fire({ title: "Wrong Dates Selection", text: "From date always less than or equal to todate.", icon: 'warning' });
            }
            else {
                asyncFunBindInboundnotifcationSearch(1,10);
            }
            }
            async function asyncFunBindInboundnotifcationSearch(PageNo,PageSize) {
       
                try {
                    SetCCLoading(true);
                    let url=ConnectionInstance+ 'reports/GetBindUntouchedInboundReport';
                        let options = {
                            method: 'POST',
                            url: url,
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json;charset=UTF-8'
                            },
                            data: { CallerId: `${ExcutiveSelectionIds}`, FromDate: `${fromDate.current.value}`,ToDate:`${toDate.current.value}`, 
                             PageNo: `${PageNo}`, PageSize: `${PageSize}`,IsRptDownloadStatus:`${"0"}` }
                        };
                        let response = await axios(options);
                        let responseOK = response && response.status == 200;
                        if (responseOK) {
                            let data = response.data;
            
                            // let data = await response.data;
                            if (data.Success == true) {
                                let ds = data.Data;
                                SetCCLoading(false);
                                if (ds != null  && ds!="-11") {
                                    setDownloadVisible(false);
                                    await SetPageHelper({
                                    ...PageHelper,
                                    PageNo:Number(PageNo),
                                    PageSize: Number(PageSize),
                                    TotalRecord: Number(ds.TotalRecord),
                                    dsInboundnotifiCallDlists: ds.UntouchedInboundcallList == null ? [] : ds.UntouchedInboundcallList
                                });
            
                                }
                                 else if(ds=="-11")
                                 {
                                    setDownloadVisible(false);
                                await SetPageHelper({
                                    ...PageHelper,
                                    PageNo:Number(PageNo),
                                    PageSize: Number(PageSize),
                                    TotalRecord: Number(ds.TotalRecord),
                                    dsInboundnotifiCallDlists:[]
                                });
                                 }
                                else { setDownloadVisible(false);
                                    await SetPageHelper({
                                        ...PageHelper,
                                        PageNo:Number(PageNo),
                                        PageSize: Number(PageSize),
                                        TotalRecord: Number(ds.TotalRecord),
                                        dsInboundnotifiCallDlists:[]
                                    });
                                }
                            }
                            else {
                                SetCCLoading(false);
                                setDownloadVisible(false);
                                await SetPageHelper({
                                    ...PageHelper,
                                    PageNo:Number(PageNo),
                                    PageSize: Number(PageSize),
                                    TotalRecord: Number(0),
                                    dsInboundnotifiCallDlists:[]
                                });
                            }
                            SetCCLoading(false);
                        }
                        else {
                            SetCCLoading(false);
                            setDownloadVisible(false);
                                await SetPageHelper({
                                    ...PageHelper,
                                    PageNo:Number(PageNo),
                                    PageSize: Number(PageSize),
                                    TotalRecord: Number(0),
                                    dsInboundnotifiCallDlists:[]
                                });
                        }
                        // return data;
                    } catch (error) {
                        SetCCLoading(false);
                        SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
                    }
            }

    async function asyncFunBindInboundnotifcationDownload(PageNo,PageSize) {
       
            try {
                SetCCLoading(true);
                let url=ConnectionInstance+ 'reports/GetBindUntouchedInboundReport';
                    let options = {
                        method: 'POST',
                        url: url,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json;charset=UTF-8'
                        },
                        data: { CallerId: `${ExcutiveSelectionIds}`, FromDate: `${fromDate.current.value}`,ToDate:`${toDate.current.value}`,
                        PageNo: `${PageNo}`, PageSize: `${PageSize}`,IsRptDownloadStatus:`${"1"}` }
                    };
                    let response = await axios(options);
                    let responseOK = response && response.status == 200;
                    if (responseOK) {
                        let data = response.data;
        
                        // let data = await response.data;
                        if (data.Success == true) {
                            SetCCLoading(false);
                            let ds = data.Data;
                            if (ds != null&& ds.UntouchedInboundcallList!=null && ds.UntouchedInboundcallList.length>0 && ds!="-11") {
                                setDownloadVisible(true);
                                SetCallHistoryDatalistCSV(ds.UntouchedInboundcallList);
                                document.getElementById("csvdownload").click();
        
                            }
                             else if(ds=="-11")
                             {
                                setDownloadVisible(false);
                                SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                             }
                            else { setDownloadVisible(false);
                                SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                            }
                        }
                        else {
                            SetCCLoading(false);
                            SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                        }
                       
                    }
                    else {
                        SetCCLoading(false);
                        SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                    }
                    // return data;
                } catch (error) {
                    SetCCLoading(false);
                    SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
                }
        }

     
        const ExecutiveSelectionHandler = (e) => {
            const val = e.target.value;
            SetExcutiveSelectionIds(val);
           
        }
        async function  asyncFunCallExecutiveDDL() {
            try {
             
            let url=ConnectionInstance+ 'master/GetCallCentreExcutiveDDList';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if(data.Success==true)
                    { let ds=data.Data;
                        if(ds!=null && ds.length>0)
                        {
                            setCallCentreExecutiveList(ds.map((option) => (<option value={option.CallerId}>{option.UserName}</option>)));                                         
                        }
                    }
                    else{
                        let Errtext=<p><div className='text-required'> no record found </div></p>;
                       setErrorPopUp(Errtext);
                       setErrorModal(true);
                        console.log('no record found');
                    }
                   
                    //setUserData(data.map((option) =>(<option value={option.sNo}>{option.ename}</option>)));
                    // do something with data
                }
                else{
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                    setErrorPopUp(Errtext);
                    setErrorModal(true);
                    console.log('no record found');
                }
             // return data;
            } catch (error) {
                console.log(error);
                let Errtext=<p><div className='text-required'>{error} </div></p>;
                setErrorPopUp(Errtext);
                setErrorModal(true);
              //alert(error);
            }
          }
       
  
    return (
        <>
           <div id="cover-spin"  style={{display: CCLoading ? 'block' : 'none'}}></div> 
            <Breadcrumb title="Untouched Inbound Calls" parent="Reports" />
            <Container fluid>
                
            <Row>
                    <Col md={12}>
                        <Card>
                          
                            <Card.Body>
                           
                            <Form.Row className="justify-content-center">
                                        <Col md={4}>
                                        <Form.Group>
                                                <Form.Label>Executive Name</Form.Label>
                                                <Form.Control as="select" id="TeamId" name="TeamId"  onChange={ExecutiveSelectionHandler}
                                                  
                                                    value={ExcutiveSelectionIds}  >
                                                    <option value='-1'>---Select All---</option>
                                                    {CallCentreExecutiveList}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                        <Form.Group>
                                  <Form.Label>From Date</Form.Label>
                                  <InputGroup className="mb-3 d-flex justify-content-start">
                                      <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                  </InputGroup>
                                      </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                        <Form.Group>
                                  <Form.Label>To Date</Form.Label>
                                  <InputGroup className="mb-3 d-flex justify-content-start">
                                      <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                  </InputGroup>
                              </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="justify-content-center">
                                      
                                    </Form.Row>
                                    <Row className='justify-content-center mt15'>
                                    <Col md={4}>
                                <Button variant='primary' onClick={searchHandler}>Search</Button>
                                        <Button className='ml-2' variant='primary' onClick={DownloadHandler}>Download CSV</Button> 
                                        
                                    </Col>
                               
                            </Row> 
                            <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Untouched Inbound Calls List</Card.Title>
                            </Card.Header>
                            <Card.Body>

                                <DataTables
                                    keyField='SNo'
                                    tableData={PageHelper.dsInboundnotifiCallDlists}
                                    columns={columns}
                                    pagination={paginationFactory(paginationOptions)}

                                />

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>         
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


                {
                    downloadvisible &&
                    <CSVLink data={CallHistoryDatalistCSV} filename={"untouchedinboundcallreport.csv"} target="_blank" id="csvdownload"></CSVLink>
                }
                
            </Container>

            {
                errorModal === true ?
                    (<Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                            {/* {ModalBodyHtml===null||ModalBodyHtml===''?
                        
                        (<><p>{formik.touched.sourceCategory && formik.errors.sourceCategory ? (
                            <div className='text-required'>{formik.errors.sourceCategory}</div>
                        ) : null}</p>  </> ):ModalBodyHtml }             */}
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>

                    </Modal>)
                    : null
            }
        </>
    )
}

export default UntouchedInboundCall;