import React, { useState, useEffect, useContext, useRef, forwardRef, useImperativeHandle } from 'react';
import { Form, Row, Col, Card, Button } from "react-bootstrap";
//import { FrontDesk_URL } from '../../../constant/index';
import ConnectionInstance from '../../apisettings/ConnectionInstance';
import classes from './CartSummary.module.css';
import Swal from 'sweetalert2';
import axios from 'axios';
var discountPaitent = [];
const Order = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        ClearDiscAmt,
    }));
    const [itemcount, setItemCount] = useState([]);
    const refDiscountAmt = useRef([]);
    const refChkDiscountPer = useRef([]);
    useEffect(() => {
        const Items = props.ItemDetails;
        const Patients = props.PatientNames;
        var countarr = [];
        var totalcount = 0;
        var totalsubAmt = 0;
        for (var key in Patients) {
            countarr.push({
                PatientId: Patients[key].PatientId,
                Count: 0,
                TotalSubAmount: 0,
                VisitingId: Patients[key].VisitingId,
            })
            totalcount = 0;
            totalsubAmt = 0;
            for (var keyy in Items) {
                if (Patients[key].PatientId === Items[keyy].PatientId) {
                    totalsubAmt += parseFloat(Items[keyy].DiscountPrice);
                    totalcount = parseInt(totalcount + 1);
                    countarr[key].Count = parseInt(totalcount);
                }
                countarr[key].TotalSubAmount = parseFloat(totalsubAmt).toFixed(2);
            }
        }
        setItemCount(countarr);
    }, [])
    const handlePercentageCheckboxPatient = (id) => (e) => {
        const checkedflg = e.target.checked;
        if (checkedflg) {

            refChkDiscountPer.current[id].value = "1";
        } else {
            refChkDiscountPer.current[id].value = "0";
        }
        discountAdd(id);
    };
    const discountChangeHandler = (id) => (e) => {
        const discountVal = e.target.value;
        refDiscountAmt.current[id].value = discountVal;
    }
    const ClearDiscAmt = async () => {
        for (var key in props.PatientNames) {
            if (refDiscountAmt.current[props.PatientNames[key].VisitingId] !== undefined) {
                if (refDiscountAmt.current[props.PatientNames[key].VisitingId].value !== "" || refDiscountAmt.current[props.PatientNames[key].VisitingId].value !== "0") {
                    refDiscountAmt.current[props.PatientNames[key].VisitingId].value = "0";
                    refChkDiscountPer.current[props.PatientNames[key].VisitingId].value = "0";
                    document.getElementById("chkPer" + props.PatientNames[key].VisitingId + "").checked = false;
                }
            }
        }
    }
    const discountAdd = async () => {
        discountPaitent = [];
        for (var key in props.PatientNames) {
            if (refDiscountAmt.current[props.PatientNames[key].VisitingId] !== undefined) {
                if (refDiscountAmt.current[props.PatientNames[key].VisitingId].value !== "") {
                    var dAmt = 0;
                    if (refChkDiscountPer.current[props.PatientNames[key].VisitingId].value == "1") {
                        if (refDiscountAmt.current[props.PatientNames[key].VisitingId].value > 100) {
                            refDiscountAmt.current[props.PatientNames[key].VisitingId].value = 0;
                            Swal.fire("Please enter percentage value less than 100.");
                            return false;
                        }
                        else {
                            dAmt = parseFloat(parseFloat(itemcount[itemcount.findIndex(x => x.VisitingId === props.PatientNames[key].VisitingId)].TotalSubAmount) * parseFloat(refDiscountAmt.current[props.PatientNames[key].VisitingId].value) / 100);
                        }
                    }
                    else {
                        dAmt = refDiscountAmt.current[props.PatientNames[key].VisitingId].value;
                    }
                    discountPaitent.push(
                        {
                            visitid: props.PatientNames[key].VisitingId,
                            discountAmt: dAmt
                        }
                    );
                }
            }
        }
        props.onChangeSomeState(discountPaitent);
    }
    const AddDiscount = (e) => (id) => {
        discountPaitent = [];
        for (var key in props.PatientNames) {
            if (refDiscountAmt.current[props.PatientNames[key].VisitingId] !== undefined) {
                if (refDiscountAmt.current[props.PatientNames[key].VisitingId].value !== "") {
                    var dAmt = 0;
                    if (refChkDiscountPer.current[props.PatientNames[key].VisitingId].value == "1") {
                        if (refDiscountAmt.current[props.PatientNames[key].VisitingId].value > 100) {
                            refDiscountAmt.current[props.PatientNames[key].VisitingId].value = 0;
                            Swal.fire("Please enter percentage value less than 100.");
                            return false;
                        }
                        else {
                            dAmt = parseFloat(parseFloat(itemcount[itemcount.findIndex(x => x.VisitingId === props.PatientNames[key].VisitingId)].TotalSubAmount) * parseFloat(refDiscountAmt.current[props.PatientNames[key].VisitingId].value) / 100);
                        }
                    }
                    else {
                        dAmt = refDiscountAmt.current[props.PatientNames[key].VisitingId].value;
                    }
                    if (dAmt > parseFloat(itemcount[itemcount.findIndex(x => x.VisitingId === props.PatientNames[key].VisitingId)].TotalSubAmount)) {
                        Swal.fire("Discount Amount can't be greater than subtotal.");
                        refDiscountAmt.current[props.PatientNames[key].VisitingId].value=0;
                        return false;
                    }
                    document.getElementById("spnDisAmount" + props.PatientNames[key].VisitingId + "").innerHTML = "&#8377;" + dAmt;
                    discountPaitent.push(
                        {
                            visitid: props.PatientNames[key].VisitingId,
                            discountAmt: dAmt
                        }
                    );
                }
            }
        }
        props.onChangeSomeState(discountPaitent);
    }
    // const AddDiscount = (e) => (id) => {
    //     if (refDiscountAmt.current[e] !== undefined) {
    //         if (refDiscountAmt.current[e].value !== "") {
    //             var dAmt = 0;
    //             if (refChkDiscountPer.current[e].value == "1") {
    //                 if (refDiscountAmt.current[e].value > 100) {
    //                     refDiscountAmt.current[e].value = 0;
    //                     Swal.fire("Please enter percentage value less than 100.");
    //                     return false;
    //                 }
    //                 else {
    //                     dAmt = parseFloat(parseFloat(itemcount[itemcount.findIndex(x => x.VisitingId === e)].TotalSubAmount) * parseFloat(refDiscountAmt.current[e].value) / 100);
    //                 }
    //             }
    //             else {
    //                 dAmt = refDiscountAmt.current[e].value;
    //             }
    //             const index = discountPaitent.findIndex(item => item.visitid === e);
    //             if (index > -1) {
    //                 discountPaitent.splice(index, 1);
    //                 discountPaitent.push(
    //                     {
    //                         visitid: e,
    //                         discountAmt: dAmt
    //                     }
    //                 );
    //             }
    //             else {
    //                 discountPaitent.push(
    //                     {
    //                         visitid: e,
    //                         discountAmt: dAmt
    //                     }
    //                 );
    //             }
    //         }
    //     }
    //     props.onChangeSomeState(discountPaitent);
    // }
    const PatientPostpone = async (e) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You are going to postpone patient collection",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            reverseButtons: true,
            cancelButtonText: 'Cancel',
            cancelButtonColor: "#000",
        })
            .then((result) => {
                if (result.value) {
                    const requestOptions = {
                        VisitingID: e,
                        LoginID: localStorage.getItem("LoggedInUser")
                    };
                    let url = ConnectionInstance + 'phlebo/PartialPostpone'
                    axios.post(url, requestOptions).then((response) => {
                        const data = response.data;
                        if (data.Success) {
                            Swal.fire({ title: "Postpone", text: "Postpone successfully", icon: "success" });
                            window.location.reload(false);
                        }
                        else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
                    }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
                }
                else { Swal.fire('Your data is safe!') }
            })
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { Swal.fire({ title: "Duplicate", text: "Postpone already", icon: "error" }) }
        else if (ErrorCode === "-1997") { Swal.fire({ title: "Empty", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-1998") { Swal.fire({ title: "Some value is missing", text: "Check server and db logs", icon: "error" }) }
        else if (ErrorCode === "-1999") { Swal.fire({ title: "Runtime Exception", text: ErrorMsg, icon: "error" }) }
        else { Swal.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { Swal.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { Swal.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { Swal.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { Swal.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { Swal.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { Swal.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }

    return (
        <>
            <Card className={classes.cardBorderd}>
                <Card.Header className={classes.bgTransparent}>
                    <Row className='align-items-left'>
                        <Col xs={12}>
                            <h2 className='headingstyle3'>Review Booking</h2>
                        </Col>

                    </Row>
                </Card.Header>
                <Card.Body className='pt-0'>
                    <Row>
                        <Col xs={12}>
                            <Card.Text>
                                {
                                    props.PatientNames == [] ? null :
                                        <>
                                            {
                                                props.PatientNames.map((e, indexx) =>
                                                    <>

                                                        <Row className='mt-4 mb-2'>
                                                            <Col xs={8}>
                                                                <small className='text-muted textfullwidth' key={indexx}><span className={classes.textDark}><b className='text-patientName'>{e.PatientName}</b></span> <br />
                                                                    <span className='ageGender'>{e.Age} {e.Gender}</span>
                                                                    {
                                                                        itemcount.length > 0 ?
                                                                            <>
                                                                                {
                                                                                    itemcount.map((f, indexxx) =>
                                                                                        (e.PatientId === f.PatientId) ?
                                                                                            <>
                                                                                                &nbsp;(x{f.Count} {f.Count === 1 ? "item" : "items"})
                                                                                            </> :
                                                                                            null
                                                                                    )
                                                                                }
                                                                            </>
                                                                            : null
                                                                    }
                                                                </small>
                                                            </Col>
                                                            {/* <Col xs={4}>
                                                                <Button variant='primary' className='btn-sm' style={{ paddingLeft: '4px', paddingRight: '4px' }} onClick={() => PatientPostpone(e.VisitingId, e)}><i className='fa fa-history'></i> Postpone</Button>
                                                            </Col> */}
                                                        </Row>

                                                        {
                                                            props.ItemDetails == [] ? null :
                                                                props.ItemDetails.map((d, index) =>
                                                                    (e.PatientId === d.PatientId) ?
                                                                        <>
                                                                            <Row className={classes.orderpara}>
                                                                                <Col xs={8}>
                                                                                    <span className={classes.textDark}>{d.ItemMasterName}</span>
                                                                                </Col>
                                                                                <Col xs={4} className='text-right'>
                                                                                    <span className={classes.salePrice}> &#8377;{d.DiscountPrice}</span>
                                                                                    {
                                                                                        d.ActualPrice === d.DiscountPrice ? null :
                                                                                            <span className={classes.comparePrice}> &#8377;{d.ActualPrice}</span>
                                                                                    }
                                                                                    {
                                                                                        d.OffPercent === "0" ? null :
                                                                                            <span className={classes.offPrice}> &#8377;{d.OffPercent}% Off</span>
                                                                                    }
                                                                                    {
                                                                                        (d.HomeCollectionAvailable === "0" || d.HomeCollectionAvailable === "false" || d.HomeCollectionAvailable === false || d.HomeCollectionAvailable === "False") ?
                                                                                            <span style={{ color: '#999999', fontSize: '14px' }}> (Centre visit required)</span>
                                                                                            : null
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </> :
                                                                        null
                                                                )
                                                        }

                                                        {/* <Row className={classes.orderpara}>
                                                            <Col xs={8}>
                                                                <span className={classes.textDark}><b>SubTotal</b></span>
                                                            </Col>
                                                            <Col xs={4} className='text-right'>
                                                                {
                                                                    itemcount.length > 0 ?
                                                                        <>
                                                                            {
                                                                                itemcount.map((f, indexxx) =>
                                                                                    (e.PatientId === f.PatientId) ?
                                                                                        <>
                                                                                            <span className={classes.salePrice}> &#8377;{f.TotalSubAmount}</span>
                                                                                        </> :
                                                                                        null
                                                                                )
                                                                            }
                                                                        </>
                                                                        : null
                                                                }
                                                            </Col>
                                                        </Row> */}
                                                        {/* <div className='discountBx'>
                                                            <Row className='align-items-left'>
                                                                <Col xs={12}>
                                                                    <h4 className='headingstyle3'>Discount</h4>
                                                                </Col>
                                                            </Row>
                                                            <Row className='align-items-center mt-2 mb-2'>
                                                                <Col xs={8}>
                                                                    <Form.Group className='mb-0'>
                                                                        <input className="checkbox_animated" id={`chkPer${e.VisitingId}`} ref={(d) => (refChkDiscountPer.current[e.VisitingId] = d)} type="checkbox" onChange={handlePercentageCheckboxPatient(e.VisitingId)} onBlur={AddDiscount(e.VisitingId)} /> In Percentage
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <Form.Group className='mb-0'>
                                                                        <span id={`spnDisAmount${e.VisitingId}`}></span>
                                                                        <Form.Control type="number" ref={(d) => (refDiscountAmt.current[e.VisitingId] = d)} defaultValue={0} onChange={discountChangeHandler(e.VisitingId)} onBlur={AddDiscount(e.VisitingId)} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </div> */}

                                                    </>
                                                )
                                            }
                                        </>
                                }
                            </Card.Text>
                        </Col>
                    </Row>
                    <Row>

                    </Row>
                    <Row className='border-bottom pb-3 mt-5'>
                        <Col md={12}>
                            <span className={classes.textDark}><b className='ft18'>Address</b></span><br />
                            {
                                props.AddressId === 0 ? null :
                                    <>
                                        {
                                            props.Address.map((d) => d.Id === props.AddressId ?
                                                (d.Pincode === "" || d.Pincode === "0") && d.HouseNo === "" && d.Address === "" ?
                                                    <>
                                                        <small className='text-muted'>{d.District},{d.State}</small>
                                                    </> :
                                                    (d.Pincode === "" || d.Pincode === "0") && d.HouseNo === "" ?
                                                        <>
                                                            <small className='text-muted'>{d.Address}, {d.District},{d.State}</small>
                                                        </> :
                                                        (d.Pincode === "" || d.Pincode === "0") && d.Address === "" ?
                                                            <>
                                                                <small className='text-muted'>{d.HouseNo}, {d.District},{d.State}</small>
                                                            </> :
                                                            d.Address === "" && d.HouseNo === "" ?
                                                                <>
                                                                    <small className='text-muted'>{d.District},{d.State} &ndash; {d.Pincode}</small>
                                                                </> :
                                                                (d.Pincode === "" || d.Pincode === "0") ?
                                                                    <>
                                                                        <small className='text-muted'>{d.HouseNo}/{d.Address}, {d.District},{d.State}</small>
                                                                    </> :
                                                                    d.Address === "" ?
                                                                        <>
                                                                            <small className='text-muted'>{d.HouseNo}, {d.District},{d.State} &ndash; {d.Pincode}</small>
                                                                        </> :
                                                                        d.HouseNo === "" ?
                                                                            <>
                                                                                <small className='text-muted'>{d.Address}, {d.District},{d.State} &ndash; {d.Pincode}</small>
                                                                            </> :
                                                                            <>
                                                                                <small className='text-muted'>{d.HouseNo}/{d.Address}, {d.District},{d.State} &ndash; {d.Pincode}</small>
                                                                            </>
                                                :
                                                null
                                            )
                                        }
                                    </>
                            }
                        </Col>
                    </Row>
                    <Row className='pb-3 pt-3'>
                        <Col md={12}>
                            <span className={classes.textDark}><b className='ft18'>Time Slot</b></span><br />
                            <small className='text-muted'>{props.DateTime}</small>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
});

export default Order