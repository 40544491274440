import React, { Fragment, useState, useEffect, useRef } from 'react'
import CallToolTip from '../UI/ToolTips/CallToolTip';
import Breadcrumb from '../commonComponent/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import EditToolTip from '../UI/ToolTips/EditToolTip';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import ActiveToolTip from '../UI/ToolTips/ActiveToolTip';
import InactiveToolTip from '../UI/ToolTips/InactiveToolTip';

import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import { useFormik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import ConnectionInstance from '../apisettings/ConnectionInstance';
import { useHistory } from 'react-router';
let buttonclickStatus = false;
let GlobalPageNo=1;
let GlobalPageSizeNo=10;
const Notifications = () => {
    const searchValue = useRef();
    const[CCLoading,SetCCLoading]=useState(false);
    const navigate = useHistory();
    const [ModalBodyHtml, SetModalBodyHtml] = useState("");
    const [ErrorPopUp, setErrorPopUp] = useState(0);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [UserTypeId, setUserTypeId] = useState(localStorage.UserType == undefined ? "" : localStorage.UserType);
    const [CallExtensionId, setCallExtensionId] = useState(localStorage.ExtenNo == undefined ? "" : localStorage.ExtenNo);
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsInboundnotifiCallDlists: []

    });
    const [errorModal, setErrorModal] = useState(false);
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
    }
    
    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            asyncFunBindInboundnotifcation(page, sizePerPage);
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            asyncFunBindInboundnotifcation(page, sizePerPage);
        }
    };
    const CallQueueFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
       <span className='grdH3'><strong>Queue: {(rowData.FlowName!="")?(rowData.FlowName):'N/A'}</strong></span><br/>
       <span className='grdH3'>IVR: {(rowData.IVR!="")?(rowData.IVR):'N/A'}</span><br/>
       <span className='grdH3'>FLOW: {(rowData.Flow!="")?(rowData.Flow):'N/A'}</span><br/>
      </p>
        </div>
        </React.Fragment>
    }
    const CallDurationFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
     <span className='grdH3'><strong>Call Duration: {(rowData.CallDuration!="")?(rowData.CallDuration):'N/A'}</strong></span><br/>
     <span className='grdH3'>Call Started At: {(rowData.CallStartedAt!="")?(rowData.CallStartedAt):'N/A'}</span><br/>
     <span className='grdH3'>Call Ended At: {(rowData.AddedOn!="")?(rowData.AddedOn):''}</span>
      </p>
        </div>
        </React.Fragment>
    }

    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        //console.log(cellData, rowData);
        return <React.Fragment>

            {<CallToolTip title="Read Notification" placement="top" id={`call${parseInt(rowData.call_id)+'pR'+rowIndex+1}`} onClick={() =>
               AddCallHandler(rowData.call_id,rowData.callfrom,rowData.callto)} />}

        </React.Fragment>
    }




    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
          let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
          return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => {
            return { width: '6%'};
            }
        //sort: true,
        // formatter: idFormatter,
        // onSort: (field, order) => {
        //     console.log(field, order);
        // }
    },
    {
        dataField: 'callfrom',
        text: 'Customer Mobile'
    },
    {
        dataField: 'CustomerName',
        text: 'Customer Name'
    } 
    ,{
        text: 'Call Queue',
        formatter: CallQueueFormatter,
        editable: false 
    },
    {
        text: 'Call Detail',
        formatter: CallDurationFormatter,
        editable: false 
    },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        formatter: actionFormatter

    }
    ];


    const AddCallHandler = async (Call_id,callfrom,callto) => {
        //    localStorage.removeItem('Call_id');
        //    localStorage.setItem('Call_id', Call_id);
           try {
   
               let url=ConnectionInstance+ 'InboundCall/InboundCallAddFirst';
   
               let options = {
                   method: 'POST',
                   url: url,
                   headers: {
                       'Accept': 'application/json',
                       'Content-Type': 'application/json;charset=UTF-8'
                   },
                 data:{Call_id: `${Call_id}`,Callfrom:`${callfrom}`,Callto:`${callto}`,  AddedBy: `${LoginId}`}
               };
   
               let response = await axios(options);
                   let responseOK = response && response.status == 200;
                   if (responseOK) {
                       let data = response.data;
                       // let data = await response.data;
                       if(data.Success==true && data.Data!="-1")
                       { 
                        localStorage.removeItem('CallingId');
                        localStorage.setItem('CallingId', data.Data);
                           setModal(false);
                           setErrorModal(false);
                           navigate.push(`/inbound/addInboundcalls`);
                       }
                       else{
                            if(data.ErrorList!=null && data.ErrorList.length>0)
                            {
                               let Errtext=<p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                               SetModalBodyHtml(Errtext);
                                setModal(!modal);
                                setErrorModal(true);
                            }
   
                       }
   
   
                   }
                   else{
                       console.log('no record found');
   
                   }
                // return data;
               } catch (error) {
                   console.log(error.message);
                   let Errtext="";
                       Errtext =<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                   SetModalBodyHtml(Errtext);
                   setModal(!modal);
                   setErrorModal(true);
                }
          
       }
   
    useEffect(() => {
     asyncFunBindInboundnotifcation(1, 10);    
     }, []);
    useEffect(() => {
        let mounted = true;
       // asyncFunBindInboundnotifcation(1, 10);
        const id5 = setInterval(() => {
         
                asyncFunBindInboundnotifcation(GlobalPageNo,GlobalPageSizeNo);
                console.log('Inboundnotifcation'); 
          
        },  25* 1000);
        return () => clearInterval(id5);

    }, []);
 
    const searchHandler = () => { 
        asyncFunBindInboundnotifcation(1, 10); }
    async function asyncFunBindInboundnotifcation(PageNo, PageSize) {
       
            try {
                SetCCLoading(true);
                let url=ConnectionInstance+ 'YeastarAPI/GetYesStarNotifcationDetailByExtDetail';
                    let options = {
                        method: 'POST',
                        url: url,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json;charset=UTF-8'
                        },
                        data: { CallExtensionId: `${CallExtensionId}`,PageNo: `${PageNo}`, PageSize: `${PageSize}`,SearchTxt:`${searchValue.current.value}` }
                    };
                    let response = await axios(options);
                    let responseOK = response && response.status == 200;
                    if (responseOK) {
                        let data = response.data;
                        SetCCLoading(false);
                        // let data = await response.data;
                        if (data.Success == true) {
                            let ds = data.Data;
                            if (ds != null) {
                                GlobalPageNo=PageNo;
                              GlobalPageSizeNo=PageSize;
                                await SetPageHelper({
                                    ...PageHelper,
                                    PageNo:Number(PageNo),
                                    PageSize: Number(PageSize),
                                    TotalRecord: Number(ds.TotalRecord),
                                    dsInboundnotifiCallDlists: ds.objYeastarAPINotificationsList == null ? [] : ds.objYeastarAPINotificationsList
                                });
        
        
                            }
                        }
                        else {
                            SetCCLoading(false);
                            let Errtext = <p><div className='text-required'> no record found </div></p>;
                            setErrorPopUp(Errtext);
                            setErrorModal(true);
                            console.log('no record found');
                        }
        
                    }
                    else {
                        SetCCLoading(false);
                        let Errtext = <p><div className='text-required'> no record found </div></p>;
                        setErrorPopUp(Errtext);
                        setErrorModal(true);
                        console.log('no record found');
                    }
                    // return data;
                } catch (error) {
                    SetCCLoading(false);
                    console.log(error);
                    let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                    setErrorPopUp(Errtext);
                    setErrorModal(true);
                    //alert(error);
                }
        }

    

    return (
        <>
         <div id="cover-spin"  style={{display: CCLoading ? 'block' : 'none'}}></div> 
            <Breadcrumb title="Answered Calls" parent="Inbound Calls" />
            <Container fluid>
            <Row>
                    <Col md={12}>
                        <Card>
                          
                            <Card.Body>
                                    <Row className='justify-content-center mt15'>
                                <Col md={5}>
                                    <Form>
                                        <div className="inner-addon right-addon">
                                            <i className="icon fa fa-search"></i>
                                            <Form.Group className="form-group">
                                                <Form.Control type="text" className='searchControl' ref={searchValue}   placeholder="Search customer contact no., IVR, FlOW " />
                                            </Form.Group>
                                        </div>
                                    </Form>
                                </Col>
                                <Col md={2}>
                                <Button variant="primary" type="submit" className='btn-block' onClick={searchHandler} > Search</Button>                                            
                                </Col>
                            </Row>      
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Answered Calls</Card.Title>
                            </Card.Header>
                            <Card.Body>

                                <DataTables
                                    keyField='Call_id'
                                    tableData={PageHelper.dsInboundnotifiCallDlists}
                                    columns={columns}
                                    pagination={paginationFactory(paginationOptions)}
                                />

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                
            </Container>

            {
                errorModal === true ?
                    (<Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                            {/* {ModalBodyHtml===null||ModalBodyHtml===''?
                        
                        (<><p>{formik.touched.sourceCategory && formik.errors.sourceCategory ? (
                            <div className='text-required'>{formik.errors.sourceCategory}</div>
                        ) : null}</p>  </> ):ModalBodyHtml }             */}
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>

                    </Modal>)
                    : null
            }
        </>
    )
}

export default Notifications;