import React, { useState, useRef, useEffect } from 'react';
import Breadcrumb from '../commonComponent/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import ConnectionInstance from '../apisettings/ConnectionInstance';
import DataTable from '../commonComponent/DataTable/DataTable';
import paginationFactory from 'react-bootstrap-table2-paginator';


const ShowAssignedRoute = () => {
  const [crntPageNO, setCrntPageNO] = useState(0);
  const [Users, setUsers] = useState([]);
  const [Location, SetLocation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [TableData, setTableData] = useState([]);
  const [SelectDS, setSelectDS] = useState(null);
  let DateId = useRef();
  let frmtime = useRef();
  let ToTime = useRef();
  let Locid = useRef();
  let userId = useRef();
  let routename = useRef();

  useEffect(() => {
    const pageAccessedByReload = (
      (window.performance.navigation && window.performance.navigation.type === 1) ||
      window.performance
        .getEntriesByType('navigation')
        .map((nav) => nav.type)
        .includes('reload')
    );
    if (pageAccessedByReload === true) {
      localStorage.removeItem("RouteDate");
      localStorage.removeItem("RouteStartTime");
      localStorage.removeItem("RouteEndTime");
      localStorage.removeItem("LocationId");
      localStorage.removeItem("PhleboId");
      localStorage.removeItem("RouteName");
      localStorage.removeItem("AppId");
    }
    if (localStorage.getItem("RouteDate") !== null) {
      const fdate = localStorage.getItem("RouteDate");
      const date = new Date(fdate);
      const finalyear = date.getFullYear();
      const finalmonth = ((date.getMonth() + 1).toString().length != 2 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1))
      const finaldate = (date.getDate().toString().length != 2 ? "0" + date.getDate() : date.getDate())
      const defaultValue = finalyear + "-" + finalmonth + "-" + finaldate;
      DateId.current.value = defaultValue;
      frmtime.current.value = localStorage.getItem("RouteStartTime");
      ToTime.current.value = localStorage.getItem("RouteEndTime");
      routename.current.value = localStorage.getItem("RouteName");
      //Locid.current.value= localStorage.getItem("LocationId");
      BindExistingusers();
    }
    return () => {
      localStorage.removeItem("RouteDate");
      localStorage.removeItem("RouteStartTime");
      localStorage.removeItem("RouteEndTime");
      localStorage.removeItem("LocationId");
      localStorage.removeItem("PhleboId");
      localStorage.removeItem("RouteName");
      localStorage.removeItem("AppId");
    }
  }, [])
  const paginationOptions = {
    sizePerPage: 10,
    showTotal: false,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
  };
  const idFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      <div>
        <span>{crntPageNO + rowIndex + 1}</span>
      </div>
    </React.Fragment>
  }
  const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      {rowData.AppointmentDate != "" && rowData.AppointmentTime != "" ? rowData.AppointmentDate + ' | ' + rowData.AppointmentTime : rowData.AppointmentDate}
    </React.Fragment>
  }
  const actionnewFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      {rowData.TotalPatients + ' | ' + '( ' + rowData.Age + ' )'}
    </React.Fragment>
  }
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: (rowData, isSelect, rowIndex, e) => {
      let fiterArr;
      const AutoCallRow = {
        'AppointmentId': rowData.AppointmentId
      }
      if (isSelect) {
        if (SelectDS != null && SelectDS.length > 0) {
          let fiter = SelectDS.filter((item) => {

            return item.callingid != rowData.callingid
          });
          if (fiter.length == 0) {
            SelectDS.push(AutoCallRow);
          }
        }
        else {
          setSelectDS([AutoCallRow]);
        }
      }
      else {
        if (SelectDS != null && SelectDS.length > 0) {
          fiterArr = SelectDS.filter((item) => {
            return item.CallingId != rowData.CallingId
          });
        }
        setSelectDS(fiterArr);
      }
    },
    onSelectAll: (isSelect, rowData, e) => {
      setSelectDS(null);
      const AutoCallListSelectedAll = [];
      if (isSelect) {
        if (rowData.length > 0) {
          for (let i = 0; i < rowData.length; i++) {
            const AutoCallRow = {
              'AppointmentId': rowData[i].AppointmentId,
            }
            AutoCallListSelectedAll.push(AutoCallRow);
          }
          setSelectDS(AutoCallListSelectedAll);
        }
      }
    }
  };
  const columns = [
    {
      dataField: '#',
      text: '#',
      formatter: idFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '9%' };
      }
    },
    {
      dataField: 'CustomerName',
      text: 'Customer Name'
    },
    {
      dataField: 'CustomerMobile',
      text: 'Customer Mobile'
    },
    {
      dataField: 'SourceCategory',
      text: 'Source Category'
    },
    {
      dataField: 'AppointmentTime',
      text: 'Appointment Date',
      formatter: actionFormatter
    },
    {
      dataField: 'TotalPatients',
      text: 'Total Patients | Age',
      formatter: actionnewFormatter
    },
    {
      dataField: 'StatusName',
      text: 'Status'
    }
  ];
  const Bindusers = async (Date) => {
    try {
      setIsLoading(true);
      let url = ConnectionInstance + 'cart/GetPhleboUsers/1/' + Date
      await axios.get(url).then((response) => {
        const data = response.data;
        setIsLoading(false);
        if (data.Success) {
          if (data.Data !== null) {
            if (data.Data.objPhlebolocation === null) { SetLocation([]); setTableData([]); }
            else { setTableData([]); SetLocation(data.Data.objPhlebolocation.map((d) => (<option key={d.Id} value={d.Id}>{d.DistrictName}</option>))); }
            if (data.Data.objPhlebousers === null) { setUsers([]); }
            else { setUsers(data.Data.objPhlebousers.map((d) => (<option key={d.EmployeeId} value={d.EmployeeId}>{d.EmployeeName}</option>))); }
          }
          else { setUsers([]); SetLocation([]); }
        }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
      }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    } catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
  }
  const BindExistingusers = async () => {
    try {
      setIsLoading(true);
      let url = ConnectionInstance + 'cart/GetPhleboUsers/2/' + DateId.current.value + '/' + Locid.current.value
      await axios.get(url).then((response) => {
        const data = response.data;
        setIsLoading(false);
        if (data.Success) {
          if (data.Data !== null) {
            if (data.Data.objPhlebolocation === null) { SetLocation([]); setTableData([]); }
            else {
              setTableData([]);
              var glbllocation = [];
              for (var key in data.Data.objPhlebolocation) {
                if (data.Data.objPhlebolocation[key].Id === localStorage.getItem("LocationId")) {
                  glbllocation.push(<option key={data.Data.objPhlebolocation[key].Id} value={data.Data.objPhlebolocation[key].Id} selected='true'>{data.Data.objPhlebolocation[key].DistrictName}</option>)
                }
                else {
                  glbllocation.push(<option key={data.Data.objPhlebolocation[key].Id} value={data.Data.objPhlebolocation[key].Id}>{data.Data.objPhlebolocation[key].DistrictName}</option>)
                }
              }
              SetLocation(glbllocation);
              if (Locid.current.value !== "0") {
                BindExistingusersLocation();
              }
            }
          }
          else { SetLocation([]); }
        }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
      }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    } catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
  }
  const BindExistingusersLocation = async () => {
    try {
      setIsLoading(true);
      let url = ConnectionInstance + 'cart/GetPhleboUsers/2/' + DateId.current.value + '/' + Locid.current.value
      await axios.get(url).then((response) => {
        const data = response.data;
        setIsLoading(false);
        if (data.Success) {
          if (data.Data !== null) {
            if (data.Data.objPhlebousers === null) { setUsers([]); }
            else {
              var glblusers = [];
              for (var key in data.Data.objPhlebousers) {
                if (data.Data.objPhlebousers[key].EmployeeId === localStorage.getItem("PhleboId")) {
                  glblusers.push(<option key={data.Data.objPhlebousers[key].EmployeeId} value={data.Data.objPhlebousers[key].EmployeeId} selected='true'>{data.Data.objPhlebousers[key].EmployeeName}</option>)
                }
                else {
                  glblusers.push(<option key={data.Data.objPhlebousers[key].EmployeeId} value={data.Data.objPhlebousers[key].EmployeeId}>{data.Data.objPhlebousers[key].EmployeeName}</option>)
                }
              }
              setUsers(glblusers);
            }
            BindGrid(DateId.current.value, Locid.current.value, frmtime.current.value, ToTime.current.value, "2");
          }
          else { setUsers([]); }
        }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
      }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    } catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
  }
  const handleSearch = async () => {
    Bindusers(DateId.current.value);
  }
  const handleSearchList = async () => {
    if (frmtime.current.value !== "" && ToTime.current.value !== "") { BindGrid(DateId.current.value, Locid.current.value, frmtime.current.value, ToTime.current.value, "1"); }
    else { BindGrid(DateId.current.value, Locid.current.value, "00:00:00", "00:00:00", "1"); }
  }
  const BindGrid = async (Date, Location, FromTime, ToTime, Flag) => {
    try {
      setIsLoading(true);
      let url = ConnectionInstance + 'cart/GetPhleboDetails/' + Date + '/' + Location + '/' + FromTime + '/' + ToTime + "/" + Flag
      await axios.get(url).then((response) => {
        const data = response.data;
        if (data.Success) {
          if (data.Data === null || data.Data.length === 0) {
            setTableData([]);
            userId.current.value = "0";
            Bindusers(DateId.current.value);
          }
          else {
            if (Flag === "1") { setTableData(data.Data); }
            else { setTableData(data.Data.filter((d) => d.AppointmentId === localStorage.getItem("AppId"))); }
          }
          setIsLoading(false);
        }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
      }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    } catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
  }
  const ResetHandler = async () => {
    handleSearchList();
  }
  const APIError = async (ErrorCode, ErrorMsg) => {
    if (ErrorCode === "-1") { SweetAlert.fire({ title: "Duplicate", text: "User already exist", icon: "error" }) }
    else if (ErrorCode === "0") { SweetAlert.fire({ title: "Location not found", text: "Please select location", icon: "error" }) }
    else if (ErrorCode === "-1997") { SweetAlert.fire({ title: "Empty", text: "No record found", icon: "error" }) }
    else if (ErrorCode === "-1998") { SweetAlert.fire({ title: "Some value is missing", text: "Check server and db logs", icon: "error" }) }
    else if (ErrorCode === "-1999") { SweetAlert.fire({ title: "Runtime Exception", text: ErrorMsg, icon: "error" }) }
    else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
  }
  const ErrorFunction = async (ErrorCode) => {
    if (ErrorCode == "400")
      SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
    else if (ErrorCode == "401")
      SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
    else if (ErrorCode == "403")
      SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
    else if (ErrorCode == "404")
      SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
    else if (ErrorCode == "500")
      SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
    else if (ErrorCode == "503")
      SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
  }

  return (
    <>
      <Breadcrumb title="Assign Route" parent="Phlebo" />
      <Container fluid>
        {
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body>
                  {
                    localStorage.getItem("RouteDate") === null ?
                      <>
                        <Form.Row>
                          <Col md={3}>
                            <Form.Group>
                              <Form.Label>Date</Form.Label>
                              <Form.Control type="date" name="date" ref={DateId} onChange={handleSearch} disabled />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group>
                              <Form.Label>Location</Form.Label>
                              <Form.Control as="select" id="Locid" ref={Locid} onChange={handleSearchList} disabled>
                                <option value='0'>---Select---</option>
                                {Location}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group>
                              <Form.Label>From Time</Form.Label>
                              <Form.Control type="Time" ref={frmtime} onChange={ResetHandler} disabled />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group>
                              <Form.Label>To Time</Form.Label>
                              <Form.Control type="Time" ref={ToTime} onChange={ResetHandler} disabled />
                            </Form.Group>
                          </Col>
                        </Form.Row>
                      </> :
                      <>
                        <Form.Row>
                          <Col md={4}>
                            <Form.Group>
                              <Form.Label>Date</Form.Label>
                              <Form.Control type="date" name="date" ref={DateId} onChange={handleSearch} disabled />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group>
                              <Form.Label>Location</Form.Label>
                              <Form.Control as="select" id="Locid" ref={Locid} onChange={handleSearchList} disabled>
                                <option value='0'>---Select---</option>
                                {Location}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group>
                              <Form.Label>Phlebo</Form.Label>
                              <Form.Control as="select" id="userid" ref={userId} disabled>
                                <option value='0'>---Select---</option>
                                {Users}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group>
                              <Form.Label>Route Name</Form.Label>
                              <Form.Control type="text" ref={routename} disabled>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group>
                              <Form.Label>From Time</Form.Label>
                              <Form.Control type="Time" ref={frmtime} onChange={ResetHandler} disabled />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group>
                              <Form.Label>To Time</Form.Label>
                              <Form.Control type="Time" ref={ToTime} onChange={ResetHandler} disabled />
                            </Form.Group>
                          </Col>
                        </Form.Row>
                      </>
                  }
                  <div className="mt15">
                    {
                      localStorage.getItem("RouteDate") === null ?
                        <>
                          <DataTable
                            keyField='AppointmentId'
                            tableData={TableData}
                            columns={columns}
                            pagination={paginationFactory(paginationOptions)}
                            selectRow={selectRow} />
                        </> :
                        <>
                          <DataTable
                            keyField='AppointmentId'
                            tableData={TableData}
                            columns={columns}
                            pagination={paginationFactory(paginationOptions)}
                          />
                        </>
                    }
                  </div>
                  {
                    TableData.length > 0 ?
                      <>
                        {
                          localStorage.getItem("RouteDate") === null ?
                            <>
                              <Row className='mt-5'>
                                <Col md={6}>
                                  <Form.Group>
                                    <Form.Label>Route Name</Form.Label>
                                    <Form.Control type="text" ref={routename}>
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group>
                                    <Form.Label>Phlebo</Form.Label>
                                    <Form.Control as="select" id="userid" ref={userId}  >
                                      <option value='0'>---Select---</option>
                                      {Users}
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                              </Row>                            
                            </>
                            :
                            null
                        }
                      </> : null
                  }
                </Card.Body>
              </Card>
            </Col>
          </Row>
        }
      </Container>
    </>
  )
}

export default ShowAssignedRoute;