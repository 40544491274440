import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import React, { Fragment, useState, useEffect, useRef } from 'react'
import CallToolTip from '../UI/ToolTips/CallToolTip';
import Breadcrumb from '../commonComponent/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import InputGroup from 'react-bootstrap/InputGroup';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import ActiveToolTip from '../UI/ToolTips/ActiveToolTip';
import InactiveToolTip from '../UI/ToolTips/InactiveToolTip';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import { useFormik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import ConnectionInstance from '../apisettings/ConnectionInstance';
import { useHistory } from 'react-router';
import ViewToolTip from '../UI/ToolTips/ViewToolTip';
import moment from 'moment';
let buttonclickStatus = false;
let GlobalPageNo=1;
let GlobalPageSizeNo=10;
const Calldata = () => {
    let fromDate = useRef();
    const[CCLoading,SetCCLoading]=useState(false);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const[ExcutiveSelectionIds,SetExcutiveSelectionIds]=useState("");
    const[FilterBy,SetFilterBy]=useState("0");
    let toDate = useRef();
    const today = new Date();
    const date = today.setDate(today.getDate());
    const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
    const [defaultDate, setDefaultDate] = useState(defaultValue);
    const searchValue = useRef();
    const [CallCentreExecutiveList, setCallCentreExecutiveList] =useState(0);
    const [CallstatusDLL, SetCallstatusDLL] = useState(0);
    const [LeadSourceDDL, SetLeadSourceDDL] = useState(0);
     const[CallStatusIds,SetCallStatusIds]=useState("");
     const[CallSourceIds,SetCallSourceIds]=useState("");
     const[TeamSelectionIds,SetTeamSelectionIds]=useState("");
     const [LeadServiceDDL, SetLeadServiceDDL] = useState(0);
      const[CallServiceIds,SetCallServiceIds]=useState("");

     const[CallHistoryDatalistCSV,SetCallHistoryDatalistCSV]=useState([]);
    const navigate = useHistory();
    const [ModalBodyHtml, SetModalBodyHtml] = useState("");
    const [ErrorPopUp, setErrorPopUp] = useState(0);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [UserTypeId, setUserTypeId] = useState(localStorage.UserType == undefined ? "" : localStorage.UserType);
    const [CallExtensionId, setCallExtensionId] = useState(localStorage.ExtenNo == undefined ? "" : localStorage.ExtenNo);
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsInboundnotifiCallDlists: []

    });
    const [errorModal, setErrorModal] = useState(false);
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
    }
    
   

       useEffect(() => {
        asyncFunCallExecutiveDDL();
        asyncFuncCallStatusDDL();
        FuncLeadServiceDDL();
       FuncLeadSourceDDL();
       asyncFunBindCallDataEARCH(1,10);
    }, []);
    
    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            asyncFunBindCallDataEARCH(page, sizePerPage);
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            asyncFunBindCallDataEARCH(page, sizePerPage);
        }
    };
    const ActiveDateFormatter = (cellData, rowData, rowIndex, extraData) => {
        //console.log(cellData, rowData);
        return <React.Fragment>
             <div>
                 
     <p>
     <span className='grdH3'><strong>Executive: {(rowData.ExecutiveName!="")?(rowData.ExecutiveName):'N/A'}</strong></span><br/>
     <span className='grdH3'>Activity Date: {(rowData.CallUpdatedDate!="")?(rowData.CallUpdatedDate):'N/A'}</span><br/>
        <span className='grdH3'>Follow Up: {(rowData.FollowUpDate!="" && rowData.FollowUpTime!="")?(rowData.FollowUpDate +' | '+ rowData.FollowUpTime):'N/A'}</span><br/>
      </p>
        </div>
    
 
        
                   {/* {<input type="checkbox" style={{ marginLeft: 90 }} checked={cellData} ></input>} */}
          

        </React.Fragment>
    }
    const LatestActiveFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
     <span className='grdH3'><strong>Team: {(rowData.TeamName!="")?(rowData.TeamName):'N/A'}</strong></span><br/>
     <span className='grdH3'>Call Service: {(rowData.CallService!="")?(rowData.CallService):'N/A'}</span><br/>
        <span className='grdH3'>Call Source: {(rowData.CallSource!="")?(rowData.CallSource):'N/A'}</span><br/>
       <span className='grdH3'>Call Status: {rowData.CallStatus!=""?rowData.CallStatus:'N/A'}</span><br/>
      </p>
        </div>
        </React.Fragment>
    } 
     const CallQueueFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
       <span className='grdH3'><strong>Queue: {(rowData.FlowName!="")?(rowData.FlowName):'N/A'}</strong></span><br/>
       <span className='grdH3'>IVR: {(rowData.IVR!="")?(rowData.IVR):'N/A'}</span><br/>
       <span className='grdH3'>FLOW: {(rowData.FLOW!="")?(rowData.FLOW):'N/A'}</span><br/>
      </p>
        </div>
        </React.Fragment>
    }
    const CallTypeFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
       <span className='grdH3'><strong>{(rowData.CallModuleName!="")?(rowData.CallModuleName):'N/A'}</strong></span><br/>
        <span className='grdH3'>{(rowData.CallType!="")?(rowData.CallType):'N/A'}</span><br/>
      </p>
        </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        //console.log(cellData, rowData);
        return <React.Fragment>

<p>
     <span className='grdH3'>StartedAt:{(rowData.CallStartedAt!="")?(rowData.CallStartedAt):'N/A'}</span><br/>
     <span className='grdH3'>Duration: {(rowData.CallDuration!="")?(rowData.CallDuration):'N/A'}</span><br/>
      </p>
        </React.Fragment>
    }

    const ViewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
        <div >
            <ViewToolTip title="View" onClick={()=>viewcallHandler(rowData.CallingId)} />   
        </div>
    </React.Fragment>
    }



    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
          let rowNumber = (PageHelper.PageNo - 1) * 10 + (rowIndex + 1);
          return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => {
            return { width: '6%'};
            }
        //sort: true,
        // formatter: idFormatter,
        // onSort: (field, order) => {
        //     console.log(field, order);
        // }
    },
    {
        dataField: 'CustomerName',
        text: 'Customer Name'
    },
    {
        dataField: 'CustomerMobile',
        text: 'Customer Mobile'
    },
    {
        isDummyField: true,
        text: 'Lastest Activity',
        formatter: LatestActiveFormatter,
        editable: false 
    },
    // {
    //     dataField: 'MSourceName',
    //     text: 'Source'
    // },
    ,
    {
        text: 'Call Queue',
        formatter: CallQueueFormatter,
        editable: false 
    },
    {
        text: 'Call Type',
        formatter: CallTypeFormatter,
        editable: false 
    },
    
    {
        isDummyField: true,
        text: 'Date',
        formatter: ActiveDateFormatter,
        editable: false 
    },
    {
        isDummyField: true,
        text: 'Call Time',
        formatter: actionFormatter

    },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        formatter: ViewFormatter

    }
    ];
    const viewcallHandler=async (CallingId) => {
       
        localStorage.removeItem('CallingId');
        localStorage.setItem('CallingId', CallingId);
        navigate.push(`/searchdb/viewcalldatadetail`);
 }
    const AddCallHandler = async (callingid) => {
        localStorage.removeItem('CallingId');
        localStorage.setItem('CallingId', callingid);
        // dispatch({
        //     type: actiontype.AddAssignClick, payload: localStorage.CallingId == undefined ? "" : localStorage.CallingId 
        // }
        // ,{
        //     type: actiontype.UpdateUserId, payload: localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser
        // }
        // ,{
        //     type: actiontype.UpdateUName, payload: localStorage.UName == undefined ? "" : localStorage.UName
        // }
        // ,{
        //     type: actiontype.UpdateUserTypeName, payload: localStorage.UserTypeName == undefined ? "" : localStorage.UserTypeName
        // }
        // ,{
        //     type: actiontype.UpdateUserType, payload: localStorage.UserType == undefined ? "" : localStorage.UserType
        // }
        // ,{
        //     type: actiontype.UpdatecurrentUser, payload: localStorage.currentUser == undefined ? "" : localStorage.currentUser
        // }
        // ,{
        //     type: actiontype.UpdateSession, payload: localStorage.Session == undefined ? "" : localStorage.Session
        // }
        // );
    }
    const DownloadHandler = () => { 
        if ((new Date(fromDate.current.value) > new Date(toDate.current.value)) || (new Date(toDate.current.value) < new Date(fromDate.current.value))) {

            SweetAlert.fire({ title: "Wrong Dates Selection", text: "From date always less than or equal to todate.", icon: 'warning' });
        }
        else {
            asyncFunBindCallDataDownLoad(1,10);
        }
        }
        const searchHandler = () => { 
            if ((new Date(fromDate.current.value) > new Date(toDate.current.value)) || (new Date(toDate.current.value) < new Date(fromDate.current.value))) {
    
                SweetAlert.fire({ title: "Wrong Dates Selection", text: "From date always less than or equal to todate.", icon: 'warning' });
            }
            else {
                asyncFunBindCallDataEARCH(1,10);
            }
            }

        async function asyncFunBindCallDataEARCH(PageNo,PageSize) {
          
            try {
                SetCCLoading(true);
                let url=ConnectionInstance+ 'reports/GetBindCallData';
                    let options = {
                        method: 'POST',
                        url: url,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json;charset=UTF-8'
                        },
                        data: { LeadServiceId:`${CallServiceIds}`,LeadSourceId:`${CallSourceIds}`,CallStatusId: `${CallStatusIds}`, FromDate: `${fromDate.current.value}`,ToDate:`${toDate.current.value}` ,SearchTxt:`${searchValue.current.value}`,
                         ExecutiveId:`${ExcutiveSelectionIds=="-1"?"0":ExcutiveSelectionIds}`,
                        PageNo: `${PageNo}`, PageSize: `${PageSize}`,IsRptDownloadStatus:`${"0"}` }
                    };
                    let response = await axios(options);
                    let responseOK = response && response.status == 200;
                    if (responseOK) {
                        let data = response.data;
        
                        // let data = await response.data;
                        if (data.Success == true) {
                            let ds = data.Data;
                            if (ds != null && ds!="-11") {
                                SetCCLoading(false);
                                setDownloadVisible(false);
                                await SetPageHelper({
                                    ...PageHelper,
                                    PageNo:Number(PageNo),
                                    PageSize: Number(PageSize),
                                    TotalRecord: Number(ds.TotalRecord),
                                    dsInboundnotifiCallDlists: ds.bindCallHistoryReportDataList == null ? [] : ds.bindCallHistoryReportDataList
                                });
        
                            }
                             else if(ds=="-11")
                             {
                                SetCCLoading(false);
                                setDownloadVisible(false);
                                await SetPageHelper({
                                    ...PageHelper,
                                    PageNo:Number(PageNo),
                                    PageSize: Number(PageSize),
                                    TotalRecord: Number(ds.TotalRecord),
                                    dsInboundnotifiCallDlists:[]
                                });
                             }
                            else {
                                SetCCLoading(false);
                                 setDownloadVisible(false);
                                await SetPageHelper({
                                    ...PageHelper,
                                    PageNo:Number(PageNo),
                                    PageSize: Number(PageSize),
                                    TotalRecord: Number(ds.TotalRecord),
                                    dsInboundnotifiCallDlists:[]
                                });
                            }
                            SetCCLoading(false);
                        }
                        else {
                            SetCCLoading(false);
                        }
                        SetCCLoading(false);
                    }
                    else {
                        SetCCLoading(false);
                    }
                    // return data;
                } catch (error) {
                    SetCCLoading(false);
                    SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
                }
        }
    async function  asyncFunBindCallDataDownLoad(PageNo,PageSize) {
       
            try {
         
                SetCCLoading(true);
                let url=ConnectionInstance+ 'reports/GetBindCallDataDownload';
                    let options = {
                        method: 'POST',
                        url: url,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json;charset=UTF-8'
                        },
                        data: { LeadServiceId:`${CallServiceIds}`,LeadSourceId:`${CallSourceIds}`,CallStatusId: `${CallStatusIds}`, FromDate: `${fromDate.current.value}`,ToDate:`${toDate.current.value}` ,SearchTxt:`${searchValue.current.value}`,
                         ExecutiveId:`${ExcutiveSelectionIds=="-1"?"0":ExcutiveSelectionIds}`,
                        PageNo: `${PageNo}`, PageSize: `${PageSize}`,IsRptDownloadStatus:`${"1"}` }
                    };
                    let response = await axios(options);
                    let responseOK = response && response.status == 200;
                    if (responseOK) {
                        let data = response.data;
        
                        // let data = await response.data;
                        if (data.Success == true) {
                            let ds = data.Data;
                            if (ds != null && ds.bindCallHistoryReportList!=null && ds.bindCallHistoryReportList.length>0 && ds!="-11") {
                                setDownloadVisible(true);
                                SetCallHistoryDatalistCSV(ds.bindCallHistoryReportList);
                              
                                document.getElementById("csvdownload").click();
        
                            }
                             else if(ds=="-11")
                             {   SetCCLoading(false);
                                setDownloadVisible(false);
                                SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                             }
                            else { setDownloadVisible(false);
                                SetCCLoading(false);
                                SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                            }
                            SetCCLoading(false);
                        }
                        else {
                            SetCCLoading(false);
                            SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                            
                        }
                        SetCCLoading(false);
        
                    }
                    else {
                        SetCCLoading(false);
                        SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                    }
                    // return data;
                } catch (error) {
                    SetCCLoading(false);
                    SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
                }
        }


        const CallStatusHandler = (e) => {
            const val = e.target.value;
            SetCallStatusIds(val);
        }
        const TeamSelectionHandler = (e) => {
            const val = e.target.value;
            SetTeamSelectionIds(val);
           
        }
    
        async function asyncFuncCallStatusDDL() {
            try {
    
                let url = ConnectionInstance + 'master/GetCallStatusDDList';
                let options = {
                    method: 'GET',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    data: {}
                };
                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if (data.Success == true) {
                        let ds = data.Data;
                        if (ds != null && ds.length > 0) {
                            SetCallstatusDLL(ds.map((option) => (<option value={option.CallStatusId}>{option.CallStatus}</option>)));
                        }
                    }
                    else {
                        let Errtext = <p><div className='text-required'> no record found </div></p>;
                        console.log('no record found');
                    }
    
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SetModalBodyHtml(Errtext);
                    setModal(!modal);
                    setErrorModal(true);
                    console.log('no record found');
                }
                // return data;
            } catch (error) {
                console.log(error.message);
                let Errtext = "";
                Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);
                //alert(error);
            }
        }
        const FuncLeadSourceDDL=async()=>{
    
            try {
        
                let url = ConnectionInstance + 'master/GetBindLeadSourceDDList';
                let options = {
                    method: 'GET',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    data: {}
                };
                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if (data.Success == true) {
                        let ds = data.Data;
                        if (ds != null && ds.length > 0) {
                            SetLeadSourceDDL(ds.map((option) => (<option value={option.SNO}>{option.Source}</option>)));
                        }
                    }
                    else {
                        let Errtext = <p><div className='text-required'> no record found </div></p>;
                        console.log('no record found');
                    }
        
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SetModalBodyHtml(Errtext);
                    setModal(!modal);
                    setErrorModal(true);
                    console.log('no record found');
                }
                // return data;
            } catch (error) {
                console.log(error.message);
                let Errtext = "";
                Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);
            }
         }  
           
         const FuncLeadServiceDDL=async()=>{
        
            try {
        
                let url = ConnectionInstance + 'master/GetBindLeadServiceDDList';
                let options = {
                    method: 'GET',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    data: {}
                };
                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if (data.Success == true) {
                        let ds = data.Data;
                        if (ds != null && ds.length > 0) {
                            SetLeadServiceDDL(ds.map((option) => (<option value={option.SNO}>{option.LeadService}</option>)));
                        }
                    }
                    else {
                        let Errtext = <p><div className='text-required'> no record found </div></p>;
                        console.log('no record found');
                    }
        
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SetModalBodyHtml(Errtext);
                    setModal(!modal);
                    setErrorModal(true);
                    console.log('no record found');
                }
                // return data;
            } catch (error) {
                console.log(error.message);
                let Errtext = "";
                Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);
            }
         }  
    const handleReportTypeRadioChange=async(e)=>{
        const val=e.target.value;
        SetFilterBy(val);
        SetExcutiveSelectionIds("-1");
        SetTeamSelectionIds("0");
      }

      const ExecutiveSelectionHandler = (e) => {
        const val = e.target.value;
        SetExcutiveSelectionIds(val);
       
    }
    async function  asyncFunCallExecutiveDDL() {
        try {
         
        let url=ConnectionInstance+ 'master/GetCallCentreExcutiveDDList';
        let options = {
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {}
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if(data.Success==true)
                { let ds=data.Data;
                    if(ds!=null && ds.length>0)
                    {
                        setCallCentreExecutiveList(ds.map((option) => (<option value={option.UserId}>{option.UserName}</option>)));                                         
                    }
                }
                else{
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                   setErrorPopUp(Errtext);
                   setErrorModal(true);
                    console.log('no record found');
                }
               
                //setUserData(data.map((option) =>(<option value={option.sNo}>{option.ename}</option>)));
                // do something with data
            }
            else{
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                setErrorPopUp(Errtext);
                setErrorModal(true);
                console.log('no record found');
            }
         // return data;
        } catch (error) {
            console.log(error);
            let Errtext=<p><div className='text-required'>{error} </div></p>;
            setErrorPopUp(Errtext);
            setErrorModal(true);
          //alert(error);
        }
      }
      const LeadSourceHandler = (e) => {
        const val = e.target.value;
        SetCallSourceIds(val);
       
    }
    const LeadServiceHandler = (e) => {
        const val = e.target.value;
        SetCallServiceIds(val);
    }
    return (
        <>
         <div id="cover-spin"  style={{display: CCLoading ? 'block' : 'none'}}></div> 
            <Breadcrumb title="Call Data" parent="SearchDB" />
            <Container fluid>
                
            <Row>
                    <Col md={12}>
                        <Card>
                          
                            <Card.Body>
                            <Form.Row className="justify-content-center">
                            <Col md={3}>
                                        <Form.Group>
                                                        <Form.Label>Call Service</Form.Label>
                                                        <Form.Control as="select" id="ldservice" name="ldservice"
                                                            onChange={LeadServiceHandler}
                                                            value={CallServiceIds}>
                                                            <option value='0'>---Select All---</option>
                                                            {LeadServiceDDL}
                                                        </Form.Control>
                                                    </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                        <Form.Group>
                                                        <Form.Label>Call Source</Form.Label>
                                                        <Form.Control as="select" id="lsource" name="lsource"
                                                            onChange={LeadSourceHandler}
                                                            value={CallSourceIds}>
                                                            <option value='0'>---Select All---</option>
                                                            {LeadSourceDDL}
                                                        </Form.Control>
                                                    </Form.Group>
                                        </Col>
                                        
                                        <Col md={3}>
                                        <Form.Group>
                                                        <Form.Label>Call Status</Form.Label>
                                                        <Form.Control as="select" id="CallStatus" name="CallStatus"
                                                            onChange={CallStatusHandler}
                                                             value={CallStatusIds}>
                                                            <option value='0'>---Select All---</option>
                                                            {CallstatusDLL}
                                                       </Form.Control>
                                                    </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="justify-content-center">
                                    <Col md={3}>
                                        <Form.Group>
                                        <Form.Label>Executive Name</Form.Label>
                                                <Form.Control as="select" id="ExecutiveId" name="ExecutiveId"  onChange={ExecutiveSelectionHandler}
                                                  
                                                    value={ExcutiveSelectionIds}  >
                                                    <option value='0'>---Select All---</option>
                                                    {CallCentreExecutiveList}
                                                </Form.Control>
                                            </Form.Group>
                                        
                                        </Col>
                                        <Col md={3}>
                                        <Form.Group>
                                  <Form.Label>From Date</Form.Label>
                                  <InputGroup className="mb-3 d-flex justify-content-start">
                                      <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                  </InputGroup>
                                      </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                        <Form.Group>
                                  <Form.Label>To Date</Form.Label>
                                  <InputGroup className="mb-3 d-flex justify-content-start">
                                      <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                  </InputGroup>
                              </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Row className='justify-content-center mt15'>
                                    <Col md={2}></Col>
                                <Col md={6}>
                                    <Form>
                                        <div className="inner-addon right-addon">
                                            <i className="icon fa fa-search"></i>
                                            <Form.Group className="form-group">
                                                <Form.Control type="text" className='searchControl' ref={searchValue}   placeholder="Search customer name, contact no.,Team, IVR, FLOW" />
                                            </Form.Group>
                                        </div>
                                    </Form>
                                </Col>
                                <Col md={4}>
                                <Button variant='primary' onClick={searchHandler}>Search</Button>
                                        <Button className='ml-2' variant='primary' onClick={DownloadHandler}>Download CSV</Button> 
                                        
                                    </Col>
                            </Row>  
                            <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Call Data List</Card.Title>
                            </Card.Header>
                            <Card.Body>

                                <DataTables
                                    keyField='CallingId'
                                    tableData={PageHelper.dsInboundnotifiCallDlists}
                                    columns={columns}
                                    pagination={paginationFactory(paginationOptions)}

                                />

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>    
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={CallHistoryDatalistCSV} filename={"call-history-report.csv"} target="_blank" id="csvdownload"></CSVLink>
                }
                
            </Container>

            {
                errorModal === true ?
                    (<Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                            {/* {ModalBodyHtml===null||ModalBodyHtml===''?
                        
                        (<><p>{formik.touched.sourceCategory && formik.errors.sourceCategory ? (
                            <div className='text-required'>{formik.errors.sourceCategory}</div>
                        ) : null}</p>  </> ):ModalBodyHtml }             */}
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>

                    </Modal>)
                    : null
            }
        </>
    )
}


export default Calldata;