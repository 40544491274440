import React, { Fragment, useState, useEffect, useRef } from 'react'
import Breadcrumb from '../commonComponent/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';

import EditToolTip from '../UI/ToolTips/EditToolTip';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import ActiveToolTip from '../UI/ToolTips/ActiveToolTip';
import InactiveToolTip from '../UI/ToolTips/InactiveToolTip';

import { useFormik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import ConnectionInstance from '../apisettings/ConnectionInstance';
import { string } from 'yup/lib/locale';



import { Typeahead } from 'react-bootstrap-typeahead';
//import TypeaheadOne from '../../components/forms/form-widgets/typeaheadComponent/typeahead-one';




let GlobalsourceCat = "";
const TeamManagement = () => {
    const [SourceCategoryData, setSourceCategoryData] = useState(0);
    const [CCLoading, SetCCLoading] = useState(false);
    const multiple = false
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsTeamManagementList: []

    });
    let typeahead = useRef();
    let edittypeahead = useRef();
    let typeaheadSource = useRef();
    let typeaheadSourceedit = useRef();
    const [CallCentreExecutiveList, setCallCentreExecutiveList] = useState([]);
    const [CallCentreSoruceTypeList, SetCallCentreSoruceTypeList] = useState([]);
    const [EditCallCentreExecutiveList, setEditCallCentreExecutiveList] = useState([]);
    const [EditCallCentreSoruceTypeList, setEditCallCentreSoruceTypeList] = useState([]);
    const [ExecutiveTyOption, SetExecutiveTyOption] = useState([]);
    const [SourceTyOption, SetSourceTyOption] = useState([]);
    const [EditExecutiveTyOption, SetEditExecutiveTyOption] = useState([]);
    const [EditSourceTyOption, SetEditSourceTyOption] = useState([]);
    const [UserLogin, SetUserLogin] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [ErrorPopUp, setErrorPopUp] = useState(0);
    const [selection, setSelection] = useState(null);
    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            asyncFunBindTeamManagementDetails(page, sizePerPage);
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            asyncFunBindTeamManagementDetails(page, sizePerPage);
        }
    };

    useEffect(() => {
        asyncFuncSourceCategoryDDL();
        asyncFunCallCentreExecutiveDDL();
        // const listener = event => {
        //     if (event.code === "Enter" || event.code === "NumpadEnter") {
        //         console.log("Enter key was pressed. Run your function.");
        //         event.preventDefault();
        //         event.stopPropagation();
        //         errorHandler();


        //     }
        // };
        // document.addEventListener("keydown", listener);
        // return () => {
        //     document.removeEventListener("keydown", listener);
        // };
    }, []);

    useEffect(() => {
        asyncFunBindTeamManagementDetails(1, 10);
    }, []);
    const [editModal, setEditModal] = useState(false);

    async function asyncFunCallCentreExecutiveDDL() {
        try {

            let url = ConnectionInstance + 'master/GetCallCentreExcutiveDDList';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        const TypeOptionDs = [];

                        ds.map((option) => {
                            const TypeOptions = {
                                'id': option.UserId,
                                'name': option.UserName
                            }
                            return TypeOptionDs.push(TypeOptions);
                        });
                        setCallCentreExecutiveList(TypeOptionDs);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    setErrorPopUp(Errtext);
                    setErrorModal(true);
                    console.log('no record found');
                }

                //setUserData(data.map((option) =>(<option value={option.sNo}>{option.ename}</option>)));
                // do something with data
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                setErrorPopUp(Errtext);
                setErrorModal(true);
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            console.log(error);
            let Errtext = <p><div className='text-required'>{error} </div></p>;
            setErrorPopUp(Errtext);
            setErrorModal(true);
            //alert(error);
        }
    }

    async function asyncFunCallCentreSourceTypeList(SourceParmsCatId) {
        try {

            let url = ConnectionInstance + 'master/GetCallCentreSourceTypeListDDL';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { SourceCatId: `${SourceParmsCatId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        const TypeOptionDs = [];

                        ds.map((option) => {
                            const TypeOptions = {
                                'id': option.SourceId,
                                'name': option.SourceName
                            }
                            return TypeOptionDs.push(TypeOptions);
                        });
                        SetCallCentreSoruceTypeList(TypeOptionDs);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    setErrorPopUp(Errtext);
                    setErrorModal(true);
                    console.log('no record found');
                }

                //setUserData(data.map((option) =>(<option value={option.sNo}>{option.ename}</option>)));
                // do something with data
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                setErrorPopUp(Errtext);
                setErrorModal(true);
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            console.log(error);
            let Errtext = <p><div className='text-required'>{error} </div></p>;
            setErrorPopUp(Errtext);
            setErrorModal(true);
            //alert(error);
        }
    }
    async function asyncFuncSourceCategoryDDL() {
        try {

            let url = ConnectionInstance + 'master/GetCallCentreSourceCategoryDDList';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        setSourceCategoryData(ds.map((option) => (<option value={option.SourceCatId}>{option.SourceCategory}</option>)));
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    setErrorPopUp(Errtext);
                    setModal(!modal);
                    setErrorModal(true);
                    console.log('no record found');
                }

            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                setErrorPopUp(Errtext);
                setModal(!modal);
                setErrorModal(true);
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            console.log(error);
            let Errtext = <p><div className='text-required'>{error} </div></p>;
            setErrorPopUp(Errtext);
            setModal(!modal);
            setErrorModal(true);
            //alert(error);
        }
    }

    // listBind
    async function asyncFunBindTeamManagementDetails(PageNo, PageSize) {
        try {
            SetCCLoading(true);
            let url = ConnectionInstance + 'Teammanagement/GetCallCentreTeamMangementList';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { PageNo: `${PageNo}`, PageSize: `${PageSize}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                SetCCLoading(false);
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {

                        await SetPageHelper({
                            ...PageHelper,
                            PageNo: Number(PageNo),
                            PageSize: Number(PageSize),
                            TotalRecord: Number(ds.TotalRecord),
                            dsTeamManagementList: ds.TeamManagementList == null ? [] : ds.TeamManagementList
                        });


                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    setErrorPopUp(Errtext);
                    setErrorModal(true);
                    console.log('no record found');
                }

                //setUserData(data.map((option) =>(<option value={option.sNo}>{option.ename}</option>)));
                // do something with data
            }
            else {
                SetCCLoading(false);
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                setErrorPopUp(Errtext);
                setErrorModal(true);
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            SetCCLoading(false);
            console.log(error);
            let Errtext = <p><div className='text-required'>{error} </div></p>;
            setErrorPopUp(Errtext);
            setErrorModal(true);
            //alert(error);
        }
    }

    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        //console.log(cellData, rowData);
        return <React.Fragment>
            <div>
                <EditToolTip title="Edit" onClick={() => editHandler(rowData.TeamId)} />
                {rowData.IsDefault == "False" ? <DeleteToolTip title="Delete" onClick={() => Displayalert(rowData.TeamId)} /> : null}
                {
                    rowData.IsActive == "False" ? <InactiveToolTip title="Inactive" onClick={() => StatusHandler(rowData.TeamId)} /> : <ActiveToolTip title="Active" onClick={() => StatusHandler(rowData.TeamId)} />
                }

            </div>
        </React.Fragment>
    }

    const columns = [{

        // dataField: 'TeamId',
        // text: 'SNo.',
        //sort: true,
        // formatter: idFormatter,
        // onSort: (field, order) => {
        //     console.log(field, order);
        // }
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
            let rowNumber = (PageHelper.PageNo - 1) * 10 + (rowIndex + 1);
            return <span>{rowNumber}</span>;
        }
    },
    {
        dataField: 'TeamName',
        text: 'TeamName'
    },
    {
        dataField: 'SourceCategory',
        text: 'Source Category'
    },
    {
        dataField: 'SourceType',
        text: 'Source Type'
    },
    {
        dataField: 'IVR',
        text: 'IVR'
    },
    {
        dataField: 'Extension',
        text: 'FLOW'
    },
    {
        dataField: 'TeamMembers',
        text: 'Team Members'
    },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        formatter: actionFormatter
    }
    ];


    const Displayalert = (TeamId) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value && result.isConfirmed === true) {

                    CallCenterTeamDeletebyId(TeamId);

                }
                else {
                    SweetAlert.fire(
                        'Your data is safe!'
                    )
                }
            })
    }

    async function CallCenterTeamDeletebyId(TeamId) {
        try {

            let url = ConnectionInstance + 'Teammanagement/DeleteCallCentreTeamById';

            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { TeamId: TeamId }
            };

            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true && data.Data == "2000") {
                    SweetAlert.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    );
                    asyncFunBindTeamManagementDetails(1, 10);
                    setErrorModal(false);

                }
                else {
                    if (data.ErrorList != null && data.ErrorList.length > 0) {
                        let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                        setErrorPopUp(Errtext);
                        setModal(!modal);
                        setErrorModal(true);
                    }

                }


            }
            else {
                console.log('no record found');

            }
            // return data;
        } catch (error) {
            console.log(error);
            alert(error);
        }
    }

    const updateHandler = () => {
        formikEdit.handleSubmit();
        // setModal(!modal);
        // SweetAlert.fire({ title: "Success!", text: "Data has been updated!", icon: "success" });
    }
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)

    }

    const [errorModal, setErrorModal] = useState(false);
    const errorHandler = (e) => {
        //e.preventDefault();
        // toggleModal();
        //setErrorModal(true);
        formik.handleSubmit();
        if ((formik.touched.executiveName && formik.errors.executiveName) || (formik.touched.teamName && formik.errors.teamName) || (formik.touched.executiveName == undefined && formik.values.executiveName == "") || (formik.touched.teamName == undefined && formik.values.teamName == "")) {

            toggleModal();
            setErrorModal(true);
            setEditModal(false);
        }

    }
    const editHandler = (TeamId) => {
        asyncFunCallCentreExecutiveDDL();
        formik.resetForm();
        formikEdit.resetForm();
        asyncFunBindTeamGetById(TeamId);
    }
    async function asyncFunBindTeamGetById(TeamId) {
        try {
            let url = ConnectionInstance + 'Teammanagement/GetCallCentreTeamById';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { TeamId: `${TeamId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status === 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                       
                        formikEdit.values.editteamName = ds.TeamName;
                        formikEdit.values.TeamID = ds.TeamID;
                        GlobalsourceCat = ds.SourceCatId;
                         if(ds.SourceCatId=="0" || ds.SourceCatId==null)
                         { 
                            SetCallCentreSoruceTypeList([]);
                         }
                        else{
                            asyncFunCallCentreSourceTypeList(ds.SourceCatId);
                        }
                        formikEdit.values.editsourceCategory = ds.SourceCatId;
                        formikEdit.values.editIVR = ds.IVR;
                        formikEdit.values.editExtension = ds.Extension;
                        if (ds.ObjTeammappingList != null && ds.ObjTeammappingList.length > 0) {
                            const EditTypeOptionDs = [];

                            ds.ObjTeammappingList.map((option) => {
                                const editTypeOptions = {
                                    'id': option.UserId,
                                    'name': option.UserName
                                }
                                return EditTypeOptionDs.push(editTypeOptions);
                            });
                            setEditCallCentreExecutiveList(EditTypeOptionDs);

                            const TypeOptEdit = [];

                            ds.ObjTeammappingList.map((item) => {
                                const TypeOptrowsEdit = {
                                    'UserId': item.UserId
                                }
                                return TypeOptEdit.push(TypeOptrowsEdit);
                            });
                            SetEditExecutiveTyOption(TypeOptEdit);

                        }
                        else {
                            const EditTypeOptionDs = [];
                            setEditCallCentreExecutiveList(EditTypeOptionDs);
                        }

                        if (ds.ObjSourceMappingList != null && ds.ObjSourceMappingList.length > 0) {
                            const EditTypeSourceOptionDs = [];

                            ds.ObjSourceMappingList.map((option) => {
                                const editTypeSourceOptions = {
                                    'id': option.SourceId,
                                    'name': option.SourceName
                                }
                                return EditTypeSourceOptionDs.push(editTypeSourceOptions);
                            });
                            setEditCallCentreSoruceTypeList(EditTypeSourceOptionDs);

                            const TypeOptSourceEdit = [];

                            ds.ObjSourceMappingList.map((item) => {
                                const TypeOptSourcerowsEdit = {
                                    'SourceId': item.SourceId
                                }
                                return TypeOptSourceEdit.push(TypeOptSourcerowsEdit);
                            });
                            SetEditSourceTyOption(TypeOptSourceEdit);

                        }
                        else {
                            const EditTypeSourceOptionDs = [];
                            setEditCallCentreSoruceTypeList(EditTypeSourceOptionDs);
                        }




                        const value = (ds.ObjTeammappingList.length > 0) ? ds.ObjTeammappingList[0].UserName : '';
                        formikEdit.values.editexecutiveName = value;

                        const values = (ds.ObjSourceMappingList.length > 0) ? ds.ObjSourceMappingList[0].SourceName : '';
                        formikEdit.values.editsourcetype = values;
                        //alert(formikEdit.values.editsourcetype);

                        ///formikEdit.setFieldValue('editexecutiveName', value);

                        setModal(!modal);
                        setEditModal(true);
                        setErrorModal(false);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    setErrorPopUp(Errtext);
                    setErrorModal(false);
                    console.log('no record found');
                }

                //setUserData(data.map((option) =>(<option value={option.sNo}>{option.ename}</option>)));
                // do something with data
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                setErrorPopUp(Errtext);
                setErrorModal(false);
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            console.log(error);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            setErrorPopUp(Errtext);
            setErrorModal(false);
            //alert(error);
        }
    }

    const StatusHandler = (TeamId) => {
        CallCentreTeamStatusbyId(TeamId);
    }
    async function CallCentreTeamStatusbyId(TeamId) {
        try {

            let url = ConnectionInstance + 'Teammanagement/UpdateCallCentreTeamStatusById';

            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { TeamId: TeamId }
            };

            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true && data.Data == "2000") {
                    SweetAlert.fire({ title: "Success!", text: "Status has been updated!", icon: "success" });
                    asyncFunBindTeamManagementDetails(1, 10);
                    setErrorModal(false);

                }
                else {
                    if (data.ErrorList != null && data.ErrorList.length > 0) {
                        let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                        setErrorPopUp(Errtext);
                        setModal(!modal);
                        setErrorModal(true);
                    }

                }


            }
            else {
                console.log('no record found');

            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            setErrorPopUp(Errtext);
            setErrorModal(true);
        }

    }

    const formik = useFormik({
        initialValues: {
            teamName: '',
            executiveName: '',
            TeamID: '0',
            sourceCategory: '',
            sourcetype: '',
            Purposename: '',
            IVR:'',
            Extension: ''
          

        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            executiveName: Yup.string().required('executiveName is Required')
            , teamName: Yup.string()
                .required('Team Name is Required'),
            sourceCategory: Yup.string().transform(v => v === '0' ? '' : v)
                .required('Source Category is Required'),
            sourcetype: Yup.string()
                .required('Source Type is Required'),
            // Extension: Yup.string().required('Extension is Required')
            IVR: Yup.string()
            .required('IVR is Required').matches(/^[0-9\b]+$/, "Only Number are allowed for IVR"),
            Extension: Yup.string()
                .required('Flow is Required').matches(/^[0-9\b]+$/, "Only Number are allowed for Flow"),

        }),
        isSubmitting: true,
        onSubmit: async (values) => {
            formikEdit.resetForm();

            //setErrorModal(false);
            //setModal(false);
            try {

                let url = ConnectionInstance + 'Teammanagement/SetUpdateTeamManagementDetails';

                let options = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },

                    data: {
                        TeamID: values.TeamID, Addedby: `${UserLogin}`, TeamName: values.teamName,
                        SourceCatId: `${values.sourceCategory}`, IVR:`${values.IVR}`,  Extension: `${values.Extension}`,
                        ObjTeammappingList: ExecutiveTyOption, objTeamSourceTypeMappingList: SourceTyOption
                    },
                };
                let response = await axios(options);
                let responseOK = response && response.status === 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if (data.Success === true && data.Data === "2000") {
                        setTimeout(() => {
                            setErrorModal(false);
                            setModal(false);
                        }, 100);

                        SweetAlert.fire({ title: "Success!", text: "Team  has been Saved!", icon: "success" });
                        asyncFunBindTeamManagementDetails(1, 10);

                        formik.resetForm();
                        typeahead.current.clear();
                        typeaheadSource.current.clear();
                        formikEdit.resetForm();
                        //edittypeahead.current.clear();

                    }
                    else if (data.Success == true && data.Data == "2") {

                        SweetAlert.fire({ title: "Warning", text: "Team already Exist!", icon: "info" });
                        setTimeout(() => {
                            setErrorModal(false);
                            setModal(false);
                        }, 100);

                    }
                    else {
                        if (data.ErrorList != null && data.ErrorList.length > 0) {
                            let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                            setErrorPopUp(Errtext);

                            setModal(!modal);
                            setErrorModal(true);

                        }

                    }


                }
                else {
                    console.log('no record found');

                }
                // return data;
            } catch (error) {
                console.log(error.message);
                let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                setErrorPopUp(Errtext);
                setErrorModal(true);
            }

            // toggleModal();



        },
    });

    const formikEdit = useFormik({
        initialValues: {
            editsourceCategory: '',
            editsourcetype: '',
            editPurposename: '',
            editExtension: '',
            editteamName: '',
            editexecutiveName: '',
            editIVR:'',
            TeamID: '0'
        },
        validationSchema: Yup.object({
            editexecutiveName: Yup.string().required('executiveName is Required')
            , editteamName: Yup.string().required('Team Name is Required'),
            editsourceCategory: Yup.string().transform(v => v === '0' ? '' : v)
                .required('Source Category is Required'),
            editsourcetype: Yup.string()
                .required('Source Type is Required'),
                editIVR:Yup.string()
                .required('IVR is Required').matches(/^[0-9\b]+$/, "Only Number are allowed for IVR"),
            editExtension: Yup.string()
            .required('Flow is Required').matches(/^[0-9\b]+$/, "Only Number are allowed for Flow")

        }),

        isSubmitting: true,
        onSubmit: async (values) => {
           // alert(JSON.stringify(values));
            //setErrorModal(false);
            try {

                let url = ConnectionInstance + 'Teammanagement/SetUpdateTeamManagementDetails';

                let options = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },

                    data: {
                        TeamID: values.TeamID, Addedby: `${UserLogin}`, TeamName: `${values.editteamName}`,
                        SourceCatId: `${values.editsourceCategory}`,IVR: `${values.editIVR}`, Extension: `${values.editExtension}`,
                        ObjTeammappingList: EditExecutiveTyOption, objTeamSourceTypeMappingList: EditSourceTyOption
                    }
                };
                let response = await axios(options);
                let responseOK = response && response.status === 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if (data.Success === true && data.Data === "2000") {
                        setTimeout(() => {
                            setErrorModal(false);
                            setModal(false);
                        }, 100);
                        SweetAlert.fire({ title: "Success!", text: "Team  has been Updated!", icon: "success" });
                        asyncFunBindTeamManagementDetails(1, 10);
                        formikEdit.resetForm();
                        edittypeahead.current.clear();

                    }
                    else if (data.Success == true && data.Data == "2") {
                        setTimeout(() => {
                            setErrorModal(false);
                            setModal(false);
                        }, 100);
                        SweetAlert.fire({ title: "Warning", text: "Team already Exist!", icon: "info" });

                    }
                    else {
                        if (data.ErrorList != null && data.ErrorList.length > 0) {
                            let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                            setErrorPopUp(Errtext);
                            setModal(!modal);
                            setErrorModal(true);

                        }

                    }


                }
                else {
                    console.log('no record found');

                }
                // return data;
            } catch (error) {
                console.log(error.message);
                let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                setErrorPopUp(Errtext);
                setErrorModal(true);
            }

            // toggleModal();



        },
    });

    const onchangeSourceCatHandler = async (e) => {
        const val = e.target.value;
        formik.setFieldValue('sourceCategory', val);
        typeaheadSource.current.clear();
        if(val=="0" || val==null)
        { 
           SetCallCentreSoruceTypeList([]);
        }
       else{
        asyncFunCallCentreSourceTypeList(val);
       }
       
    }
    const onchangeSourceCatHandleredit = async (e) => {
        const val = e.target.value;
        formikEdit.setFieldValue('editsourceCategory', val);
        typeaheadSource.current.clear();
        typeaheadSourceedit.current.clear();
        asyncFunCallCentreSourceTypeList(val);
    }
    const onExtensionHanlder = async (e) => {
        const val = e.target.value.replace(/[^0-9]/gi, '');
        formik.setFieldValue("Extension", val);
    }
    const onEditExtensionHanlder = async (e) => {
        const val = e.target.value.replace(/[^0-9]/gi, '');
        formikEdit.setFieldValue("editExtension", val);
    }
    const onIVRHanlder=async(e)=>{
        const val = e.target.value.replace(/[^0-9]/gi, '');
        formik.setFieldValue("IVR", val);
    }
    const onEditIVRHanlder=async(e)=>{
        const val = e.target.value.replace(/[^0-9]/gi, '');
        formikEdit.setFieldValue("editIVR", val);
    }

    return (
        <>
            <div id="cover-spin" style={{ display: CCLoading ? 'block' : 'none' }}></div>
            <Breadcrumb title="Team Management" parent="Admin" />


            <Container fluid>

                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>


                                <Form.Row className="justify-content-center">
                                    <Col md={4} lg={3}>
                                        <Form.Group>
                                            <Form.Label>Source Category <span className='text-required'>*</span></Form.Label>
                                            <Form.Control as="select" id="sourceCategory" name="sourceCategory"
                                                onChange={onchangeSourceCatHandler}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.sourceCategory}>
                                                <option value='0'>---select---</option>
                                                {SourceCategoryData}
                                            </Form.Control>

                                            {formik.touched.sourceCategory && formik.errors.sourceCategory ? (
                                                <div className='text-required'>{formik.errors.sourceCategory}</div>
                                            ) : null}

                                        </Form.Group>
                                    </Col>
                                    <Col md={4} lg={3}>
                                        <Form.Group>
                                            <Form.Label>Source Type <span className='text-required'>*</span></Form.Label>
                                            <Typeahead
                                                id="dlistSourceType" name="dlistSourceType"
                                                //clearButton
                                                defaultSelected={CallCentreSoruceTypeList.slice(0, 5)}
                                                labelKey="name"
                                                multiple
                                                ref={typeaheadSource}
                                                options={CallCentreSoruceTypeList}
                                                value={formik.values.sourcetype}
                                                // onChange is on valid option selection (a menu click)
                                                onChange={option => {
                                                    const value = (option.length > 0) ? option[0].name : '';
                                                    formik.setFieldValue('sourcetype', value);
                                                    const TypeOpt = [];

                                                    option.map((item) => {
                                                        const TypeOptrows = {
                                                            'SourceId': item.id
                                                        }
                                                        return TypeOpt.push(TypeOptrows);
                                                    });
                                                    SetSourceTyOption(TypeOpt);
                                                }}

                                                // onBlur is on leaving the control. Can be used for error checks
                                                onBlur={(e) => {
                                                    if (formik.values.sourcetype == '' || formik.values.sourcetype == null)
                                                        formik.setFieldTouched('sourcetype', true);
                                                    else
                                                        //formik.setFieldTouched('executiveName',false);
                                                        formik.errors.sourcetype = "";

                                                }}
                                                placeholder="...Select..."
                                            />
                                            {formik.touched.sourcetype && formik.errors.sourcetype ? (
                                                <div className='text-required'>{formik.errors.sourcetype}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} lg={3}>
                                        <Form.Group>
                                            <Form.Label>IVR <span className='text-required'>*</span></Form.Label>
                                            <Form.Control type='text' id="IVR" name="IVR"
                                                onChange={onIVRHanlder}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.IVR} />
                                            {formik.touched.IVR && formik.errors.IVR ? (
                                                <div className='text-required'>{formik.errors.IVR}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    {/* <Col md={4} lg={3}>
                                        <Form.Group>
                                            <Form.Label>Purpose</Form.Label>
                                            <Form.Control type='text' id="Purposename" name="Purposename"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.Purposename} />
                                            {formik.touched.Purposename && formik.errors.Purposename ? (
                                                <div className='text-required'>{formik.errors.Purposename}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col> */}
                                </Form.Row>

                                <Form.Row className="justify-content-center">
                                    <Col md={4} lg={3}>
                                        <Form.Group>
                                            <Form.Label>Flow <span className='text-required'>*</span></Form.Label>
                                            <Form.Control type='text' id="Extension" name="Extension"
                                                onChange={onExtensionHanlder}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.Extension} />
                                            {formik.touched.Extension && formik.errors.Extension ? (
                                                <div className='text-required'>{formik.errors.Extension}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} lg={3}>
                                        <Form.Group>
                                            <Form.Label>Executive Name <span className='text-required'>*</span></Form.Label>
                                            <Typeahead
                                                id="executiveName" name="executiveName"
                                                //clearButton
                                                defaultSelected={CallCentreExecutiveList.slice(0, 5)}
                                                labelKey="name"
                                                multiple
                                                ref={typeahead}
                                                options={CallCentreExecutiveList}
                                                value={formik.values.executiveName}
                                                // onChange is on valid option selection (a menu click)
                                                onChange={option => {
                                                    const value = (option.length > 0) ? option[0].name : '';
                                                    formik.setFieldValue('executiveName', value);
                                                    const TypeOpt = [];

                                                    option.map((item) => {
                                                        const TypeOptrows = {
                                                            'UserId': item.id
                                                        }
                                                        return TypeOpt.push(TypeOptrows);
                                                    });
                                                    SetExecutiveTyOption(TypeOpt);
                                                    // SetExecutiveTyOption(option);

                                                }}

                                                // onBlur is on leaving the control. Can be used for error checks
                                                onBlur={(e) => {
                                                    if (formik.values.executiveName == '' || formik.values.executiveName == null)
                                                        formik.setFieldTouched('executiveName', true);
                                                    else
                                                        //formik.setFieldTouched('executiveName',false);
                                                        formik.errors.executiveName = "";

                                                }}
                                                placeholder="...Select..."
                                            />
                                            {formik.touched.executiveName && formik.errors.executiveName ? (
                                                <div className='text-required'>{formik.errors.executiveName}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} lg={3}>
                                        <Form.Group>
                                            <Form.Label htmlFor="purpose">Team Name <span className='text-required'>*</span></Form.Label>
                                            <Form.Control type='text' id="teamName" name="teamName"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.teamName} />
                                            {formik.touched.teamName && formik.errors.teamName ? (
                                                <div className='text-required'>{formik.errors.teamName}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>

                                </Form.Row>
                                <Form.Row className='mt15'>
                                    <Col md={12} className='text-center'>
                                        <Button variant='primary' type='submit' disabled={formik.isSubmitting} onClick={(e) => errorHandler(e)}>Save</Button>
                                    </Col>
                                </Form.Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Team List</Card.Title>
                            </Card.Header>
                            <Card.Body className='txtteamManagement'>

                                <DataTables
                                    keyField='TeamId'
                                    tableData={PageHelper.dsTeamManagementList}
                                    columns={columns}
                                    pagination={paginationFactory(paginationOptions)}

                                    noDataIndication={NoRecordFounds}
                                // cellEdit={cellEditFactory({
                                //     mode: 'dbclick',//dbclick
                                //     blurToSave: true
                                // })}
                                // filter={filterFactory()}
                                />

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>



            {/******** Modal Popups ******* */}

            <Modal isOpen={modal} toggle={toggleModal}   id='error'  centered={true} size='lg'>
                {editModal && 
                    <>
                        <ModalHeader toggle={toggleModal}>Edit Team Management</ModalHeader>
                        <ModalBody>
                            <Form onSubmit={formikEdit.handleSubmit} >
                                <Form.Row>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Source Category <span className='text-required'>*</span></Form.Label>
                                            <Form.Control as="select" id="editsourceCategory" name="editsourceCategory"
                                                onChange={onchangeSourceCatHandleredit}
                                                onBlur={formikEdit.handleBlur}
                                                value={formikEdit.values.editsourceCategory} disabled={GlobalsourceCat == "0" ? false : true}>
                                                <option value='0'>---select---</option>
                                                {SourceCategoryData}
                                            </Form.Control>

                                            {formikEdit.touched.editsourceCategory && formikEdit.errors.editsourceCategory ? (
                                                <div className='text-required'>{formikEdit.errors.editsourceCategory}</div>
                                            ) : null}

                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Source Type <span className='text-required'>*</span></Form.Label>
                                            <Typeahead
                                                id="editdlistSourceType" name="editdlistSourceType"
                                                //clearButton
                                                defaultSelected={EditCallCentreSoruceTypeList.slice(0, 5)}
                                                labelKey="name"
                                                multiple
                                                ref={typeaheadSourceedit}
                                                options={CallCentreSoruceTypeList}
                                                value={formikEdit.values.editsourcetype}
                                                // onChange is on valid option selection (a menu click)
                                                onChange={option => {
                                                    const value = (option.length > 0) ? option[0].name : '';
                                                    formikEdit.setFieldValue('editsourcetype', value);
                                                    const TypeOptS = [];

                                                    option.map((item) => {
                                                        const TypeOptrowss = {
                                                            'SourceId': item.id
                                                        }
                                                        return TypeOptS.push(TypeOptrowss);
                                                    });
                                                    SetEditSourceTyOption(TypeOptS);
                                                }}

                                                // onBlur is on leaving the control. Can be used for error checks
                                                onBlur={(e) => {
                                                    if (formikEdit.values.editsourcetype == '' || formikEdit.values.editsourcetype == null)
                                                        formikEdit.setFieldTouched('editsourcetype', true);
                                                    else
                                                        formikEdit.setFieldTouched('editsourcetype', true);
                                                    //formik.setFieldTouched('executiveName',false);

                                                    //formikEdit.errors.editsourcetype = "";

                                                }}
                                                placeholder="...Select..."
                                            />
                                            {formikEdit.touched.editsourcetype && formikEdit.errors.editsourcetype ? (
                                                <div className='text-required'>{formikEdit.errors.editsourcetype}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>IVR <span className='text-required'>*</span></Form.Label>
                                            <Form.Control type='text' id="editIVR" name="editIVR"
                                             onChange={onEditIVRHanlder}
                                                onBlur={formikEdit.handleBlur}
                                                value={formikEdit.values.editIVR} />
                                            {formikEdit.touched.editIVR && formikEdit.errors.editIVR ? (
                                                <div className='text-required'>{formikEdit.errors.editIVR}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    {/* <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Purpose</Form.Label>
                                            <Form.Control type='text' id="editPurposename" name="editPurposename"
                                                onChange={formikEdit.handleChange}
                                                onBlur={formikEdit.handleBlur}
                                                value={formikEdit.values.editPurposename} />
                                            {formikEdit.touched.Purposename && formikEdit.errors.editPurposename ? (
                                                <div className='text-required'>{formikEdit.errors.editPurposename}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col> */}
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Flow <span className='text-required'>*</span></Form.Label>
                                            <Form.Control type='text' id="editExtension" name="editExtension"
                                                onChange={onEditExtensionHanlder}
                                                onBlur={formikEdit.handleBlur}
                                                value={formikEdit.values.editExtension} />
                                            {formikEdit.touched.editExtension && formikEdit.errors.editExtension ? (
                                                <div className='text-required'>{formikEdit.errors.editExtension}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Executive Name <span className='text-required'>*</span></Form.Label>
                                            <Typeahead
                                                id="executiveNameedit" name="executiveNameedit"
                                                //clearButton
                                                defaultSelected={EditCallCentreExecutiveList}
                                                labelKey="name"
                                                multiple
                                                ref={edittypeahead}
                                                options={CallCentreExecutiveList}
                                                value={formikEdit.values.editexecutiveName}
                                                // onChange is on valid option selection (a menu click)
                                                onChange={option => {
                                                    const value = (option.length > 0) ? option[0].name : '';
                                                    formikEdit.setFieldValue('editexecutiveName', value);
                                                    const TypeOptE = [];
                                                    option.map((item) => {
                                                        const TypeOptrowsE = {
                                                            'UserId': item.id
                                                        }
                                                        return TypeOptE.push(TypeOptrowsE);
                                                    });
                                                    SetEditExecutiveTyOption(TypeOptE);
                                                    // SetExecutiveTyOption(option);

                                                }}

                                                // onBlur is on leaving the control. Can be used for error checks
                                                onBlur={(e) => {
                                                    if (formikEdit.values.editexecutiveName == '' || formikEdit.values.editexecutiveName == null)
                                                        formikEdit.setFieldTouched('editexecutiveName', true);
                                                    else
                                                        formikEdit.setFieldTouched('editexecutiveName', true);
                                                    //formik.setFieldTouched('executiveName',false);
                                                    // formikEdit.errors.editexecutiveName="";

                                                }}
                                                placeholder="...Select..."
                                            />
                                            {formikEdit.touched.editexecutiveName && formikEdit.errors.editexecutiveName ? (
                                                <div className='text-required'>{formikEdit.errors.editexecutiveName}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Team Name <span className='text-required'>*</span></Form.Label>
                                            <Form.Control type='text' id="editteamName" name="editteamName"
                                                onChange={formikEdit.handleChange}
                                                onBlur={formikEdit.handleBlur}
                                                value={formikEdit.values.editteamName} />
                                            {formikEdit.touched.editteamName && formikEdit.errors.editteamName ? (
                                                <div className='text-required'>{formikEdit.errors.editteamName}</div>
                                            ) : null}

                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" disabled={formikEdit.isSubmitting} onClick={updateHandler}>Update</Button>
                        </ModalFooter>
                    </>
                }

                {editModal == false && errorModal &&
                    <>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                            {ErrorPopUp ? ErrorPopUp : <p>
                                {formik.touched.sourceCategory && formik.errors.sourceCategory ? (
                                    <div className='text-required'>{formik.errors.sourceCategory}</div>
                                ) : null}
                                {formik.touched.sourcetype && formik.errors.sourcetype ? (
                                    <div className='text-required'>{formik.errors.sourcetype}</div>
                                ) : null}
                                {formik.touched.executiveName && formik.errors.executiveName ? (
                                    <div className='text-required'>{formik.errors.executiveName}</div>
                                ) : null}
                                {formik.touched.teamName && formik.errors.teamName ? (
                                    <div className='text-required'>{formik.errors.teamName}</div>
                                ) : null}
                                    {formik.touched.IVR && formik.errors.IVR ? (
                                    <div className='text-required'>{formik.errors.IVR}</div>
                                   ) : null}                              
                                {formik.touched.Extension && formik.errors.Extension ? (
                                    <div className='text-required'>{formik.errors.Extension}</div>
                                ) : null}

                            </p>}


                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>
                    </>
                }
            </Modal>


        </>
    )
}

export default TeamManagement;
