import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import '../../src/index.scss';
import '../../src/pages/css/layout.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { configureFakeBackend, authHeader, handleResponse } from "../services/fack.backend";
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import * as serviceWorker from '../serviceWorker';

// ** Import custom components for redux **
import { Provider } from 'react-redux';
import store from '../store';

import { Auth0Provider } from '@auth0/auth0-react'
//*******Component *//
import Dashboard from '../pages/admin/dashboard';
import Assignedcalls from '../pages/admin/outbound/assignedcalls';
import OutBoundAppointments from '../pages/admin/outbound/appointments';
import InBoundAppointments from '../pages/Inbound/appointments';
import Unassignedcalls from '../pages/admin/outbound/unassignedcalls';
import AddAssignCalls from '../pages/admin/outbound/addassigncalls';
import UploadCalls from '../pages/admin/outbound/uploadcalls';
import VerifyManualSheets from '../pages/admin/outbound/verifymanualsheets';
import ApiManagement from '../pages/admin/apimanagement';
import AutomationCalls from '../pages/admin/outbound/automationcalls';
import PurposeManagement from '../pages/admin/purposemanagement';
import SourceManagement from '../pages/admin/sourcemanagement';
import TeamManagement from '../pages/admin/teammanagement';
import ViewAssignCalls from '../pages/admin/outbound/viewassignedcalls';
import AddComplaintCalls from '../pages/admin/addcomplaintcalls';
import ViewComplaintCall from '../pages/admin/viewcomplaintcall';
import ReAssignedCalls from '../pages/admin/outbound/reassignedcalls';
import ViewReAssignedCalls from '../pages/admin/outbound/viewreassignedcalls';
import PbxUserExtensionmaster from '../pages/admin/pbxUserExtensionmaster';
import PbxcallType from '../pages/admin/pbxcallType';
import Ldapsync from '../pages/admin/ldapsync';
import TestStatus from '../pages/admin/LabReport/TestStatus';
import ReportOverview from '../pages/admin/LabReport/ReportOverview';
import Notifications from '../pages/Inbound/Notifications';
import AddInboundcalls from '../pages/Inbound/addInboundcalls';
import InCompletedCalls from '../pages/Inbound/InCompletedCalls';
import AssignRoute from '../pages/Phlebo/AssignRoute';
import AssignPhlebo from '../pages/Phlebo/AssignPhlebo';
import RouteInfo from '../pages/Phlebo/RouteInfo';
import ShowAssignRoute from '../pages/Phlebo/ShowAssignedRoute';
import CallHistory from '../pages/reports/CallHistory';
import UntouchedInboundCall from '../pages/reports/UntouchedInboundCall';
import Appointments from '../pages/booking/Appointments';
import DirectAppointments  from '../pages/booking/DirectAppointments';
import SelfAnsweredCalls from '../pages/reports/SelfAnsweredCalls';
import SelfCallHistory  from '../pages/reports/SelfCallHistory';
import Calldata from '../pages/searchdb/Calldata';
import ViewCallDataDetail from '../pages/searchdb/ViewCallDataDetail';

const Nav = () => {

    return (

        <>
            {/* dashboard menu */}
            <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                return (<Redirect to={`${process.env.PUBLIC_URL}/admin/dashboard`} />)
            }} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/dashboard`} caseSensitive={false} component={Dashboard} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/outbound/assignedcalls`} caseSensitive={false} component={Assignedcalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/outbound/appointments`} caseSensitive={false} component={OutBoundAppointments} />
            <Route exact path={`${process.env.PUBLIC_URL}/inbound/appointments`} caseSensitive={false} component={InBoundAppointments} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/outbound/unassignedcalls`} caseSensitive={false} component={Unassignedcalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/outbound/addassigncalls`} caseSensitive={false} component={AddAssignCalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/outbound/uploadcalls`} caseSensitive={false} component={UploadCalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/outbound/verifymanualsheets`} caseSensitive={false} component={VerifyManualSheets} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/apimanagement`} caseSensitive={false} component={ApiManagement} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/outbound/automationcalls`} caseSensitive={false} component={AutomationCalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/purposemanagement`} caseSensitive={false} component={PurposeManagement} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/sourcemanagement`} caseSensitive={false} component={SourceManagement} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/teammanagement`} caseSensitive={false} component={TeamManagement} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/outbound/viewassignedcalls`} caseSensitive={false} component={ViewAssignCalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/addcomplaintcalls`} caseSensitive={false} component={AddComplaintCalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/viewcomplaintcall`} caseSensitive={false} component={ViewComplaintCall} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/outbound/reassignedcalls`} caseSensitive={false} component={ReAssignedCalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/outbound/viewreassignedcalls`} caseSensitive={false} component={ViewReAssignedCalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/pbxUserExtensionmaster`} caseSensitive={false} component={PbxUserExtensionmaster} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/PbxcallType`} caseSensitive={false} component={PbxcallType} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/ldapsync`} caseSensitive={false} component={Ldapsync} />
            <Route exact path={`${process.env.PUBLIC_URL}/labreport/test-status`} caseSensitive={false} component={TestStatus} />
            <Route exact path={`${process.env.PUBLIC_URL}/lab-report/overview`} caseSensitive={false} component={ReportOverview} />
            <Route exact path={`${process.env.PUBLIC_URL}/inbound/notifications`} caseSensitive={false} component={Notifications} />
            <Route exact path={`${process.env.PUBLIC_URL}/inbound/addInboundcalls`} caseSensitive={false} component={AddInboundcalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/inbound/incompletedcalls`} caseSensitive={false} component={InCompletedCalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/phlebo/assignroute`} caseSensitive={false} component={AssignRoute} />
            <Route exact path={`${process.env.PUBLIC_URL}/phlebo/assignphlebo`} caseSensitive={false} component={AssignPhlebo} />
            <Route exact path={`${process.env.PUBLIC_URL}/phlebo/routeinfo`} caseSensitive={false} component={RouteInfo} />
            <Route exact path={`${process.env.PUBLIC_URL}/phlebo/showassignedroute`} caseSensitive={false} component={ShowAssignRoute} />
            <Route exact path={`${process.env.PUBLIC_URL}/reports/call-history`} caseSensitive={false} component={CallHistory} />
            <Route exact path={`${process.env.PUBLIC_URL}/reports/untouched-inbound-calls`} caseSensitive={false} component={UntouchedInboundCall} />
            <Route exact path={`${process.env.PUBLIC_URL}/booking/appointments`} caseSensitive={false} component={Appointments} />
            <Route exact path={`${process.env.PUBLIC_URL}/booking/directAppointments`} caseSensitive={false} component={DirectAppointments} />  
            <Route exact path={`${process.env.PUBLIC_URL}/reports/SelfAnsweredCalls`} caseSensitive={false} component={SelfAnsweredCalls} />  
            <Route exact path={`${process.env.PUBLIC_URL}/reports/SelfCallHistory`} caseSensitive={false} component={SelfCallHistory} />  
            <Route exact path={`${process.env.PUBLIC_URL}/searchdb/calldata`} caseSensitive={false} component={Calldata} />  
            <Route exact path={`${process.env.PUBLIC_URL}/searchdb/viewcalldatadetail`} caseSensitive={false} component={ViewCallDataDetail} />  
            
             </>
    )


}

export default Nav;