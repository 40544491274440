import React, { useState, useEffect, useContext } from 'react'
import CallToolTip from '../../UI/ToolTips/CallToolTip';
import ToolTip from '../../UI/ToolTips/ViewToolTip';
import Breadcrumb from '../../commonComponent/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DataTable from '../../commonComponent/DataTable/DataTable';
import axios from 'axios';
import ConnectionInstance from '../../apisettings/ConnectionInstance';
import AppointmentDetails from '../../../pages/commonComponent/cart/appointmentdetails';
import { GlobalInfo } from '../../../components/context/context'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Loading from '../../commonComponent/cart/LoadingButton'

const Appointments = () => {
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [table, setTable] = useState([]);
    const [appointmentid, setAppointmentId] = useState("0");
    const [newappointmentid, setNewAppointmentId] = useState("0");
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [centerid, setCenterId] = useState("0");
    const [userexistflag, setUserExistFlag] = useState("");
    const { dispatchUserEvent } = useContext(GlobalInfo);
    const [status, setStatus] = useState("0");
    const [isconfirmed, setIsConfirmed] = useState("0");
    const [IsLoadButton, setIsLoadButton] = useState(false);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [PBXfrom, setPBXfrom] = useState(localStorage.ExtenNo == undefined ? "" : localStorage.ExtenNo);
    const [modal, setModal] = useState();
    const [mobno, setMobNo] = useState("0");

    useEffect(() => {
        ClearStoredState();
        BindGrid();
        const pageAccessedByReload = (
            (window.performance.navigation && window.performance.navigation.type === 1) ||
            window.performance
                .getEntriesByType('navigation')
                .map((nav) => nav.type)
                .includes('reload')
        );
        if (pageAccessedByReload === true) {
            ClearStoredState();
            localStorage.removeItem("samplerid");
            localStorage.removeItem("sampledid");
            localStorage.removeItem("samplecid");
            localStorage.removeItem("sampleitemmasterdetails");
        }
        return () => {
            ClearStoredState();
            localStorage.removeItem("samplerid");
            localStorage.removeItem("sampledid");
            localStorage.removeItem("samplecid");
            localStorage.removeItem("sampleitemmasterdetails");
        }
    }, [])
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            {
                localStorage.ExtenNo === "" || localStorage.ExtenNo === "0" || localStorage.ExtenNo === undefined ? null :
                    <>
                        <CallToolTip title="Call" placement="top" id={`call${rowData.AppointmentId}`} onClick={() =>
                            AddCallHandler(rowData.AppointmentId, rowData.MobileNo)} />
                    </>
            }
            <ToolTip title="Details" placement="top" id={`view${rowData.AppointmentId}`} onClick={() =>
                GetWebsiteAppointmentDetails(rowData.AppointmentId, rowData.Age, rowData.Gender, rowData.CentreId, rowData.UserId, rowData.MobileNo)} />
        </React.Fragment>
    }
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'PatientName', text: 'Customer Name', editable: false },
        { dataField: 'MobileNo', text: 'Customer Mobile', editable: false },
        { dataField: 'AppointmentDate', text: 'Appointment Date', editable: false },
        { dataField: 'SourceCategory', text: 'Source Category', editable: false },
        { dataField: 'Purpose', text: 'Purpose', editable: false },
        { dataField: 'CallType', text: 'CallType', editable: false },
        { dataField: 'Team', text: 'Team', editable: false },
        { dataField: 'actions', text: 'Actions', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '10%' }; } }
    ];
    const BindGrid = async () => {
        let url = ConnectionInstance + 'master/getallwebsiteappointments/'
        await axios.get(url).then((response) => {
            const data = response.data.Data;
            if (data === null) { setTable([]); } else { setTable(data); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const GetWebsiteAppointmentDetails = async (AppointmentId, Age, Gender, CentreId, UserId, MobileNo) => {
        setMobNo(MobileNo);
        setUserExistFlag("1");
        setAppointmentId(AppointmentId);
        setAge(Age);
        setGender(Gender);
        setCenterId(CentreId);
        localStorage.setItem("lid", UserId);
        setStatus("0");
        setIsConfirmed("0");
        dispatchUserEvent('ADD_LOGINDATA', { loginid: UserId });
    }
    const AddCallHandler = (AppointmentId, MobileNo) => {
        setModal(!modal);
        setNewAppointmentId(AppointmentId);
        setMobNo(MobileNo);
    }
    const AddFinalCall = () => {
        if (mobno == "" || mobno.length < 10) {
            SweetAlert.fire({ title: "Incomplete Mobile", text: "Please enter correct mobile no.", icon: "warning" });
        }
        else if (mobno.length > 10) {
            SweetAlert.fire({ title: "Incomplete Mobile", text: "Please enter 10 digit mobile no without +91 or 0 only.", icon: "warning" });
        }
        else {
            asyncFunCustomerMobileUpdate(newappointmentid, mobno);
        }
    }
    async function asyncFunCustomerMobileUpdate(AppointmentId, MobileNo) {
        try {
            setIsLoadButton(true);
            let url = ConnectionInstance + 'pbxcall/pbxclicktocallswebsite';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { CallingId: `${AppointmentId}`, AddedBy: `${LoginId}`, PBXfrom: `${PBXfrom}`, PBXto: `0${MobileNo}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    setTimeout(() => {
                        setIsLoadButton(false);
                        setModal(!modal);
                        SweetAlert.fire({ title: "Success!", text: "Call has been successfully connected!", icon: "success" });
                    }, 3000);
                }
                else {
                    setIsLoadButton(false);
                    if (data.ErrorList != null && data.ErrorList.length > 0) {
                        SweetAlert.fire(data.ErrorList[0].errorMsg);
                    }
                }
            }
            else { setIsLoadButton(false); SweetAlert.fire("No record found"); }
        } catch (error) { setIsLoadButton(false); SweetAlert.fire("You may not be connected to a network or Unable to connect to a server"); }
    }
    const ClearStoredState = () => {
        setAppointmentId("0");
        setAge("");
        setGender("");
        setCenterId("0");
        dispatchUserEvent('REMOVE_LOGINDATA', { loginid: '0' });
        dispatchUserEvent('REMOVE_CENTREID', { centreid: '0' });
        dispatchUserEvent('REMOVE_DISTRICTID', { districtid: '0' });
        dispatchUserEvent('REMOVE_REGIONID', { regionid: '0' });
        dispatchUserEvent('REMOVE_ITEMMASTERDETAILS', { itemmasterdetails: [] });
        dispatchUserEvent('REMOVE_CARTSUMMARY', { cartsummary: [] });
        localStorage.setItem('loginid', '0');
        localStorage.setItem('centreid', '0');
        localStorage.setItem('districtid', '0');
        localStorage.setItem('regionid', '0');
        localStorage.setItem('itemmasterdetails', []);
        localStorage.setItem('cartsummary', []);
        localStorage.removeItem('lid');
        localStorage.removeItem('cid');
        localStorage.removeItem('did');
        localStorage.removeItem('rid');
        localStorage.removeItem('itemmasterdetails');
        localStorage.removeItem("appdate");
        localStorage.removeItem("apptime");
        localStorage.removeItem("appaddress");
        localStorage.removeItem("apptimetext");
        localStorage.removeItem("addedmoreitems");
        localStorage.removeItem("RateTempId");
        BindGrid();
    }
    const toggleModal = () => {
        setModal(!modal)
    }
    const changeCustMobHandle = (e) => {
        setMobNo(e.target.value);
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") {
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        }
        else if (ErrorCode == "401") {
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        }
        else if (ErrorCode == "403") {
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        }
        else if (ErrorCode == "404") {
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        }
        else if (ErrorCode == "500") {
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        }
        else if (ErrorCode == "503") {
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
        }
    }
    return (
        <>
            <Form.Row>
                <Col md={10}>
                    <Breadcrumb title="Appointments" parent="Outbound Calls" />
                </Col>
                {
                    appointmentid === "0" ? null :
                        <>
                            <Col md={2} style={{ textAlign: 'right', marginTop: 25, right: 14 }}>
                                <Button variant='secondary' type='submit' onClick={() => ClearStoredState()}>Back</Button>
                            </Col>
                        </>
                }
            </Form.Row>
            <Container fluid>
                {
                    appointmentid === "0" ?
                        <>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Header className='pb-0'>
                                            <Form.Row>
                                                <Col md={10}>
                                                    <Card.Title>Appointment List</Card.Title>
                                                </Col>
                                            </Form.Row>
                                        </Card.Header>
                                        <Card.Body>
                                            <DataTable
                                                keyField='AppointmentId'
                                                tableData={table}
                                                columns={columns}
                                                pagination={paginationFactory(paginationOptions)}
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            {
                                userexistflag === "" ? null
                                    :
                                    <AppointmentDetails AppointmentId={appointmentid} Age={age} Gender={gender} CenterId={centerid} UserExistFlag={userexistflag} Status={status} IsConfirmed={isconfirmed} />
                            }
                        </>
                }
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Edit Customer Mobile Number</ModalHeader>
                    <ModalBody>
                        <form>
                            <div className="form-group">
                                <label className="col-form-label">Mobile:</label>
                            </div> <input className="form-control" type="text" id='txtcustmobile' value={mobno} onChange={changeCustMobHandle} name='txtcustmobile' />
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        {
                            IsLoadButton === true ?
                                <Loading />
                                :
                                <Button color="primary" onClick={AddFinalCall}>Update and Call</Button>
                        }
                    </ModalFooter>
                </Modal>
            </Container>
        </>
    )
}

export default Appointments;