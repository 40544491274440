import React, { useEffect, useState,useRef,Fragment,createRef} from 'react';
import Breadcrumb from '../../commonComponent/common/breadcrumb';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import ConnectionInstance from '../../apisettings/ConnectionInstance';
import { useFormik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DataTables from '../../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../../commonComponent/DataTable/NoRecordFounds';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import CallToolTip from '../../UI/ToolTips/CallToolTip';
import { analytics } from 'firebase';
import ViewToolTip from '../../UI/ToolTips/ViewToolTip';
import EditToolTip from '../../UI/ToolTips/EditToolTip';
 import { useHistory } from 'react-router';

const  AutoCallList=[];
const AutomationCalls = () => {
    const searchValue = useRef();
    const [CallCentreExecutiveList, setCallCentreExecutiveList] =useState(0);
    const[ExcutiveSelectionIds,SetExcutiveSelectionIds]=useState("");
    const CalltypeSelctionRef = useRef();
    const[CallTypeSelection,SetCallTypeSelection]=useState("0");
    const [CallTypeData, setCallTypeData] = useState(0);
    const[CCLoading,SetCCLoading]=useState(false);
    const [CallstatusDLL, SetCallstatusDLL] = useState(0);
    const [LeadSourceDDL, SetLeadSourceDDL] = useState(0);
    const [LeadServiceDDL, SetLeadServiceDDL] = useState(0);
     const[CallStatusIds,SetCallStatusIds]=useState("");
     const[CallSourceIds,SetCallSourceIds]=useState("");
     const[CallServiceIds,SetCallServiceIds]=useState("");
    const [crntPageNO, setCrntPageNO] = useState(0);
    const[currentStep,SetcurrentStep]=useState(1);
    const [TeamMData, SetTeamMData] = useState(0);
    const[UserLogin,SetUserLogin]=useState(1);
    const [ModalBodyHtml, SetModalBodyHtml] = useState("");
    const[ManageOutBound,SetManageOutBound]=useState(null);
     const[SelectDS,setSelectDS]=useState(null);
     const navigate=useHistory();
     const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsAssignedCallDlists: []

    });

    const paginationOptions = {

        totalSize:PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            asyncFunBindAutomatedCallDetails(page, sizePerPage);
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            asyncFunBindAutomatedCallDetails(page, sizePerPage);
        }
    };

    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        //console.log(cellData, rowData);
        return <React.Fragment>
             <div>
                 
     <p>
     <span className='grdH3'><strong>Executive:{(rowData.ExecutiveName!="")?(rowData.ExecutiveName):'N/A'}</strong></span><br/>
     <span className='grdH3'>Activity Date: {(rowData.CallUpdatedDate!="")?(rowData.CallUpdatedDate):'N/A'}</span><br/>
        <span className='grdH3'>Follow Up: {(rowData.CFollowUpDate!="" && rowData.CTime!="")?(rowData.CFollowUpDate +' | '+ rowData.CTime):'N/A'}</span><br/>
      </p>
        </div>
    
 
        
                   {/* {<input type="checkbox" style={{ marginLeft: 90 }} checked={cellData} ></input>} */}
          

        </React.Fragment>
    }
    const LatestActiveFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
     <span className='grdH3'><strong>Team: {(rowData.TeamName!="")?(rowData.TeamName):'N/A'}</strong></span><br/>
     <span className='grdH3'>Call Service: {(rowData.LeadService!="")?(rowData.LeadService):'N/A'}</span><br/>
        <span className='grdH3'>Call Source: {(rowData.LeadSource!="")?(rowData.LeadSource):'N/A'}</span><br/>
       <span className='grdH3'>Call Status: {rowData.CallStatus!=""?rowData.CallStatus:'N/A'}</span><br/>
      </p>
        </div>
        </React.Fragment>
    }
    const CallQueueFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
       <span className='grdH3'><strong>Queue: {(rowData.FlowName!="")?(rowData.FlowName):'N/A'}</strong></span><br/>
       <span className='grdH3'>IVR: {(rowData.IVR!="")?(rowData.IVR):'N/A'}</span><br/>
       <span className='grdH3'>FLOW: {(rowData.Flow!="")?(rowData.Flow):'N/A'}</span><br/>
      </p>
        </div>
        </React.Fragment>
    }
    const CallTypeFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
       <span className='grdH3'><strong>{(rowData.CallModuleName!="")?(rowData.CallModuleName):'N/A'}</strong></span><br/>
        <span className='grdH3'>{(rowData.CallType!="")?(rowData.CallType):'N/A'}</span><br/>
      </p>
        </div>
        </React.Fragment>
    }
    const ViewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
        <div >
            <ViewToolTip title="View" onClick={()=>viewAutocallHandler(rowData.CallingId)} />   
        </div>
    </React.Fragment>
    }

   
    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
          let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
          return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => {
            return { width: '9%'};
            }
       
        //sort: true,
        // formatter: idFormatter,
        // onSort: (field, order) => {
        //     console.log(field, order);
        // }
    },
    {
        dataField: 'CustomerName',
        text: 'Customer Name'
    },
    {
        dataField: 'CustomerMobile',
        text: 'Customer Mobile'
    },
    {
        isDummyField: true,
        text: 'Lastest Activity',
        formatter: LatestActiveFormatter,
        editable: false 
    },
    // {
    //     dataField: 'MSourceName',
    //     text: 'Source'
    // },
    {
        text: 'Call Queue',
        formatter: CallQueueFormatter,
        editable: false
    },
    {
        text: 'Call Type',
        formatter: CallTypeFormatter,
        editable: false 
    },
    
    {
        isDummyField: true,
        text: 'Date',
        formatter: actionFormatter,
        editable: false 
    },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        formatter: ViewFormatter
        
       

    }
    ];
    

    useEffect(() => {
        
        asyncFunCallTeamDDL();
        asyncFunCallExecutiveDDL();
        setVisible(true);
    }, []);
    async function  asyncFunCallExecutiveDDL() {
        try {
         
        let url=ConnectionInstance+ 'master/GetCallCentreExcutiveDDList';
        let options = {
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {}
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if(data.Success==true)
                { let ds=data.Data;
                    if(ds!=null && ds.length>0)
                    {
                        setCallCentreExecutiveList(ds.map((option) => (<option value={option.UserId}>{option.UserName}</option>)));                                         
                    }
                }
                else{
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                   setErrorPopUp(Errtext);
                   setErrorModal(true);
                    console.log('no record found');
                }
               
                //setUserData(data.map((option) =>(<option value={option.sNo}>{option.ename}</option>)));
                // do something with data
            }
            else{
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                setErrorPopUp(Errtext);
                setErrorModal(true);
                console.log('no record found');
            }
         // return data;
        } catch (error) {
            console.log(error);
            let Errtext=<p><div className='text-required'>{error} </div></p>;
            setErrorPopUp(Errtext);
            setErrorModal(true);
          //alert(error);
        }
      }
      async function asyncFunCallTypeDDL() {
        try {
         
        let url=ConnectionInstance+ 'master/GetCallTypeDetailsDDList';
        let options = {
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {}
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if(data.Success==true)
                { let ds=data.Data;
                    if(ds!=null && ds.length>0)
                    {
                   setCallTypeData(ds.map((option) =>(<option value={option.CallTypeId}>{option.CallType}</option>)));   
                    }
                }
                else{
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                   setErrorPopUp(Errtext);
                   setErrorModal(true);
                    console.log('no record found');
                }
               
                //setUserData(data.map((option) =>(<option value={option.sNo}>{option.ename}</option>)));
                // do something with data
            }
            else{
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                setErrorPopUp(Errtext);
                setErrorModal(true);
                console.log('no record found');
            }
         // return data;
        } catch (error) {
            console.log(error);
            let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            setErrorPopUp(Errtext);
            setErrorModal(true);
          //alert(error);
        }
      }
    const [visible, setVisible] = useState(false);

    const [value, setValue] = useState('0');

    const changeHandler = (e) => {
        setValue(e.target.value);
        //alert(e.target.value);
        const s1 = e.target.value;
        if (s1 === "1" || s1 === "2" || s1 === "3") {
            setVisible(true);
        }
        if (s1 === "0") {
            setVisible(false);
        }
    }

    const viewAutocallHandler=async (CallingId) => {
            localStorage.removeItem('CallingId');
            localStorage.setItem('CallingId', CallingId);
            navigate.push(`/admin/outbound/viewassignedcalls`);
         
            // try {
    
            //     let url=ConnectionInstance+ 'OutboundCalling/UpdateCallCentreCallProcessStatusbyId';
    
            //     let options = {
            //         method: 'POST',
            //         url: url,
            //         headers: {
            //             'Accept': 'application/json',
            //             'Content-Type': 'application/json;charset=UTF-8'
            //         },
            //       data:{CallingId: `${callingid}`,AddedBy: `${LoginId}`, ProcessStatusId:`${2}`}
            //     };
    
            //     let response = await axios(options);
            //         let responseOK = response && response.status == 200;
            //         if (responseOK) {
            //             let data = response.data;
            //             // let data = await response.data;
            //             if(data.Success==true && data.Data=="2000")
            //             {
                           
            //                 setModal(false);
            //                 setErrorModal(false);
            //                 navigate.push(`/admin/outbound/addassigncalls`);
            //             }
            //             else{
            //                  if(data.ErrorList!=null && data.ErrorList.length>0)
            //                  {
            //                     let Errtext=<p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
            //                     SetModalBodyHtml(Errtext);
            //                      setModal(!modal);
            //                      setErrorModal(true);
            //                  }
    
            //             }
    
    
            //         }
            //         else{
            //             console.log('no record found');
    
            //         }
            //      // return data;
            //     } catch (error) {
            //         console.log(error.message);
            //         let Errtext="";
            //             Errtext =<p><div className='text-required'>{error.message} </div></p>;
            //         SetModalBodyHtml(Errtext);
            //         setModal(!modal);
            //         setErrorModal(true);
            //      }
           
          
        

     }
     const searchHandler = () => { 
        asyncFunBindAutomatedCallDetails(1, 10); }

    const assignHandler = () => {
        toggleModal(); 
        setErrorModal(true);
        formik.handleSubmit();
        // alert(JSON.stringify(SelectDS));
        //console.log(JSON.stringify(SelectDS));
       // SweetAlert.fire({ title: "Success!", text: "Calls have been Assigned Successfully!", icon: "success" });
    }
 /********** Modals  ***********/
 const [modal, setModal] = useState();
 const toggleModal = () => {
     setModal(!modal)
 }
 const[ErrorPopUp, setErrorPopUp]=useState(0);
 const [errorModal, setErrorModal] = useState(false);

 /********** Validations  ***********/
 const formik = useFormik({
    initialValues: {
        TeamId: ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
        // email: Yup.string().email('Invalid email address').required('Required'),
        TeamId: Yup.string().transform(v => v=== '0' ? '' : v)
        .required('Team is Required')
    }),

    onSubmit:  async (values) => {
    
       
       if(SelectDS!=null)
       { 
           
        setModal(false);
         setErrorModal(false);
         SetModalBodyHtml("");
        try {

            let url=ConnectionInstance+ 'OutboundCalling/SETCallingAutoAssignedDetails';

            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
              data:{TeamId: `${values.TeamId}`,AddedBy: `${LoginId}`,TblcallList:SelectDS}
            };

            let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if(data.Success==true && data.Data=="2000")
                    {
                       
                        SweetAlert.fire({ title: "Success!", text: "Calls have been Assigned Successfully!", icon: "success" });
                        asyncFunBindAutomatedCallDetails(1, 10);
                        setModal(false);
                        setErrorModal(false);
                        formik.resetForm();
                      
                    }
                    else{
                         if(data.ErrorList!=null && data.ErrorList.length>0)
                         {
                            let Errtext=<p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                            SetModalBodyHtml(Errtext);
                             setModal(!modal);
                             setErrorModal(true);
                         }

                    }


                }
                else{
                    console.log('no record found');

                }
             // return data;
            } catch (error) {
                console.log(error.message);
                let Errtext="";
                    Errtext =<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);
             }
       }
       else{
        let Errtext=<p><div className='text-required'>Selection call required </div></p>;
        SetModalBodyHtml(Errtext);
        setErrorModal(true);
        setModal(true);
       
       }
     
    },
});
    async function asyncFunCallTeamDDL() {
        
        try {

            let url = ConnectionInstance + 'master/GetCallCentreTeamDDList';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds =data.Data;
                    if (ds != null && ds.length > 0) {
                        SetTeamMData(ds.map((option) => (<option value={option.TeamID}>{option.TeamName}</option>)));
                 setModal(false);
                setErrorModal(false);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SetModalBodyHtml(Errtext);
                    setModal(!modal);
                    setErrorModal(true);
                  
                    // modelpopUp(toggleModal,Errtext); 
                    console.log('no record found');
                }
            }
            else {
              
                let Errtext = <p><div className='text-required'> no record found </div></p>;
               
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);
              
                console.log('no record found');
            }
           
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext="";
                Errtext =<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);
           
        }
       
    }
    useEffect(() => {
        asyncFuncCallStatusDDL();
        FuncLeadSourceDDL();
        FuncLeadServiceDDL();
        asyncFunBindAutomatedCallDetails(1, 10);   
    }, []);


    async function  asyncFunBindAutomatedCallDetails(PageNo, PageSize) {
        try {
            SetCCLoading(true);
        let url=ConnectionInstance+ 'OutboundCalling/GetCallCentreAssignedAutomatedCalls';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {  PageNo: `${PageNo}`, PageSize: `${PageSize}`, LeadServiceId:`${CallServiceIds}`,LeadSourceId:`${CallSourceIds}`,CallStatusId:`${CallStatusIds}`, ExecutiveId:`${ExcutiveSelectionIds}`,
                SearchTxt:`${searchValue.current.value}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                SetCCLoading(false);
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        //SetPageHelper({});
                        await SetPageHelper({
                            ...PageHelper,
                             PageNo:Number(PageNo),
                             PageSize: Number(PageSize),
                            TotalRecord: Number(ds.TotalRecord),
                            dsAssignedCallDlists: ds.CallCentreAssignedcallList == null ? [] : ds.CallCentreAssignedcallList
                        });


                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    setErrorPopUp(Errtext);
                    setErrorModal(true);
                    console.log('no record found');
                }

                //setUserData(data.map((option) =>(<option value={option.sNo}>{option.ename}</option>)));
                // do something with data
            }
            else {
                SetCCLoading(false);
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                setErrorPopUp(Errtext);
                setErrorModal(true);
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            SetCCLoading(false);
            console.log(error);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            setErrorPopUp(Errtext);
            setErrorModal(true);
            //alert(error);
        }
    }
    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: (rowData, isSelect, rowIndex, e) => {
            const AutoCallListArr=[];
            let fiterArr;
           // setSelectDS(null); 
            const AutoCallRow={
                'CallingId':rowData.CallingId
            }
            if(isSelect)
             {
              
                if(SelectDS!=null && SelectDS.length>0)
                 {

                     let fiter=SelectDS.filter((item)=>{

                         return  item.callingid!=rowData.callingid
                      });
                      if(fiter.length==0)
                      {
                        
                        //AutoCallList.push(AutoCallRow);
                        SelectDS.push(AutoCallRow);
                      }
                 }
                 else{
                    setSelectDS([AutoCallRow]);
                 }
            
                //alert(rowData.CallingId +'Status:'+ isSelect)
             }
             else{
                if(SelectDS!=null && SelectDS.length>0)
                {
                    fiterArr=SelectDS.filter((item)=>{
                 
                        return  item.CallingId!=rowData.CallingId
                     });
                }
                //alert(rowData.CallingId+'|Status:'+isSelect); 
                setSelectDS(fiterArr); 
             }
            
            //  if(isSelect)
            //  {
            //     const AutoCallRow={
            //         'CallingId':rowData.CallingId,
            //         'CheckedItem':isSelect
            //     }
            
            //     alert(rowData.CallingId +'Status:'+ isSelect)
            //  }
            //  else{
            //     alert(rowData.CallingId+'|Status:'+isSelect); 
            //  }
            //  const AutoCallRow={
            //     'CallingId':rowData.CallingId,
            //     'CheckedItem':isSelect
            // }
            //  AutoCallList.push(AutoCallRow);
            
         
            // if (SOME_CONDITION) {
            //   return false;
            // }
        },
        onSelectAll: ( isSelect,rowData, e) => {
            setSelectDS(null);
            const  AutoCallListSelectedAll=[];
            if (isSelect) {
                if(rowData.length>0)
                {
                   for(let i=0;i<rowData.length; i++)
                   {
                       const AutoCallRow={
                           'CallingId':rowData[i].CallingId,
                       }
                       AutoCallListSelectedAll.push(AutoCallRow);
                      // alert( rowData[i].CallingId +'-:Status:'+ isSelect)
                   }
                   setSelectDS(AutoCallListSelectedAll); 
                }
               //return this.props.myReducer.myDataItems.map( row => row.DATA_TABLE1 );
            }
                 
               
           
           
            
            
            // if(isSelect)
            // {
            //      if(rowData.length>0)
            //      {
            //         for(let i=0; i<rowData.length; i++)
            //         {
            //             alert( rowData[i].CallingId +'-:Status:'+ isSelect)
            //         }
            //      }
               
              
            // }
          }
      };
      const CallStatusHandler = (e) => {
        const val = e.target.value;
        SetCallStatusIds(val);
    }
    const LeadSourceHandler = (e) => {
        const val = e.target.value;
        SetCallSourceIds(val);
       
    }
    const LeadServiceHandler = (e) => {
        const val = e.target.value;
        SetCallServiceIds(val);
    }
    
    const CallTypeHandler = (e) => {
        const val = e.target.value;
        SetCallTypeSelection(val);
    }
    const ExecutiveSelectionHandler = (e) => {
        const val = e.target.value;
        SetExcutiveSelectionIds(val);
       
    }
    async function asyncFuncCallStatusDDL() {
        try {

            let url = ConnectionInstance + 'master/GetCallStatusDDList';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        SetCallstatusDLL(ds.map((option) => (<option value={option.CallStatusId}>{option.CallStatus}</option>)));
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                }

            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = "";
            Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);
            //alert(error);
        }
    }

 const FuncLeadSourceDDL=async()=>{

    try {

        let url = ConnectionInstance + 'master/GetBindLeadSourceDDList';
        let options = {
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {}
        };
        let response = await axios(options);
        let responseOK = response && response.status == 200;
        if (responseOK) {
            let data = response.data;
            // let data = await response.data;
            if (data.Success == true) {
                let ds = data.Data;
                if (ds != null && ds.length > 0) {
                    SetLeadSourceDDL(ds.map((option) => (<option value={option.SNO}>{option.Source}</option>)));
                }
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                console.log('no record found');
            }

        }
        else {
            let Errtext = <p><div className='text-required'> no record found </div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);
            console.log('no record found');
        }
        // return data;
    } catch (error) {
        console.log(error.message);
        let Errtext = "";
        Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
        SetModalBodyHtml(Errtext);
        setModal(!modal);
        setErrorModal(true);
    }
 }  
   
 const FuncLeadServiceDDL=async()=>{

    try {

        let url = ConnectionInstance + 'master/GetBindLeadServiceDDList';
        let options = {
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {}
        };
        let response = await axios(options);
        let responseOK = response && response.status == 200;
        if (responseOK) {
            let data = response.data;
            // let data = await response.data;
            if (data.Success == true) {
                let ds = data.Data;
                if (ds != null && ds.length > 0) {
                    SetLeadServiceDDL(ds.map((option) => (<option value={option.SNO}>{option.LeadService}</option>)));
                }
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                console.log('no record found');
            }

        }
        else {
            let Errtext = <p><div className='text-required'> no record found </div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);
            console.log('no record found');
        }
        // return data;
    } catch (error) {
        console.log(error.message);
        let Errtext = "";
        Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
        SetModalBodyHtml(Errtext);
        setModal(!modal);
        setErrorModal(true);
    }
 } 
    return (
        <>
         <div id="cover-spin"  style={{display: CCLoading ? 'block' : 'none'}}></div> 
            <Breadcrumb title="Follow Up Calls" parent="Admin" />

            <Container fluid>

                 <Row>
                    <Col md={12}>
                        <Card>
                          
                            <Card.Body>
                               
                                    <Form.Row className="justify-content-center">
                                        <Col md={4}>
                                        <Form.Group>
                                                        <Form.Label>Call Service</Form.Label>
                                                        <Form.Control as="select" id="ldservice" name="ldservice"
                                                            onChange={LeadServiceHandler}
                                                            value={CallServiceIds}>
                                                            <option value='0'>---Select All---</option>
                                                            {LeadServiceDDL}
                                                        </Form.Control>
                                                    </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                        <Form.Group>
                                                        <Form.Label>Call Source</Form.Label>
                                                        <Form.Control as="select" id="lsource" name="lsource"
                                                            onChange={LeadSourceHandler}
                                                            value={CallSourceIds}>
                                                            <option value='0'>---Select All---</option>
                                                            {LeadSourceDDL}
                                                        </Form.Control>
                                                    </Form.Group>
                                        </Col>
                                       
                                    </Form.Row>
                                    <Row className='justify-content-center mt15'>
                                    <Col md={4}>
                                        <Form.Group>
                                                        <Form.Label>Call Status</Form.Label>
                                                        <Form.Control as="select" id="CallStatus" name="CallStatus"
                                                            onChange={CallStatusHandler}
                                                             value={CallStatusIds}>
                                                            <option value='0'>---Select All---</option>
                                                            {CallstatusDLL}
                                                       </Form.Control>
                                                    </Form.Group>
                                        </Col>
                                 
                                       <Col md={4}>
                                           <Form.Group>
                                           <Form.Label>Executive Name</Form.Label>
                                               <Form.Control as="select" id="ExecutiveId" name="ExecutiveId"  onChange={ExecutiveSelectionHandler}
                                                 
                                                   value={ExcutiveSelectionIds}  >
                                                   <option value='-1'>---Select All---</option>
                                                   {CallCentreExecutiveList}
                                               </Form.Control>

                                           </Form.Group>
                                       </Col>
                                       
                                       </Row>
                                    <Row className='justify-content-center mt15'>
                                <Col md={5}>
                                    <Form>
                                        <div className="inner-addon right-addon">
                                            <i className="icon fa fa-search"></i>
                                            <Form.Group className="form-group">
                                                <Form.Control type="text" className='searchControl' ref={searchValue}   placeholder="Search customer name, mobile no., IVR, FLOW, Team" />
                                            </Form.Group>
                                        </div>
                                    </Form>
                                </Col>
                                <Col md={2}>
                                <Button variant="primary" type="submit" className='btn-block' onClick={searchHandler} > Search</Button>                                            
                                </Col>
                            </Row>      
                                    {/* <Form.Row className='mt15'>
                                        <Col md={12} className='text-center'>
                                            <Button onClick={searchHandler}>Search</Button>
                                        </Col>
                                    </Form.Row> */}
                                
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {visible &&
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form.Row className='justify-content-center'>
                                        <Col md={6} lg={4}>
                                        <Form.Group>
                                                <Form.Label>Team <span className='text-required'>*</span></Form.Label>
                                                <Form.Control as="select" id="TeamId" name="TeamId"  onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.TeamId}  >
                                                    <option value='0'>---select---</option>
                                                    {TeamMData}
                                                </Form.Control>
                                                {formik.touched.TeamId && formik.errors.TeamId ? (
                                                    <div className='text-required'>{formik.errors.TeamId}</div>
                                                ) : null}

                                   
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>

                                    {/* <div className="table-responsive mt15"> */}
                                    <div className="mt15">
                                    <DataTables
                                    keyField='CallingId'
                                    tableData={PageHelper.dsAssignedCallDlists}
                                    columns={columns}
                                    pagination={paginationFactory(paginationOptions)}
                                    selectRow={ selectRow }

                                // cellEdit={cellEditFactory({
                                //     mode: 'dbclick',//dbclick
                                //     blurToSave: true
                                // })}
                                // filter={filterFactory()}
                                />
                                    </div>
                                    <div className='mt15 text-center'>
                                        <Button variant='secondary' onClick={assignHandler}>Assign Calls</Button>
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }


            </Container>
            {
                errorModal===true?
                 (<Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>
                    <><p>{formik.touched.TeamId && formik.errors.TeamId ? (
                            <div className='text-required'>{formik.errors.TeamId}</div>
                            ) : null}</p>
                            <p>{SelectDS==null?<div className='text-required'>Selection call required</div>:''}
                            </p>
                            </> 
                     
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>
                    
                </Modal>)
                    : null
            }
        </>
    )
}

export default AutomationCalls;