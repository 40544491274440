import React, { useState, useRef, useEffect, useContext } from 'react';
import Breadcrumb from '../commonComponent/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import ConnectionInstance from '../apisettings/ConnectionInstance';
import DataTable from '../commonComponent/DataTable/DataTable';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolTip from '../UI/ToolTips/ViewToolTip';
import { GlobalInfo } from '../../components/context/context';
import AppointmentDetails from '../../pages/commonComponent/cart/appointmentdetails';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import UncontrolledTooltip from '../UI/ToolTips/AddToolTip';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import LoadingButton from '../commonComponent/Usecomponent/loadingbutton';

const AssignPhlebo = () => {
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [Location, SetLocation] = useState([]);
    const [TableData, setTableData] = useState([]);
    const [SelectDS, setSelectDS] = useState(0);
    const [appointmentid, setAppointmentId] = useState("0");
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [centerid, setCenterId] = useState("0");
    const [userexistflag, setUserExistFlag] = useState("");
    const { dispatchUserEvent, loginid, centreid, } = useContext(GlobalInfo);
    const [status, setStatus] = useState("0");
    const [isconfirmed, setIsConfirmed] = useState("0");
    const [mobno, setMobNo] = useState("0");
    const [routephlebos, setRoutePhlebos] = useState([]);
    const [phlebotimeschedule, setPhleboTimeSchedule] = useState([]);
    const [phlebohourlytimeschedule, setPhleboHourlyTimeSchedule] = useState([]);
    const [slottiming, setSlotTiming] = useState([]);
    const [openmodal, setOpenModal] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [appid, setAppId] = useState("0");
    const [defaultDate, setDefaultDate] = useState("");
    const [Routelist, SetRoutelist] = useState([]);
    const [phleboId, SetPhleboId] = useState(0);
    const [openPhlebomodal, setOpenPhleboModal] = useState(false);
    const [phleboappid, setPhleboAppId] = useState("0");
    const [customerdetails, setCustomerDetails] = useState("");
    const [appointmentDateTime, setAppointmentDateTime] = useState("");
    const [totalPatientage, setTotalPatientAge] = useState("");
    const [notes, setSpecialNotes] = useState("");
    let dtime = useRef();
    let DateId = useRef();
    let routeid = useRef();
    let Locid = useRef();
    let userId = useRef();
    let routename = useRef();
    let appointmentDateId = useRef();
    var finaltime = "";
    let frmPhlebotime = useRef();
    let toPhlebotime = useRef();
    let specialNotes = useRef();
    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        BindLocation(defaultValue);
        GetSlotTiming(defaultValue);
        if (localStorage.getItem("RouteDate") !== null) {
            const fdate = localStorage.getItem("RouteDate");
            const date = new Date(fdate);
            const finalyear = date.getFullYear();
            const finalmonth = ((date.getMonth() + 1).toString().length != 2 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1))
            const finaldate = (date.getDate().toString().length != 2 ? "0" + date.getDate() : date.getDate())
            const defaultValue = finalyear + "-" + finalmonth + "-" + finaldate;
            DateId.current.value = defaultValue;
        }
        return () => {
            localStorage.removeItem("RouteDate");
            localStorage.removeItem("RouteStartTime");
            localStorage.removeItem("RouteEndTime");
            localStorage.removeItem("LocationId");
            localStorage.removeItem("RouteName");
            localStorage.removeItem("AppId");
            localStorage.removeItem("samplerid");
            localStorage.removeItem("sampledid");
            localStorage.removeItem("samplecid");
            localStorage.removeItem("sampleitemmasterdetails");
        }
    }, [])
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const AppointmentFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {rowData.AppointmentDate != "" && rowData.AppointmentTime != "" ? rowData.AppointmentDate + ' | ' + rowData.AppointmentTime : rowData.AppointmentDate}
                &nbsp; <EditToolTip title="Edit" onClick={() => editHandler(rowData)} />
            </div>
        </React.Fragment>
    }
    const TotalPatientFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            {rowData.TotalPatients + ' | ' + '( ' + rowData.Age + ' )'}
        </React.Fragment>
    }
    const CustomerDetailsFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <p>{rowData.CustomerName}<br />{rowData.CustomerMobile}</p>
            </div>

        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <ToolTip title="Details" placement="top" id={`view${rowData.AppointmentId}`} onClick={() =>
                GetWebsiteAppointmentDetails(rowData.AppointmentId, rowData.UserAge, rowData.Gender, rowData.CentreId, rowData.UserId, rowData.MobileNo)} />
            &nbsp; <UncontrolledTooltip title="Assign Phlebo" onClick={() => AssignPhleboHandler(rowData)} />
        </React.Fragment>
    }   
    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        onSelect: (rowData, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSelectDS(rowData.AppointmentId);
            }
            else {
                setSelectDS(0);
            }
        }
    }
    const columns = [
        {
            dataField: '#',
            text: '#',
            formatter: idFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }
        },
        {
            dataField: 'CustomerName',
            text: 'Customer Details',
            formatter: CustomerDetailsFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: '10%' };
            }
        },
        {
            dataField: 'Address',
            text: 'Address'
        },
        {
            dataField: 'AppointmentTime',
            text: 'Appointment Date',
            formatter: AppointmentFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: '20%' };
            }
        },
        {
            dataField: 'TotalPatients',
            text: 'Total Patients | Age',
            formatter: TotalPatientFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: '15%' };
            }
        },
        {
            dataField: 'StatusName',
            text: 'Status'
        },
        ,
        {
            dataField: 'LastPhlebo',
            text: 'Last Phlebo'
        },
        {
            dataField: 'Action',
            text: 'Action',
            formatter: actionFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }
        }
    ];
    const idphleboFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columnsphlebo = [
        {
            dataField: '#',
            text: '#',
            formatter: idphleboFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: '9%' };
            }
        },
        {
            dataField: 'RouteName',
            text: 'Route Name'
        },
        {
            dataField: 'Name',
            text: 'Patient Name'
        },
        {
            dataField: 'Address',
            text: 'Patient Address'
        },
        {
            dataField: 'LocationName',
            text: 'Location Name'
        },
        {
            dataField: 'CentreName',
            text: 'Centre Name'
        },
        {
            dataField: 'AppointmentTime',
            text: 'Appointment Time'
        },
        {
            dataField: 'AppointmentStatus',
            text: 'Appointment Status'
        }
    ];
    const BindRoute = async (LocationId) => {
        if (LocationId != "0") {
            try {
                setIsButtonLoading(true);
                let url = ConnectionInstance + 'cart/getroutebylocation/' + LocationId
                await axios.get(url).then((response) => {
                    const data = response.data;
                    setIsButtonLoading(false);
                    if (data.Success) {
                        if (data.Data !== null) {
                            if (data.Data === null) { SetRoutelist([]); setTableData([]); }
                            else { setTableData([]); SetRoutelist(data.Data.map((d) => (<option key={d.RouteId} value={d.RouteId}>{d.RouteName}</option>))); }
                        }
                        else { SetRoutelist([]);setTableData([]);  }
                    }
                    else { SetRoutelist([]); setTableData([]); }
                }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsButtonLoading(false); });
            } catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        }
        else {
            SetRoutelist([]); setTableData([]);
        }
    }
    const BindLocation = async (Date) => {
        try {
            setIsButtonLoading(true);
            let url = ConnectionInstance + 'cart/getappointmentlocation/' + Date;
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data === null) { SetLocation([]); setTableData([]); }
                    else { setTableData([]); SetLocation(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.DistrictName}</option>))); }
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsButtonLoading(false); }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsButtonLoading(false); });
        } catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const editHandler = (rowData) => {
        setOpenModal(true);
        setAppId(rowData.AppointmentId);
    }
    const AssignPhleboHandler = (rowData) => {
        setSpecialNotes(rowData.SpecialNotes);
        setOpenPhleboModal(true);
        setPhleboAppId(rowData.AppointmentId);
        setCustomerDetails(rowData.CustomerName + ' | ' + rowData.CustomerMobile)
        setAppointmentDateTime(rowData.AppointmentDate + ' | ' + rowData.AppointmentTime)
        setTotalPatientAge(rowData.TotalPatients + ' | ' + '( ' + rowData.Age + ' )')
    }
    const handleSearch = async () => {
        BindLocation(DateId.current.value);
    }
    const handleSearchList = async () => {
        BindGrid(DateId.current.value, Locid.current.value, routeid.current.value);
        GetPhleboSchedule(routeid.current.value);
    }
    const BindGrid = async (Date, Location, Route) => {     
        try {
            setIsButtonLoading(true);
            let url = ConnectionInstance + 'cart/GetRouteAssignedCustomers/' + Date + '/' + Location + '/' + "/" + Route
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data === null || data.Data.length === 0) {
                        setTableData([]);
                        userId.current.value = "0";
                        BindLocation(DateId.current.value);
                    }
                    else {
                        setTableData(data.Data);             
                    }
                    setIsButtonLoading(false);
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsButtonLoading(false); }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsButtonLoading(false); });
        } catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsButtonLoading(false); }
    }
    const AssignPhleboFunc = async () => {
        if (DateId.current.value === "") { SweetAlert.fire("Please enter date"); return false; }
        else if (phleboappid == 0 || phleboappid == undefined) { SweetAlert.fire("Please select customer"); return false; }
        else if (routeid.current.value === "") { SweetAlert.fire("Please select route name"); return false; }
        else if (phleboId === 0) { SweetAlert.fire("Please select phlebo"); return false; }
        else if (frmPhlebotime.current.value === "00:00" && frmPhlebotime.current.value === "") { SweetAlert.fire("Please enter from time"); return false; }
        else if (frmPhlebotime.current.value !== "00:00" && toPhlebotime.current.value === "") { SweetAlert.fire("Please enter to time"); return false; }
        else if (frmPhlebotime.current.value >= toPhlebotime.current.value) { SweetAlert.fire("Please enter valid from & to time"); return false; }
        else {
            const requestOptions = {
                LoginId: localStorage.LoggedInUser,
                Date: DateId.current.value,
                FromTime: frmPhlebotime.current.value === "" ? "00:00:00" : frmPhlebotime.current.value,
                ToTime: toPhlebotime.current.value === "" ? "00:00:00" : toPhlebotime.current.value,
                RouteID: routeid.current.value,
                AppointmentId: phleboappid,
                PhleboId: phleboId,
                SpecialNotes:specialNotes.current.value
            };
            setIsButtonLoading(true);
            let url = ConnectionInstance + 'Cart/AssignPhlebo'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    SweetAlert.fire({
                        text: "Assigned to phlebo",
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        reverseButtons: true,
                    })
                        .then((result) => {
                            if (result.isConfirmed) {
                                setSelectDS(0);
                                setOpenPhleboModal(false);
                                BindGrid(DateId.current.value, Locid.current.value, routeid.current.value);
                                GetPhleboSchedule(routeid.current.value);
                               
                                // setTableData([]);
                                // Locid.current.value = "0";
                                // SetLocation([]);                       
                                // window.location.reload(false);
                                // routeid.current.value = "";
                                // frmPhlebotime.current.value = "";
                                // toPhlebotime.current.value = "";
                            }
                            else {
                                setSelectDS(0);
                                setTableData([]);

                                // Locid.current.value = "0";
                                // SetLocation([]);
                                // window.location.reload(false);
                                // routeid.current.value = "";
                                // frmPhlebotime.current.value = "";
                                // toPhlebotime.current.value = "";
                            }
                        })
                }
                else {
                    if (data.Data == "-2001") {
                        SweetAlert.fire({ title: "Appointment Time!", text: "Appointment slot should be greater than current date & time.", icon: "error" });
                    }
                    if (data.Data == "-2002") {
                        SweetAlert.fire({ title: "Appointment Time!", text: "Appointment slot already given to another patient.", icon: "error" });
                    }
                    if (data.Data == "-2003") {
                        SweetAlert.fire({ title: "Appointment Time!", text: "Appointment slot available between 6:00 to 20:00.", icon: "error" });
                    }
                    if (data.Data == "-2004") {
                        SweetAlert.fire({ title: "Appointment Time!", text: "Appointment slot could not be greater than 1 hour.", icon: "error" });
                    }
                }
            }).catch(function (error) {
                setIsButtonLoading(false);
                if (error.response) {
                    ErrorFunction(error.response.status);
                }
            });
        }
    }
    const GetWebsiteAppointmentDetails = async (AppointmentId, UserAge, Gender, CentreId, UserId, MobileNo) => {
        setMobNo(MobileNo);
        setUserExistFlag("1");
        setAppointmentId(AppointmentId);
        setAge(UserAge);
        setGender(Gender);
        setCenterId(CentreId);
        localStorage.setItem("lid", UserId);
        setStatus("0");
        setIsConfirmed("0");
        dispatchUserEvent('ADD_LOGINDATA', { loginid: UserId });
    }
    const ClearStoredState = () => {
        setAppointmentId("0");
        setAge("");
        setGender("");
        setCenterId("0");
        dispatchUserEvent('REMOVE_LOGINDATA', { loginid: '0' });
        dispatchUserEvent('REMOVE_CENTREID', { centreid: '0' });
        dispatchUserEvent('REMOVE_DISTRICTID', { districtid: '0' });
        dispatchUserEvent('REMOVE_REGIONID', { regionid: '0' });
        dispatchUserEvent('REMOVE_ITEMMASTERDETAILS', { itemmasterdetails: [] });
        dispatchUserEvent('REMOVE_CARTSUMMARY', { cartsummary: [] });
        localStorage.setItem('loginid', '0');
        localStorage.setItem('centreid', '0');
        localStorage.setItem('districtid', '0');
        localStorage.setItem('regionid', '0');
        localStorage.setItem('itemmasterdetails', []);
        localStorage.setItem('cartsummary', []);
        localStorage.removeItem('lid');
        localStorage.removeItem('cid');
        localStorage.removeItem('did');
        localStorage.removeItem('rid');
        localStorage.removeItem('itemmasterdetails');
        localStorage.removeItem("appdate");
        localStorage.removeItem("apptime");
        localStorage.removeItem("appaddress");
        localStorage.removeItem("apptimetext");
        localStorage.removeItem("addedmoreitems");
        localStorage.removeItem("RateTempId");
        setTableData([]);
    }
    const GetPhleboSchedule = async (RouteId) => {
        if (RouteId === "0") { setRoutePhlebos([]); setPhleboTimeSchedule([]); setPhleboHourlyTimeSchedule([]); return false; }
        try {
            setIsButtonLoading(true);
            const requestOptions = {
                RouteId: routeid.current.value,
                Date: DateId.current.value,
                TimeInterval: "5"
            };
            let url = ConnectionInstance + 'cart/GetPhleboSchedule/'
            await axios.post(url, requestOptions).then((response) => {
                const result = response.data;
                if (result.Success) {
                    if (result.Data === null || result.Data.length === 0) { setRoutePhlebos([]); setPhleboTimeSchedule([]); setPhleboHourlyTimeSchedule([]); }
                    else {
                        setRoutePhlebos(result.Data.routePhlebos);
                        setPhleboTimeSchedule(result.Data.phleboTimeSchedule);
                        setPhleboHourlyTimeSchedule(result.Data.phleboHourlySchedule);
                    }
                    setIsButtonLoading(false);
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsButtonLoading(false); }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsButtonLoading(false); });
        } catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsButtonLoading(false); }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Duplicate", text: "User already exist", icon: "error" }) }
        else if (ErrorCode === "0") { SweetAlert.fire({ title: "Location not found", text: "Please select location", icon: "error" }) }
        else if (ErrorCode === "-1997") { SweetAlert.fire({ title: "Empty", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-1998") { SweetAlert.fire({ title: "Some value is missing", text: "Check server and db logs", icon: "error" }) }
        else if (ErrorCode === "-1999") { SweetAlert.fire({ title: "Runtime Exception", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    const RequestTime = (e) => {
        const value = e.target.options[e.target.selectedIndex].text;
        finaltime = value;
        if (finaltime === "---Select---") { finaltime = ""; }
    }
    const handleSlotDateChange = () => {
        GetSlotTiming(appointmentDateId.current.value);
    }
    const GetSlotTiming = async (date) => {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) { dd = '0' + dd; }
        if (mm < 10) { mm = '0' + mm; }
        var todaydate = yyyy + '-' + mm + '-' + dd;
        if (date === "") { SweetAlert.fire({ title: "Error", text: "Please select date", icon: "error" }); setSlotTiming([]); return false; }
        if (todaydate > date) { SweetAlert.fire({ title: "Error", text: "You cannot select past date slots", icon: "error" }); setSlotTiming([]); return false; }
        setIsButtonLoading(true);
        let url = ConnectionInstance + 'Cart/getslottiming/' + centreid + '/' + loginid + '/' + date
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsButtonLoading(false);
            if (data.Success) {
                setSlotTiming(data.Data.map((d) => <option key={d.SlotTimingId} value={d.SlotTimingId}>{d.FromTime} - {d.ToTime}</option>));
            }
            else { setSlotTiming = [] }
        }).catch(function (error) { setIsButtonLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const openRequiredFieldModal = () => { setOpenModal(!openmodal); }
    const updateAppDateTime = async () => {
        if (appointmentDateId.current.value === "") { SweetAlert.fire("Please enter date"); return false; }
        else if (dtime.current.options[dtime.current.selectedIndex].value == "") { SweetAlert.fire("Please select time"); return false; }
        else {
            setOpenModal(false);
            const requestOptions = {
                LoginId: localStorage.LoggedInUser,
                AppointmentId: appid,
                AppointmentDate: appointmentDateId.current.value,
                AppointmentTime: dtime.current.options[dtime.current.selectedIndex].value,
            };
            setIsButtonLoading(true);
            let url = ConnectionInstance + 'Cart/UpdateAppointmentDate'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    SweetAlert.fire({
                        text: "Successfully Appointment date & time updated.",
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setSelectDS(null);
                            setTableData([]);
                            // frmtime.current.value = "";
                            // ToTime.current.value = "";
                            Locid.current.value = "0";
                            // setUsers([]);
                            SetLocation([]);
                            window.location.reload(false);
                        }
                    })
                }
                else {
                    APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
                }
            }).catch(function (error) {
                setIsButtonLoading(false);
                if (error.response) {
                    ErrorFunction(error.response.status);
                }
            });
        }

    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const handleRadioChange = async (e) => {
        SetPhleboId(e.target.value);
        GetPhleboAvailableTime(phleboappid,e.target.value);
    }
    const openReqPhleboModal = () => { setOpenPhleboModal(!openPhlebomodal); }
    const GetPhleboAvailableTime = async (AppointmentId,PhleboId) => {
        try {
            setIsButtonLoading(true);
            let url = ConnectionInstance + 'cart/getphleboavailabletime/' + AppointmentId+'/'+PhleboId;
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data === null || data.Data=="") {                                        
                         frmPhlebotime.current.value = "";
                         toPhlebotime.current.value = ""; 
                         SweetAlert.fire({ title: "Alert", text: "Slot not available", icon: "error" }); setIsButtonLoading(false);
                        }
                    else { 
                        frmPhlebotime.current.value = data.Data.FromTime;
                        toPhlebotime.current.value = data.Data.ToTime;
                     }
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsButtonLoading(false); }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsButtonLoading(false); });
        } catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    return (
        <>
            <div id='phleboSchedule'>
                <Form.Row>
                    <Col md={10}>
                        <Breadcrumb title="Assign Phlebo" parent="Phlebo" />
                    </Col>
                    {
                        appointmentid === "0" ? null :
                            <>
                                <Col md={2} style={{ textAlign: 'right', marginTop: 25, right: 14 }}>
                                    <Button variant='secondary' type='submit' onClick={() => ClearStoredState()}>Back</Button>
                                </Col>
                            </>
                    }
                </Form.Row>
                <Container fluid>
                    {
                        appointmentid === "0" ?
                            <>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Body>
                                                <Form.Row>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Date</Form.Label>
                                                            <Form.Control type="date" name="date" ref={DateId} defaultValue={defaultDate} onChange={handleSearch} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Location</Form.Label>
                                                            <Form.Control as="select" id="Locid" ref={Locid} onChange={(e) => BindRoute(e.target.value)} >
                                                                <option value='0'>---Select---</option>
                                                                {Location}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Route</Form.Label>
                                                            <Form.Control as="select" id="routeid" ref={routeid} onChange={handleSearchList}>
                                                                <option value='0'>---Select---</option>
                                                                {Routelist}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                                <div className="mt15">
                                                    <DataTable
                                                        keyField='AppointmentId'
                                                        tableData={TableData}
                                                        columns={columns}
                                                        pagination={paginationFactory(paginationOptions)}
                                                        // selectRow={selectRow} 
                                                        />
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </> :
                            <>
                                {
                                    userexistflag === "" ? null
                                        :
                                        <AppointmentDetails AppointmentId={appointmentid} Age={age} Gender={gender} CenterId={centerid} UserExistFlag={userexistflag} Status={status} IsConfirmed={isconfirmed} />
                                }
                            </>
                    }
                    {
                        openmodal &&
                        <Modal isOpen={openmodal} centered={true} size="lg">
                            <ModalHeader> Appointment</ModalHeader>
                            <ModalBody>
                                <Form.Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3 calandersec">
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control type="date" name="date" ref={appointmentDateId} defaultValue={defaultDate} onChange={handleSlotDateChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Select time<span className='text-red'>*</span></Form.Label>
                                            <Form.Control as="select" name="time" ref={dtime} onChangeCapture={(e) => RequestTime(e)}>
                                                <option value="">---Select---</option>
                                                {slottiming}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} xs={11}>
                                        <Form.Group className='m-t-15'>
                                            <Form.Label className='icRight'>
                                                <Button variant='secondary' className="mr-2" onClick={openRequiredFieldModal}>Cancel</Button>
                                                {
                                                    isButtonLoading ?
                                                        <LoadingButton variantName='primary' cssName="" /> :
                                                        <Button variant='primary' type='submit' id="btnUpdate" onClick={updateAppDateTime}>Update</Button>
                                                }
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </ModalBody>
                        </Modal>
                    }
                    {
                        openPhlebomodal &&
                        <Modal isOpen={openPhlebomodal} toggle={openReqPhleboModal} centered={true} size='xl' style={{ width: '1400px', maxWidth: '1400px' }}>
                            <ModalHeader> Phlebo Schedule</ModalHeader>
                            <ModalBody>
                                <div>
                                    {
                                        TableData != null && TableData.length > 0 ?
                                            <>
                                                {
                                                    localStorage.getItem("RouteDate") === null ?
                                                        <>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <Form.Group>
                                                                        <Form.Label> <b>Customer Details : </b></Form.Label>
                                                                        {customerdetails}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Form.Group>
                                                                        <Form.Label> <b>Appointment Date Time : </b></Form.Label>
                                                                        {appointmentDateTime}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Form.Group>
                                                                        <Form.Label> <b>Total Patients | Age : </b></Form.Label>
                                                                        {totalPatientage}
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <Form.Group>
                                                                        <Form.Label>From Time</Form.Label>
                                                                        <Form.Control type="Time" ref={frmPhlebotime} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Form.Group>
                                                                        <Form.Label>To Time</Form.Label>
                                                                        <Form.Control type="Time" ref={toPhlebotime} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={8}>
                                                                    <Form.Group>
                                                                        <Form.Label>Special Notes</Form.Label>
                                                                        <Form.Control type="text" ref={specialNotes} defaultValue={notes} />
                                                                    </Form.Group>
                                                                </Col>                                                              
                                                            </Row>
                                                            {
                                                                routephlebos != null && routephlebos.length > 0 ?
                                                                    <>
                                                                        {
                                                                            routephlebos.map((p, index) =>
                                                                                <>
                                                                                    <Row className='mt-4'>
                                                                                        <Col xs={12}>
                                                                                            <input className="radio_animated" id={'rdPhlebo' + p.PhleboID} value={p.PhleboID} onChange={handleRadioChange} type="radio" name="rdo-ani" />
                                                                                            {p.PhleboName}
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row className='mt-2'>
                                                                                        {
                                                                                            phlebohourlytimeschedule != null && phlebohourlytimeschedule.length > 0 ?
                                                                                                <>
                                                                                                    {
                                                                                                        phlebohourlytimeschedule.map((d) =>
                                                                                                            <>
                                                                                                                <Col xs={1} className='timeCol'>
                                                                                                                    <Row>
                                                                                                                        <Col xs={12}>
                                                                                                                            {d.StartTime + '-' + d.EndTime}
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                    <Row>
                                                                                                                        {
                                                                                                                            phlebotimeschedule != null && phlebotimeschedule.length > 0 ?
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        phlebotimeschedule.map((x) =>
                                                                                                                                            <>
                                                                                                                                                {
                                                                                                                                                    x.PhleboId === p.PhleboID && x.EndHourTime === d.EndTime ? x.Flag == "1" ?
                                                                                                                                                        <div className='col-1 activetime'>
                                                                                                                                                        </div>
                                                                                                                                                        :
                                                                                                                                                        <div className='col-1 idletime'>
                                                                                                                                                        </div>
                                                                                                                                                        : ""
                                                                                                                                                }
                                                                                                                                            </>
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                                : ""
                                                                                                                        }
                                                                                                                    </Row>
                                                                                                                </Col>
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                <Col md={12}>
                                                                                                    <Card className='routeCard'>
                                                                                                        <Card.Body>
                                                                                                        </Card.Body>
                                                                                                    </Card>
                                                                                                </Col>
                                                                                        }
                                                                                    </Row>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </>
                                                                    :
                                                                    ""
                                                            }
                                                            <div className='mt15 text-center'>
                                                                <Button variant='secondary' className="mr-2" onClick={openReqPhleboModal}>Cancel</Button>
                                                                <Button variant='secondary' type='submit' onClick={(e) => AssignPhleboFunc()}>Assign Phlebo</Button>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </> : null
                                    }
                                </div>
                            </ModalBody>
                        </Modal>
                    }

                </Container >
            </div>
        </>
    )
}
export default AssignPhlebo