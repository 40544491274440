import React from 'react';
import { Row, Col, Form, Button, Modal, Card } from "react-bootstrap";
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ConnectionInstance from '../../apisettings/ConnectionInstance';
import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import LoadingButton from './LoadingButton';
import classes from './CartSummary.module.css';
import { Spinner } from 'react-bootstrap';
import DataTables from '../DataTable/DataTables';
import { GlobalInfo } from '../../../components/context/context';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

var glblage = "";
var fdate = "";
var glblGender = "";
const Signup = (props) => {
    const [districtsList, setDistrictsList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [disablefields, setDisableFields] = useState({});
    const [gender, setGender] = useState();
    const [family, setFamily] = useState();
    const [cityModal, setCityModal] = useState(false);
    const [viewcities, setViewCities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [maxdate, setMaxDate] = useState("");
    const [otp, setOtp] = useState(false);
    const [signup, setSignUp] = useState(true);
    const [holdmobno, setHoldMobNo] = useState();
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState();
    const { dispatchUserEvent, loginid, centreid, centrename, pagevisitbeforelogin, districtid, districtname, signupmobileno, itemmasterdetails, regionid } = useContext(GlobalInfo);
    const calendarRef = React.createRef();
    useEffect(() => {
        var dtToday = new Date();
        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();
        setMaxDate(year + '-' + month + '-' + day);
        getgenderandfamily();
        GetStates();
        formik.values.mobno = signupmobileno;
    }, [])
    const openCitiesModal = () => { setCrntPageNO(0); setCityModal(!cityModal); }
    /********** Validations  ***********/
    const phoneRegExppatientname = /^[a-zA-Z .']{0,500}$/
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const formik = useFormik({
        initialValues: {
            fullname: '',
            email: '',
            mobno: '',
            address: '',
            pincode: '',
            gender: '',
            dob: '',
            title: '',
            family: '',
            refdoctor: '',
            locStateId: '',
            locDistrictId: '',
            houseno: '',
        },
        validationSchema: Yup.object({
            fullname: Yup.string()
                .required('Full name is required').matches(phoneRegExppatientname, 'Special characters is not allowed'),
            email: Yup.string()
                .email('Invalid email id'),
            mobno: Yup.string()
                .required('Mobile number is required')
                .max(10, 'Mobile number must be 10 numbers')
                .min(10, 'Mobile number must be 10 numbers')
                .matches(phoneRegExp, 'Mobile number is not valid'),
            pincode: Yup.number()
                .typeError('Pincode must be a number'),
            //dob: Yup.string().required('Date of birth is required'),
            locStateId: Yup.string().required('State is required'),
            locDistrictId: Yup.string().required('District is required'),
        }),
        onSubmit: values => {
            createAccountHandler(values);
        }
    });
    const otpformik = useFormik({
        initialValues: {
            otpvalue: ''
        },
        validationSchema: Yup.object({
            otpvalue: Yup.string()
                .required('OTP is required')
                .max(6, 'OTP must be 6 numbers')
                .min(6, 'OTP must be 6 numbers')
                .matches(phoneRegExp, 'OTP is not valid')
        }),
        onSubmit: values => {
            CheckOTPandLogin(values);
        }
    });
    /********** Validations  ***********/
    const popupidcityFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const popupactioncityFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div><a href="javascript:void(0)" onClick={() => SelectedCity(rowData)}>Select</a></div>
        </React.Fragment>
    }
    const popupcityColumns = [
        { dataField: '#', text: '#', editable: false, formatter: popupidcityFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'StateName', text: 'State', editable: false },
        { dataField: 'DistrictName', text: 'District', editable: false },
        { dataField: 'actions', text: 'Actions', formatter: popupactioncityFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '15%' }; } }
    ];
    const StateChangeHandler = (e) => {
        if (e.target.value === "") { setDistrictsList([]); } else { GetDistrictsAgainstState(e.target.value); }
    }
    const GetDistrictsAgainstState = async (StateId) => {
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/getDistrictsAgainstState/' + centreid + '/' + loginid + '/' + StateId
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                setDistrictsList(data.Data.map((d) => <option key={d.DistrictId} value={d.DistrictId}>{d.DistrictName}</option>));
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const ChangeGender = async (e) => {
        if (e.target.value === "Mr.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Mrs.") { formik.values.gender = "2"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Miss.") { formik.values.gender = "2"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Baby.") { formik.values.gender = "2"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Baba.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Master.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Dr.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? formik.dirty : !formik.dirty }) }
        else if (e.target.value === "B/O.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? formik.dirty : !formik.dirty }) }
        else if (e.target.value === "Ms.") { formik.values.gender = "2"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "C/O.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? formik.dirty : !formik.dirty }) }
        else if (e.target.value === "Mx.") { formik.values.gender = "3"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
    }
    const getgenderandfamily = async () => {
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/getgenderandfamily/' + centreid + '/' + loginid
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data.genderdescription !== null) {
                    setGender(data.Data.genderdescription.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
                    setDisableFields({
                        gender: formik.dirty == true ? formik.dirty : !formik.dirty,
                    })
                }
                if (data.Data.familydescription !== null) { setFamily(data.Data.familydescription.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>))) }
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { AxiosError(error.response.status); }
        });
    }
    const GetStates = async () => {
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/getStates/' + centreid + '/' + loginid
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                setStatesList(data.Data.map((d) => <option key={d.StateId} value={d.StateId}>{d.StateName}</option>));
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const CallAge = async (DOB) => {
        glblage = "0";
        var d = new Date(), cmonth = d.getMonth(), cyear = d.getFullYear();
        var birthyear = DOB.split('-')[0];
        var birthmonth = DOB.split('-')[1];
        if (parseInt(cmonth + 1) > parseInt(birthmonth)) { glblage = parseInt(cyear - birthyear); }
        else { glblage = parseInt(cyear - birthyear - 1); }
    }
    const CallStateDistrict = (e) => {
        let text = e.target.value;
        //Pincode Validation//
        let lastvalue = text.charAt(text.length - 1);
        var arraynew = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
        const valueexist = arraynew.includes(lastvalue.toString());
        if (valueexist === false && text !== "") {
            Swal.fire("Invalid! Pincode must be a number");
            formik.values.pincode = "";
        }
        //Pincode Validation//
        let length = text.length;
        if (length === 6) {
            CallStateDistrictData(e.target.value);
        }
        else {
            setDistrictsList([]);
            formik.values.locStateId = ""
            formik.values.locDistrictId = ""
        }
    }
    const CallStateDistrictData = async (Pincode) => {
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/getlocationagainstpincode/' + centreid + '/' + loginid + '/' + Pincode
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                const pinCodeData = data.Data;
                if (pinCodeData.length === 1) {
                    setCityModal(false);
                    formik.values.locStateId = pinCodeData[0].StateId;
                    GetDistrictsAgainstState(pinCodeData[0].StateId);
                    formik.values.locDistrictId = pinCodeData[0].DistrictId;
                }
                else {
                    setCityModal(true);
                    setViewCities(pinCodeData);
                }
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const SelectedCity = (pinCodeData) => {
        setCityModal(false);
        formik.values.locStateId = pinCodeData.StateId;
        GetDistrictsAgainstState(pinCodeData.StateId);
        formik.values.locDistrictId = pinCodeData.DistrictId;
    }
    const createAccountHandler = async (values) => {
        if (fdate === "") {
            Swal.fire("Date of birth is required");
            return false;
        }
        CallAge(fdate);
        glblGender = (values.gender === undefined || values.gender === "") ? "1" : values.gender;
        const requestOptions = {
            CentreId: centreid,
            Pincode: values.pincode.toString() === undefined ? "" : values.pincode.toString(),
            State: values.locStateId,
            District: values.locDistrictId,
            Title: values.title === "" ? "Mr." : values.title,
            FullName: values.fullname === undefined ? "" : values.fullname,
            Email: values.email === undefined ? "" : values.email,
            MobileNumber: values.mobno === undefined ? "" : values.mobno,
            DOB: fdate,
            Age: glblage.toString(),
            Gender: (values.gender === undefined || values.gender === "") ? "1" : values.gender,
            Family: values.family === "" ? "1" : values.family,
            RefDoctor: (values.refdoctor === undefined || values.refdoctor === "") ? "Self" : values.refdoctor,
            HouseNo: "",//values.houseno === undefined ? "" : values.houseno,
            Address: values.address === undefined ? "" : values.address,
        };
        setIsButtonLoading(true);
        let url = ConnectionInstance + 'Cart/usersignup'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsButtonLoading(false);
            if (data.Success) {
                Swal.fire({
                    title: 'Registered',
                    text: "Your account has been created successfully.",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                })
                    .then((result) => {
                        if (result.value) {
                            formik.resetForm();
                            setSignUp(false);
                            setOtp(true);
                            { props.hidebackbutton(true); }
                            window.scrollTo(0, 0);
                            SendOTP(values.mobno);
                        }
                    })
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { setIsButtonLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const SendOTP = async (mobno) => {
        setIsButtonLoading(true);
        let url = ConnectionInstance + 'Cart/userlogin/' + mobno
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsButtonLoading(false);
            if (data.Success) {
                Swal.fire({ title: 'OTP sent', icon: 'success', text: "We have sent an otp to your registered mobile no" });
                setHoldMobNo(mobno);
            }
            else {
                if (data.Data === "0") {
                    Swal.fire({
                        title: 'Invalid',
                        text: "User not exist!Please signup before login",
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    })
                        .then((result) => {
                            if (result.value) {
                                setOtp(false);
                                setSignUp(true);
                                { props.hidebackbutton(false); }
                            }
                        })
                }
                else {
                    if (data.Data === "1") {
                        Swal.fire({ title: "Application Exception", text: "Unable to send otp.Please try again later!", icon: "error" })
                    } else {
                        APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
                    }
                }
            }
        }).catch(function (error) {
            setIsButtonLoading(false);
            if (error.response) {
                AxiosError(error.response.status);
            }
        });
    }
    const CheckOTPandLogin = async (values) => {
        setIsButtonLoading(true);
        let url = ConnectionInstance + 'Cart/checkotpandlogin/' + values.otpvalue + '/' + holdmobno
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsButtonLoading(false);
            otpformik.resetForm();
            if (data.Success) {
                localStorage.setItem("lid", data.Data[0].UserId);
                dispatchUserEvent('ADD_LOGINDATA', { loginid: data.Data[0].UserId });
                { props.age(glblage.toString()); }
                { props.gender(glblGender); }
                { props.centerid(centreid); }
                { props.hidebackbutton(false); }
                { props.appointmentid("-1"); }
                { props.userexistflag("0"); }
            }
            else {
                if (data.Data === "0") { Swal.fire({ title: "Failed", text: "Invalid otp", icon: "error" }); }
                else if (data.Data === "-3") { Swal.fire({ title: "Failed", text: "No data found", icon: "error" }); }
                else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }
        }).catch(function (error) {
            setIsButtonLoading(false);
            if (error.response) {
                AxiosError(error.response.status);
            }
        });
    }
    const resendHandler = () => {
        SendOTP(holdmobno);
    }
    const handleDateChange = (date) => {
        var datenew = new Date(date),
            mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
            day = ("0" + datenew.getDate()).slice(-2);
        fdate = [datenew.getFullYear(), mnth, day].join("-");
        setSelectedDate(datenew);
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { Swal.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { Swal.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { Swal.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { Swal.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { Swal.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { Swal.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { Swal.fire({ title: "Duplicate", text: "User already exist.Please login", icon: "error" }) }
        else if (ErrorCode === "0") { Swal.fire({ title: "Location not found", text: "Please select location", icon: "error" }) }
        else if (ErrorCode === "-1997") { Swal.fire({ title: "Empty", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-1998") { Swal.fire({ title: "Some value is missing", text: "Check server and db logs", icon: "error" }) }
        else if (ErrorCode === "-1999") { Swal.fire({ title: "Runtime Exception", text: ErrorMsg, icon: "error" }) }
        else { Swal.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    return (
        <>
            <Row className="align-items-center justify-content-between">
                <Col md={12}>
                    {signup &&
                        <>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Sign Up</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Col lg={3} md={4}>
                                            <Form.Group>
                                                <Form.Label>Pincode</Form.Label>
                                                <Form.Control type="text" name="pincode" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.pincode} onKeyUp={(e) => CallStateDistrict(e)} />
                                                {formik.touched.pincode && formik.errors.pincode ? (<div className='text-red'>{formik.errors.pincode}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} md={4}>
                                            <Form>
                                                <Form.Group>
                                                    <Form.Label>State<span className='text-red'>*</span></Form.Label>
                                                    <Form.Control as="select" name="locStateId" value={formik.values.locStateId} onChange={formik.handleChange} onBlur={formik.handleBlur} onChangeCapture={StateChangeHandler}>
                                                        <option value="">---Select---</option>
                                                        {statesList}
                                                    </Form.Control>
                                                    {formik.touched.locStateId && formik.errors.locStateId ? (<div className='text-required'>{formik.errors.locStateId}</div>) : null}
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                        <Col lg={3} md={4}>
                                            <Form>
                                                <Form.Group>
                                                    <Form.Label>District<span className='text-red'>*</span></Form.Label>
                                                    <Form.Control as="select" name="locDistrictId" value={formik.values.locDistrictId} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                        <option value="">---Select---</option>
                                                        {districtsList}
                                                    </Form.Control>
                                                    {formik.touched.locDistrictId && formik.errors.locDistrictId ? (<div className='text-required'>{formik.errors.locDistrictId}</div>) : null}
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                        <Col lg={3} md={4}>
                                            <Form>
                                                <Form.Group>
                                                    <Form.Label>Title<span className='text-red'>*</span></Form.Label>
                                                    <Form.Control as="select" name="title" value={formik.values.title} onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur} onChangeCapture={(e) => ChangeGender(e)}>
                                                        <option value="Mr.">Mr.</option>
                                                        <option value="Mrs.">Mrs.</option>
                                                        <option value="Miss.">Miss.</option>
                                                        <option value="Baby.">Baby.</option>
                                                        <option value="Baba.">Baba.</option>
                                                        <option value="Master.">Master.</option>
                                                        <option value="Dr.">Dr.</option>
                                                        <option value="B/O.">B/O.</option>
                                                        <option value="Ms.">Ms.</option>
                                                        <option value="C/O.">C/O.</option>
                                                        <option value="Mx.">Mx.</option>
                                                    </Form.Control>
                                                    {formik.touched.title && formik.errors.title ? (<div className='text-required'>{formik.errors.title}</div>) : null}
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                        <Col lg={3} md={4}>
                                            <Form.Group>
                                                <Form.Label>Full Name<span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="text" name="fullname" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.fullname} />
                                                {formik.touched.fullname && formik.errors.fullname ? (<div className='text-red'>{formik.errors.fullname}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} md={4}>
                                            <Form.Group>
                                                <Form.Label>Mobile Number<span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="text" name="mobno" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobno} />
                                                {formik.touched.mobno && formik.errors.mobno ? (<div className='text-red'>{formik.errors.mobno}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} md={4}>
                                            <Form>
                                                <Form.Group className="calandersec">
                                                    <Form.Label>Date of Birth<span className='text-red'>*</span></Form.Label>
                                                    <DatePicker selected={selectedDate} maxDate={startDate} showMonthDropdown={true} showYearDropdown={true} dateFormat="yyyy-MM-dd" className='form-control'
                                                        onChange={handleDateChange} ref={calendarRef}
                                                    />
                                                    <span className='calandericon' onClick={() => { calendarRef.current.setOpen(true) }}><i className="fa fa-calendar" aria-hidden="true"></i></span>
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                        <Col lg={3} md={4}>
                                            <Form.Group>
                                                <Form.Label>Gender<span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" name="gender" value={formik.values.gender} onChange={formik.handleChange} onBlur={formik.handleBlur} disabled={disablefields.gender}>
                                                    {gender}
                                                </Form.Control>
                                                {formik.touched.gender && formik.errors.gender ? (<div className='text-red'>{formik.errors.gender}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} md={4}>
                                            <Form>
                                                <Form.Group>
                                                    <Form.Label>Family<span className='text-red'>*</span></Form.Label>
                                                    {
                                                        isLoading ? <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                            <>
                                                                <Form.Control as="select" name="family" value={formik.values.family} onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur} >
                                                                    {family}
                                                                </Form.Control>
                                                                {formik.touched.family && formik.errors.family ? (
                                                                    <div className='text-required'>{formik.errors.family}</div>
                                                                ) : null}
                                                            </>
                                                    }
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                        <Col lg={3} md={4}>
                                            <Form.Group>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email" name="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
                                                {formik.touched.email && formik.errors.email ? (<div className='text-red'>{formik.errors.email}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} md={4}>
                                            <Form>
                                                <Form.Group>
                                                    <Form.Label>Reffering Doctor</Form.Label>
                                                    <Form.Control type="text" name="refdoctor" value={formik.values.refdoctor} onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur} />
                                                    {formik.touched.refdoctor && formik.errors.refdoctor ? (<div className='text-required'>{formik.errors.refdoctor}</div>) : null}
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                        {/* <Col lg={3} md={4}>
                                            <Form>
                                                <Form.Group>
                                                    <Form.Label>House No.</Form.Label>
                                                    <Form.Control type="text" name="houseno" value={formik.values.houseno} onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur} />
                                                    {formik.touched.houseno && formik.errors.houseno ? (<div className='text-required'>{formik.errors.houseno}</div>) : null}
                                                </Form.Group>
                                            </Form>
                                        </Col> */}
                                        <Col md={12}>
                                            <Form.Group>
                                                <Form.Label>Address</Form.Label>
                                                {/* <Form.Control type="text" name="address" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.address} /> */}
                                                <Form.Control as="textarea" rows={3} name="address" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.address} />
                                                {formik.touched.address && formik.errors.address ? (<div className='text-red'>{formik.errors.address}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} className="mt-2 mb-3 text-center d-grid">
                                            {
                                                isButtonLoading ?
                                                    <LoadingButton variantName='primary' cssName="" /> :
                                                    <Button variant="primary" className="btn btn-primary" type='submit' onClick={formik.handleSubmit}>Create an account</Button>
                                            }
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </>
                    }
                    {otp &&
                        <>
                            <Card>
                                <Card.Body>
                                    <Form.Row className='justify-content-center mt-3'>
                                        <Col lg={3} md={4}>
                                            <Form.Group>
                                                <Form.Control type="password" placeholder="Please enter OTP" name="otpvalue" onChange={otpformik.handleChange} onBlur={otpformik.handleBlur} value={otpformik.values.otpvalue} />
                                                {otpformik.touched.otpvalue && otpformik.errors.otpvalue ? (<div className='text-required'>{otpformik.errors.otpvalue}</div>) : null}
                                                <p className="text-danger text-center mt-4">
                                                    <Button variant='light' className='resendOtp btn-sm' onClick={resendHandler}><i className='fa fa-refresh'></i> Resend OTP</Button>
                                                </p>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} className="mt-2 mb-3 text-center d-grid">
                                            {
                                                isButtonLoading ?
                                                    <LoadingButton variantName='dark' cssName="" /> :
                                                    <Button variant="primary" type='submit' onClick={otpformik.handleSubmit}>Submit</Button>
                                            }
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </>
                    }
                </Col>
            </Row>
            {/* <Row id="banner-home" className="align-items-center justify-content-between">
                <Col lg={12} md={12}>
                    {signup &&
                        <>
                            <Form>
                                <Row className='mt-3 justify-content-start'>
                                    <Col md={12}>
                                        <p><b>Sign Up</b></p>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={classes.txtBlk}>Pincode</Form.Label>
                                            <Form.Control type="text" name="pincode" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.pincode} onKeyUp={(e) => CallStateDistrict(e)} />
                                            {formik.touched.pincode && formik.errors.pincode ? (<div className='text-red'>{formik.errors.pincode}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={classes.txtBlk}>State<span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" name="locStateId" value={formik.values.locStateId} onChange={formik.handleChange} onBlur={formik.handleBlur} onChangeCapture={StateChangeHandler}>
                                                    <option value="">---Select---</option>
                                                    {statesList}
                                                </Form.Control>
                                                {formik.touched.locStateId && formik.errors.locStateId ? (<div className='text-required'>{formik.errors.locStateId}</div>) : null}
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col md={6}>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={classes.txtBlk}>District<span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" name="locDistrictId" value={formik.values.locDistrictId} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">---Select---</option>
                                                    {districtsList}
                                                </Form.Control>
                                                {formik.touched.locDistrictId && formik.errors.locDistrictId ? (<div className='text-required'>{formik.errors.locDistrictId}</div>) : null}
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col md={6}>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={classes.txtBlk}>Title<span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" name="title" value={formik.values.title} onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur} onChangeCapture={(e) => ChangeGender(e)}>
                                                    <option value="Mr.">Mr.</option>
                                                    <option value="Mrs.">Mrs.</option>
                                                    <option value="Miss.">Miss.</option>
                                                    <option value="Baby.">Baby.</option>
                                                    <option value="Baba.">Baba.</option>
                                                    <option value="Master.">Master.</option>
                                                    <option value="Dr.">Dr.</option>
                                                    <option value="B/O.">B/O.</option>
                                                    <option value="Ms.">Ms.</option>
                                                    <option value="C/O.">C/O.</option>
                                                    <option value="Mx.">Mx.</option>
                                                </Form.Control>
                                                {formik.touched.title && formik.errors.title ? (<div className='text-required'>{formik.errors.title}</div>) : null}
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={classes.txtBlk}>Full Name<span className='text-red'>*</span></Form.Label>
                                            <Form.Control type="text" name="fullname" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.fullname} />
                                            {formik.touched.fullname && formik.errors.fullname ? (<div className='text-red'>{formik.errors.fullname}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={classes.txtBlk}>Mobile Number<span className='text-red'>*</span></Form.Label>
                                            <Form.Control type="text" name="mobno" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobno} />
                                            {formik.touched.mobno && formik.errors.mobno ? (<div className='text-red'>{formik.errors.mobno}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form>
                                            <Form.Group className="mb-3 calandersec">
                                                <Form.Label className={classes.txtBlk}>Date of Birth<span className='text-red'>*</span></Form.Label>
                                                <DatePicker selected={selectedDate} maxDate={startDate} showMonthDropdown={true} showYearDropdown={true} dateFormat="yyyy-MM-dd" className='form-control'
                                                    onChange={handleDateChange} ref={calendarRef}
                                                />
                                                <span className='calandericon' onClick={() => { calendarRef.current.setOpen(true) }}><i className="fa fa-calendar" aria-hidden="true"></i></span>
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={classes.txtBlk}>Gender<span className='text-red'>*</span></Form.Label>
                                            <Form.Control as="select" name="gender" value={formik.values.gender} onChange={formik.handleChange} onBlur={formik.handleBlur} disabled={disablefields.gender}>
                                                {gender}
                                            </Form.Control>
                                            {formik.touched.gender && formik.errors.gender ? (<div className='text-red'>{formik.errors.gender}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={classes.txtBlk}>Family<span className='text-red'>*</span></Form.Label>
                                                {
                                                    isLoading ? <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <Form.Control as="select" name="family" value={formik.values.family} onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur} >
                                                                {family}
                                                            </Form.Control>
                                                            {formik.touched.family && formik.errors.family ? (
                                                                <div className='text-required'>{formik.errors.family}</div>
                                                            ) : null}
                                                        </>
                                                }
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={classes.txtBlk}>Email</Form.Label>
                                            <Form.Control type="email" name="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
                                            {formik.touched.email && formik.errors.email ? (<div className='text-red'>{formik.errors.email}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={classes.txtBlk}>Reffering Doctor</Form.Label>
                                                <Form.Control type="text" name="refdoctor" value={formik.values.refdoctor} onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur} />
                                                {formik.touched.refdoctor && formik.errors.refdoctor ? (<div className='text-required'>{formik.errors.refdoctor}</div>) : null}
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col md={6}>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={classes.txtBlk}>House No.</Form.Label>
                                                <Form.Control type="text" name="houseno" value={formik.values.houseno} onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur} />
                                                {formik.touched.houseno && formik.errors.houseno ? (<div className='text-required'>{formik.errors.houseno}</div>) : null}
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={classes.txtBlk}>Address</Form.Label>
                                            <Form.Control type="text" name="address" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.address} />
                                            {formik.touched.address && formik.errors.address ? (<div className='text-red'>{formik.errors.address}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} className="mt-2 mb-3 text-center d-grid">
                                        {
                                            isButtonLoading ?
                                                <LoadingButton variantName='dark' cssName="" /> :
                                                <Button variant="dark" className="btn-hmblock" size="lg" type='submit' onClick={formik.handleSubmit}>Create an account</Button>
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    }
                    {otp &&
                        <>
                            <Form>
                                <Row className='mt-3 justify-content-start'>
                                    <Col md={12}>
                                        <Form.Group className="mb-4">
                                            <Form.Control type="password" placeholder="Please enter OTP" name="otpvalue" onChange={otpformik.handleChange} onBlur={otpformik.handleBlur} value={otpformik.values.otpvalue} />
                                            {otpformik.touched.otpvalue && otpformik.errors.otpvalue ? (<div className='text-red'>{otpformik.errors.otpvalue}</div>) : null}
                                            <p className="text-danger text-center mt-4">
                                                <Button variant='light' className='text-danger' onClick={resendHandler}><i className='fa fa-refresh'></i> Resend OTP</Button>
                                            </p>
                                        </Form.Group>
                                        <Form.Group className="mb-3 text-center d-grid" >
                                            {
                                                isButtonLoading ?
                                                    <LoadingButton variantName='dark' cssName="" /> :
                                                    <Button variant="dark" className="btn-hmblock" size="lg" type='submit' onClick={otpformik.handleSubmit}>Submit</Button>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    }
                </Col>
            </Row> */}
            {
                cityModal &&
                <Modal show={cityModal} onHide={openCitiesModal} size='l' centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Districts</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <DataTables
                                    keyField='DistrictId'
                                    tableData={viewcities}
                                    columns={popupcityColumns}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}

export default Signup;