import React, { Fragment, useState, useEffect, useRef } from 'react'
import CallToolTip from '../../UI/ToolTips/CallToolTip';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import axios from 'axios';
import ConnectionInstance from '../../apisettings/ConnectionInstance';
import { useHistory } from 'react-router';
let buttonclickStatus = false;
let GlobalPageNo=1;
let GlobalPageSizeNo=10;
const InCompletedcallLists = () => {
    const navigate = useHistory();
    const [ModalBodyHtml, SetModalBodyHtml] = useState("");
    const [ErrorPopUp, setErrorPopUp] = useState(0);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [UserTypeId, setUserTypeId] = useState(localStorage.UserType == undefined ? "" : localStorage.UserType);
    const [CallExtensionId, setCallExtensionId] = useState(localStorage.ExtenNo == undefined ? "" : localStorage.ExtenNo);
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsInCompletednotifiCallDlists: []

    });
    const [errorModal, setErrorModal] = useState(false);
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
    }
    
   


  
    const AddCallHandler = async (callingid) => {
        localStorage.removeItem('CallingId');
        localStorage.setItem('CallingId', callingid);
      
        
        try {

            let url=ConnectionInstance+ 'InboundCall/UpdateCallCentreCallInboundProStatusbyId';

            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
              data:{CallingId: `${callingid}`,AddedBy: `${LoginId}`, ProcessStatusId:`${2}`}
            };

            let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if(data.Success==true && data.Data=="2000")
                    {
                       
                        setModal(false);
                        setErrorModal(false);
                        navigate.push(`/inbound/addInboundcalls`);
                    }
                    else{
                         if(data.ErrorList!=null && data.ErrorList.length>0)
                         {
                            let Errtext=<p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                            SetModalBodyHtml(Errtext);
                             setModal(!modal);
                             setErrorModal(true);
                         }

                    }


                }
                else{
                    console.log('no record found');

                }
             // return data;
            } catch (error) {
                console.log(error.message);
                let Errtext="";
                    Errtext =<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);
             }
    }
    useEffect(() => {
     asyncFunBindInboundnotifcation(1, 10);    
     }, []);
    useEffect(() => {
      
        const id5 = setInterval(() => {
                asyncFunBindInboundnotifcation(GlobalPageNo,GlobalPageSizeNo);
                console.log('Inboundnotifcation'); 
          
        },  40* 1000);
        return () => clearInterval(id5);

    }, []);
 
    const assignedViewHandler = () => {
        navigate.push(`/inbound/incompletedcalls`);
        //   window.location.href = `${process.env.PUBLIC_URL}/admin/outbound/assignedcalls`;
    }
    async function asyncFunBindInboundnotifcation(PageNo, PageSize) {
       
            try {
         
                let url=ConnectionInstance+ 'InboundCall/GetCallCentreIboundInCompletedCalls';
                    let options = {
                        method: 'POST',
                        url: url,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json;charset=UTF-8'
                        },
                        data: { UserId: `${LoginId}`,PageNo: `${PageNo}`, PageSize: `${PageSize}` }
                    };
                    let response = await axios(options);
                    let responseOK = response && response.status == 200;
                    if (responseOK) {
                        let data = response.data;
        
                        // let data = await response.data;
                        if (data.Success == true) {
                            let ds = data.Data;
                            if (ds != null) {
                                GlobalPageNo=PageNo;
                              GlobalPageSizeNo=PageSize;
                                await SetPageHelper({
                                    ...PageHelper,
                                    PageNo:Number(PageNo),
                                    PageSize: Number(PageSize),
                                    TotalRecord: Number(ds.TotalRecord),
                                    dsInCompletednotifiCallDlists: ds.CallCentreAssignedcallList == null ? [] : ds.CallCentreAssignedcallList
                                });
        
        
                            }
                        }
                        else {
                            let Errtext = <p><div className='text-required'> no record found </div></p>;
                            setErrorPopUp(Errtext);
                            setErrorModal(true);
                            console.log('no record found');
                        }
        
                    }
                    else {
                        let Errtext = <p><div className='text-required'> no record found </div></p>;
                        setErrorPopUp(Errtext);
                        setErrorModal(true);
                        console.log('no record found');
                    }
                    // return data;
                } catch (error) {
                    console.log(error);
                    let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                    setErrorPopUp(Errtext);
                    setErrorModal(true);
                    //alert(error);
                }
        }

    

    return (
        <><div className="table-responsive">
        <table className="table">
            <thead className="thead-light">
                <tr>
                    <th scope="col">#</th>
                    {/* <th scope="col">Executive Name</th>
                    <th scope="col">Executive Number</th> */}
                    <th scope="col">Customer Name</th>
                    <th scope="col">Customer Mobile</th>
                    <th scope="col">Source</th>
                    <th scope="col">Call Queue</th>
                    <th scope="col">Call Type</th>
                    <th scope="col" className='text-center'>Action</th>
                </tr>
            </thead>
            <tbody>
                {(PageHelper.dsInCompletednotifiCallDlists==null || PageHelper.dsInCompletednotifiCallDlists==undefined ||  PageHelper.dsInCompletednotifiCallDlists=='')?<tr><td colSpan={7} style={{ textAlign: 'center', color: 'red' }}>
                <h5>No record Found</h5>
                </td></tr>
                :PageHelper.dsInCompletednotifiCallDlists.map((tb,index) => {
                    return (
                        <>
                            <tr key={tb.CallingId}>
                                <th>{parseInt(index+1)}</th>
                                <td>{tb.CustomerName}</td>
                                <td>{tb.CustomerMobile}</td>
                                <td><p>
                                <span className='grdH3'><strong>Source Category: {(tb.MSourceCategory!="")?(tb.MSourceCategory):'N/A'}</strong></span><br/>
                               <span className='grdH3'>Source: {(tb.MSourceName!="")?(tb.MSourceName):'N/A'}</span><br/>
                                  </p></td>
                                  <td><p>
                                  <span className='grdH3'><strong>Team: {(tb.TeamName!="")?(tb.TeamName):'N/A'}</strong></span><br/>
                                <span className='grdH3'><strong>Queue: {(tb.FlowName!="")?(tb.FlowName):'N/A'}</strong></span><br/>
     <span className='grdH3'>IVR: {(tb.IVR!="")?(tb.IVR):'N/A'}</span><br/>
     <span className='grdH3'>FLOW: {(tb.Flow!="")?(tb.Flow):'N/A'}</span>
                                     </p></td>
                                <td><p>
     <span className='grdH3'><strong>{(tb.CallModuleName!="")?(tb.CallModuleName):'N/A'}</strong></span><br/>
     <span className='grdH3'>{(tb.CallType!="")?(tb.CallType):'N/A'}</span>
                                     </p></td>
                                <td className='text-center'>
                                    <CallToolTip title="Call" placement="top" id={`call${tb.CallingId}`} onClick={() => AddCallHandler(tb.CallingId)} />
                                </td>
                            </tr>
                        </>
                    )
                })}
          

            </tbody>
        </table>
    </div>
    {(PageHelper.dsInCompletednotifiCallDlists==null || PageHelper.dsInCompletednotifiCallDlists==undefined ||  PageHelper.dsInCompletednotifiCallDlists=='')?null:
   <p className='text-center mt15'>
   <button className='btn btn-primary' onClick={PageHelper.TotalRecord=='0'?'javascript:void(0);': assignedViewHandler}>View All</button>
</p>}
    
    {
        errorModal===true?
         (<Modal isOpen={modal} toggle={toggleModal} centered={true}>
            <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
            <ModalBody>  
                {ModalBodyHtml}              
            </ModalBody>
            <ModalFooter>
                <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                <Button variant="primary" onClick={toggleModal}>Ok</Button>
            </ModalFooter>
            
        </Modal>)
            : null
    } 
</>

    )
}



export default InCompletedcallLists;