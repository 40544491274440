// import axios from 'axios';

// import React from 'react';
// //const  ConnectionInstance ='http://10.0.1.98:1014/api/';
// // const ConnectionInstance = 'https://devcallcentreapi.atulaya.com/api/';
// // const  ConnectionInstance ='http://10.0.1.92:1014/api/';
// //const ConnectionInstance = 'https://localhost:44348/api/';
// // const  ConnectionInstance ='https://atulayacallcenterapi.azurewebsites.net/api/';
// //  const ConnectionInstance = () => {
// //    return <div></div>;
// //  };
// const ConnectionInstance =process.env.REACT_APP_ConnectionInstance;
//  export const YeaStatNotificationAPI=process.env.REACT_APP_YeaStarNotification;
// export default ConnectionInstance;

const ConnectionInstance = process.env.REACT_APP_URL_Start;
export default ConnectionInstance;

 

