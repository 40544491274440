import React, { Fragment, useEffect, useState } from 'react';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
import Footer from './common/footer';
import { ToastContainer } from 'react-toastify';
import Loader from './common/loader';
import { useHistory } from 'react-router';
import man from '../assets/images/dashboard/user.png';
import { GlobalInfo } from '../components/context/context';

const AppLayout = (props) => {
    const [loginid, setLoginId] = useState('0');
    const [regionid, setRegionId] = useState('0');
    const [districtid, setDistrictId] = useState('0');
    const [centreid, setCentreId] = useState('0');
    const [itemmasterdetails, setItemMasterDetails] = useState([]);
    const [cartsummary, setCartSummary] = useState([]);

    const [currentUser, setCurrentUser] = useState(localStorage.currentUser == undefined ? false : localStorage.currentUser);
    const navigate = useHistory();

    const [value, setValue] = useState(
        localStorage.getItem('profileURL' || man)
    );

    useEffect(() => {
        const lid = localStorage.getItem('loginid');
        if (lid) { setLoginId(lid); }
        const rid = localStorage.getItem('regionid');
        if (rid) { setRegionId(rid); }
        const did = localStorage.getItem('districtid');
        if (did) { setDistrictId(did); }
        const cid = localStorage.getItem('centreid');
        if (cid) { setCentreId(cid); }
        const itemmstr = localStorage.getItem('itemmasterdetails');
        if (itemmstr) { setItemMasterDetails(JSON.parse(itemmstr)); }
        const crtsum = localStorage.getItem('cartsummary');
        if (crtsum) { setCartSummary(JSON.parse(crtsum)); }
    }, [])
    useEffect(() => {
        localStorage.setItem('loginid', loginid);
        localStorage.setItem('regionid', regionid);
        localStorage.setItem('districtid', districtid);
        localStorage.setItem('centreid', centreid);
        localStorage.setItem('itemmasterdetails', JSON.stringify(itemmasterdetails));
        localStorage.setItem('cartsummary', JSON.stringify(cartsummary));

    }, [loginid, regionid, districtid, centreid, itemmasterdetails, cartsummary])

    const dispatchUserEvent = (actionType, payload) => {
        switch (actionType) {
            case 'ADD_LOGINDATA':
                setLoginId(payload.loginid);
                return;
            case 'ADD_REGIONID':
                setRegionId(payload.regionid);
                return;
            case 'ADD_DISTRICTID':
                setDistrictId(payload.districtid);
                return;
            case 'ADD_CENTREID':
                setCentreId(payload.centreid);
                return;
            case 'ADD_ITEMMASTERDETAILS':
                setItemMasterDetails(payload.itemmasterdetails);
                return;
            case 'ADD_CARTSUMMARY':
                setCartSummary(payload.cartsummary);
                return;
            case 'REMOVE_LOGINDATA':
                setLoginId(payload.loginid);
                return;
            case 'REMOVE_REGIONID':
                setRegionId(payload.regionid);
                return;
            case 'REMOVE_DISTRICTID':
                setDistrictId(payload.districtid);
                return;
            case 'REMOVE_CENTREID':
                setCentreId(payload.centreid);
                return;
            case 'REMOVE_ITEMMASTERDETAILS':
                setItemMasterDetails(payload.itemmasterdetails);
                return;
            case 'REMOVE_CARTSUMMARY':
                setCartSummary(payload.cartsummary);
                return;
            default:
                return;
        }
    };

    return (
        <Fragment>
            <GlobalInfo.Provider value={{ loginid, regionid, districtid, centreid, itemmasterdetails, cartsummary, dispatchUserEvent }}>
                <Loader />
                <div className="page-wrapper">
                    <div className="page-body-wrapper">
                        <Header />
                        <Sidebar />
                        <div className="page-body">
                            {props.children}
                        </div>
                        <Footer />
                    </div>
                </div>
                <ToastContainer />
            </GlobalInfo.Provider>
        </Fragment >
    );
}

export default AppLayout;