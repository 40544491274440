import { validLetter } from 'jspreadsheet-ce';
import {  Home,
  Box,
  DollarSign,
  UserPlus,
  Users,
  Chrome,
  Settings,
  Airplay,
  FolderPlus,
  File,
  Command, Cloud, Book, FileText, Server, Image, Sliders, Map, GitPullRequest, Calendar, Edit, Mail, MessageSquare, UserCheck, Layers, HelpCircle, Database, Headphones, Mic, ShoppingBag, Search, AlertOctagon, Lock, Briefcase, BarChart,Target, List, Package, User } from 'react-feather';
import SweetAlert from 'sweetalert2';
import { string } from 'yup';
const menuT=[];
let hasMatch1="";
const SessionInfo=localStorage.Session == undefined && localStorage.Session ==null? "" : localStorage.getItem('Session');
const UserSubGroupDetails=localStorage.UserSubGroups == undefined && localStorage.UserSubGroups ==null? "" : JSON.parse(localStorage.getItem('UserSubGroups'));
const UserType=localStorage.UserType == undefined && localStorage.UserType ==null? "" : localStorage.getItem('UserType');
const UserGroupDetails=localStorage.UserGroups == undefined && localStorage.UserGroups ==null? "" : JSON.parse(localStorage.getItem('UserGroups'));
const pathnameHit = window.location.pathname;
let params = (new URL( window.location)).searchParams;
let AccessKeyCust = params.get("Loginkey");

if(SessionInfo!="Active" && pathnameHit.toLowerCase()!='/login'.toLowerCase())
 {
  if(AccessKeyCust!=null)
    {
      window.location.reload();
    }
   else{
     window.location.assign(`${process.env.PUBLIC_URL}/login`);
   }
 }
  else{
     if(pathnameHit.toLowerCase()=='/login'.toLowerCase() && SessionInfo=="Active")
     {
      if (UserType=== "0" ||UserType=== "2" ||UserType=== "1") {
        if(AccessKeyCust!=null)
        {
          window.location.reload();
        }
        else{
          window.location.assign(`${process.env.PUBLIC_URL}/admin/dashboard`);
        }
          }
           else if (UserType=== "3") {
            if(AccessKeyCust!=null)
            {
              window.location.reload();
            }
            else{
              window.location.assign(`${process.env.PUBLIC_URL}/user/dashboard`);
            }
         
     }
    }
     if(UserGroupDetails!=null && UserGroupDetails!= undefined && UserGroupDetails!="" &&UserType!='' &&UserGroupDetails.length>0)
     {
       
        // const ppat=window.location.pathname.split('?')[0]
    
        if(pathnameHit.toLowerCase()=='/dashboard'.toLowerCase() )
        {
           if (UserType=== "0" ||UserType=== "2" ||UserType=== "1") {
            window.location.assign(`${process.env.PUBLIC_URL}/admin/dashboard`);  }
               else if (UserType=== "3") {
               window.location.assign(`${process.env.PUBLIC_URL}/user/dashboard`);
            }
            else{
              window.location.assign(`${process.env.PUBLIC_URL}/login`); 
            }
        }
          if(pathnameHit.toLowerCase()!='/'.toLowerCase() && pathnameHit.toLowerCase()!='/dashboard'.toLowerCase() && pathnameHit.toLowerCase()!='/accessDenied'.toLowerCase() && pathnameHit.toLowerCase()!='/login'.toLowerCase())
          {
            
            const hasMatch=UserGroupDetails.some(function (d) {
              return d.PageURL ==pathnameHit 
           });
           hasMatch1=hasMatch;
            if(hasMatch1==false)
            {
              if(UserSubGroupDetails!=null && UserSubGroupDetails!= undefined && UserSubGroupDetails!="")
              {
                const hasMatchSub=UserSubGroupDetails.some(function (d) {
                  return d.PageURL==pathnameHit 
               });
               hasMatch1=hasMatchSub;
              }
            }
    
            if(hasMatch1==false)
            {
              window.location.assign(`${process.env.PUBLIC_URL}/accessDenied`);
            }
            
            // else{
            //     if (UserType=== "0" ||UserType=== "2" ||UserType=== "1") {
    
            //         window.location.assign(`${process.env.PUBLIC_URL}/admin/dashboard`);
        
            //     }
            //     else if (UserType=== "3") {
            //         window.location.assign(`${process.env.PUBLIC_URL}/user/dashboard`);
            //     }
            // }
           
          }
          
         
         let i=0;
        UserGroupDetails.map((item)=>{
            let menurow=
            {
                title:'', 
                icon:'',
                 type:'' , 
                 active:false,
                 PageId:'',
                 Level:'', 
                 children:[] 
            }
            
        if(item.Level=="1")
        {
           const IconUI=item.PageName=="Dashboard"? Home: 
           item.PageName=="Outbound Calls"? Headphones:
           item.PageName=="Inbound Calls"? Headphones:
           item.PageName=="Admin"? Users:
          item.PageName=="Lab Report"? FileText:FileText;
        
          menurow=
            {
                title: item.PageName,
                icon:IconUI,
                 type:item.PageURL==''?'sub':'link', 
                 active:i=='0'?true:false,
                 path:item.PageURL==''?'':item.PageURL, 
                 PageId:item.PageId,
                 Level:item.Level, 
                 children:[] 
            }
            menuT.push(menurow);
        }
         i++;
        });
  
      ////********SubChild**************//
      menuT.map((item1)=>{
        let Childrow=
        {
            title:'', 
             type:'' , 
             active:false,
             PageId:'',
             Level:'',  
             path: '',
             children:[] 
        }
       let ChildArr=[];
    UserGroupDetails.map((val)=>{
    
         if(val.ParentId==item1["PageId"] && val.Level=="2")
        {
            Childrow=
                {
                    title:val.PageName, 
                     type:val.PageURL==''?'sub':'link', 
                     active:false,
                     PageId:val.PageId,
                     Level:val.Level,   
                     path:val.PageURL,
                     children:[]  
                }
                ChildArr.push(Childrow);
                item1["children"]= ChildArr;
            }   
    }); 
    
      });
    
    
       ////********ThirdChild**************//
       menuT.map((item1)=>{
        let Childrow=
        {
            title:'', 
             type:'' , 
             active:false,
             PageId:'',
             Level:'',  
             path: '' 
        }
       let SubChildArr=[];
    
      //&& val.Level=="3
    
       if(item1["children"].length>0 && item1["children"]!=null)
       {
        item1["children"].map((item2)=>{
          UserGroupDetails.map((val)=>{
          if(item2["PageId"]==val.ParentId && val.Level=="3")
          {
              Childrow=
                  {
                      title:val.PageName, 
                       type:val.PageURL==''?'sub':'link', 
                       active:false,
                       PageId:val.PageId,
                       Level:val.Level,   
                       path:val.PageURL 
                  }
                  SubChildArr.push(Childrow);
                  item2["children"]=SubChildArr;
              }  
            }); 
    
        });
    
       }
      
    
      });
      
     }
    }

 export const MENUITEMS = menuT;
