import React, { useState, useRef, useEffect } from 'react';
import Breadcrumb from '../commonComponent/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import ConnectionInstance from '../apisettings/ConnectionInstance';
import DataTable from '../commonComponent/DataTable/DataTable';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router';
import DetailsToolTip from '../UI/ToolTips/DetailsToolTip';
import ReassignToolTip from '../UI/ToolTips/ReassignToolTip';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const RouteInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [crntPageNO, setCrntPageNO] = useState(0);
  const [TableData, setTableData] = useState([]);
  const [defaultDate, setDefaultDate] = useState("");
  const [routes, setRoutes] = useState(0);
  const [statuslist, setstatuslist] = useState([]);
  const [openmodal, setOpenModal] = useState(false);
  const [appointmentId, setAppointmentId] = useState("0");
  const navigate = useHistory();
  let DateIdNew = useRef();
  let statusid = useRef();
  let RouteName = useRef();
  let CustMobNo = useRef();
  let otherReason = useRef();
  useEffect(() => {
    const today = new Date();
    const date = today.setDate(today.getDate());
    const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
    setDefaultDate(defaultValue);
    GetRoutes(defaultValue);
    GetPhleboStatus();
    BindGrid(defaultValue, '0', '0', '0');
  }, [])

  const paginationOptions = {
    sizePerPage: 10,
    showTotal: false,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
  };
  const idFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      <div>
        <span>{crntPageNO + rowIndex + 1}</span>
      </div>
    </React.Fragment>
  }
  const actionAppointmentFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      {rowData.AppointmentDate != "" && rowData.AppointmentTime != "" ?
        rowData.AppointmentDate + ' | ' + rowData.AppointmentTime :
        rowData.AppointmentDate
      }
    </React.Fragment>
  }
  const actionPhleboFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      {rowData.PhleboDate != "" && rowData.PhleboTime != "" ?
        rowData.PhleboDate + ' | ' + rowData.PhleboTime :
        rowData.PhleboDate
      }
    </React.Fragment>
  }
  const actionnewFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      {rowData.TotalPatients + ' | ' + '( ' + rowData.Age + ' )'}
    </React.Fragment>
  }
  const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      {/* <DetailsToolTip title="Details" placement="top" id={`show${rowData.AppointmentId}`}
        onClick={(e) => OpenAssignScreen(rowData.RouteDate, rowData.RouteStartTime, rowData.RouteEndTime, rowData.LocationId, rowData.PhleboId, rowData.RouteName, rowData.AppointmentId)} /> */}
      {
        rowData.StatusName.toLowerCase() === "payment done" || rowData.StatusName.toLowerCase() === "completed" || rowData.StatusName.toLowerCase() === "canceled" ? null :
          <ReassignToolTip title="Cancel & Reassign Route" placement="top" id={`refresh${rowData.AppointmentId}`}
            onClick={(e) => OpenReassignScreen(rowData.AppointmentId)} />
      }
    </React.Fragment>
  }
  const CustomerDetailsFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      <div>
        <p>{rowData.CustomerName}<br />{rowData.CustomerMobile}</p>
      </div>

    </React.Fragment>
  }
  const StatusFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      <div>
        {
           rowData.LastAppointmentID==0?
          rowData.Postpone.toLocaleLowerCase() == 'true' ? rowData.StatusName + ' (Postone)' : rowData.StatusName
          : rowData.StatusName + ' (Postponed Appointment)'
        }
      </div>

    </React.Fragment>
  }
  const columns = [
    {
      dataField: '#',
      text: '#',
      formatter: idFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '3%' };
      }
    },
    {
      dataField: 'CustomerName',
      text: 'Customer Details',
      formatter: CustomerDetailsFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '10%' };
      }
    },
    {
      dataField: 'TotalPatients',
      text: 'Total Patients | Age',
      formatter: actionnewFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '15%' };
      }
    },   
    {
      dataField: 'PhleboName',
      text: 'Phlebo Name',
      headerStyle: (colum, colIndex) => {
        return { width: '10%' };
      }
    },
    {
      dataField: 'RouteName',
      text: 'Route Name',
      headerStyle: (colum, colIndex) => {
        return { width: '10%' };
      }
    },
    {
      dataField: 'AppointmentDate',
      text: 'Appointment Date | Time',
      formatter: actionAppointmentFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '15%' };
      }
    },
    {
      dataField: 'PhleboDateTime',
      text: 'Phlebo Date | Time',
      formatter: actionPhleboFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '15%' };
      }
    },
    {
      dataField: 'StatusName',
      text: 'Status',
      formatter: StatusFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '8%' };
      }
    },   
    {
      dataField: 'Action',
      text: 'Action',
      formatter: actionFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '5%' };
      }
    }
  ];
  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.Postpone.toLocaleLowerCase() == "true") {
      style.backgroundColor = '#c8e6c9';
    } else {
      style.backgroundColor = '';
    }
    return style;
  };
  const BindGrid = async (Date, StatusId, RouteName, CustMobNo) => {
    if (Date === "") {
      setTableData([]);
      SweetAlert.fire("Date is mandatory");
      return false;
    }
    try {
      setIsLoading(true);
      let url = ConnectionInstance + 'cart/GetAssignedPhleboDetails/' + Date + '/' + (StatusId === "" ? "0" : StatusId) + '/' + (RouteName === "" ? "0" : RouteName) + '/' + (CustMobNo === "" ? "0" : CustMobNo)
      await axios.get(url).then((response) => {
        const data = response.data;
        if (data.Success) {
          if (data.Data === null || data.Data.length === 0) { setTableData([]); }
          else { setTableData(data.Data); }
          setIsLoading(false);
        }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
      }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    } catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
  }
  const OpenAssignScreen = async (RouteDate, RouteStartTime, RouteEndTime, LocationId, PhleboId, RouteName, AppId) => {
    localStorage.setItem("RouteDate", RouteDate);
    localStorage.setItem("RouteStartTime", RouteStartTime);
    localStorage.setItem("RouteEndTime", RouteEndTime);
    localStorage.setItem("LocationId", LocationId);
    localStorage.setItem("PhleboId", PhleboId);
    localStorage.setItem("RouteName", RouteName);
    localStorage.setItem("AppId", AppId);
    navigate.push(`/phlebo/showassignedroute`);
  }
  const OpenReassignScreen = async (AppointmentId) => {
    setAppointmentId(AppointmentId);
    setOpenModal(true);
  }
  const UpdateStatus = async () => {
    if (otherReason.current.value === "") { SweetAlert.fire("Please enter cancel reason"); return false; }
    else {
      const requestOptions = {
        AppointmentId: appointmentId,
        Status: "1",
        CancelReason: otherReason.current.value,
        LoginId: localStorage.LoggedInUser
      };
      setIsLoading(true);
      let url = ConnectionInstance + 'Cart/UpdateStatus'
      await axios.post(url, requestOptions).then((response) => {
        const data = response.data;
        setIsLoading(false);
        if (data.Success) {
          SweetAlert.fire("Successfuly canceled appointment.Please re assign.");
          localStorage.removeItem("RouteDate");
          localStorage.removeItem("RouteStartTime");
          localStorage.removeItem("RouteEndTime");
          localStorage.removeItem("LocationId");
          localStorage.removeItem("PhleboId");
          localStorage.removeItem("RouteName");
          localStorage.removeItem("AppId");
          setAppointmentId("");
          setOpenModal(false);
          navigate.push(`/phlebo/assignroute`);
        }
        else {
          APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
        }
      }).catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          ErrorFunction(error.response.status);
        }
      });
    }
  }
  const handleRouteSearch = (e) => {
    setRoutes([]);
    setTableData([]);
    GetRoutes(DateIdNew.current.value);
  }
  const handleSearch = (e) => {
    setTableData([]);
    BindGrid(DateIdNew.current.value, statusid.current.value, RouteName.current.value, CustMobNo.current.value);
  }
  const CallRoutePage = async () => {
    navigate.push(`/phlebo/assignroute`);
  }
  const APIError = async (ErrorCode, ErrorMsg) => {
    if (ErrorCode === "-1") { SweetAlert.fire({ title: "Duplicate", text: "User already exist", icon: "error" }) }
    else if (ErrorCode === "0") { SweetAlert.fire({ title: "Location not found", text: "Please select location", icon: "error" }) }
    else if (ErrorCode === "-1997") { SweetAlert.fire({ title: "Empty", text: "No record found", icon: "error" }) }
    else if (ErrorCode === "-1998") { SweetAlert.fire({ title: "Some value is missing", text: "Check server and db logs", icon: "error" }) }
    else if (ErrorCode === "-1999") { SweetAlert.fire({ title: "Runtime Exception", text: ErrorMsg, icon: "error" }) }
    else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
  }
  const ErrorFunction = async (ErrorCode) => {
    if (ErrorCode == "400")
      SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
    else if (ErrorCode == "401")
      SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
    else if (ErrorCode == "403")
      SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
    else if (ErrorCode == "404")
      SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
    else if (ErrorCode == "500")
      SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
    else if (ErrorCode == "503")
      SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
  }
  const GetRoutes = async (Date) => {
    let url = ConnectionInstance + 'cart/getassignedroutes/' + Date
    await axios.get(url).then((response) => {
      const data = response.data;
      if (data.Success) {
        if (data.Data !== null) {
          setRoutes([]);
          setRoutes(data.Data.map((d) => (<option key={d.RouteId} value={d.RouteId}>{d.RouteName}</option>)));
        }
        else {
          setRoutes([]);
        }
      }
      else { setRoutes([]); }
    }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
  }
  const GetPhleboStatus = async () => {
    let url = ConnectionInstance + 'cart/getphlebostatus/';
    await axios.get(url).then((response) => {
      const data = response.data;
      if (data.Success) {
        if (data.Data !== null) {
          setstatuslist([]);
          setstatuslist(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.StatusName}</option>)));
        }
        else {
          setstatuslist([]);
        }
      }
      else { setstatuslist([]); }
    }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
  }
  const openRequiredFieldModal = () => { setOpenModal(!openmodal); }
  return (
    <>
      <Breadcrumb title="Route Info" parent="Phlebo" />
      <Container fluid>
        {
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body>
                  <Form.Row className='justify-content-center'>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Date</Form.Label>
                        <Form.Control type="date" name="date" ref={DateIdNew} defaultValue={defaultDate} onChange={handleRouteSearch} />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <Form.Control as="select" id="statusid" name="statusid" ref={statusid} >
                          <option value="0">---Select---</option>
                          {statuslist}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Route Name</Form.Label>
                        <Form.Control as="select" id="routeId" name="routeId" ref={RouteName} >
                          <option value="0">---Select---</option>
                          {routes}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control type="Number" maxLength="10" name="customerMobNo" ref={CustMobNo} />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col md={12} className='text-center'>
                      <Form.Group>
                        <Button variant='primary' type='submit' onClick={handleSearch}>Search</Button>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12}>
              <Card>
                <Card.Body>
                  <Form.Row >
                    <Col md={12} className='text-right'>
                      <Form.Group>
                        <Button variant='primary' type='submit' onClick={CallRoutePage}>Assign Route</Button>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <div className="mt15">
                    <DataTable
                      keyField='AppointmentId'
                      tableData={TableData}
                      columns={columns}
                      pagination={paginationFactory(paginationOptions)}
                      rowStyle={rowStyle}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        }
      </Container>
      {
        openmodal &&
        <Modal isOpen={openmodal} centered={true} size="lg">
          <ModalHeader>Reason</ModalHeader>
          <ModalBody>
            <Form.Row>
              <>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Cancel Reason</Form.Label>
                    <Form.Control type="text" name="OtherText" ref={otherReason} />
                  </Form.Group>
                </Col>
              </>

              <Col md={12} xs={11}>
                <Form.Group className='m-t-15'>
                  <Form.Label className='icRight'>
                    <Button variant='secondary' className="mr-2" onClick={openRequiredFieldModal}>Cancel</Button>
                    <Button variant='primary' type='submit' onClick={(e) => UpdateStatus()}>Save</Button>
                  </Form.Label>
                </Form.Group>
              </Col>
            </Form.Row>
          </ModalBody>
        </Modal>
      }
    </>
  )
}

export default RouteInfo