import React, { Fragment, useState, useEffect, useRef } from 'react'
import CallToolTip from '../UI/ToolTips/CallToolTip';
import Breadcrumb from '../commonComponent/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import EditToolTip from '../UI/ToolTips/EditToolTip';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import ActiveToolTip from '../UI/ToolTips/ActiveToolTip';
import InactiveToolTip from '../UI/ToolTips/InactiveToolTip';

import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import { useFormik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import ConnectionInstance from '../apisettings/ConnectionInstance';
import { useHistory } from 'react-router';
let buttonclickStatus = false;
let GlobalPageNo=1;
let GlobalPageSizeNo=10;

const InCompletedCalls = () => {
    const searchValue = useRef();
    const navigate = useHistory();
    const[CCLoading,SetCCLoading]=useState(false);
    const [ModalBodyHtml, SetModalBodyHtml] = useState("");
    const [ErrorPopUp, setErrorPopUp] = useState(0);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [UserTypeId, setUserTypeId] = useState(localStorage.UserType == undefined ? "" : localStorage.UserType);
    const [CallExtensionId, setCallExtensionId] = useState(localStorage.ExtenNo == undefined ? "" : localStorage.ExtenNo);
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsInCompletednotifiCallDlists: []

    });
    const [errorModal, setErrorModal] = useState(false);
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
    }
    
    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            asyncFunBindInboundnotifcation(page, sizePerPage);
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            asyncFunBindInboundnotifcation(page, sizePerPage);
        }
    };
    const CallQueueFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
     <span className='grdH3'><strong>Team: {(rowData.TeamName!="")?(rowData.TeamName):'N/A'}</strong></span><br/>
       <span className='grdH3'><strong>Queue: {(rowData.FlowName!="")?(rowData.FlowName):'N/A'}</strong></span><br/>
       <span className='grdH3'>IVR: {(rowData.IVR!="")?(rowData.IVR):'N/A'}</span><br/>
       <span className='grdH3'>FLOW: {(rowData.Flow!="")?(rowData.Flow):'N/A'}</span><br/>
      </p>
        </div>
        </React.Fragment>
    }
    const CallSourceFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
     <span className='grdH3'><strong>Source Category: {(rowData.MSourceCategory!="")?(rowData.MSourceCategory):'N/A'}</strong></span><br/>
      <span className='grdH3'>Source: {(rowData.MSourceName!="")?(rowData.MSourceName):'N/A'}</span><br/>
      </p>
        </div>
        </React.Fragment>
    }
    const CallTypeFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
     <span className='grdH3'><strong>{(rowData.CallModuleName!="")?(rowData.CallModuleName):'N/A'}</strong></span><br/>
     <span className='grdH3'>{(rowData.CallType!="")?(rowData.CallType):'N/A'}</span>
      </p>
        </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        //console.log(cellData, rowData);
        return <React.Fragment>

            {<CallToolTip title="Call" placement="top" id={`call${rowData.CallingId}`} onClick={() =>
                AddCallHandler(rowData.CallingId)} />}
            {/* <EditToolTip title="Edit" onClick={()=>editHandler(rowData.SourceId)} />
                  <DeleteToolTip title="Delete" onClick={()=>Displayalert(rowData.SourceId)} />
                  {
                     rowData.IsActive=="False"?<InactiveToolTip title="Inactive" onClick={()=>StatusHandler(rowData.SourceId)} />: <ActiveToolTip title="Active" onClick={()=>StatusHandler(rowData.SourceId)}/>
                  } */}

        </React.Fragment>
    }



    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
          let rowNumber = (PageHelper.PageNo - 1) * 10 + (rowIndex + 1);
          return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => {
            return { width: '6%'};
            }
        //sort: true,
        // formatter: idFormatter,
        // onSort: (field, order) => {
        //     console.log(field, order);
        // }
    },
    {
        dataField: 'CustomerName',
        text: 'Customer Name'
    },
    {
        dataField: 'CustomerMobile',
        text: 'Customer Mobile'
    },{
        text: 'Source',
        formatter: CallSourceFormatter,
        editable: false 
    },
    {
        text: 'Call Queue',
        formatter: CallQueueFormatter,
        editable: false 
    },
    {
        text: 'Call Type',
        formatter: CallTypeFormatter,
        editable: false 
    },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        formatter: actionFormatter

    }
    ];

    const AddCallHandler = async (callingid) => {
        localStorage.removeItem('CallingId');
        localStorage.setItem('CallingId', callingid);
      
        
        try {

            let url=ConnectionInstance+ 'InboundCall/UpdateCallCentreCallInboundProStatusbyId';

            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
              data:{CallingId: `${callingid}`,AddedBy: `${LoginId}`, ProcessStatusId:`${2}`}
            };

            let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if(data.Success==true && data.Data=="2000")
                    {
                       
                        setModal(false);
                        setErrorModal(false);
                        navigate.push(`/inbound/addInboundcalls`);
                    }
                    else{
                         if(data.ErrorList!=null && data.ErrorList.length>0)
                         {
                            let Errtext=<p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                            SetModalBodyHtml(Errtext);
                             setModal(!modal);
                             setErrorModal(true);
                         }

                    }


                }
                else{
                    console.log('no record found');

                }
             // return data;
            } catch (error) {
                console.log(error.message);
                let Errtext="";
                    Errtext =<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);
             }
    }
    useEffect(() => {
     asyncFunBindInboundnotifcation(1, 10);    
     }, []);
    useEffect(() => {
        let mounted = true;
       // asyncFunBindInboundnotifcation(1, 10);
        const id5 = setInterval(() => {
                asyncFunBindInboundnotifcation(GlobalPageNo,GlobalPageSizeNo);
                console.log('Inboundnotifcation');
          
        },  40* 1000);
        return () => clearInterval(id5);

    }, []);
 
    const searchHandler = () => { 
        asyncFunBindInboundnotifcation(1, 10); }
    async function asyncFunBindInboundnotifcation(PageNo, PageSize) {
       
            try {
                SetCCLoading(true);
                let url=ConnectionInstance+ 'InboundCall/GetCallCentreIboundInCompletedCalls';
                    let options = {
                        method: 'POST',
                        url: url,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json;charset=UTF-8'
                        },
                        data: { UserId: `${LoginId}`,PageNo: `${PageNo}`, PageSize: `${PageSize}`,SearchTxt:`${searchValue.current.value}` }
                    };
                    let response = await axios(options);
                    let responseOK = response && response.status == 200;
                    if (responseOK) {
                        let data = response.data;
        
                        // let data = await response.data;
                        if (data.Success == true) {
                            SetCCLoading(false);
                            let ds = data.Data;
                            if (ds != null) {
                                GlobalPageNo=PageNo;
                              GlobalPageSizeNo=PageSize;
                                await SetPageHelper({
                                    ...PageHelper,
                                    PageNo:Number(PageNo),
                                    PageSize: Number(PageSize),
                                    TotalRecord: Number(ds.TotalRecord),
                                    dsInCompletednotifiCallDlists: ds.CallCentreAssignedcallList == null ? [] : ds.CallCentreAssignedcallList
                                });
        
        
                            }
                        }
                        else {
                            SetCCLoading(false);
                            let Errtext = <p><div className='text-required'> no record found </div></p>;
                            setErrorPopUp(Errtext);
                            setErrorModal(true);
                            console.log('no record found');
                        }
        
                    }
                    else {
                        SetCCLoading(false);
                        let Errtext = <p><div className='text-required'> no record found </div></p>;
                        setErrorPopUp(Errtext);
                        setErrorModal(true);
                        console.log('no record found');
                    }
                    // return data;
                } catch (error) {
                    SetCCLoading(false);
                    console.log(error);
                    let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                    setErrorPopUp(Errtext);
                    setErrorModal(true);
                    //alert(error);
                }
        }

    

    return (
        <>
         <div id="cover-spin"  style={{display: CCLoading ? 'block' : 'none'}}></div> 
            <Breadcrumb title="Answered Calls - Status Pending" parent="Inbound Calls" />
            <Container fluid>
            <Row>
                    <Col md={12}>
                        <Card>
                          
                            <Card.Body>
                                    <Row className='justify-content-center mt15'>
                                <Col md={5}>
                                    <Form>
                                        <div className="inner-addon right-addon">
                                            <i className="icon fa fa-search"></i>
                                            <Form.Group className="form-group">
                                                <Form.Control type="text" className='searchControl' ref={searchValue}   placeholder="Search customername, mobile no., IVR, FLOW" />
                                            </Form.Group>
                                        </div>
                                    </Form>
                                </Col>
                                <Col md={2}>
                                <Button variant="primary" type="submit" className='btn-block' onClick={searchHandler} > Search</Button>                                            
                                </Col>
                            </Row>      
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Answered Calls - Status Pending</Card.Title>
                            </Card.Header>
                            <Card.Body>

                            <DataTables
                                    keyField='CallingId'
                                    tableData={PageHelper.dsInCompletednotifiCallDlists}
                                    columns={columns}
                                    pagination={paginationFactory(paginationOptions)}
                                />

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                
            </Container>

            {
                errorModal === true ?
                    (<Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                            {/* {ModalBodyHtml===null||ModalBodyHtml===''?
                        
                        (<><p>{formik.touched.sourceCategory && formik.errors.sourceCategory ? (
                            <div className='text-required'>{formik.errors.sourceCategory}</div>
                        ) : null}</p>  </> ):ModalBodyHtml }             */}
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>

                    </Modal>)
                    : null
            }
        </>
    )
}


export default InCompletedCalls;