import React, { Fragment, useState, useEffect, useRef } from 'react'
import CallToolTip from '../../UI/ToolTips/CallToolTip';
import Breadcrumb from '../../commonComponent/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import EditToolTip from '../../UI/ToolTips/EditToolTip';
import DeleteToolTip from '../../UI/ToolTips/DeleteToolTip';
import ActiveToolTip from '../../UI/ToolTips/ActiveToolTip';
import InactiveToolTip from '../../UI/ToolTips/InactiveToolTip';

import DataTables from '../../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../../commonComponent/DataTable/NoRecordFounds';
import { useFormik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import ConnectionInstance from '../../apisettings/ConnectionInstance';
import AddAssignCalls from './addassigncalls';
import { icon } from 'leaflet';
import reactDragula from 'react-dragula';
import { useSelector, useDispatch } from 'react-redux';
import { actiontype } from '../../redux/actiontype';
import { useHistory } from 'react-router';
let SourceCatMId = 0;
let buttonclickStatus = false;
let dropclick=false;
const Assignedcalls = () => {
    const searchValue = useRef();
    const [CallCentreExecutiveList, setCallCentreExecutiveList] =useState(0);
    const[ExcutiveSelectionIds,SetExcutiveSelectionIds]=useState("");
    const CalltypeSelctionRef = useRef();
    const [CallTypeData, setCallTypeData] = useState(0);
    const[CCLoading,SetCCLoading]=useState(false);
    const [CallstatusDLL, SetCallstatusDLL] = useState(0);
    const [LeadSourceDDL, SetLeadSourceDDL] = useState(0);
    const [LeadServiceDDL, SetLeadServiceDDL] = useState(0);
    const[CallTypeSelection,SetCallTypeSelection]=useState("0");
     const[CallStatusIds,SetCallStatusIds]=useState("");
     const[CallSourceIds,SetCallSourceIds]=useState("");
     const[CallServiceIds,SetCallServiceIds]=useState("");
    const dispatch = useDispatch();
    const navigate = useHistory();
    const [SourceCategoryData, setSourceCategoryData] = useState(0);
    const [SourceCatId, SetSourceCatId] = useState(0);
    const [ErrorPopUp, setErrorPopUp] = useState(0);
    const [buttonclick, setbuttonclick] = useState(false);
    const [ModalBodyHtml, SetModalBodyHtml] = useState("");
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [UserTypeId, setUserTypeId] = useState(localStorage.UserType == undefined ? "" : localStorage.UserType);
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsAssignedCallDlists: []

    });
    const [errorModal, setErrorModal] = useState(false);
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
    }


    useEffect(() => {
        asyncFunCallExecutiveDDL();
        asyncFuncSourceCategoryDDL();
        asyncFunCallTypeDDL();
    }, []);
    async function  asyncFunCallExecutiveDDL() {
        try {
         
        let url=ConnectionInstance+ 'master/GetCallCentreExcutiveDDList';
        let options = {
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {}
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if(data.Success==true)
                { let ds=data.Data;
                    if(ds!=null && ds.length>0)
                    {
                        setCallCentreExecutiveList(ds.map((option) => (<option value={option.UserId}>{option.UserName}</option>)));                                         
                    }
                }
                else{
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                   setErrorPopUp(Errtext);
                   setErrorModal(true);
                    console.log('no record found');
                }
               
                //setUserData(data.map((option) =>(<option value={option.sNo}>{option.ename}</option>)));
                // do something with data
            }
            else{
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                setErrorPopUp(Errtext);
                setErrorModal(true);
                console.log('no record found');
            }
         // return data;
        } catch (error) {
            console.log(error);
            let Errtext=<p><div className='text-required'>{error} </div></p>;
            setErrorPopUp(Errtext);
            setErrorModal(true);
          //alert(error);
        }
      }
   
    async function asyncFunCallTypeDDL() {
        try {
         
        let url=ConnectionInstance+ 'master/GetCallTypeDetailsDDList';
        let options = {
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {}
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if(data.Success==true)
                { let ds=data.Data;
                    if(ds!=null && ds.length>0)
                    {
                   setCallTypeData(ds.map((option) =>(<option value={option.CallTypeId}>{option.CallType}</option>)));   
                    }
                }
                else{
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                   setErrorPopUp(Errtext);
                   setErrorModal(true);
                    console.log('no record found');
                }
               
                //setUserData(data.map((option) =>(<option value={option.sNo}>{option.ename}</option>)));
                // do something with data
            }
            else{
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                setErrorPopUp(Errtext);
                setErrorModal(true);
                console.log('no record found');
            }
         // return data;
        } catch (error) {
            console.log(error);
            let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            setErrorPopUp(Errtext);
            setErrorModal(true);
          //alert(error);
        }
      }

     
    async function asyncFuncSourceCategoryDDL() {
        try {

            let url = ConnectionInstance + 'master/GetCallCentreSourceCategoryDDList';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        setSourceCategoryData(ds.map((option) => (<option value={option.SourceCatId}>{option.SourceCategory}</option>)));
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    setErrorPopUp(Errtext);
                    setModal(!modal);
                    setErrorModal(true);
                    console.log('no record found');
                }

            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                setErrorPopUp(Errtext);
                setModal(!modal);
                setErrorModal(true);
                console.log('no record found');
            }
        } catch (error) {
            console.log(error);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            setErrorPopUp(Errtext);
            setModal(!modal);
            setErrorModal(true);
        }
    }
    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            asyncFunBindAssignedCallUserByCatId(page, sizePerPage);
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            asyncFunBindAssignedCallUserByCatId(page, sizePerPage);
        }
    };
    const ActiveDateFormatter = (cellData, rowData, rowIndex, extraData) => {
        //console.log(cellData, rowData);
        return <React.Fragment>
             <div>
                 
     <p>
      <span className='grdH3'><strong>Executive:{(rowData.ExecutiveName!="")?(rowData.ExecutiveName):'N/A'}</strong></span><br/> 
     <span className='grdH3'>Activity Date: {(rowData.CallUpdatedDate!="")?(rowData.CallUpdatedDate):'N/A'}</span><br/>
        <span className='grdH3'>Follow Up: {(rowData.CFollowUpDate!="" && rowData.CFollowUpTime!="")?(rowData.CFollowUpDate +' | '+ rowData.CFollowUpTime):'N/A'}</span><br/>
      </p>
        </div>
    
 
        
                   {/* {<input type="checkbox" style={{ marginLeft: 90 }} checked={cellData} ></input>} */}
          

        </React.Fragment>
    }
    const LatestActiveFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
     <span className='grdH3'><strong>Team: {(rowData.TeamName!="")?(rowData.TeamName):'N/A'}</strong></span><br/>
     <span className='grdH3'>Call Service: {(rowData.LeadService!="")?(rowData.LeadService):'N/A'}</span><br/>
        <span className='grdH3'>Call Source: {(rowData.LeadSource!="")?(rowData.LeadSource):'N/A'}</span><br/>
       <span className='grdH3'>Call Status: {rowData.CallStatus!=""?rowData.CallStatus:'N/A'}</span><br/>
      </p>
        </div>
        </React.Fragment>
    }
    const CallQueueFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
       <span className='grdH3'><strong>Queue: {(rowData.FlowName!="")?(rowData.FlowName):'N/A'}</strong></span><br/>
       <span className='grdH3'>IVR: {(rowData.IVR!="")?(rowData.IVR):'N/A'}</span><br/>
       <span className='grdH3'>FLOW: {(rowData.Flow!="")?(rowData.Flow):'N/A'}</span><br/>
      </p>
        </div>
        </React.Fragment>
    }
    const CallTypeFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
             <div>           
     <p>
       <span className='grdH3'><strong>{(rowData.CallModuleName!="")?(rowData.CallModuleName):'N/A'}</strong></span><br/>
        <span className='grdH3'>{(rowData.CallType!="")?(rowData.CallType):'N/A'}</span><br/>
      </p>
        </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {

        return <React.Fragment>

            {<CallToolTip title="Call" placement="top" id={`call${rowData.CallingId}`} onClick={() =>
                AddCallHandler(rowData.CallingId)} />}


        </React.Fragment>
    }




    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
            let rowNumber = (PageHelper.PageNo - 1) * 10 + (rowIndex + 1);
            return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => {
            return { width: '6%' };
        }

    },
    {
        dataField: 'CustomerName',
        text: 'Customer Name'
    },
    {
        dataField: 'CustomerMobile',
        text: 'Customer Mobile'
    },
    {
        isDummyField: true,
        text: 'Lastest Activity',
        formatter: LatestActiveFormatter,
        editable: false 
    },
    {
        text: 'Call Queue',
        formatter: CallQueueFormatter,
        editable: false 
    },
    {
        text: 'Call Type',
        formatter: CallTypeFormatter,
        editable: false 
    },
    
    {
        isDummyField: true,
        text: 'Date',
        formatter: ActiveDateFormatter,
        editable: false 
    },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        formatter: actionFormatter

    }
    ];
    const drophandleChange = (e) => {
        SetSourceCatId(Number(e.target.value));
        SourceCatMId = Number(e.target.value);
        buttonclickStatus = false;
        dropclick=true;

    }
    const AddSearchHandler = () => {
        buttonclickStatus = true;
        asyncFunBindAssignedCallUserByCatId(1, 10);

    }
    const AddCallHandler = async (callingid) => {
        localStorage.removeItem('CallingId');
        localStorage.setItem('CallingId', callingid);
        dispatch({
            type: actiontype.AddAssignClick, payload: localStorage.CallingId == undefined ? "" : localStorage.CallingId
        }
            , {
                type: actiontype.UpdateUserId, payload: localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser
            }
            , {
                type: actiontype.UpdateUName, payload: localStorage.UName == undefined ? "" : localStorage.UName
            }
            , {
                type: actiontype.UpdateUserTypeName, payload: localStorage.UserTypeName == undefined ? "" : localStorage.UserTypeName
            }
            , {
                type: actiontype.UpdateUserType, payload: localStorage.UserType == undefined ? "" : localStorage.UserType
            }
            , {
                type: actiontype.UpdatecurrentUser, payload: localStorage.currentUser == undefined ? "" : localStorage.currentUser
            }
            , {
                type: actiontype.UpdateSession, payload: localStorage.Session == undefined ? "" : localStorage.Session
            }
        );


        try {

            let url = ConnectionInstance + 'OutboundCalling/UpdateCallCentreCallProcessStatusbyId';

            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { CallingId: `${callingid}`, AddedBy: `${LoginId}`, ProcessStatusId: `${2}` }
            };

            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                if (data.Success == true && data.Data == "2000") {

                    setModal(false);
                    setErrorModal(false);
                    navigate.push(`/user/outbound/addassigncalls`);
                }
                else {
                    if (data.ErrorList != null && data.ErrorList.length > 0) {
                        let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                        SetModalBodyHtml(Errtext);
                        setModal(!modal);
                        setErrorModal(true);
                    }

                }


            }
            else {
                console.log('no record found');
                let Errtext = <p><div className='text-required'>{'no record found'} </div></p>;
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);

            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = "";
            Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);
        }

    }
    useEffect(() => {
        buttonclickStatus=false;
        asyncFuncCallStatusDDL();
        FuncLeadSourceDDL();
        FuncLeadServiceDDL();
        asyncFunBindAssignedCallUserByCatId(1, 10);
    }, []);
    useEffect(() => {
       
        // if (buttonclickStatus) {
        //     const id = setInterval(() => {
        //         asyncFunBindAssignedCallUserByCatId(1, 10);
        //       }, 100);
        //       return () => clearInterval(id);
        //     }
        //PageHelper
    }, [buttonclickStatus]);




    async function asyncFunBindAssignedCallUserByCatId(PageNo, PageSize) {
        try {
            SetCCLoading(true);
            let url = ConnectionInstance + 'OutboundCalling/GetCallCentreAssignedCallbyUserCatId';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { UserId: `${LoginId}`, PageNo: `${PageNo}`, PageSize: `${PageSize}`, LeadServiceId:`${CallServiceIds}`,LeadSourceId:`${CallSourceIds}`,CallStatusId:`${CallStatusIds}`,
                ExecutiveId:`${ExcutiveSelectionIds}`, CallTypeId:`${CallTypeSelection}`,
                SearchTxt:`${searchValue.current.value}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
               // buttonclickStatus=false;

                // let data = await response.data;
                if (data.Success == true) {
                    SetCCLoading(false);
                    let ds = data.Data;
                    if (ds != null) {

                        await SetPageHelper({
                            ...PageHelper,
                            PageNo: Number(PageNo),
                            PageSize: Number(PageSize),
                            TotalRecord: Number(ds.TotalRecord),
                            dsAssignedCallDlists: ds.CallCentreAssignedcallList == null ? [] : ds.CallCentreAssignedcallList
                        });
                     
                    }
                }
                else {
                    SetCCLoading(false);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    setErrorPopUp(Errtext);
                    setErrorModal(true);
                    console.log('no record found');
                }


            }
            else {
                SetCCLoading(false);
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                setErrorPopUp(Errtext);
                setErrorModal(true);
                console.log('no record found');
            }

        } catch (error) {
            console.log(error);
            SetCCLoading(false);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            setErrorPopUp(Errtext);
            setErrorModal(true);

        }
    }

    const CallStatusHandler = (e) => {
        const val = e.target.value;
        SetCallStatusIds(val);
    }
    const LeadSourceHandler = (e) => {
        const val = e.target.value;
        SetCallSourceIds(val);
       
    }
    const LeadServiceHandler = (e) => {
        const val = e.target.value;
        SetCallServiceIds(val);
    }
    const CallTypeHandler = (e) => {
        const val = e.target.value;
        SetCallTypeSelection(val);
    }
    const ExecutiveSelectionHandler = (e) => {
        const val = e.target.value;
        SetExcutiveSelectionIds(val);
       
    }
    async function asyncFuncCallStatusDDL() {
        try {

            let url = ConnectionInstance + 'master/GetCallStatusDDList';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        SetCallstatusDLL(ds.map((option) => (<option value={option.CallStatusId}>{option.CallStatus}</option>)));
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                }

            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = "";
            Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);
            //alert(error);
        }
    }

 const FuncLeadSourceDDL=async()=>{

    try {

        let url = ConnectionInstance + 'master/GetBindLeadSourceDDList';
        let options = {
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {}
        };
        let response = await axios(options);
        let responseOK = response && response.status == 200;
        if (responseOK) {
            let data = response.data;
            // let data = await response.data;
            if (data.Success == true) {
                let ds = data.Data;
                if (ds != null && ds.length > 0) {
                    SetLeadSourceDDL(ds.map((option) => (<option value={option.SNO}>{option.Source}</option>)));
                }
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                console.log('no record found');
            }

        }
        else {
            let Errtext = <p><div className='text-required'> no record found </div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);
            console.log('no record found');
        }
        // return data;
    } catch (error) {
        console.log(error.message);
        let Errtext = "";
        Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
        SetModalBodyHtml(Errtext);
        setModal(!modal);
        setErrorModal(true);
    }
 }  
   
 const FuncLeadServiceDDL=async()=>{

    try {

        let url = ConnectionInstance + 'master/GetBindLeadServiceDDList';
        let options = {
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {}
        };
        let response = await axios(options);
        let responseOK = response && response.status == 200;
        if (responseOK) {
            let data = response.data;
            // let data = await response.data;
            if (data.Success == true) {
                let ds = data.Data;
                if (ds != null && ds.length > 0) {
                    SetLeadServiceDDL(ds.map((option) => (<option value={option.SNO}>{option.LeadService}</option>)));
                }
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                console.log('no record found');
            }

        }
        else {
            let Errtext = <p><div className='text-required'> no record found </div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);
            console.log('no record found');
        }
        // return data;
    } catch (error) {
        console.log(error.message);
        let Errtext = "";
        Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
        SetModalBodyHtml(Errtext);
        setModal(!modal);
        setErrorModal(true);
    }
 } 
 const searchHandler = () => {
    buttonclickStatus = true; 
    asyncFunBindAssignedCallUserByCatId(1, 10);} 
    return (
        <>
          <div id="cover-spin"  style={{display: CCLoading ? 'block' : 'none'}}></div> 
            <Breadcrumb title="Assigned Calls" parent="Outbound Calls" />
            <Container fluid>

            <Row>
                    <Col md={12}>
                        <Card>
                          
                            <Card.Body>
                               
                                    <Form.Row className="justify-content-center">
                                        <Col md={3}>
                                        <Form.Group>
                                                        <Form.Label>Call Service</Form.Label>
                                                        <Form.Control as="select" id="ldservice" name="ldservice"
                                                            onChange={LeadServiceHandler}
                                                            value={CallServiceIds}>
                                                            <option value='0'>---Select All---</option>
                                                            {LeadServiceDDL}
                                                        </Form.Control>
                                                    </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                        <Form.Group>
                                                        <Form.Label>Call Source</Form.Label>
                                                        <Form.Control as="select" id="lsource" name="lsource"
                                                            onChange={LeadSourceHandler}
                                                            value={CallSourceIds}>
                                                            <option value='0'>---Select All---</option>
                                                            {LeadSourceDDL}
                                                        </Form.Control>
                                                    </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                        <Form.Group>
                                                        <Form.Label>Call Status</Form.Label>
                                                        <Form.Control as="select" id="CallStatus" name="CallStatus"
                                                            onChange={CallStatusHandler}
                                                             value={CallStatusIds}>
                                                            <option value='0'>---Select All---</option>
                                                            {CallstatusDLL}
                                                       </Form.Control>
                                                    </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Row className='justify-content-center mt15'>
                                   
                                    <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Call Type</Form.Label>
                                                <Form.Control as="select" id="callType" name="callType"
                                                    onChange={CallTypeHandler}
                                                    value={CallTypeSelection}  ref={CalltypeSelctionRef} >
                                                        <option value='0'>---Select All---</option>
                                                             {CallTypeData}
                                                </Form.Control>

                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                            <Form.Label>Executive Name</Form.Label>
                                                <Form.Control as="select" id="ExecutiveId" name="ExecutiveId"  onChange={ExecutiveSelectionHandler}
                                                  
                                                    value={ExcutiveSelectionIds}  >
                                                    <option value='-1'>---Select All---</option>
                                                    {CallCentreExecutiveList}
                                                </Form.Control>

                                            </Form.Group>
                                        </Col>
                                        
                                        </Row>
                                    <Row className='justify-content-center mt15'>
                                  
                                <Col md={6}>
                                 
                                        <div className="inner-addon right-addon">
                                            <i className="icon fa fa-search"></i>
                                            <Form.Group className="form-group">
                                                <Form.Control type="text" className='searchControl' ref={searchValue}   placeholder="Search customer name, mobile no., IVR, FLOW, Teams" />
                                            </Form.Group>
                                        </div>
                                  
                                </Col>
                                <Col md={2}>
                                <Button variant="primary" type="submit" className='btn-block' onClick={searchHandler} > Search</Button>                                            
                                </Col>
                            </Row>      
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Assigned Outbound Call List</Card.Title>
                            </Card.Header>
                            <Card.Body>

                                <DataTables
                                    keyField='CallingId'
                                    tableData={PageHelper.dsAssignedCallDlists}
                                    columns={columns}
                                    pagination={paginationFactory(paginationOptions)}


                                />

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {
                errorModal === true ?
                    (<Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>

                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>

                    </Modal>)
                    : null
            }
        </>
    )
}

export default Assignedcalls;