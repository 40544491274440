import React, { useState, useRef, useEffect, useContext } from 'react';
import Breadcrumb from '../commonComponent/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import ConnectionInstance from '../apisettings/ConnectionInstance';
import DataTable from '../commonComponent/DataTable/DataTable';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolTip from '../UI/ToolTips/ViewToolTip';
import { GlobalInfo } from '../../components/context/context';
import AppointmentDetails from '../../pages/commonComponent/cart/appointmentdetails';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import LoadingButton from '../commonComponent/Usecomponent/loadingbutton';
import Select from 'react-select';

const AssignRoute = () => {
  const [crntPageNO, setCrntPageNO] = useState(0);
  const [Location, SetLocation] = useState([]);
  const [TableData, setTableData] = useState([]);
  const [SelectDS, setSelectDS] = useState(0);
  const [appointmentid, setAppointmentId] = useState("0");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [centerid, setCenterId] = useState("0");
  const [userexistflag, setUserExistFlag] = useState("");
  const { dispatchUserEvent, loginid, centreid, } = useContext(GlobalInfo);
  const [status, setStatus] = useState("0");
  const [isconfirmed, setIsConfirmed] = useState("0");
  const [slottiming, setSlotTiming] = useState([]);
  const [openmodal, setOpenModal] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [appid, setAppId] = useState("0");
  const [defaultDate, setDefaultDate] = useState("");
  const [Routelist, SetRoutelist] = useState([]);
  const [SelectedRouteList, setSelectedRouteList] = useState([]);

  let dtime = useRef();
  let DateId = useRef();
  let frmtime = useRef();
  let ToTime = useRef();
  let Locid = useRef();
  let appointmentDateId = useRef();
  var finaltime = "";
  useEffect(() => {
    const today = new Date();
    const date = today.setDate(today.getDate());
    const defaultValue = new Date(date).toISOString().split('T')[0]
    setDefaultDate(defaultValue);
    BindLocation(defaultValue);
    GetSlotTiming(defaultValue);
    if (localStorage.getItem("RouteDate") !== null) {
      const fdate = localStorage.getItem("RouteDate");
      const date = new Date(fdate);
      const finalyear = date.getFullYear();
      const finalmonth = ((date.getMonth() + 1).toString().length != 2 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1))
      const finaldate = (date.getDate().toString().length != 2 ? "0" + date.getDate() : date.getDate())
      const defaultValue = finalyear + "-" + finalmonth + "-" + finaldate;
      DateId.current.value = defaultValue;
      frmtime.current.value = localStorage.getItem("RouteStartTime");
      ToTime.current.value = localStorage.getItem("RouteEndTime");
    }
    return () => {
      localStorage.removeItem("RouteDate");
      localStorage.removeItem("RouteStartTime");
      localStorage.removeItem("RouteEndTime");
      localStorage.removeItem("LocationId");
      localStorage.removeItem("PhleboId");
      localStorage.removeItem("RouteName");
      localStorage.removeItem("AppId");
      localStorage.removeItem("samplerid");
      localStorage.removeItem("sampledid");
      localStorage.removeItem("samplecid");
      localStorage.removeItem("sampleitemmasterdetails");
    }
  }, [])
  const BindLocation = async (Date) => {
    try {
      setIsButtonLoading(true);
      let url = ConnectionInstance + 'cart/getappointmentlocation/' + Date;
      await axios.get(url).then((response) => {
        const data = response.data;
        setIsButtonLoading(false);
        if (data.Success) {
          if (data.Data === null) { SetLocation([]); setTableData([]); }
          else { setTableData([]); SetLocation(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.DistrictName}</option>))); }
        }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsButtonLoading(false); }
      }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsButtonLoading(false); });
    } catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
  }
  const paginationOptions = {
    sizePerPage: 10,
    showTotal: false,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
  };
  const idFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      <div>
        <span>{crntPageNO + rowIndex + 1}</span>
      </div>
    </React.Fragment>
  }
  const CustomerDetailsFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      <div>
        <p>{rowData.CustomerName}<br />{rowData.CustomerMobile}</p>
      </div>

    </React.Fragment>
  }
  const AppointmentDateFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      <div>
        {rowData.AppointmentDate != "" && rowData.AppointmentTime != "" ? rowData.AppointmentDate + ' | ' + rowData.AppointmentTime : rowData.AppointmentDate}
        &nbsp; <EditToolTip title="Edit" onClick={() => editHandler(rowData)} />
      </div>
    </React.Fragment>
  }
  const TotalPatientFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      {rowData.TotalPatients + ' | ' + '( ' + rowData.Age + ' )'}
    </React.Fragment>
  }
  const RouteSelectionHandler = (cellData) => {
    //setSelectedRouteList(options);
    var isUpdate = 0;
    for (var key in TableData) {
      if (TableData[key].AppointmentId == cellData.AppointmentId) {
        isUpdate = 1;
        if (cellData != 0) {
          TableData[key].RouteID = cellData;
        }
        if (isUpdate == 1)
          setTableData([...TableData]);
      }
      else {
        isUpdate = 0;
      }
    }
  }
  const RouteAppointmentMap = async (cellData, item) => {
    setSelectedRouteList(cellData);
    var isUpdate = 0;
    for (var key in TableData) {
      if (TableData[key].AppointmentId == item.AppointmentId) {
        isUpdate = 1;
        if (cellData.value != 0) {
          TableData[key].RouteID = cellData.value;
        }
        if (isUpdate == 1)
          setTableData([...TableData]);
      }
      else {
        isUpdate = 0;
      }
    }
  }
  const RouteFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      <Form.Group>
        {/* <Form.Control as="select"  id={"Route_" + rowData.AppointmentId} value={TableData[rowIndex].RouteID} onChange={(e) => RouteAppointmentMap(e.target.value, rowData)} >
          <option value='0'>---Select---</option>
          {Routelist}
        </Form.Control> */}
        <Select options={[...Routelist]} isMulti={false}
          //onChange={(e) => RouteAppointmentMap(e.target.value, rowData)}
          //onChange={RouteSelectionHandler}
          onChange={(val) => RouteAppointmentMap(val,rowData)}
          value={SelectedRouteList}     
          //value={TableData[rowIndex].RouteID}
        />
      </Form.Group>
    </React.Fragment>
  }
  const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      <ToolTip title="Details" placement="top" id={`view${rowData.AppointmentId}`} onClick={() =>
        GetWebsiteAppointmentDetails(rowData.AppointmentId, rowData.UserAge, rowData.Gender, rowData.CentreId, rowData.UserId, rowData.MobileNo)} />
    </React.Fragment>
  }
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    onSelect: (rowData, isSelect, rowIndex, e) => {
      let fiterArr;
      const AutoCallRow = {
        'AppointmentId': rowData.AppointmentId,
        'RouteId': rowData.RouteID,
      }
      if (isSelect) {
        if (rowData.RouteID == 0) {
          SweetAlert.fire('Please select route name.');
          return false;
        }
        else {
          if (SelectDS != null && SelectDS.length > 0) {
            let fiter = SelectDS.filter((item) => {
              return item.callingid != rowData.callingid
            });
            if (fiter.length == 0) {
              SelectDS.push(AutoCallRow);
            }
          }
          else {
            setSelectDS([AutoCallRow]);
          }
        }
      }
      else {
        if (SelectDS != null && SelectDS.length > 0) {
          fiterArr = SelectDS.filter((item) => {
            return item.CallingId != rowData.CallingId
          });
        }
        setSelectDS(fiterArr);
      }

    },
    onSelectAll: (isSelect, rowData, e) => {
      setSelectDS(null);
      const AutoCallListSelectedAll = [];
      if (isSelect) {
        if (rowData.length > 0) {
          for (let i = 0; i < rowData.length; i++) {
            if (rowData[i].RouteID == 0) {
              SweetAlert.fire('Please select route name.');
              return [];
            }
            const AutoCallRow = {
              'AppointmentId': rowData[i].AppointmentId,
              'RouteId': rowData[i].RouteID,
            }
            AutoCallListSelectedAll.push(AutoCallRow);
          }
          setSelectDS(AutoCallListSelectedAll);
        }
      }
    }
  };
  const columns = [
    {
      dataField: '#',
      text: '#',
      formatter: idFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '5%' };
      }
    },
    {
      dataField: 'CustomerName',
      text: 'Customer Details',
      formatter: CustomerDetailsFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '10%' };
      }
    },
    {
      dataField: 'Address',
      text: 'Address'
    },
    {
      dataField: 'AppointmentTime',
      text: 'Appointment Date',
      formatter: AppointmentDateFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '20%' };
      }
    },
    {
      dataField: 'TotalPatients',
      text: 'Total Patients | Age',
      formatter: TotalPatientFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '15%' };
      }
    },
    {
      dataField: 'StatusName',
      text: 'Status'
    },
    ,
    {
      dataField: 'RouteID',
      text: 'Route Name',
      formatter: RouteFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '15%' };
      }
    },
    {
      dataField: 'Action',
      text: 'Action',
      formatter: actionFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '5%' };
      }
    }
  ];
   const editHandler = (rowData) => {
    setOpenModal(true);
    setAppId(rowData.AppointmentId);
  }
  const BindRoute = async (LocationId) => {
    try {
      let url = ConnectionInstance + 'cart/getroutebylocation/' + LocationId
      await axios.get(url).then((response) => {
        const data = response.data;
        if (data.Success == true) {
          let ds = data.Data;
          if (ds != null && ds.length > 0) {
            const TypeOptionDs = [];
            ds.map((option) => {
              const TypeOptions = { 'label': option.RouteName+' ('+option.RouteDesc+' )', 'value': option.RouteId }
              return TypeOptionDs.push(TypeOptions);
            });
            SetRoutelist(TypeOptionDs);
          }
          else { SetRoutelist([]); }
        }
        else {
          SetRoutelist([]);        
        }
      }).catch(function (error) { });
    }
    catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
  }
  // const BindRoute = async (LocationId) => {
  //   try {
  //     setIsButtonLoading(true);
  //     let url = ConnectionInstance + 'cart/getroutebylocation/' + LocationId
  //     await axios.get(url).then((response) => {
  //       const data = response.data;
  //       setIsButtonLoading(false);
  //       if (data.Success) {
  //         if (data.Data !== null) {
  //           if (data.Data === null) { SetRoutelist([]); setTableData([]); }
  //           else { setTableData([]); SetRoutelist(data.Data.map((d) => (<option key={d.RouteId} value={d.RouteId}>{d.RouteName}</option>))); }
  //         }
  //         else { SetRoutelist([]); }
  //       }
  //       else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsButtonLoading(false); }
  //     }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsButtonLoading(false); });
  //   } catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
  // }
  const handleSearch = async () => {
    BindLocation(DateId.current.value);
  }
  const handleSearchList = async () => {
    BindRoute(Locid.current.value);
    if (frmtime.current.value !== "" && ToTime.current.value !== "") { BindGrid(DateId.current.value, Locid.current.value, frmtime.current.value, ToTime.current.value, "1"); }
    else { BindGrid(DateId.current.value, Locid.current.value, "00:00:00", "00:00:00", "1"); }
  }
  const BindGrid = async (Date, Location, FromTime, ToTime, Flag) => {
    try {
      setIsButtonLoading(true);
      let url = ConnectionInstance + 'cart/GetPhleboDetails/' + Date + '/' + Location + '/' + FromTime + '/' + ToTime + "/" + Flag
      await axios.get(url).then((response) => {
        const data = response.data;
        if (data.Success) {
          if (data.Data === null || data.Data.length === 0) {
            setTableData([]);
          }
          else {
            if (Flag === "1") { setTableData(data.Data); }
            else { setTableData(data.Data.filter((d) => d.AppointmentId === localStorage.getItem("AppId"))); }
          }
          setIsButtonLoading(false);
        }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsButtonLoading(false); }
      }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsButtonLoading(false); });
    } catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsButtonLoading(false); }
  }
  const AssignRouteFunc = async () => {
    if (SelectDS == "0" || SelectDS == undefined) { SweetAlert.fire("Please select customer"); return false; }
    else {
      const requestOptions = {
        objAppointmentRouteMap: SelectDS,
        AddedBy: localStorage.LoggedInUser,
      };
      setIsButtonLoading(true);
      let url = ConnectionInstance + 'Cart/AssignRoute'
      await axios.post(url, requestOptions).then((response) => {
        const data = response.data;
        setIsButtonLoading(false);
        if (data.Success) {
          SweetAlert.fire({
            text: "Assigned to Route",
            icon: 'success',
            confirmButtonText: 'Ok',
            reverseButtons: true,
          })
            .then((result) => {
              if (result.isConfirmed) {
                setSelectDS(0);
                BindGrid(DateId.current.value, Locid.current.value, "00:00:00", "00:00:00", "1");
                // setTableData([]);
                // frmtime.current.value = "";
                // ToTime.current.value = "";
                // Locid.current.value = "0";
                // SetLocation([]);
                // window.location.reload(false);
              }
              else {
                setSelectDS(0);
                BindGrid(DateId.current.value, Locid.current.value, "00:00:00", "00:00:00", "1");
                // setTableData([]);
                // Locid.current.value = "0";
                // SetLocation([]);
                // window.location.reload(false);
              }
            })
        }
      }).catch(function (error) {
        setIsButtonLoading(false);
        if (error.response) {
          ErrorFunction(error.response.status);
        }
      });
    }
  }
  const GetWebsiteAppointmentDetails = async (AppointmentId, UserAge, Gender, CentreId, UserId, MobileNo) => {
    setUserExistFlag("1");
    setAppointmentId(AppointmentId);
    setAge(UserAge);
    setGender(Gender);
    setCenterId(CentreId);
    localStorage.setItem("lid", UserId);
    setStatus("0");
    setIsConfirmed("0");
    dispatchUserEvent('ADD_LOGINDATA', { loginid: UserId });
  }
  const ClearStoredState = () => {
    setAppointmentId("0");
    setAge("");
    setGender("");
    setCenterId("0");
    dispatchUserEvent('REMOVE_LOGINDATA', { loginid: '0' });
    dispatchUserEvent('REMOVE_CENTREID', { centreid: '0' });
    dispatchUserEvent('REMOVE_DISTRICTID', { districtid: '0' });
    dispatchUserEvent('REMOVE_REGIONID', { regionid: '0' });
    dispatchUserEvent('REMOVE_ITEMMASTERDETAILS', { itemmasterdetails: [] });
    dispatchUserEvent('REMOVE_CARTSUMMARY', { cartsummary: [] });
    localStorage.setItem('loginid', '0');
    localStorage.setItem('centreid', '0');
    localStorage.setItem('districtid', '0');
    localStorage.setItem('regionid', '0');
    localStorage.setItem('itemmasterdetails', []);
    localStorage.setItem('cartsummary', []);
    localStorage.removeItem('lid');
    localStorage.removeItem('cid');
    localStorage.removeItem('did');
    localStorage.removeItem('rid');
    localStorage.removeItem('itemmasterdetails');
    localStorage.removeItem("appdate");
    localStorage.removeItem("apptime");
    localStorage.removeItem("appaddress");
    localStorage.removeItem("apptimetext");
    localStorage.removeItem("addedmoreitems");
    localStorage.removeItem("RateTempId");
    setTableData([]);
  }
  const APIError = async (ErrorCode, ErrorMsg) => {
    if (ErrorCode === "-1") { SweetAlert.fire({ title: "Duplicate", text: "User already exist", icon: "error" }) }
    else if (ErrorCode === "0") { SweetAlert.fire({ title: "Location not found", text: "Please select location", icon: "error" }) }
    else if (ErrorCode === "-1997") { SweetAlert.fire({ title: "Empty", text: "No record found", icon: "error" }) }
    else if (ErrorCode === "-1998") { SweetAlert.fire({ title: "Some value is missing", text: "Check server and db logs", icon: "error" }) }
    else if (ErrorCode === "-1999") { SweetAlert.fire({ title: "Runtime Exception", text: ErrorMsg, icon: "error" }) }
    else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
  }
  const ErrorFunction = async (ErrorCode) => {
    if (ErrorCode == "400")
      SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
    else if (ErrorCode == "401")
      SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
    else if (ErrorCode == "403")
      SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
    else if (ErrorCode == "404")
      SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
    else if (ErrorCode == "500")
      SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
    else if (ErrorCode == "503")
      SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
  }
  const RequestTime = (e) => {
    const value = e.target.options[e.target.selectedIndex].text;
    finaltime = value;
    if (finaltime === "---Select---") { finaltime = ""; }
  }
  const handleSlotDateChange = () => {
    GetSlotTiming(appointmentDateId.current.value);
  }
  const GetSlotTiming = async (date) => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) { dd = '0' + dd; }
    if (mm < 10) { mm = '0' + mm; }
    var todaydate = yyyy + '-' + mm + '-' + dd;
    if (date === "") { SweetAlert.fire({ title: "Error", text: "Please select date", icon: "error" }); setSlotTiming([]); return false; }
    if (todaydate > date) { SweetAlert.fire({ title: "Error", text: "You cannot select past date slots", icon: "error" }); setSlotTiming([]); return false; }
    setIsButtonLoading(true);
    let url = ConnectionInstance + 'Cart/getslottiming/' + centreid + '/' + loginid + '/' + date
    await axios.get(url).then((response) => {
      const data = response.data;
      setIsButtonLoading(false);
      if (data.Success) {
        setSlotTiming(data.Data.map((d) => <option key={d.SlotTimingId} value={d.SlotTimingId}>{d.FromTime} - {d.ToTime}</option>));
      }
      else { setSlotTiming = [] }
    }).catch(function (error) { setIsButtonLoading(false); if (error.response) { AxiosError(error.response.status); } });
  }
  const openRequiredFieldModal = () => { setOpenModal(!openmodal); }
  const updateAppDateTime = async () => {
    if (appointmentDateId.current.value === "") { SweetAlert.fire("Please enter date"); return false; }
    else if (dtime.current.options[dtime.current.selectedIndex].value == "") { SweetAlert.fire("Please select time"); return false; }
    else {
      setOpenModal(false);
      const requestOptions = {
        LoginId: localStorage.LoggedInUser,
        AppointmentId: appid,
        AppointmentDate: appointmentDateId.current.value,
        AppointmentTime: dtime.current.options[dtime.current.selectedIndex].value,
      };
      setIsButtonLoading(true);
      let url = ConnectionInstance + 'Cart/UpdateAppointmentDate'
      await axios.post(url, requestOptions).then((response) => {
        const data = response.data;
        setIsButtonLoading(false);
        if (data.Success) {
          SweetAlert.fire({
            text: "Successfully Appointment date & time updated.",
            icon: 'success',
            confirmButtonText: 'Ok',
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              setSelectDS(null);
              setTableData([]);
              frmtime.current.value = "";
              ToTime.current.value = "";
              Locid.current.value = "0";
              SetLocation([]);
              window.location.reload(false);
            }
          })
        }
        else {
          APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
        }
      }).catch(function (error) {
        setIsButtonLoading(false);
        if (error.response) {
          ErrorFunction(error.response.status);
        }
      });
    }

  }
  const AxiosError = async (ErrorCode) => {
    if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
    else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
    else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
    else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
    else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
    else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
  }
  return (
    <>
      <Form.Row>
        <Col md={10}>
          <Breadcrumb title="Assign Route" parent="Phlebo" />
        </Col>
        {
          appointmentid === "0" ? null :
            <>
              <Col md={2} style={{ textAlign: 'right', marginTop: 25, right: 14 }}>
                <Button variant='secondary' type='submit' onClick={() => ClearStoredState()}>Back</Button>
              </Col>
            </>
        }
      </Form.Row>
      <Container fluid>
        {
          appointmentid === "0" ?
            <>
              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Body>
                      <Form.Row>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label>Date</Form.Label>
                            <Form.Control type="date" name="date" ref={DateId} defaultValue={defaultDate} onChange={handleSearch} />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label>Location</Form.Label>
                            <Form.Control as="select" id="Locid" ref={Locid} onChange={handleSearchList} >
                              <option value='0'>---Select---</option>
                              {Location}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label>From Time</Form.Label>
                            <Form.Control type="Time" ref={frmtime} onChange={handleSearchList} />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label>To Time</Form.Label>
                            <Form.Control type="Time" ref={ToTime} onChange={handleSearchList} />
                            {/* <Select options={[...Routelist]} isMulti={false}
                              onChange={RouteSelectionHandler}
                              value={SelectedRouteList}
                            /> */}
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <div className="mt15">
                        <DataTable
                          keyField='AppointmentId'
                          tableData={TableData}
                          columns={columns}                         
                          pagination={paginationFactory(paginationOptions)}                    
                          selectRow={selectRow} />
                      </div>
                      {
                        TableData != null && TableData.length > 0 ?
                          <>
                            {
                              localStorage.getItem("RouteDate") === null ?
                                <>
                                  <div className='mt15 text-center'>
                                    <Button variant='secondary' type='submit' onClick={(e) => AssignRouteFunc()}>Assign Route</Button>
                                  </div>
                                </>
                                :
                                null
                            }
                          </> : null
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </> :
            <>
              {
                userexistflag === "" ? null
                  :
                  <AppointmentDetails AppointmentId={appointmentid} Age={age} Gender={gender} CenterId={centerid} UserExistFlag={userexistflag} Status={status} IsConfirmed={isconfirmed} />
              }
            </>
        }
        {
          openmodal &&
          <Modal isOpen={openmodal} centered={true} size="lg">
            <ModalHeader> Appointment</ModalHeader>
            <ModalBody>
              <Form.Row>
                <Col md={6}>
                  <Form.Group className="mb-3 calandersec">
                    <Form.Label>Date</Form.Label>
                    <Form.Control type="date" name="date" ref={appointmentDateId} defaultValue={defaultDate} onChange={handleSlotDateChange} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Select time<span className='text-red'>*</span></Form.Label>
                    <Form.Control as="select" name="time" ref={dtime} onChangeCapture={(e) => RequestTime(e)}>
                      <option value="">---Select---</option>
                      {slottiming}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={12} xs={11}>
                  <Form.Group className='m-t-15'>
                    <Form.Label className='icRight'>
                      <Button variant='secondary' className="mr-2" onClick={openRequiredFieldModal}>Cancel</Button>
                      {
                        isButtonLoading ?
                          <LoadingButton variantName='primary' cssName="" /> :
                          <Button variant='primary' type='submit' id="btnUpdate" onClick={updateAppDateTime}>Update</Button>
                      }
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ModalBody>
          </Modal>
        }
      </Container >
    </>
  )
}

export default AssignRoute;