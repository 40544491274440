import React, { useState, useEffect, useContext, useRef } from 'react'
import CallToolTip from '../UI/ToolTips/CallToolTip';
import ToolTip from '../UI/ToolTips/ViewToolTip';
import Breadcrumb from '../commonComponent/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DataTable from '../commonComponent/DataTable/DataTable';
import axios from 'axios';
import ConnectionInstance from '../apisettings/ConnectionInstance';
import AppointmentDetails from '../../pages/commonComponent/cart/appointmentdetails';
import { GlobalInfo } from '../../components/context/context'
import classes from '../commonComponent/cart/CartSummary.module.css';
import SignUp from '../../pages/commonComponent/cart/signup';
import { Typeahead } from 'react-bootstrap-typeahead';
import InputGroup from 'react-bootstrap/InputGroup';
import DetailsToolTip from '../UI/ToolTips/DetailsToolTip';

const Appointments = () => {
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [table, setTable] = useState([]);
    const [appointmentid, setAppointmentId] = useState("0");
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [centerid, setCenterId] = useState("0");
    const [userexistflag, setUserExistFlag] = useState("");
    const [showdetails, setShowDetails] = useState(false);
    const [hidebackbutton, setHideBackButton] = useState(false);
    const { dispatchUserEvent } = useContext(GlobalInfo);
    const [status, setStatus] = useState("0");
    const [isconfirmed, setIsConfirmed] = useState("0");
    const [moboruhidoptions, setMobOrUhidOptions] = useState([]);
    let moboruhid = useRef();
    let btnnew = useRef();

    useEffect(() => {
        ClearStoredState();
        const pageAccessedByReload = (
            (window.performance.navigation && window.performance.navigation.type === 1) ||
            window.performance
                .getEntriesByType('navigation')
                .map((nav) => nav.type)
                .includes('reload')
        );
        if (pageAccessedByReload === true) {
            ClearStoredState();
            localStorage.removeItem("samplerid");
            localStorage.removeItem("sampledid");
            localStorage.removeItem("samplecid");
            localStorage.removeItem("sampleitemmasterdetails");
        }
        return () => {
            ClearStoredState();
            localStorage.removeItem("samplerid");
            localStorage.removeItem("sampledid");
            localStorage.removeItem("samplecid");
            localStorage.removeItem("sampleitemmasterdetails");
        }
    }, [])

    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <ToolTip title="Details" placement="top" id={`view${rowData.AppointmentId}`} onClick={() =>
                GetWebsiteAppointmentDetails(rowData.AppointmentId, rowData.Age, rowData.Gender, rowData.CentreId, rowData.UserId, rowData.MobileNo, rowData.Status, rowData.IsConfirmedByCallCentre)} />
            {
                rowData.Status === "0" || rowData.Status === "False" ? null :
                    <DetailsToolTip title={`Your phlebo name: ${rowData.PhleboName}, Your phlebo will arrive to you at : ${rowData.RouteDate} | ${rowData.RouteStartTime} - ${rowData.RouteEndTime}`} placement="top" id={`show${rowData.AppointmentId}`} />
            }
        </React.Fragment>
    }
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'PatientName', text: 'Customer Name', editable: false },
        { dataField: 'MobileNo', text: 'Customer Mobile', editable: false },
        { dataField: 'AppointmentDate', text: 'Appointment Date', editable: false },
        { dataField: 'SourceCategory', text: 'Source Category', editable: false },
        { dataField: 'Purpose', text: 'Purpose', editable: false },
        { dataField: 'CallType', text: 'CallType', editable: false },
        { dataField: 'Team', text: 'Team', editable: false },
        { dataField: 'StatusName', text: 'Status', editable: false },
        { dataField: 'actions', text: 'Actions', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '10%' }; } }
    ];
    const GetWebsiteAppointmentDetails = async (AppointmentId, Age, Gender, CentreId, UserId, MobileNo, Status, IsConfirmedByCallCentre) => {
        localStorage.removeItem("samplerid");
        localStorage.removeItem("sampledid");
        localStorage.removeItem("samplecid");
        localStorage.removeItem("sampleitemmasterdetails");
        setUserExistFlag("1");
        setAppointmentId(AppointmentId);
        setAge(Age);
        setGender(Gender);
        setCenterId(CentreId);
        if (Status === "0" || Status === "False") { setStatus("0"); }
        else { setStatus("1"); }
        if (IsConfirmedByCallCentre === "0" || IsConfirmedByCallCentre === "False") { setIsConfirmed("0"); }
        else { setIsConfirmed("1"); }
        localStorage.setItem("lid", UserId);
        dispatchUserEvent('ADD_LOGINDATA', { loginid: UserId });
    }
    const ClearStoredState = () => {
        setAppointmentId("0");
        setAge("");
        setGender("");
        setCenterId("0");
        setTable([]);
        setMobOrUhidOptions([]);
        setShowDetails(false);
        dispatchUserEvent('REMOVE_LOGINDATA', { loginid: '0' });
        dispatchUserEvent('REMOVE_CENTREID', { centreid: '0' });
        dispatchUserEvent('REMOVE_DISTRICTID', { districtid: '0' });
        dispatchUserEvent('REMOVE_REGIONID', { regionid: '0' });
        dispatchUserEvent('REMOVE_ITEMMASTERDETAILS', { itemmasterdetails: [] });
        dispatchUserEvent('REMOVE_CARTSUMMARY', { cartsummary: [] });
        localStorage.setItem('loginid', '0');
        localStorage.setItem('centreid', '0');
        localStorage.setItem('districtid', '0');
        localStorage.setItem('regionid', '0');
        localStorage.setItem('itemmasterdetails', []);
        localStorage.setItem('cartsummary', []);
        localStorage.removeItem('lid');
        localStorage.removeItem('cid');
        localStorage.removeItem('did');
        localStorage.removeItem('rid');
        localStorage.removeItem('itemmasterdetails');
        localStorage.removeItem("appdate");
        localStorage.removeItem("apptime");
        localStorage.removeItem("appaddress");
        localStorage.removeItem("apptimetext");
        localStorage.removeItem("addedmoreitems");
        localStorage.removeItem("RateTempId");
    }
    const openSignUp = async () => {
        setStatus("0");
        setAppointmentId("-1");
        setUserExistFlag("");
    }
    const ClickSearchPatient = async () => {
        if (moboruhid.current.value.length != "10") SweetAlert.fire('Patient mobile must be exactly 10 characters');
    }
    // const SearchPatient = async (MobileNo) => {
    //     try {
    //         let url = ConnectionInstance + 'master/getallwebsiteappointments/'
    //         await axios.get(url).then((response) => {
    //             const data = response.data.Data;
    //             if (response.data.Success === true) {
    //                 if (data !== null) {
    //                     var isexist = data.filter((d) => d.MobileNo === MobileNo);
    //                     if (isexist.length === 0) { setTable([]); }
    //                     else { setTable(data.filter((d) => d.MobileNo === MobileNo)); }
    //                 }
    //                 else { setTable([]); }
    //             }
    //             else { setTable([]); }
    //         }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    //     }
    //     catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    // }
    const SearchPatient = async (MobileNo) => {
        try {
            let url = ConnectionInstance + 'master/getallexistingwebsiteappointments/' + MobileNo
            await axios.get(url).then((response) => {
                const data = response.data.Data;
                if (response.data.Success === true) {
                    if (data !== null) {
                        var isexist = data.filter((d) => d.MobileNo === MobileNo);
                        if (isexist.length === 0) { setTable([]); }
                        else { setTable(data.filter((d) => d.MobileNo === MobileNo)); }
                    }
                    else { setTable([]); }
                }
                else { setTable([]); }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const SearchPatientByMobNo = async (e) => {
        if (e.target.value.length == "10") {
            try {
                let url = ConnectionInstance + 'master/getsearchpatientbymobno/' + e.target.value
                await axios.get(url).then((response) => {
                    const data = response.data.Data;
                    if (response.data.Success === true) {
                        if (data !== null) {
                            var isexist = data.filter((d) => d.MobileNo === e.target.value);
                            if (isexist.length === 0) { setShowDetails(false); setMobOrUhidOptions([]); SweetAlert.fire("No patient exist"); }
                            else {
                                setShowDetails(true);
                                setMobOrUhidOptions(data.filter((d) => d.MobileNo === e.target.value));
                                btnnew.current.focus();
                                setTimeout(() => {
                                    moboruhid.current.focus();
                                }, 100);
                                SearchPatient(e.target.value);
                            }
                            moboruhid.current.clear();
                        }
                        else { setShowDetails(false); setMobOrUhidOptions([]); moboruhid.current.clear(); SweetAlert.fire("No patient exist"); }
                    }
                    else { setShowDetails(false); setMobOrUhidOptions([]); moboruhid.current.clear(); SweetAlert.fire("No patient exist"); }
                }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
            }
            catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
            e.preventDefault();
        }
    }
    const BindNewUserOrder = async (UserId, Age, Gender) => {
        setStatus("0");
        localStorage.setItem("lid", UserId);
        dispatchUserEvent('ADD_LOGINDATA', { loginid: UserId });
        setAge(Age);
        setGender(Gender);
        setCenterId(centerid);
        setHideBackButton(false);
        setAppointmentId("-1");
        setUserExistFlag("0");
    }
    const BindNewUserOrderButton = async () => {
        setStatus("0");
        localStorage.setItem("lid", moboruhidoptions[0].UserId);
        dispatchUserEvent('ADD_LOGINDATA', { loginid: moboruhidoptions[0].UserId });
        setAge(moboruhidoptions[0].Age);
        setGender(moboruhidoptions[0].Gender);
        setCenterId(centerid);
        setHideBackButton(false);
        setAppointmentId("-1");
        setUserExistFlag("0");
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") {
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        }
        else if (ErrorCode == "401") {
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        }
        else if (ErrorCode == "403") {
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        }
        else if (ErrorCode == "404") {
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        }
        else if (ErrorCode == "500") {
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        }
        else if (ErrorCode == "503") {
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
        }
    }
    return (
        <>
            <Container fluid>
                <Row className='align-items-center pt-2 pb-2'>
                    <Col md={10}>
                        <Breadcrumb title="Appointments" parent="Inbound Calls" />
                    </Col>
                    {
                        appointmentid === "0" ? null :
                            <>
                                {
                                    hidebackbutton === false ?
                                        <>
                                            <Col md={2} className='text-right'>
                                                <Button variant='secondary' type='submit' onClick={() => ClearStoredState()}>Back</Button>
                                            </Col>
                                        </> : null
                                }
                            </>
                    }
                </Row>
            </Container>
            <Container fluid>
                {
                    appointmentid === "0" ?
                        <>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <Form.Row className='justify-content-center align-items-center'>
                                                <Col className='col-auto adPatientColLeft'>
                                                    <Form.Group className='mb-0'>
                                                        <InputGroup id='inpt'>
                                                            <Typeahead
                                                                id="mobuhid-typeahead"
                                                                labelKey="PatientUHID"
                                                                multiple={false}
                                                                options={moboruhidoptions}
                                                                ref={moboruhid}
                                                                placeholder="Search by patient mobile"
                                                                onInputChange={(text, e) => { SearchPatientByMobNo(e, text); }}
                                                                onChange={(text) => { JSON.stringify(text) == "[]" ? BindNewUserOrder("0", "", "") : BindNewUserOrder(text[0].UserId, text[0].Age, text[0].Gender) }}
                                                            />
                                                            <InputGroup.Append>
                                                                <Button variant="secondary" onClick={ClickSearchPatient}><i className='fa fa-search'></i></Button>
                                                            </InputGroup.Append>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>

                                                <Col className='col-auto adPatientColRight'>
                                                    <Form.Group className='mb-0'>
                                                        <Button variant='primary' ref={btnnew} type='submit' onClick={openSignUp}>Add New Patient</Button>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            {showdetails &&
                                <>
                                    <Row>
                                        <Col md={12}>
                                            <Card>
                                                <Card.Header className='pb-0'>
                                                    <Form.Row>
                                                        <Col md={6}>
                                                            <Card.Title>Appointment List</Card.Title>
                                                        </Col>
                                                        <Col md={6} className='mt-4 mt-md-0' style={{ textAlign: 'right' }}>
                                                            <Form.Group>
                                                                <Button variant='primary' type='submit' onClick={BindNewUserOrderButton}>New Appointment</Button>
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Row>
                                                </Card.Header>
                                                <Card.Body>
                                                    <DataTable
                                                        keyField='AppointmentId'
                                                        tableData={table}
                                                        columns={columns}
                                                        pagination={paginationFactory(paginationOptions)}
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </>
                        :
                        <>
                            {
                                userexistflag === "" ?
                                    <SignUp appointmentid={(appointmentid) => setAppointmentId(appointmentid)}
                                        age={(age) => setAge(age)}
                                        gender={(gender) => setGender(gender)}
                                        centerid={(centerid) => setCenterId(centerid)}
                                        userexistflag={(userexistflag) => setUserExistFlag(userexistflag)}
                                        hidebackbutton={(hidebackbutton) => setHideBackButton(hidebackbutton)} />
                                    :
                                    <AppointmentDetails AppointmentId={appointmentid} Age={age} Gender={gender} CenterId={centerid} UserExistFlag={userexistflag} Status={status} IsConfirmed={isconfirmed} />
                            }
                        </>
                }
            </Container>
        </>
    )
}

export default Appointments;