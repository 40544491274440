import React, { useState, useEffect, useContext, useRef } from 'react';
import { Row, Col, Card, Button, Form, Modal } from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DataTables from '../../commonComponent/DataTable/DataTable';
import useRazorpay from "react-razorpay";
import Loader from './loader';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Accordion from 'react-bootstrap/Accordion';
import LoadingButton from './LoadingButton';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConnectionInstance from '../../apisettings/ConnectionInstance';
import classes from './CartSummary.module.css';
import CartSummary from './CartSummary';
import Order from './Order';
import OrderSummary from './OrderSummary';
import { GlobalInfo } from '../../../components/context/context';




var notes = "";
var glblage = "";
var glblhomeclass = "";
var glblcentreclass = "";
var finaldate = "";
var finaltime = "";
var patientdetailsid = [];
var multiplepatientdetailsid = [];
var glbldate = "";
var grandtotalamount = 0;
var itemselected = [];
var fdate = "";
var fslotdate = "";
var glbldistrictList = [];
var allexistingcartdata = [];
const Cart = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [cartdescription, setCartDescription] = useState([]);
    const [cartpatientdescription, setCartPatientDescription] = useState([]);
    const [selectPatient, setSelectPatient] = useState(true);
    const [selectAddress, setSelectAddress] = useState(false);
    const [selecteditpatient, setSelectEditPatient] = useState(false);
    const [selecteditaddress, setSelectEditAddress] = useState(false);
    const [selectedittime, setSelectEditTime] = useState(false);
    const [selectTime, setSelectTime] = useState(false);
    const [patientHeader, setPatientHeader] = useState(true);
    const [addPatient, setAddPatient] = useState(false);
    const [addressHeader, setAddressHeader] = useState(true);
    const [addAddress, setAddAddress] = useState(false);
    const [homeCollection, setHomeCollection] = useState(true);
    const [visitCollection, setVisitCollection] = useState(false);
    const [disablefields, setDisableFields] = useState({});
    const [gender, setGender] = useState();
    const [family, setFamily] = useState();
    const [districtsList, setDistrictsList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [cityModal, setCityModal] = useState(false);
    const [viewcities, setViewCities] = useState([]);
    const [addressdetailid, setAddressDetailId] = useState("0");
    const [carthomeaddress, setCartHomeAddress] = useState([]);
    const [cartcentreaddress, setCartCentreAddress] = useState([]);
    const [finaldatetime, setFinalDateTime] = useState();
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [patientname, setPatientName] = useState("");
    const [slottiming, setSlotTiming] = useState([]);
    const [cartprecautions, setCartPrecautions] = useState("");
    const [cartprerequisite, setCartPreRequisite] = useState("");
    const [allpatientname, setAllPatientName] = useState([]);
    const [reviewstatus, setReviewStatus] = useState(false);
    const [maxdate, setMaxDate] = useState("");
    const [statevalues, setStateValues] = useState([]);
    const [patientdetailid, setPatientDetailId] = useState([]);
    const [show, setShow] = useState(false);
    const [allitemdetails, setAllItemDetails] = useState([]);
    const [packageitems, setPackageItems] = useState([]);
    const [packageitemModal, setPackageItemModal] = useState(false);
    const [validationModal, setValidationModal] = useState(false);
    const [filteredpackageitems, setFilteredPackageItems] = useState([]);
    const [allselecteditems, setAllSelectedItems] = useState([]);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState();
    const [selectedslotDate, setSelectedSlotDate] = useState();
    const [checkcentreExist, setCheckCentreExist] = useState(false);
    const [checkavailablepage, setCheckAvailablePage] = useState(false);
    const [centrevisitrequired, setCentreVisitRequired] = useState(false);
    const [allvisitids, setAllVisitIds] = useState("");
    const [allvaliditems, setAllValidItems] = useState("");
    const [cartnotes, setCartNotes] = useState();
    const [patientDetailsID, setPatientDetailsID] = useState(0);
    const [dobstatus, setDobStatus] = useState(false);
    const [finaldateofbirth, setFinalDateOfBirth] = useState("");
    const [dobtext, setDobText] = useState();
    const [agetext, setAgeText] = useState();
    let dateobirth = useRef();
    let day = useRef();
    let month = useRef();
    let year = useRef();
    // let refPhleboNotes =useRef();
    var { dispatchUserEvent, loginid, centreid, districtid, itemmasterdetails, regionid } = useContext(GlobalInfo);
    const calendarRef = React.createRef();
    const calendarRef2 = React.createRef();
    let dtime = useRef();
    let refcancelreason = useRef();
    const Razorpay = useRazorpay();
    const addPatientHandler = () => {
        setPatientDetailsID(0);
        formik.resetForm();
        formik.values.patientname = '';
        formik.values.title = '';
        formik.values.gender = '';
        formik.values.family = '';
        formik.values.emailid = '';
        formik.values.refdoctor = '';
        formik.values.dob = '';
        setDobText(formik.dirty == true ? !formik.dirty : formik.dirty);
        setAgeText(formik.dirty == true ? !formik.dirty : formik.dirty);
        setAddPatient(true);
        setPatientHeader(false);
    }
    const editPatientHandler = (patientid) => {
        setPatientDetailsID(patientid);
        setAddPatient(true);
        setPatientHeader(false);
        GetPatientDetails(patientid);
    }
    const closePatientHandler = () => { setPatientHeader(true); setAddPatient(false); }
    const addAddressHandler = () => { setAddAddress(true); setAddressHeader(false); addressformik.resetForm(); }
    const closeAddressHandler = () => { setAddressHeader(true); setAddAddress(false); setDistrictsList([]); }
    const homeCollectionHandler = () => { setHomeCollection(true); setVisitCollection(false); GetCartAddress("1"); }
    const visitCollectionHandler = () => { setVisitCollection(true); setHomeCollection(false); GetCartAddress("2"); }
    const selectAddressHandler = () => { setCheckAvailablePage(true); SavePatientId(); }
    const openpatientCartHandler = () => {
        setSelectPatient(true); setSelectAddress(false); setSelectTime(false); setSelectEditPatient(false); setSelectEditAddress(false);
    }
    const openaddressCartHandler = () => {
        setSelectAddress(true); setSelectTime(false); setSelectPatient(false); setSelectEditAddress(false); setSelectEditTime(false);
        if (addressdetailid !== "0") {
            setTimeout(() => {
                document.getElementById("home" + addressdetailid).classList.add(`${classes.active}`);
            }, 100);
        }
    }
    const selectTimeHandler = () => {
        if (addressdetailid !== "0") {
            SaveCartPatientAddress();//Updated Patient Details Address of all patients against login
            setSelectTime(true); setSelectEditAddress(true); setSelectAddress(false);
        }
        else {
            Swal.fire("Please select address or add new address")
        }
    }
    const openCitiesModal = () => { setCrntPageNO(0); setCityModal(!cityModal); }
    const openPackageItemModal = () => { setCrntPageNO(0); setPackageItemModal(!packageitemModal); }
    const responsive = {
        superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 2 },
        desktop: { breakpoint: { max: 3000, min: 1024 }, items: 2 },
        tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
        mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
    };
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    useEffect(() => {
        if (selectTime === true) {
            if (props.UserExistFlag === "1" && props.ShowNew === false) {
                var fdate = localStorage.getItem("appdate").split(" ");
                var alldate = fdate[0].split("-");
                var date = alldate[0];
                var month = alldate[1];
                var year = alldate[2];
                // var date = alldate[2];
                // var month = alldate[1];
                // var year = alldate[0];
                var finaldate = [month, date, year].join("-");
                var datenew = new Date(finaldate),
                    mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
                    day = ("0" + datenew.getDate()).slice(-2);
                fslotdate = [datenew.getFullYear(), mnth, day].join("-");
                setSelectedSlotDate(datenew);
                RequestNewDate(fslotdate);
            }
        }
    }, [selectTime])
    useEffect(() => {
        if (centreid !== "0") { localStorage.setItem("cid", centreid); }
        if (loginid !== "0") { localStorage.setItem("lid", loginid); }
        if (districtid !== "0") { localStorage.setItem("did", districtid); }
        if (regionid !== "0") { localStorage.setItem("rid", regionid); }
        const pageAccessedByReload = (
            (window.performance.navigation && window.performance.navigation.type === 1) ||
            window.performance
                .getEntriesByType('navigation')
                .map((nav) => nav.type)
                .includes('reload')
        );
        if (pageAccessedByReload === true) {
            if (localStorage.getItem("cid") === null || localStorage.getItem("cid" === null)) { }
            else { centreid = localStorage.getItem("cid") }
            if (localStorage.getItem("lid") === null || localStorage.getItem("lid" === null)) { }
            else { loginid = localStorage.getItem("lid") }
            if (localStorage.getItem("did") === null || localStorage.getItem("did" === null)) { }
            else { districtid = localStorage.getItem("did") }
            if (localStorage.getItem("rid") === null || localStorage.getItem("rid" === null)) { }
            else { regionid = localStorage.getItem("rid") }
        }
        var dtToday = new Date();
        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();
        setMaxDate(year + '-' + month + '-' + day);
        getallitemdata();
        GetPackageItems();
        getgenderandfamily();
        GetStates();
        GetDistrictsOnlyOnce();
    }, [centreid, loginid])
    // useEffect(() => {
    //     var dtToday = new Date();
    //     var month = dtToday.getMonth() + 1;
    //     var day = dtToday.getDate();
    //     var year = dtToday.getFullYear();
    //     if (month < 10)
    //         month = '0' + month.toString();
    //     if (day < 10)
    //         day = '0' + day.toString();
    //     setMaxDate(year + '-' + month + '-' + day);
    //     getallitemdata();
    //     GetPackageItems();
    //     getgenderandfamily();
    //     GetStates();
    //     GetDistrictsOnlyOnce();
    // }, [centreid, loginid])
    useEffect(() => {
        var linkedPatId = [];
        var visitreq = "0";      
        if (reviewstatus === false) {
            if (cartdescription.length > 0 && cartpatientdescription.length > 0 && selecteditpatient === false) {
                if (props.UserExistFlag === "1" && props.ShowNew === false) {
                    for (var g in cartpatientdescription) {
                        for (var h in cartdescription) {
                            if (cartdescription[h].PatientId === cartpatientdescription[g].PatientId) {
                                if ((cartdescription[h].Gender.toLowerCase() === cartpatientdescription[g].GenderName.toLowerCase() || cartdescription[h].Gender.toLowerCase() === "all")
                                    && (cartdescription[h].Age === cartpatientdescription[g].PatientAge || cartdescription[h].Age.toLowerCase() === "all")) {
                                    document.getElementById("rd" + cartpatientdescription[g].PatientId + h).checked = true;
                                    linkedPatId.push({
                                        PatientId: cartpatientdescription[g].PatientId
                                    })
                                    if (cartdescription[h].HomeCollectionAvailable == "0" || cartdescription[h].HomeCollectionAvailable.toLowerCase() == "false") { visitreq = "1"; }
                                }
                            }
                        }
                    }
                }
                else {
                    for (var g in cartpatientdescription) {
                        for (var h in cartdescription) {
                            if ((cartdescription[h].Gender.toLowerCase() === cartpatientdescription[g].GenderName.toLowerCase() || cartdescription[h].Gender.toLowerCase() === "all")
                                && (cartdescription[h].Age === cartpatientdescription[g].PatientAge || cartdescription[h].Age.toLowerCase() === "all")) {
                                document.getElementById("rd" + cartpatientdescription[g].PatientId + h).checked = true;
                                linkedPatId.push({
                                    PatientId: cartpatientdescription[g].PatientId
                                })
                                if (cartdescription[h].HomeCollectionAvailable == "0" || cartdescription[h].HomeCollectionAvailable.toLowerCase() == "false") { visitreq = "1"; }
                            }
                        }
                    }
                }
                if (visitreq == "1") { setCentreVisitRequired(true); }
                var IsBreak = "0";
                itemselected = [];
                var PtId = "";
                var ICount = 0;
                for (var a in cartpatientdescription) {
                    PtId = "";
                    ICount = 0;
                    IsBreak = "0";
                    for (var b in linkedPatId) {
                        if (cartpatientdescription[a].PatientId === linkedPatId[b].PatientId) {
                            PtId = cartpatientdescription[a].PatientId
                            ICount = parseFloat(ICount) + 1;
                            IsBreak = "1";
                        }
                        else {
                            if (IsBreak === "0") {
                                PtId = cartpatientdescription[a].PatientId
                                ICount = 0
                            }
                        }
                    }
                    itemselected.push({
                        PatientId: PtId === "" ? cartpatientdescription[a].PatientId : PtId,
                        ItemCount: ICount
                    });
                }
                setAllSelectedItems(itemselected);
            }
        }
    }, [cartdescription, cartpatientdescription])
    useEffect(() => {
        setCheckCentreExist(true);
        { props.reviewstatus(reviewstatus); }
    }, [reviewstatus])
    useEffect(() => {
        if (checkavailablepage === false) {
            multiplepatientdetailsid = [];
        }
        if (selectPatient === true) {
            for (var key in multiplepatientdetailsid) {
                if (multiplepatientdetailsid[key] !== "") {
                    document.getElementById('rd' + multiplepatientdetailsid[key]).checked = true;
                }
            }
            setTimeout(() => { multiplepatientdetailsid = []; }, 1000);
        }
    }, [selectPatient, checkavailablepage])
    useEffect(() => {
        if (finaldateofbirth != "") {
            formik.values.dob = finaldateofbirth;
            setFinalDateOfBirth("");
        }
    }, [dobstatus])
    /******Formik Starts*******/
    const phoneRegExppatientname = /^[a-zA-Z .']{0,500}$/
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const formik = useFormik({
        initialValues: {
            patientname: '',
            dob: '',
            gender: '',
            emailid: '',
            title: '',
            family: '',
            refdoctor: ''
        },
        validationSchema: Yup.object({
            patientname: Yup.string().required('Full name is required').matches(phoneRegExppatientname, 'Special characters is not allowed'),
            emailid: Yup.string().email('Invalid email id')
        }),
        onSubmit: values => {
            if (patientDetailsID == 0) {
                addNewPatientHandler(values);
            }
            else {
                updatePatientDetailsHandler(values);
            }
        }
    });
    const GetPatientDetails = async (patientdetailsid) => {
        let url = ConnectionInstance + 'Master/getpatientdetailsbyid/' + patientdetailsid
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                formik.setFieldValue('patientname', data.Data.PatientName);
                formik.setFieldValue('title', data.Data.Title);
                formik.setFieldValue('gender', data.Data.PatientGender);
                formik.setFieldValue('family', data.Data.FamilyId);
                formik.setFieldValue('emailid', data.Data.EmailID);
                formik.setFieldValue('refdoctor', data.Data.OtherDr);
                formik.setFieldValue('dob', data.Data.PatientDOB);
                //formik.values.dob = data.Data.DOB;
                //dateobirth.current.value = data.Data.PatientDOB;
                year.current.value = data.Data.Year;
                month.current.value = data.Data.Month;
                day.current.value = data.Data.Day;

                // var datenew = new Date(data.Data.PatientDOB),
                //     mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
                //     day = ("0" + datenew.getDate()).slice(-2);
                // fdate = [datenew.getFullYear(), mnth, day].join("-");
                // setSelectedDate(datenew);
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const updatePatientDetailsHandler = async (values) => {
        if (values.dob === "") {
            Swal.fire("Date of birth is required");
            return false;
        }
        else if (year.current.value == "" && month.current.value == "" && day.current.value == "") {
            Swal.fire("Please enter valid Date of Birth");
            return false;
        }
        else if ((year.current.value > 100 || year.current.value < 0 || month.current.value < 0 || day.current.value < 0)) {
            Swal.fire("Please enter valid Date of Birth");
            return false;
        }
        //CallAge(fdate);
        const requestOptions = {
            PatientDetailsID: patientDetailsID,
            LoginId: loginid,
            Title: values.title === "" ? "Mr." : values.title,
            FullName: values.patientname,
            // DOB: fdate,
            // Age: glblage.toString(),
            DOB: values.dob.split('-')[2] + '-' + values.dob.split('-')[1] + '-' + values.dob.split('-')[0],//values.dob.replaceAll("-", "/"),
            Age: year.current.value,
            Gender: (values.gender === undefined || values.gender === "") ? "1" : values.gender,
            Family: values.family === "" ? "1" : values.family,
            EmailId: values.emailid === undefined ? "" : values.emailid,
            RefDoctor: (values.refdoctor === undefined || values.refdoctor === "") ? "Self" : values.refdoctor,
            Year: year.current.value,
            Month: month.current.value,
            Day: day.current.value,
        };
        let url = ConnectionInstance + 'Cart/updatepatientdetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                setPatientDetailsID(0);
                formik.resetForm();
                formik.values.patientname = '';
                formik.values.title = '';
                formik.values.gender = '';
                formik.values.family = '';
                formik.values.emailid = '';
                formik.values.refdoctor = '';
                formik.values.dob = '';
                setSelectedDate("");
                setAddPatient(false);
                setPatientHeader(true);
                Swal.fire({
                    title: 'Success!',
                    text: "Patient has been updated successfully!",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true,
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            if (props.UserExistFlag === "1" && props.ShowNew === false) {
                                GetUserAppointments();
                            }
                            else { GetCartItemsandPackages(); }
                        }
                        else {
                            if (props.UserExistFlag === "1" && props.ShowNew === false) {
                                GetUserAppointments();
                            }
                            else { GetCartItemsandPackages(); }
                        }
                    })
            }
            else {
                if (data.Data === "-1") { Swal.fire("Patient already added.") }
                else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
    }
    const addressformik = useFormik({
        initialValues: {
            pincode: '',
            locStateId: '',
            locDistrictId: '',
            houseno: '',
            address: ''
        },
        validationSchema: Yup.object({
            pincode: Yup.number().typeError('Pincode  must be a number'),
            locStateId: Yup.string().required('State is required'),
            locDistrictId: Yup.string().required('District is required'),
            // houseno: Yup.string().required('House No. is required'),
            // address: Yup.string().required('Address is required')
        }),
        onSubmit: values => {
            addNewAddressHandler(values);
        }
    });
    const datetimeformik = useFormik({
        initialValues: {
            date: '',
            time: ''
        },
        validationSchema: Yup.object({
            time: Yup.string().required('Time is required')
        }),
        onSubmit: values => {
            if (fslotdate === "") {
                Swal.fire("Date is required")
            }
            else {
                GetCartItemsandPackagesAgainstDate(fslotdate, values);
            }
        }
    });
    /******Formik Ends*******/
    const popupidcityFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const popupactioncityFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div><a href="javascript:void(0)" onClick={() => SelectedCity(rowData)}>Select</a></div>
        </React.Fragment>
    }
    const popupcityColumns = [
        { dataField: '#', text: '#', editable: false, formatter: popupidcityFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'StateName', text: 'State', editable: false },
        { dataField: 'DistrictName', text: 'District', editable: false },
        { dataField: 'actions', text: 'Actions', formatter: popupactioncityFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '15%' }; } }
    ];
    const packageitemFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const popuppackageitemsColumns = [
        { dataField: '#', text: '#', editable: false, formatter: packageitemFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'InvestigationName', text: 'Tests', editable: false },
    ];
    const GetCartItemsandPackages = async () => {
        var arr = [];
        var glblitemstore = [];
        if (itemmasterdetails.length > 0) { arr = JSON.parse(itemmasterdetails); }
        for (var key in arr) {
            glblitemstore.push(
                {
                    ItemMasterId: arr[key].ItemMasterId,
                    IsPackage: arr[key].IsPackage
                }
            );
        }
        const requestOptions = {
            CentreId: centreid,
            LoginId: loginid,
            ItemDetailsList: JSON.stringify(glblitemstore)
        };
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/getcartitemsandpackages'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data.cartdescription !== null) { setCartDescription(data.Data.cartdescription); }
                if (data.Data.cartpatientdescription !== null) { setCartPatientDescription(data.Data.cartpatientdescription); }
                setCartPrecautions(data.Data.cartpatientdescription[0].Precautions);
                setCartPreRequisite(data.Data.cartpatientdescription[0].PreRequisite);
                dispatchUserEvent('ADD_CARTSUMMARY', { cartsummary: JSON.stringify(data.Data.cartdescription) });
                var holdarr = [];
                for (var key in data.Data.cartdescription) {
                    holdarr.push({
                        ItemMasterId: data.Data.cartdescription[key].ItemMasterId,
                        IsPackage: data.Data.cartdescription[key].IsPackage === "True" ? "1" : data.Data.cartdescription[key].IsPackage === "False" ? "0" : data.Data.cartdescription[key].IsPackage
                    })
                }
                if (holdarr.length === 0) {
                    { props.opencart(false); }
                    dispatchUserEvent('REMOVE_ITEMMASTERDETAILS', { itemmasterdetails: [] }); dispatchUserEvent('REMOVE_CARTSUMMARY', { cartsummary: [] });
                }
                else { dispatchUserEvent('ADD_ITEMMASTERDETAILS', { itemmasterdetails: JSON.stringify(holdarr) }); }
            }
            else {
                { props.opencart(false); }
                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                AxiosError(error.response.status);
            }
        });
    }
    const GetCartItemsandPackagesAgainstDate = async (fslotdate, values) => {
        var arr = [];
        var glblitemstore = [];
        if (itemmasterdetails.length > 0) { arr = JSON.parse(itemmasterdetails); }
        for (var key in arr) {
            glblitemstore.push(
                {
                    ItemMasterId: arr[key].ItemMasterId,
                    IsPackage: arr[key].IsPackage
                }
            );
        }
        const requestOptions = {
            CentreId: centreid,
            LoginId: loginid,
            SlotDate: fslotdate,
            ItemDetailsList: JSON.stringify(glblitemstore)
        };
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/getcartitemsandpackagesagainstdate'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                /////////////////////////////////////////////////////
                if (data.Data.cartdescription !== null) {
                    var cartdescription = data.Data.cartdescription;
                    var isBreak = "0";
                    for (var key in allitemdetails) {
                        for (var keyy in cartdescription) {
                            if ((allitemdetails[key].ItemMasterId === cartdescription[keyy].ItemMasterId) && (allitemdetails[key].IsPackage === cartdescription[keyy].IsPackage)) {
                                if ((allitemdetails[key].DiscountPrice !== cartdescription[keyy].DiscountPrice) && isBreak === "0") {
                                    Swal.fire({
                                        title: 'Warning',
                                        text: 'Rates have changed',
                                        icon: 'warning',
                                        confirmButtonText: 'Ok',
                                        reverseButtons: true
                                    });
                                    isBreak = "1";
                                }
                                allitemdetails[key].DiscountPrice = cartdescription[keyy].DiscountPrice;
                                allitemdetails[key].ActualPrice = cartdescription[keyy].ActualPrice;
                                allitemdetails[key].OffPercent = cartdescription[keyy].OffPercent;
                            }
                        }
                    }
                }
                setAllItemDetails(allitemdetails);
                /////////////////////////////////////////////////////
                setCartPrecautions(data.Data.cartpatientdescription[0].Precautions);
                setCartPreRequisite(data.Data.cartpatientdescription[0].PreRequisite);
                dispatchUserEvent('ADD_CARTSUMMARY', { cartsummary: JSON.stringify(data.Data.cartdescription) });
                var holdarr = [];
                for (var key in data.Data.cartdescription) {
                    holdarr.push({
                        ItemMasterId: data.Data.cartdescription[key].ItemMasterId,
                        IsPackage: data.Data.cartdescription[key].IsPackage === "True" ? "1" : data.Data.cartdescription[key].IsPackage === "False" ? "0" : data.Data.cartdescription[key].IsPackage
                    })
                }
                if (holdarr.length === 0) {
                    //{ props.opencart(false); }
                    dispatchUserEvent('REMOVE_ITEMMASTERDETAILS', { itemmasterdetails: [] }); dispatchUserEvent('REMOVE_CARTSUMMARY', { cartsummary: [] });
                }
                else { dispatchUserEvent('ADD_ITEMMASTERDETAILS', { itemmasterdetails: JSON.stringify(holdarr) }); }
                setReviewStatus(true);
                setStateValues(values);
            }
            else {
                { props.opencart(false); }
                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                AxiosError(error.response.status);
            }
        });
    }
    const ChangeGender = async (e) => {
        if (e.target.value === "Mr.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Mrs.") { formik.values.gender = "2"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Miss.") { formik.values.gender = "2"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Baby.") { formik.values.gender = "2"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Baba.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Master.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Dr.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? formik.dirty : !formik.dirty }) }
        else if (e.target.value === "B/O.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? formik.dirty : !formik.dirty }) }
        else if (e.target.value === "Ms.") { formik.values.gender = "2"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "C/O.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? formik.dirty : !formik.dirty }) }
        else if (e.target.value === "Mx.") { formik.values.gender = "3"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
    }
    const getgenderandfamily = async () => {
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/getgenderandfamily/' + centreid + '/' + loginid
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data.genderdescription !== null) {
                    setGender(data.Data.genderdescription.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
                    setDisableFields({
                        gender: formik.dirty == true ? formik.dirty : !formik.dirty,
                    })
                }
                if (data.Data.familydescription !== null) { setFamily(data.Data.familydescription.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>))) }
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { AxiosError(error.response.status); }
        });
    }
    const addNewPatientHandler = async (values) => {
        if (values.dob === "") {
            Swal.fire("Date of birth is required");
            return false;
        }
        else if (year.current.value == "" && month.current.value == "" && day.current.value == "") {
            Swal.fire("Please enter valid Date of Birth");
            return false;
        }
        else if ((year.current.value > 100 || year.current.value < 0 || month.current.value < 0 || day.current.value < 0)) {
            Swal.fire("Please enter valid Date of Birth");
            return false;
        }
        //CallAge(fdate);
        const requestOptions = {
            LoginId: loginid,
            CentreId: centreid,
            Title: values.title === "" ? "Mr." : values.title,
            FullName: values.patientname,
            Mobile: '',//values.patientmobile.toString(),
            // DOB: fdate,
            // Age: glblage.toString(),
            DOB: values.dob.split('-')[2] + '-' + values.dob.split('-')[1] + '-' + values.dob.split('-')[0],//values.dob.replaceAll("-", "/"),
            Age: year.current.value,
            Gender: (values.gender === undefined || values.gender === "") ? "1" : values.gender,
            Family: values.family === "" ? "1" : values.family,
            EmailId: values.emailid === undefined ? "" : values.emailid,
            RefDoctor: (values.refdoctor === undefined || values.refdoctor === "") ? "Self" : values.refdoctor,
            Year: year.current.value,
            Month: month.current.value,
            Day: day.current.value
        };
        let url = ConnectionInstance + 'Cart/savepatientdetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                setAddPatient(false); setPatientHeader(true);
                Swal.fire({
                    title: 'Success!',
                    text: "Patient has been added successfully!",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true,
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            if (props.UserExistFlag === "1" && props.ShowNew === false) {
                                //GetNewUserDetails(data.Data);
                                GetUserAppointments();
                            }
                            else { GetCartItemsandPackages(); }
                        }
                        else {
                            if (props.UserExistFlag === "1" && props.ShowNew === false) {
                                //GetNewUserDetails(data.Data);
                                GetUserAppointments();
                            }
                            else { GetCartItemsandPackages(); }
                        }
                    })
            }
            else {
                if (data.Data === "-1") { Swal.fire("Patient already added.") }
                else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
    }
    const DisableKeyPress = (e) => {
        e.preventDefault();
        return false;
    }
    const CallAge = async (DOB) => {
        glblage = "0";
        var d = new Date(), cmonth = d.getMonth(), cyear = d.getFullYear();
        var birthyear = DOB.split('-')[0];
        var birthmonth = DOB.split('-')[1];
        if (parseInt(cmonth + 1) > parseInt(birthmonth)) { glblage = parseInt(cyear - birthyear); }
        else { glblage = parseInt(cyear - birthyear - 1); }
    }
    const GetStates = async () => {
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/getLocationStates/' + centreid + '/' + loginid + '/' + districtid
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                setStatesList(data.Data.map((d) => <option key={d.StateId} value={d.StateId}>{d.StateName}</option>));
            }
            else {
                //APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const StateChangeHandler = (e) => {
        if (e.target.value === "") { setDistrictsList([]); } else {
            GetDistricts();
        }
    }
    const GetDistricts = async () => {
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/getLocationDistricts/' + centreid + '/' + loginid + '/' + districtid
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                setDistrictsList(data.Data.map((d) => <option key={d.DistrictId} value={d.DistrictId}>{d.DistrictName}</option>));
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const GetDistrictsOnlyOnce = async () => {
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/getLocationDistricts/' + centreid + '/' + loginid + '/' + districtid
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                glbldistrictList = data.Data;
            }
        }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const CallStateDistrict = (e) => {
        let text = e.target.value;
        let lastvalue = text.charAt(text.length - 1);
        var arraynew = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
        const valueexist = arraynew.includes(lastvalue.toString());
        if (valueexist === false && text !== "") {
            Swal.fire("Invalid! Pincode must be a number");
            addressformik.values.pincode = "";
        }
        let length = text.length;
        if (length === 6) {
            CallStateDistrictData(e.target.value);
        }
        else {
            setDistrictsList([]);
            addressformik.values.locStateId = ""
            addressformik.values.locDistrictId = ""
        }
    }
    const CallStateDistrictData = async (Pincode) => {
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/getlocationagainstpincode/' + centreid + '/' + loginid + '/' + Pincode
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                const pinCodeData = data.Data;
                var pincodearr = [];
                var isBreak = 0;
                for (var keyy in pinCodeData) {
                    for (var key in glbldistrictList) {
                        if (pinCodeData[keyy].DistrictId === glbldistrictList[key].DistrictId && isBreak === 0) {
                            pincodearr.push({
                                StateId: pinCodeData[keyy].StateId,
                                StateName: pinCodeData[keyy].StateName,
                                DistrictId: glbldistrictList[key].DistrictId,
                                DistrictName: glbldistrictList[key].DistrictName
                            });
                            isBreak = 1;
                        }
                    }
                }
                if (isBreak === 0) {
                    GetDistricts();
                    alert('testB');
                    // Swal.fire("Sorry! Currently we are not serving at this location.");
                    // addressformik.values.pincode = "";
                }
                else {
                    if (pincodearr.length === 1) {
                        setCityModal(false);
                        addressformik.values.locStateId = pincodearr[0].StateId;
                        //GetDistrictsAgainstState(pinCodeData[0].StateId);
                        GetDistricts();
                        addressformik.values.locDistrictId = pincodearr[0].DistrictId;
                    }
                    else {
                        setCityModal(true);
                        setViewCities(pincodearr);
                    }
                }
            }
            else {
                if (data.Data === "-1997"){
                    GetDistricts();
                } else{
                    
                 APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);   
                }
                // if (data.Data === "-1997") { Swal.fire("Sorry! Currently we are not serving at this location."); addressformik.values.pincode = ""; }
                // else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
   
                // if (data.Data === "-1997") { Swal.fire("Sorry! Currently we are not serving at this location."); addressformik.values.pincode = ""; }
                // else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }
        }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const SelectedCity = (pinCodeData) => {
        setCityModal(false);
        addressformik.values.locStateId = pinCodeData.StateId;
        GetDistricts();
        addressformik.values.locDistrictId = pinCodeData.DistrictId;
    }
    const addNewAddressHandler = async (values) => {
        const requestOptions = {
            LoginId: loginid,
            CentreId: centreid,
            Pincode: values.pincode.toString() === undefined ? "" : values.pincode.toString(),
            State: values.locStateId,
            District: values.locDistrictId,
            HouseNo: "",//values.houseno === undefined ? "" : values.houseno,
            Address: values.address === undefined ? "" : values.address,
        };
        let url = ConnectionInstance + 'Cart/savepatientaddressdetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                setAddAddress(false); setAddressHeader(true);
                Swal.fire({ title: "Success!", text: "Patient address has been added successfully!", icon: "success" });
                GetCartAddress("1");
            }
            else {
                if (data.Data === "-1") { Swal.fire("Address already added.") }
                else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
    }
    const GetCartAddress = async (flag) => {
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/getcartaddress/' + centreid + '/' + districtid + '/' + loginid
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (flag === "1") {
                    if (data.Data.carthomeaddress !== null) {
                        var garray = [];
                        for (var keyy in data.Data.carthomeaddress) {
                            if (data.Data.carthomeaddress[keyy].Id === localStorage.getItem("appaddress"))
                                garray.push({
                                    Id: data.Data.carthomeaddress[keyy].Id,
                                    Pincode: data.Data.carthomeaddress[keyy].Pincode,
                                    State: data.Data.carthomeaddress[keyy].State,
                                    District: data.Data.carthomeaddress[keyy].District,
                                    HouseNo: data.Data.carthomeaddress[keyy].HouseNo,
                                    Address: data.Data.carthomeaddress[keyy].Address,
                                    DeleteFlag: data.Data.carthomeaddress[keyy].DeleteFlag,
                                    EditFlag: data.Data.carthomeaddress[keyy].EditFlag
                                })
                        }
                        for (var keyy in data.Data.carthomeaddress) {
                            if (data.Data.carthomeaddress[keyy].Id !== localStorage.getItem("appaddress"))
                                garray.push({
                                    Id: data.Data.carthomeaddress[keyy].Id,
                                    Pincode: data.Data.carthomeaddress[keyy].Pincode,
                                    State: data.Data.carthomeaddress[keyy].State,
                                    District: data.Data.carthomeaddress[keyy].District,
                                    HouseNo: data.Data.carthomeaddress[keyy].HouseNo,
                                    Address: data.Data.carthomeaddress[keyy].Address,
                                    DeleteFlag: data.Data.carthomeaddress[keyy].DeleteFlag,
                                    EditFlag: data.Data.carthomeaddress[keyy].EditFlag
                                })
                        }
                        // setCartHomeAddress(data.Data.carthomeaddress);
                        setCartHomeAddress(garray);
                        for (var key in data.Data.carthomeaddress) {
                            if (key === "0") {
                                if (props.UserExistFlag === "1" && props.ShowNew === false) {
                                    glblhomeclass = localStorage.getItem("appaddress");
                                    document.getElementById("home" + localStorage.getItem("appaddress")).classList.add(`${classes.active}`);
                                    setAddressDetailId(localStorage.getItem("appaddress"));
                                } else {
                                    glblhomeclass = data.Data.carthomeaddress[key].Id;
                                    document.getElementById("home" + data.Data.carthomeaddress[key].Id).classList.add(`${classes.active}`);
                                    setAddressDetailId(data.Data.carthomeaddress[key].Id);
                                }
                            }
                        }
                    }
                    else {
                        setCartHomeAddress([]);
                    }
                }
                else {
                    if (data.Data.cartcentreaddress !== null) {
                        setCartCentreAddress(data.Data.cartcentreaddress);
                        for (var keyy in data.Data.cartcentreaddress) {
                            if (keyy === "0") {
                                glblcentreclass = data.Data.cartcentreaddress[keyy].Id;
                                document.getElementById("centre" + data.Data.cartcentreaddress[keyy].Id).classList.add(`${classes.active}`);
                            }
                        }
                    }
                }
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const AddHomeCollectionClass = (Id) => {
        if (glblhomeclass !== "") {
            document.getElementById("home" + glblhomeclass).classList.remove(`${classes.active}`);
        }
        document.getElementById("home" + Id).classList.add(`${classes.active}`);
        setAddressDetailId(Id);
        glblhomeclass = Id;
    }
    const AddCentreCollectionClass = (Id) => {
        if (glblcentreclass !== "") {
            document.getElementById("centre" + glblcentreclass).classList.remove(`${classes.active}`);
        }
        document.getElementById("centre" + Id).classList.add(`${classes.active}`);
        glblcentreclass = Id;
    }
    const RequestDate = (e) => {
        finaltime = "";
        datetimeformik.values.time = "";
        const value = e.split("-");
        glbldate = e.split("-");
        finaldate = toMonthName(value[1]) + " " + value[2] + ", " + value[0];
        setFinalDateTime(finaldate + ", " + finaltime);
        GetSlotTiming();
    }
    const RequestNewDate = (e) => {
        finaltime = "";
        datetimeformik.values.time = "";
        const value = e.split("-");
        glbldate = e.split("-");
        finaldate = toMonthName(value[1]) + " " + value[2] + ", " + value[0];
        setFinalDateTime(finaldate + ", " + finaltime);
        GetSlotTiming();
        const valuenew = localStorage.getItem("apptimetext");
        finaltime = valuenew;
        setFinalDateTime(finaldate + ", " + finaltime);
        datetimeformik.values.time = localStorage.getItem("apptime");
    }
    const RequestTime = (e) => {
        const value = e.target.options[e.target.selectedIndex].text;
        finaltime = value;
        if (finaltime === "---Select---") { finaltime = ""; }
        setFinalDateTime(finaldate + ", " + finaltime);
    }
    const SaveCartPatientAddress = async () => {
        var PID = "";
        for (var key in patientdetailid) {
            PID += patientdetailid[key] + ","
        }
        const requestOptions = {
            LoginId: loginid,
            CentreId: centreid,
            PatientDetailId: PID,
            AddressId: addressdetailid
        };
        let url = ConnectionInstance + 'Cart/savecartpatientaddress'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) { }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
    }
    const SavePatientId = () => {
        var patientname = [];
        var itemdetails = [];
        patientdetailsid = [];
        multiplepatientdetailsid = [];
        for (var keyy in cartdescription) {
            for (var key in cartpatientdescription) {
                if (document.getElementById('rd' + cartpatientdescription[key].PatientId + keyy) !== null) {
                    if (document.getElementById('rd' + cartpatientdescription[key].PatientId + keyy).checked) {
                        patientdetailsid.push(cartpatientdescription[key].PatientId);
                        multiplepatientdetailsid.push(cartpatientdescription[key].PatientId + keyy);
                        patientname.push({
                            PatientId: cartpatientdescription[key].PatientId,
                            PatientName: cartpatientdescription[key].PatientName,
                            Age: cartpatientdescription[key].PatientAge,
                            Gender: cartpatientdescription[key].GenderName
                        })
                        itemdetails.push({
                            Key: keyy,
                            ItemMasterId: cartdescription[keyy].ItemMasterId,
                            IsPackage: cartdescription[keyy].IsPackage === "True" ? "1" : cartdescription[keyy].IsPackage === "False" ? "0" : cartdescription[keyy].IsPackage,
                            DiscountPrice: cartdescription[keyy].DiscountPrice,
                            PatientId: cartpatientdescription[key].PatientId,
                            ActualPrice: cartdescription[keyy].ActualPrice,
                            ItemMasterName: cartdescription[keyy].ItemMasterName,
                            OffPercent: cartdescription[keyy].OffPercent,
                            HomeCollectionAvailable: cartdescription[keyy].HomeCollectionAvailable
                        })
                    }
                }
            }
        }
        itemselected = [];
        var PtId = "";
        var ICount = 0;
        const unique = [...new Map(patientname.map((m) => [m.PatientId, m])).values()];
        patientname = unique;

        setAllPatientName(patientname);
        setAllItemDetails(itemdetails);
        setPatientDetailId(patientdetailsid);
        if (multiplepatientdetailsid.length > 0) {
            /////////// ITEM COUNT SECTION ONLY//////////
            const uniquePatients = [...new Map(cartpatientdescription.map((m) => [m.PatientId, m])).values()];
            var IsBreak = "0";
            for (var a in uniquePatients) {
                PtId = "";
                ICount = 0;
                IsBreak = "0";
                for (var b in itemdetails) {
                    if (uniquePatients[a].PatientId === itemdetails[b].PatientId) {
                        PtId = uniquePatients[a].PatientId
                        ICount = parseFloat(ICount) + 1;
                        IsBreak = "1";
                    }
                    else {
                        if (IsBreak === "0") {
                            PtId = uniquePatients[a].PatientId
                            ICount = 0
                        }
                    }
                }
                itemselected.push({
                    PatientId: PtId === "" ? cartpatientdescription[a].PatientId : PtId,
                    ItemCount: ICount
                });
            }
            setAllSelectedItems(itemselected);
            /////////// ITEM COUNT SECTION ONLY//////////
            var arr1 = [];
            var arr2 = [];
            var arr3 = [];
            var arrnew = [];
            var isBreak = "0";
            var allitemsarray = [];
            var allitems = "";
            arr1 = itemdetails.filter((d) => d.IsPackage === "1");
            arr3 = itemdetails.filter((d) => d.IsPackage === "0");
            for (var x in cartpatientdescription) {
                arr2 = [];
                for (var key in arr1) {
                    if (cartpatientdescription[x].PatientId === arr1[key].PatientId) {
                        for (var keyy in packageitems) {
                            if (packageitems[keyy].PackageMasterId === arr1[key].ItemMasterId) {
                                arr2.push(packageitems[keyy].InvestigationMasterId)
                            }
                        }
                    }
                    arrnew = arr2.filter((item, index) => arr2.indexOf(item) !== index)
                    if (arrnew.length > 0) {
                        Swal.fire("One of the items is included in package, So you need to remove item or the same package.");
                        return false;
                    }
                    for (var index in arr3) {
                        //if (isBreak === "0") {
                        for (var indexx in arr2) {
                            if (arr2[indexx] === arr3[index].ItemMasterId && cartpatientdescription[x].PatientId === arr3[index].PatientId) {
                                isBreak = "1";
                                allitemsarray.push({ "ItemMasterName": arr3[index].ItemMasterName });
                            }
                        }
                        //}
                    }
                }
            }
            const unique = [...new Map(allitemsarray.map((m) => [m.ItemMasterName, m])).values()];
            allitemsarray = unique;
            for (var keyzz in allitemsarray) {
                if (allitems === "") { allitems += allitemsarray[keyzz].ItemMasterName }
                else { allitems += ", " + allitemsarray[keyzz].ItemMasterName }
            }
            if (isBreak === "1") {
                setAllValidItems(allitems);
                openValidationItemModal();
                //Swal.fire("Below are the items which is included in package, So you need to remove item or the same package." + allitems);
                //Swal.fire("One of the items is included in package, So you need to remove item or the same package.");
                isBreak = "0";
                return false;
            }
            ///////////
            SavePatientAddressToTempAdddress(patientdetailsid);
            setSelectAddress(true); setSelectEditPatient(true); setSelectPatient(false);
        }
        else {
            Swal.fire("Patient is mandatory.Please select by clicking on checkbox");
            return false;
        }
        for (var keyy in cartdescription) {
            for (var key in cartpatientdescription) {
                if (document.getElementById('rd' + cartpatientdescription[key].PatientId + keyy) !== null) {
                    if (document.getElementById('rd' + cartpatientdescription[key].PatientId + keyy).checked) {
                        setPatientName(cartpatientdescription[key].PatientName);
                        return false;
                    }
                }
            }
        }
    }
    const confirmBookingHandler = async (values, onlineflag) => {
        if (props.UserExistFlag === "1" && props.ShowNew === false) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You are going to confirm an appointment for " + finaldatetime,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                reverseButtons: true,
                cancelButtonText: 'Cancel',
                cancelButtonColor: "#000",
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        DeleteExistingVisits(values, onlineflag);
                        // added & commented test with kavish
                        localStorage.removeItem('addedmoreitems');
                    }
                    else { Swal.fire('Appointment still pending') }
                })
        }
        else {
            Swal.fire({
                title: 'Are you sure?',
                text: "You are going to to confirm an appointment for " + finaldatetime,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                reverseButtons: true,
                cancelButtonText: 'Cancel',
                cancelButtonColor: "#000",
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        CheckUserAppoitmentExist(values, onlineflag);
                        // added & comented test with kavish
                        //localStorage.removeItem('addedmoreitems');
                    }
                    else { Swal.fire('Appointment still pending') }
                })
        }

    }
    const SavePatientAddressToTempAdddress = async (patientdetailsid) => {
        var PID = "";
        for (var key in patientdetailsid) {
            PID += patientdetailsid[key] + ","
        }
        const requestOptions = {
            LoginId: loginid,
            CentreId: centreid,
            PatientDetailId: PID
        };
        let url = ConnectionInstance + 'Cart/savepatientaddresstotemp'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) { GetCartAddress("1"); }
            else { GetCartAddress("1"); }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
    }
    const toMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('en-US', {
            month: 'long',
        });
    }
    const GetSlotTiming = async () => {
        var fdate = glbldate[0] + '-' + glbldate[1] + '-' + glbldate[2];
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) { dd = '0' + dd; }
        if (mm < 10) { mm = '0' + mm; }
        var todaydate = yyyy + '-' + mm + '-' + dd;
        if (finaldate === "") { Swal.fire("Please select date"); setSlotTiming([]); return false; }
        if (todaydate > fdate) { Swal.fire("You cannot select past date slots"); setSlotTiming([]); return false; }
        var callfinaldate = "";
        callfinaldate = finaldate;
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/getslottiming/' + centreid + '/' + loginid + '/' + callfinaldate
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                setSlotTiming(data.Data.map((d) => <option key={d.SlotTimingId} value={d.SlotTimingId}>{d.FromTime} - {d.ToTime}</option>));
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const CallPatientId = async (PatientId) => {
        var itemdetails = [];
        itemdetails = [];
        var visitreq = "0";
        for (var keyy in cartdescription) {
            for (var key in cartpatientdescription) {
                if (document.getElementById('rd' + cartpatientdescription[key].PatientId + keyy) !== null) {
                    if (document.getElementById('rd' + cartpatientdescription[key].PatientId + keyy).checked) {
                        itemdetails.push({
                            PatientId: cartpatientdescription[key].PatientId
                        })
                        if (cartdescription[keyy].HomeCollectionAvailable == "0" || cartdescription[keyy].HomeCollectionAvailable.toLowerCase() == "false") { visitreq = "1"; }
                    }
                }
            }
        }
        if (visitreq == "1") { setCentreVisitRequired(true); }
        else { setCentreVisitRequired(false); }
        var IsBreak = "0";
        var PtId = "";
        var ICount = 0;
        itemselected = [];
        for (var a in cartpatientdescription) {
            PtId = "";
            ICount = 0;
            IsBreak = "0";
            for (var b in itemdetails) {
                if (cartpatientdescription[a].PatientId === itemdetails[b].PatientId) {
                    PtId = cartpatientdescription[a].PatientId
                    ICount = parseFloat(ICount) + 1;
                    IsBreak = "1";
                }
                else {
                    if (IsBreak === "0") {
                        PtId = cartpatientdescription[a].PatientId
                        ICount = 0
                    }
                }
            }
            itemselected.push({
                PatientId: PtId === "" ? cartpatientdescription[a].PatientId : PtId,
                ItemCount: ICount
            });

        }
        setAllSelectedItems(itemselected);
    }
    const RemoveCartAddress = async (AddressId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You are going to delete an address",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            reverseButtons: true,
            cancelButtonText: 'Cancel',
            cancelButtonColor: "#000",
        })
            .then((result) => {
                if (result.value) {
                    const requestOptions = {
                        LoginId: loginid,
                        CentreId: centreid,
                        AddressId: AddressId,
                    };
                    let url = ConnectionInstance + 'Cart/removecartadress'
                    axios.post(url, requestOptions).then((response) => {
                        const data = response.data;
                        if (data.Success) {
                            Swal.fire({ title: "Deleted", text: "Address successfully deleted", icon: "success" });
                            setAddressDetailId("0");
                            GetCartAddress("1");
                        }
                        else { setSlotTiming([]); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
                    }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
                }
                else { Swal.fire('Your data is safe!') }
            })
    }
    const CheckUserAppoitmentExist = async (values, onlineflag) => {
        var PID = "";
        for (var key in patientdetailid) {
            PID += patientdetailid[key] + ","
        }
        const requestOptions = {
            LoginId: loginid,
            CentreId: centreid,
            PatientDetailId: PID,
            AddressId: addressdetailid,
            DateSlot: fslotdate,
            TimeSlot: values.time
        };
        let url = ConnectionInstance + 'Cart/checkuserappointmentexist'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                const finalvisitids = data.Data.FinalVisitId;
                var patientvrecord = [];
                patientvrecord = data.Data.patientvisitrecord;
                ProceedPayment(values, finalvisitids, patientvrecord, onlineflag);
            }
            else {
                if (data.Data === "-1") { Swal.fire({ title: "Duplicate", text: "Appointment already booked for a same date", icon: "error" }) }
                else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
    }
    const ProceedPayment = (values, finalvisitids, patientvrecord, onlineflag) => {
        handlePayment(values, finalvisitids, patientvrecord, onlineflag);
    }
    const handlePayment = async (values, finalvisitids, patientvrecord, onlineflag) => {
        grandtotalamount = 0;
        for (var key in allitemdetails) {
            grandtotalamount += parseInt(allitemdetails[key].DiscountPrice);
        }
        if (onlineflag === "2") {
            BookAppointment(values, grandtotalamount, "4", "", finalvisitids, "Pay on delivery", patientvrecord);
        }
        else {
            const order = { id: finalvisitids.split(",")[0] };//await createOrder(params);
            const options = {
                key: "rzp_test_8BGw3Zqj37sCyE",
                amount: parseFloat(grandtotalamount) * 100, //in paise
                currency: "INR",
                name: "PIS",
                description: "Test Transaction",
                image: "https://example.com/your_logo",
                //order_id: order.id,
                handler: (response) => {
                    // alert(response.razorpay_payment_id);
                    // alert(response.razorpay_order_id);
                    // alert(response.razorpay_signature);
                    const referenceno = response.razorpay_payment_id;
                    BookAppointment(values, grandtotalamount, "1", referenceno, finalvisitids, '', patientvrecord);
                },
                prefill: {
                    name: "Piyush Garg",
                    email: "youremail@example.com",
                    contact: "9999999999",
                },
                notes: {
                    address: "Razorpay Corporate Office",
                },
                theme: {
                    color: "#3399cc",
                },
            };
            const rzpay = new Razorpay(options);
            rzpay.on("payment.failed", function (response) {
                // alert(response.error.code);
                // alert(response.error.description);
                // alert(response.error.source);
                // alert(response.error.step);
                // alert(response.error.reason);
                // alert(response.error.metadata.order_id);
                // alert(response.error.metadata.payment_id);
                const referenceno = response.error.metadata.payment_id;
                BookAppointment(values, grandtotalamount, "3", referenceno, finalvisitids, response.error.description, patientvrecord);
            });
            rzpay.open();
        }
    }//, [Razorpay]);
    const BookAppointment = async (values, grandtotalamount, paymentstatus, referenceno, finalvisitids, failedreason, patientvrecord) => {
        setShow(true);
        var finalarr = [];
        for (var keyy in patientvrecord) {
            for (var key in allitemdetails) {
                if (allitemdetails[key].PatientId === patientvrecord[keyy].PatientDetailId) {
                    finalarr.push({
                        ItemMasterId: allitemdetails[key].ItemMasterId,
                        IsPackage: allitemdetails[key].IsPackage,
                        PatientAmount: allitemdetails[key].DiscountPrice,
                        PatientId: allitemdetails[key].PatientId,
                        VisitingId: patientvrecord[keyy].VisitingId,
                        MRPAmount: allitemdetails[key].ActualPrice
                    });
                }
            }
        }
        const requestOptions = {
            LoginId: loginid,
            CentreId: centreid,
            AddressId: addressdetailid,
            DateSlot: fslotdate,
            TimeSlot: values.time,
            TotalAmount: grandtotalamount.toString(),
            PaymentStatus: paymentstatus,
            ReferenceNo: referenceno,
            VisitIds: finalvisitids,
            ItemDetails: JSON.stringify(finalarr),
            UserExistFlag: props.UserExistFlag,
            Notes: notes,
            ModuleID: "1",
            AppointmentID: props.AppointmentId
        };
        let url = ConnectionInstance + 'Cart/confirmBooking'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setShow(false);
            if (data.Success) {
                if (data.Data === "2002") {
                    Swal.fire({
                        title: 'Appointment Declined',
                        text: failedreason,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    })
                        .then((result) => {
                            if (result.value) { }
                        })
                }
                else {
                    GetVisitCode(finalvisitids);
                }
            }
            else {

                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) { setShow(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const GetVisitCode = async (finalvisitids) => {
        setIsLoading(true);
        setShow(true);
        let url = ConnectionInstance + 'Cart/getvisitcode/' + centreid + '/' + finalvisitids + '/' + loginid;
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            setShow(false);
            if (data.Success) {
                if (props.UserExistFlag === "1" && props.ShowNew === false) {
                    Swal.fire({
                        title: 'Success',
                        text: "Appointment confirmed with visitid " + data.Data.VisitingCode + "",
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    })
                        .then((result) => {
                            if (result.value) {
                                dispatchUserEvent('REMOVE_ITEMMASTERDETAILS', { itemmasterdetails: [] });
                                dispatchUserEvent('REMOVE_CARTSUMMARY', { cartsummary: [] });
                                window.location.reload(false);
                            }
                            else {
                                dispatchUserEvent('REMOVE_ITEMMASTERDETAILS', { itemmasterdetails: [] });
                                dispatchUserEvent('REMOVE_CARTSUMMARY', { cartsummary: [] });
                                window.location.reload(false);
                            }
                        })
                }
                else {
                    Swal.fire({
                        title: 'Success',
                        text: "Appointment confirmed with visitid " + data.Data.VisitingCode + "",
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    })
                        .then((result) => {
                            if (result.value) {
                                dispatchUserEvent('REMOVE_ITEMMASTERDETAILS', { itemmasterdetails: [] });
                                dispatchUserEvent('REMOVE_CARTSUMMARY', { cartsummary: [] });
                                window.location.reload(false);
                            }
                            else {
                                dispatchUserEvent('REMOVE_ITEMMASTERDETAILS', { itemmasterdetails: [] });
                                dispatchUserEvent('REMOVE_CARTSUMMARY', { cartsummary: [] });
                                window.location.reload(false);
                            }
                        })
                }
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { setShow(false); setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const GetPackageItems = async () => {
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/getpackageitems/' + centreid + '/' + loginid
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data !== null) {
                    setPackageItems(data.Data);
                }
                else {
                    setPackageItems([]);
                }
            }
            else { setPackageItems([]); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { AxiosError(error.response.status); }
        });
    }
    const ShowPackageItems = async (PackageMasterId) => {
        setFilteredPackageItems(packageitems.filter((d) => d.PackageMasterId === PackageMasterId));
        openPackageItemModal();
    }
    const handleDateChange = (date) => {
        var datenew = new Date(date),
            mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
            day = ("0" + datenew.getDate()).slice(-2);
        fdate = [datenew.getFullYear(), mnth, day].join("-");
        setSelectedDate(datenew);
    }
    const handleSlotDateChange = (date) => {
        var datenew = new Date(date),
            mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
            day = ("0" + datenew.getDate()).slice(-2);
        fslotdate = [datenew.getFullYear(), mnth, day].join("-");
        setSelectedSlotDate(datenew);
        RequestDate(fslotdate);
    }
    const GetUserAppointments = async () => {
        const requestOptions = {
            CentreId: centreid,
            LoginId: loginid,
            AppointmentId: props.AppointmentId,
            ModuleID: "1"
        };
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/getuserappointments'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            debugger;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data !== null) {
                    var IsOldVisit = "0";
                    for (var x in data.Data) {
                        if (data.Data[x].ItemMasterId !== "0" && IsOldVisit === "0") {
                            IsOldVisit = "1"
                        }
                    }
                    if (IsOldVisit === "1") {
                        var cartpatarr = [];
                        var visitids = "";
                        for (var n in data.Data) {
                            cartpatarr.push({
                                PatientId: data.Data[n].PatientId,
                                PatientName: data.Data[n].PatientName,
                                PatientAge: data.Data[n].PatientAge,
                                GenderName: data.Data[n].GenderName,
                                MobileNo: data.Data[n].MobileNo,
                                DeleteFlag: data.Data[n].DeleteFlag,
                                EditFlag: data.Data[n].EditFlag
                            });
                            if (data.Data[n].VisitingId != "0") { visitids += data.Data[n].VisitingId + "," }
                        }
                        setAllVisitIds(visitids);
                        const unique = [...new Map(cartpatarr.map((m) => [m.PatientId, m])).values()];
                        cartpatarr = unique;
                        ////////////////////
                        var cartfinalarr = [];
                        for (var a in data.Data) {
                            if (data.Data[a].ItemMasterId !== "0") {
                                cartfinalarr.push({
                                    ItemMasterId: data.Data[a].ItemMasterId,
                                    ItemMasterName: data.Data[a].ItemMasterName,
                                    ActualPrice: data.Data[a].ActualPrice,
                                    DiscountPrice: data.Data[a].DiscountPrice,
                                    OffPercent: data.Data[a].OffPercent,
                                    IsPackage: data.Data[a].IsPackage === "True" ? "1" : data.Data[a].IsPackage === "False" ? "0" : data.Data[a].IsPackage,
                                    Gender: data.Data[a].Gender,
                                    Age: data.Data[a].Age,
                                    HomeCollectionAvailable: data.Data[a].HomeCollectionAvailable,
                                    PatientId: data.Data[a].PatientId,
                                    PatientName: data.Data[a].PatientName,
                                    PatientAge: data.Data[a].PatientAge,
                                    GenderName: data.Data[a].GenderName,
                                    MobileNo: data.Data[a].MobileNo,
                                    DeleteFlag: data.Data[a].DeleteFlag,
                                    EditFlag: data.Data[a].EditFlag
                                });
                            }
                        }
                        if (allexistingcartdata.length > 0) {
                            for (var g in cartpatarr) {
                                for (var f in allexistingcartdata) {
                                    cartfinalarr.push({
                                        ItemMasterId: allexistingcartdata[f].ItemMasterId,
                                        ItemMasterName: allexistingcartdata[f].ItemMasterName,
                                        ActualPrice: allexistingcartdata[f].ActualPrice,
                                        DiscountPrice: allexistingcartdata[f].DiscountPrice,
                                        OffPercent: allexistingcartdata[f].OffPercent,
                                        IsPackage: allexistingcartdata[f].IsPackage === "True" ? "1" : allexistingcartdata[f].IsPackage === "False" ? "0" : allexistingcartdata[f].IsPackage,
                                        Gender: allexistingcartdata[f].Gender,
                                        Age: allexistingcartdata[f].Age,
                                        HomeCollectionAvailable: allexistingcartdata[f].HomeCollectionAvailable,
                                        PatientId: cartpatarr[g].PatientId,
                                        PatientName: cartpatarr[g].PatientName,
                                        PatientAge: cartpatarr[g].PatientAge,
                                        GenderName: cartpatarr[g].GenderName,
                                        MobileNo: cartpatarr[g].MobileNo,
                                        DeleteFlag: cartpatarr[g].DeleteFlag,
                                        EditFlag: cartpatarr[g].EditFlag,
                                    });
                                }
                            }
                        }
                        var jsonObject = cartfinalarr.map(JSON.stringify);
                        var uniqueSet = new Set(jsonObject);
                        var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
                        ////////////////////
                        //setCartDescription(data.Data);
                        setCartDescription(uniqueArray);
                        setCartPatientDescription(cartpatarr);
                        setCartPrecautions(data.Data[0].Precautions);
                        setCartPreRequisite(data.Data[0].PreRequisite);
                        setCartNotes(data.Data[0].Notes);
                       notes = data.Data[0].Notes;
                        dispatchUserEvent('ADD_CARTSUMMARY', { cartsummary: JSON.stringify(data.Data) });
                        // var holdarr = [];
                        // for (var key in data.Data) {
                        //     holdarr.push({
                        //         ItemMasterId: data.Data[key].ItemMasterId,
                        //         IsPackage: data.Data[key].IsPackage === "True" ? "1" : data.Data[key].IsPackage === "False" ? "0" : data.Data[key].IsPackage,
                        //         PatientId: data.Data[key].PatientId
                        //     })
                        // }
                        // if (holdarr.length === 0) {
                        //     dispatchUserEvent('REMOVE_ITEMMASTERDETAILS', { itemmasterdetails: [] }); dispatchUserEvent('REMOVE_CARTSUMMARY', { cartsummary: [] });
                        // }
                        // else { dispatchUserEvent('ADD_ITEMMASTERDETAILS', { itemmasterdetails: JSON.stringify(holdarr) }); }
                    }
                    else {
                        dispatchUserEvent('REMOVE_ITEMMASTERDETAILS', { itemmasterdetails: [] });
                        dispatchUserEvent('REMOVE_CARTSUMMARY', { cartsummary: [] });
                        { props.opencart(false); }
                    }
                }
            }
            else {
                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                AxiosError(error.response.status);
            }
        });
    }
    const DeleteExistingVisits = async (values, onlineflag) => {
        const requestOptions = {
            CentreId: centreid,
            LoginId: loginid,
            AllVisitIds: allvisitids
        };
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/DeleteExistingVisits'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                CheckUserAppoitmentExist(values, onlineflag);
            }
            else {
                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                AxiosError(error.response.status);
            }
        });
    }
    const cancelBookingHandler = async () => {
        if (refcancelreason.current.value === "") { Swal.fire("Please enter cancellation reason."); return false; }
        Swal.fire({
            title: 'Are you sure?',
            text: "You are going to cancel an appointment request for " + finaldatetime,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            reverseButtons: true,
            cancelButtonText: 'Cancel',
            cancelButtonColor: "#000",
        })
            .then((result) => {
                if (result.isConfirmed) {
                    CancelAppointment();
                }
                else { Swal.fire('Appointment still pending') }
            })
    }
    const CancelAppointment = async () => {
        const requestOptions = {
            CentreId: centreid,
            LoginId: loginid,
            AllVisitIds: allvisitids,
            CancelReason: refcancelreason.current.value
        };
        setIsLoading(true);
        let url = ConnectionInstance + 'Cart/CancelAppointment'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                Swal.fire({
                    title: 'Success',
                    text: "Appointment cancelled",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                })
                    .then((result) => {
                        if (result.value) {
                            dispatchUserEvent('REMOVE_ITEMMASTERDETAILS', { itemmasterdetails: [] });
                            dispatchUserEvent('REMOVE_CARTSUMMARY', { cartsummary: [] });
                            window.location.reload(false);
                        }
                        else {
                            dispatchUserEvent('REMOVE_ITEMMASTERDETAILS', { itemmasterdetails: [] });
                            dispatchUserEvent('REMOVE_CARTSUMMARY', { cartsummary: [] });
                            window.location.reload(false);
                        }
                    })
            }
            else {
                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                AxiosError(error.response.status);
            }
        });
    }
    const getallitemdata = async () => {
        var arr = [];
        var glblitemstore = [];
        if (localStorage.getItem('addedmoreitems') !== null) { arr = JSON.parse(localStorage.getItem('addedmoreitems')) }
        for (var key in arr) {
            if (arr[key].ItemMasterId !== "0") {
                glblitemstore.push(
                    {
                        ItemMasterId: arr[key].ItemMasterId,
                        IsPackage: arr[key].IsPackage
                    }
                );
            }
        }
        const requestOptions = {
            CentreId: centreid,
            LoginId: loginid,
            ItemDetailsList: JSON.stringify(glblitemstore)
        };
        let url = ConnectionInstance + 'Cart/getallitemdata'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            allexistingcartdata = [];
            if (data.Success) {
                if (data.Data !== null) {
                    allexistingcartdata = data.Data;
                }
                if (props.UserExistFlag === "1" && props.ShowNew === false) { GetUserAppointments(); }
                else { GetCartItemsandPackages(); }
            }
            else {
                allexistingcartdata = [];
                if (props.UserExistFlag === "1" && props.ShowNew === false) { GetUserAppointments(); }
                else { GetCartItemsandPackages(); }
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                AxiosError(error.response.status);
            }
        });
    }
    // const ChangeAccordianArrow = (id) => {
    //     var element = document.getElementById("acc" + id);
    //     if (element.classList.contains("active") === true) { element.classList.remove("active") }
    //     else { element.classList.add("active"); }
    // }
    const openValidationItemModal = () => { setValidationModal(!validationModal); }
    const SaveNotes = async (value) => {
        notes = value;
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { Swal.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { Swal.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { Swal.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { Swal.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { Swal.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { Swal.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { Swal.fire({ title: "Duplicate", text: "User already exist", icon: "error" }) }
        else if (ErrorCode === "0") { Swal.fire({ title: "Location not found", text: "Please select location", icon: "error" }) }
        else if (ErrorCode === "-1997") { Swal.fire({ title: "Empty", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-1998") { Swal.fire({ title: "Some value is missing", text: "Check server and db logs", icon: "error" }) }
        else if (ErrorCode === "-1999") { Swal.fire({ title: "Runtime Exception", text: ErrorMsg, icon: "error" }) }
        else { Swal.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    function CallDob(dateString) {
        if (formik.values.dob == "") {
            day.current.value = "";
            month.current.value = "";
            year.current.value = "";
            setAgeText(formik.dirty === true ? !formik.dirty : formik.dirty);
            return false;
        }
        if ((dateString.target.value.length == 10 && dateString.target.value.split('-').length != 3)) {
            Swal.fire('Please enter valid date of birth');
            formik.values.dob = "";
            day.current.value = "";
            month.current.value = "";
            year.current.value = "";
            setAgeText(!formik.dirty);
            return false;
        }
        setAgeText(formik.dirty);
        if (dateString.target.value.split('-')[1] > 12 || dateString.target.value.split('-')[1].length > 2 || isNaN(dateString.target.value.split('-')[1])) {
            Swal.fire('Please enter valid month');
            formik.values.dob = "";
            day.current.value = "";
            month.current.value = "";
            year.current.value = "";
            setAgeText(!formik.dirty);
            return false;
        }
        var mNow = dateString.target.value.split('-')[1];
        if (mNow < 10 && mNow.length < 2) { mNow = "0" + mNow; }
        if (mNow == "04" || mNow == "06" || mNow == "09" || mNow == "11") {
            if (dateString.target.value.split('-')[0] > 30 || dateString.target.value.split('-')[0].length > 2 || isNaN(dateString.target.value.split('-')[0])) {
                Swal.fire('Please enter valid day');
                formik.values.dob = "";
                day.current.value = "";
                month.current.value = "";
                year.current.value = "";
                setAgeText(!formik.dirty);
                return false;
            }
        }
        else if (mNow == "02") {
            if ((dateString.target.value.split('-')[0] == 29 && dateString.target.value.length == 10 && dateString.target.value.split('-').length == 3 && (dateString.target.value.split('-')[2]) % 4 != 0) || dateString.target.value.split('-')[0].length > 2 || isNaN(dateString.target.value.split('-')[0])) {
                Swal.fire('Please enter valid day');
                formik.values.dob = "";
                day.current.value = "";
                month.current.value = "";
                year.current.value = "";
                setAgeText(!formik.dirty);
                return false;
            }
            if (dateString.target.value.split('-')[0] > 29 || dateString.target.value.split('-')[0].length > 2 || isNaN(dateString.target.value.split('-')[0])) {
                Swal.fire('Please enter valid day');
                formik.values.dob = "";
                day.current.value = "";
                month.current.value = "";
                year.current.value = "";
                setAgeText(!formik.dirty);
                return false;
            }
        }
        else {
            if (dateString.target.value.split('-')[0] > 31 || dateString.target.value.split('-')[0].length > 2 || isNaN(dateString.target.value.split('-')[0])) {
                Swal.fire('Please enter valid day');
                formik.values.dob = "";
                day.current.value = "";
                month.current.value = "";
                year.current.value = "";
                setAgeText(!formik.dirty);
                return false;
            }
        }
        if (dateString.target.value !== "") {
            if (dateString.target.value.split('-')[2].length > 4 || isNaN(dateString.target.value.split('-')[2])) {
                Swal.fire('Please enter valid year');
                formik.values.dob = "";
                formik.values.age = "";
                day.current.value = "";
                month.current.value = "";
                year.current.value = "";
                setAgeText(!formik.dirty);
                return false;
            }
        }
        var now = new Date();
        var yearNow = now.getYear();
        var monthNow = now.getMonth();
        var dateNow = now.getDate();

        var dob = new Date(dateString.target.value.substring(6, 10),
            dateString.target.value.substring(3, 5) - 1,
            dateString.target.value.substring(0, 2)
        );

        var yearDob = dob.getYear();
        var monthDob = dob.getMonth();
        var dateDob = dob.getDate();
        var age = {};
        var yearAge = "";

        yearAge = yearNow - yearDob;

        if (monthNow >= monthDob)
            var monthAge = monthNow - monthDob;
        else {
            yearAge--;
            var monthAge = 12 + monthNow - monthDob;
        }
        if (dateNow >= dateDob)
            var dateAge = dateNow - dateDob;
        else {
            monthAge--;
            var dateAge = 31 + dateNow - dateDob;

            if (monthAge < 0) {
                monthAge = 11;
                yearAge--;
            }
        }
        age = {
            years: yearAge,
            months: monthAge,
            days: dateAge
        };
        if (dateString.target.value.split('-')[2].length === 4) {
            day.current.value = age.days;
            month.current.value = age.months;
            year.current.value = age.years;
            CalculateAge();
        }
        else {
            day.current.value = "";
            month.current.value = "";
            year.current.value = "";
            formik.values.age = "";
        }
    }
    const CalculateAge = () => {
        formik.values.age = year.current.value;
    }
    function formatDate(input) {
        var datePart = input.match(/\d+/g),
            year = datePart[0], month = datePart[1], day = datePart[2];
        setFinalDateOfBirth(day + '-' + month + '-' + year);
        if (dobstatus == false) { setDobStatus(true); }
        else { setDobStatus(false); }
        const date = new Date();
        const currentyear = date.getFullYear();
        formik.values.age = (currentyear - year).toString();
    }
    const CallAllFunctions = async (evt) => {
        if (day.current.value == "" && month.current.value == "" && year.current.value == "") {
            formik.values.dob = "";
            formik.values.age = "";
            setDobText(formik.dirty == true ? !formik.dirty : formik.dirty);
        }
        else {
            getBirthDateFromAge(day.current.value, month.current.value, year.current.value);
        }
    }
    const getBirthDateFromAge = (ageDays, ageMonths, ageYears) => {
        const date = new Date();
        date.setFullYear(date.getFullYear() - ageYears);
        date.setMonth(date.getMonth() - ageMonths);
        date.setDate(date.getDate() - ageDays);
        convert(date);
        setDobText(formik.dirty === false ? !formik.dirty : formik.dirty);
    };
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        formatDate([date.getFullYear(), mnth, day].join("-"));
    }
    const CallValidationCheck = async (evt) => {
        if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57) { evt.preventDefault(); }
    }
    return (
        <>
            {
                show === true ? <Loader /> :
                    <>
                        {
                            reviewstatus === false ?
                                <>
                                    <section className='secWrapper'>
                                        {
                                            centrevisitrequired == true ?
                                                <div className="row mb-4 mt-4 mt-md-0">
                                                    <div className="col-md-12 text-center">
                                                        <span className='blink'><i className='fa fa-hospital-o'> </i> Centre Visit Required</span>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                        <Row>
                                            <Col lg={12} md={12} className='text-left align-items-center justify-content-between'>
                                                <div className='card'>
                                                    <div className='card-body'>
                                                        <Row className='align-items-center'>
                                                            <Col md={10} style={{ fontSize: '18px' }}>
                                                                <b>1. Select Items</b> <br />
                                                                <small className='text-muted'>Please select items</small>
                                                            </Col>
                                                            {
                                                                selecteditpatient &&
                                                                <Col md={2} className='text-right'>
                                                                    <a className={classes.actionBtn} onClick={openpatientCartHandler}><i className='fa fa-edit'></i> Edit</a>
                                                                </Col>
                                                            }
                                                        </Row>

                                                        {selectPatient &&
                                                            <>

                                                                {patientHeader &&
                                                                    <div>
                                                                        <Row className='align-items-center'>
                                                                            <Col xs={12} className="text-right">
                                                                                <Button variant="light" className='fw-bold' onClick={addPatientHandler}>+ Add Patient</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                }
                                                                {addPatient &&
                                                                    <div>
                                                                        <Row className='align-items-center'>
                                                                            <Col xs={7}>
                                                                            </Col>
                                                                            <Col xs={5} className="text-right">
                                                                                <Button variant="light" className='fw-bold' onClick={closePatientHandler}><i className='fa fa-close'></i> Close</Button>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='mt-4'>
                                                                            <Col lg={3} md={4}>
                                                                                <Form>
                                                                                    <Form.Group className="mb-3">
                                                                                        <Form.Label>Title<span className='text-red'>*</span></Form.Label>
                                                                                        <Form.Control as="select" name="title" value={formik.values.title} onChange={formik.handleChange}
                                                                                            onBlur={formik.handleBlur} onChangeCapture={(e) => ChangeGender(e)}>
                                                                                            <option value="Mr.">Mr.</option>
                                                                                            <option value="Mrs.">Mrs.</option>
                                                                                            <option value="Miss.">Miss.</option>
                                                                                            <option value="Baby.">Baby.</option>
                                                                                            <option value="Baba.">Baba.</option>
                                                                                            <option value="Master.">Master.</option>
                                                                                            <option value="Dr.">Dr.</option>
                                                                                            <option value="B/O.">B/O.</option>
                                                                                            <option value="Ms.">Ms.</option>
                                                                                            <option value="C/O.">C/O.</option>
                                                                                            <option value="Mx.">Mx.</option>
                                                                                        </Form.Control>
                                                                                        {formik.touched.title && formik.errors.title ? (
                                                                                            <div className='text-required'>{formik.errors.title}</div>
                                                                                        ) : null}
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Col>
                                                                            <Col lg={3} md={4}>
                                                                                <Form>
                                                                                    <Form.Group className="mb-3">
                                                                                        <Form.Label>Full Name<span className='text-red'>*</span></Form.Label>
                                                                                        <Form.Control type="text" maxLength={150} name="patientname"
                                                                                            onChange={formik.handleChange}
                                                                                            onBlur={formik.handleBlur}
                                                                                            value={formik.values.patientname}
                                                                                        />
                                                                                        {formik.touched.patientname && formik.errors.patientname ? (
                                                                                            <div className='text-required'>{formik.errors.patientname}</div>
                                                                                        ) : null}
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Col>
                                                                            <Col lg={3} md={4}>
                                                                                <Form>
                                                                                    <Form.Group className="mb-3">
                                                                                        <Form.Label>Gender<span className='text-red'>*</span></Form.Label>
                                                                                        {
                                                                                            isLoading ? <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                                                                <>
                                                                                                    <Form.Control as="select" name="gender" value={formik.values.gender} onChange={formik.handleChange}
                                                                                                        onBlur={formik.handleBlur} disabled={disablefields.gender}>
                                                                                                        {gender}
                                                                                                    </Form.Control>
                                                                                                    {formik.touched.gender && formik.errors.gender ? (
                                                                                                        <div className='text-required'>{formik.errors.gender}</div>
                                                                                                    ) : null}
                                                                                                </>
                                                                                        }
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Col>
                                                                            <Col lg={3} md={4}>
                                                                                <Form>
                                                                                    <Form.Group className="mb-3">
                                                                                        <Form.Label>Family<span className='text-red'>*</span></Form.Label>
                                                                                        {
                                                                                            isLoading ? <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                                                                <>
                                                                                                    <Form.Control as="select" name="family" value={formik.values.family} onChange={formik.handleChange}
                                                                                                        onBlur={formik.handleBlur}>
                                                                                                        {family}
                                                                                                    </Form.Control>
                                                                                                    {formik.touched.family && formik.errors.family ? (
                                                                                                        <div className='text-required'>{formik.errors.family}</div>
                                                                                                    ) : null}
                                                                                                </>
                                                                                        }
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Col>
                                                                            <Col lg={3} md={4}>
                                                                                <Form>
                                                                                    <Form.Label>Date of Birth (DD-MM-YYYY)<span className='text-red'>*</span></Form.Label>
                                                                                    <Form.Group className="mb-3 calandersec">
                                                                                        {/* <Form.Label>Date of Birth<span className='text-red'>*</span></Form.Label>
                                                                                            <DatePicker selected={selectedDate} maxDate={startDate} showMonthDropdown={true} showYearDropdown={true} dateFormat="yyyy-MM-dd" className='form-control'
                                                                                                onChange={handleDateChange} ref={calendarRef} />
                                                                                            <span className='calandericon1' onClick={() => { calendarRef.current.setOpen(true) }}><i className="fa fa-calendar" aria-hidden="true"></i></span> */}
                                                                                        <Form.Control type="text" ref={dateobirth} name="dob" value={formik.values.dob} onChange={formik.handleChange}
                                                                                            onBlur={formik.handleBlur} disabled={dobtext} onKeyUp={(e) => CallDob(e)} />
                                                                                        {formik.touched.dob && formik.errors.dob ? (
                                                                                            <div className='text-required'>{formik.errors.dob}</div>
                                                                                        ) : null}
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Col>
                                                                            <Col lg={3} md={3}>
                                                                                <Form>
                                                                                    <Form.Group>
                                                                                        <Form.Label>Year<span className='text-red'>*</span></Form.Label>
                                                                                        <Form.Control type="tel" ref={year} maxLength={3} name="year" disabled={agetext} onKeyPress={(e) => CallValidationCheck(e)} onKeyUp={(e) => CallAllFunctions(e)} />
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Col>
                                                                            <Col lg={3} md={3}>
                                                                                <Form>
                                                                                    <Form.Group>
                                                                                        <Form.Label>Month<span className='text-red'>*</span></Form.Label>
                                                                                        <Form.Control type="tel" ref={month} maxLength={2} name="month" disabled={agetext} onKeyPress={(e) => CallValidationCheck(e)} onKeyUp={(e) => CallAllFunctions(e)} />
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Col>
                                                                            <Col lg={3} md={3}>
                                                                                <Form>
                                                                                    <Form.Group>
                                                                                        <Form.Label>Day<span className='text-red'>*</span></Form.Label>
                                                                                        <Form.Control type="tel" maxLength={2} ref={day} name="day" disabled={agetext} onKeyPress={(e) => CallValidationCheck(e)} onKeyUp={(e) => CallAllFunctions(e)} />
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Col>

                                                                            <Col lg={3} md={4}>
                                                                                <Form>
                                                                                    <Form.Group className="mb-3">
                                                                                        <Form.Label>Email</Form.Label>
                                                                                        <Form.Control type="email" name="emailid" maxLength={150} value={formik.values.emailid} onChange={formik.handleChange}
                                                                                            onBlur={formik.handleBlur} />
                                                                                        {formik.touched.emailid && formik.errors.emailid ? (
                                                                                            <div className='text-required'>{formik.errors.emailid}</div>
                                                                                        ) : null}
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Col>
                                                                            <Col lg={3} md={4}>
                                                                                <Form>
                                                                                    <Form.Group className="mb-3">
                                                                                        <Form.Label>Reffering Doctor</Form.Label>
                                                                                        <Form.Control type="text" name="refdoctor"  maxLength={100} value={formik.values.refdoctor} onChange={formik.handleChange}
                                                                                            onBlur={formik.handleBlur} />
                                                                                        {formik.touched.refdoctor && formik.errors.refdoctor ? (
                                                                                            <div className='text-required'>{formik.errors.refdoctor}</div>
                                                                                        ) : null}
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='align-items-center mt-4'>
                                                                            <Col xs={12} className="text-center">
                                                                                <Button variant="primary" className='fw-bold' type="submit" onClick={formik.handleSubmit}>{patientDetailsID == 0 ? "Save Patient" : "Update Patient"}</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                }
                                                                {
                                                                    (props.UserExistFlag === "1" && props.ShowNew === false) ?
                                                                        <>
                                                                            {
                                                                                cartpatientdescription != [] ?
                                                                                    <>
                                                                                        {
                                                                                            <div className='card-body cartAccordian'>
                                                                                                <Accordion defaultActiveKey="0">
                                                                                                    {
                                                                                                        cartpatientdescription.map((d, index) =>
                                                                                                            <>
                                                                                                                {
                                                                                                                    <Card>
                                                                                                                        <Card.Header>
                                                                                                                            <Accordion.Toggle as={Button} variant="link" id={`acc${index}`} eventKey={`${index}`}>
                                                                                                                                {
                                                                                                                                    allselecteditems.length > 0 ?
                                                                                                                                        <>
                                                                                                                                            {
                                                                                                                                                allselecteditems.map((x) =>
                                                                                                                                                    <>
                                                                                                                                                        {
                                                                                                                                                            x.PatientId === d.PatientId ?
                                                                                                                                                                <>
                                                                                                                                                                    <div className='accordionheaderleft'>{d.PatientName},<span> {d.PatientAge} {d.GenderName} </span></div> <div className='accordionheaderright'><span className='selecteditems'>{x.ItemCount} {x.ItemCount < 2 ? "item" : "items"} selected</span></div>
                                                                                                                                                                </>
                                                                                                                                                                :
                                                                                                                                                                null

                                                                                                                                                        }
                                                                                                                                                    </>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        </>
                                                                                                                                        :
                                                                                                                                        <>{d.PatientName}, <span> {d.PatientAge} {d.GenderName} </span> </>
                                                                                                                                }
                                                                                                                            </Accordion.Toggle>
                                                                                                                        </Card.Header>
                                                                                                                        <Accordion.Collapse eventKey={`${index}`}>
                                                                                                                            <Card.Body>
                                                                                                                                {
                                                                                                                                    cartdescription != [] ?
                                                                                                                                        <>
                                                                                                                                            {
                                                                                                                                                d.EditFlag === "1" ? <div style={{ float: "right" }}><Button variant="light" className='fw-bold' onClick={() => editPatientHandler(d.PatientId)} ><i class="fa fa-edit"></i> Edit Patient</Button></div> : ""
                                                                                                                                            }
                                                                                                                                            {
                                                                                                                                                cartdescription.map((e, indexx) =>
                                                                                                                                                    <>
                                                                                                                                                        {
                                                                                                                                                            (e.Gender.toLowerCase() === d.GenderName.toLowerCase() || e.Gender.toLowerCase() === "all") &&
                                                                                                                                                                (e.Age === d.PatientAge || e.Age.toLowerCase() === "all") && d.PatientId === e.PatientId && e.ItemMasterId != "0"
                                                                                                                                                                ?
                                                                                                                                                                <>
                                                                                                                                                                    <ul>
                                                                                                                                                                        <li>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input className="form-check-input" type="checkbox" name="rdo" id={`rd${d.PatientId}${indexx}`} onClick={() => CallPatientId(d.PatientId)} />
                                                                                                                                                                            </div>
                                                                                                                                                                            {e.ItemMasterName} <span className={classes.salePrice}>₹{e.DiscountPrice}</span><span className={classes.comparePrice}> {e.ActualPrice === e.DiscountPrice ? null : "₹" + e.ActualPrice}</span><span className={classes.offPrice}>{e.OffPercent === "0" ? null : e.OffPercent + "% off"}</span>
                                                                                                                                                                            {
                                                                                                                                                                                e.IsPackage === "1" ? <p>(Gender: {e.Gender} , Ideal for: {e.Age} age group) <i className='fa fa-info-circle' onClick={(event) => ShowPackageItems(e.ItemMasterId)}></i></p>
                                                                                                                                                                                    : <p>(Gender: {e.Gender} , Ideal for: {e.Age} age group) </p>
                                                                                                                                                                            }
                                                                                                                                                                        </li>
                                                                                                                                                                    </ul>
                                                                                                                                                                </> :
                                                                                                                                                                null
                                                                                                                                                        }
                                                                                                                                                    </>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        </> :
                                                                                                                                        null
                                                                                                                                }
                                                                                                                            </Card.Body>
                                                                                                                        </Accordion.Collapse>
                                                                                                                    </Card>
                                                                                                                }
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                </Accordion>
                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </> :
                                                                        <>
                                                                            {
                                                                                cartpatientdescription != [] ?
                                                                                    <>
                                                                                        {
                                                                                            <div className='card-body cartAccordian'>
                                                                                                <Accordion defaultActiveKey="0">
                                                                                                    {
                                                                                                        cartpatientdescription.map((d, index) =>
                                                                                                            <>
                                                                                                                {
                                                                                                                    <Card>
                                                                                                                        <Card.Header>
                                                                                                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`}>
                                                                                                                                {
                                                                                                                                    allselecteditems.length > 0 ?
                                                                                                                                        <>
                                                                                                                                            {
                                                                                                                                                allselecteditems.map((x) =>
                                                                                                                                                    <>
                                                                                                                                                        {
                                                                                                                                                            x.PatientId === d.PatientId ?
                                                                                                                                                                <>
                                                                                                                                                                    <div className='accordionheaderleft'>{d.PatientName},<span> {d.PatientAge} {d.GenderName} </span></div> <div className='accordionheaderright'><span className='selecteditems'>{x.ItemCount} {x.ItemCount < 2 ? "item" : "items"} selected</span></div>
                                                                                                                                                                </>
                                                                                                                                                                :
                                                                                                                                                                null

                                                                                                                                                        }
                                                                                                                                                    </>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        </>
                                                                                                                                        :
                                                                                                                                        <>{d.PatientName}, <span> {d.PatientAge} {d.GenderName} </span> </>
                                                                                                                                }
                                                                                                                            </Accordion.Toggle>
                                                                                                                        </Card.Header>
                                                                                                                        <Accordion.Collapse eventKey={`${index}`}>
                                                                                                                            <Card.Body>
                                                                                                                                {
                                                                                                                                    cartdescription != [] ?
                                                                                                                                        <>
                                                                                                                                            {
                                                                                                                                                cartdescription.map((e, indexx) =>
                                                                                                                                                    <>
                                                                                                                                                        {
                                                                                                                                                            (e.Gender.toLowerCase() === d.GenderName.toLowerCase() || e.Gender.toLowerCase() === "all") &&
                                                                                                                                                                (e.Age === d.PatientAge || e.Age.toLowerCase() === "all")
                                                                                                                                                                ?
                                                                                                                                                                <>
                                                                                                                                                                    <ul>
                                                                                                                                                                        <li>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input className="form-check-input" type="checkbox" name="rdo" id={`rd${d.PatientId}${indexx}`} onClick={() => CallPatientId(d.PatientId)} />
                                                                                                                                                                            </div>
                                                                                                                                                                            {e.ItemMasterName} <span className="salePrice">₹{e.DiscountPrice}</span><span className="comparePrice"> {e.ActualPrice === e.DiscountPrice ? null : "₹" + e.ActualPrice}</span><span className="offPrice">{e.OffPercent === "0" ? null : e.OffPercent + "% off"}</span>
                                                                                                                                                                            {
                                                                                                                                                                                e.IsPackage === "1" ? <p>(Gender: {e.Gender} , Ideal for: {e.Age} age group) <i className='fa fa-info-circle' onClick={(event) => ShowPackageItems(e.ItemMasterId)}></i></p>
                                                                                                                                                                                    : <p>(Gender: {e.Gender} , Ideal for: {e.Age} age group) </p>
                                                                                                                                                                            }
                                                                                                                                                                        </li>
                                                                                                                                                                    </ul>
                                                                                                                                                                </> :
                                                                                                                                                                null
                                                                                                                                                        }
                                                                                                                                                    </>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        </> :
                                                                                                                                        null
                                                                                                                                }
                                                                                                                            </Card.Body>
                                                                                                                        </Accordion.Collapse>
                                                                                                                    </Card>
                                                                                                                }
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                </Accordion>
                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </>
                                                                }


                                                                <div className='contWrap'>
                                                                    <div className='row align-items-center'>
                                                                        <div className='col-md-6'>
                                                                            <div>{cartprecautions === "" ? "No specific preparation is required" : cartprecautions}</div>
                                                                            <div>{cartprerequisite === "" ? "No prerequisite is required" : cartprerequisite}</div>
                                                                        </div>
                                                                        <div className='col-md-6 text-right mt-4 mt-md-0'>
                                                                            <button className='btn btn-primary' onClick={selectAddressHandler}>
                                                                                Continue
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }

                                                    </div>

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} className='text-left align-items-center justify-content-between'>
                                                <Card className={`${classes.cardBorderd} ${classes.mt20}`}>
                                                    <Card.Header className={classes.bgTransparent}>
                                                        <Row className='align-items-center'>
                                                            <Col md={10}>
                                                                <b>2. Select Patient Address</b> <br />
                                                                <small className='text-muted'>Please select appointment type</small>
                                                            </Col>
                                                            {
                                                                selecteditaddress &&
                                                                <Col md={2} className='text-right'>
                                                                    <a className={classes.actionBtn} onClick={openaddressCartHandler}><i className='fa fa-edit'></i> Edit</a>
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </Card.Header>
                                                    {selectAddress &&
                                                        <>
                                                            <Card.Body>
                                                                {addressHeader &&
                                                                    <div>
                                                                        <Row className='align-items-center'>
                                                                            <Col xs={12} className="text-right">
                                                                                <Button variant="light" className='fw-bold' onClick={addAddressHandler}>+ Add New Address</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                }
                                                                {addAddress &&
                                                                    <div>
                                                                        <Row className='align-items-center'>
                                                                            <Col xs={12} className="text-right">
                                                                                <Button variant="light" className='fw-bold' onClick={closeAddressHandler}><i className='fa fa-close'></i> Close</Button>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='mt-2'>
                                                                            <Col lg={3} md={4}>
                                                                                <Form>
                                                                                    <Form.Group className="mb-3">
                                                                                        <Form.Label>Pincode</Form.Label>
                                                                                        <Form.Control type="text" name="pincode" value={addressformik.values.pincode} onChange={addressformik.handleChange}
                                                                                            onBlur={addressformik.handleBlur} onKeyUp={(e) => CallStateDistrict(e)} />
                                                                                        {addressformik.touched.pincode && addressformik.errors.pincode ? (
                                                                                            <div className='text-required'>{addressformik.errors.pincode}</div>
                                                                                        ) : null}
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Col>
                                                                            <Col lg={3} md={4}>
                                                                                <Form>
                                                                                    <Form.Group className="mb-3">
                                                                                        <Form.Label>State<span className='text-red'>*</span></Form.Label>
                                                                                        <Form.Control as="select" name="locStateId" value={addressformik.values.locStateId} onChange={addressformik.handleChange} onBlur={addressformik.handleBlur} onChangeCapture={StateChangeHandler}>
                                                                                            <option value="">---Select---</option>
                                                                                            {statesList}
                                                                                        </Form.Control>
                                                                                        {addressformik.touched.locStateId && addressformik.errors.locStateId ? (<div className='text-required'>{addressformik.errors.locStateId}</div>) : null}
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Col>
                                                                            <Col lg={3} md={4}>
                                                                                <Form>
                                                                                    <Form.Group className="mb-3">
                                                                                        <Form.Label >District<span className='text-red'>*</span></Form.Label>
                                                                                        <Form.Control as="select" name="locDistrictId" value={addressformik.values.locDistrictId} onChange={addressformik.handleChange} onBlur={addressformik.handleBlur}>
                                                                                            <option value="">---Select---</option>
                                                                                            {districtsList}
                                                                                        </Form.Control>
                                                                                        {addressformik.touched.locDistrictId && addressformik.errors.locDistrictId ? (<div className='text-required'>{addressformik.errors.locDistrictId}</div>) : null}
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Col>
                                                                            {/* <Col lg={3} md={4}>
                                                                                <Form>
                                                                                    <Form.Group className="mb-3">
                                                                                        <Form.Label>House No.</Form.Label>
                                                                                        <Form.Control type="text" name="houseno" value={addressformik.values.houseno} onChange={addressformik.handleChange}
                                                                                            onBlur={addressformik.handleBlur} />
                                                                                        {addressformik.touched.houseno && addressformik.errors.houseno ? (
                                                                                            <div className='text-required'>{addressformik.errors.houseno}</div>
                                                                                        ) : null}
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Col> */}
                                                                            <Col lg={12} md={12}>
                                                                                <Form>
                                                                                    <Form.Group className="mb-3">
                                                                                        <Form.Label>Address</Form.Label>
                                                                                        <Form.Control as="textarea" rows={3} name="address" onChange={addressformik.handleChange}
                                                                                            onBlur={addressformik.handleBlur} value={addressformik.values.address} />
                                                                                        {addressformik.touched.address && addressformik.errors.address ? (
                                                                                            <div className='text-required'>{addressformik.errors.address}</div>
                                                                                        ) : null}
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='align-items-center mt-4'>
                                                                            <Col xs={12} className="text-center">
                                                                                <Button variant="primary" className='fw-bold' type="submit" onClick={addressformik.handleSubmit}>Save Address</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                }
                                                                {homeCollection &&
                                                                    carthomeaddress != [] ?
                                                                    <>
                                                                        {
                                                                            <Row className='mt-4'>
                                                                                <Col lg={12}>
                                                                                    <Carousel responsive={responsive}>
                                                                                        {
                                                                                            carthomeaddress.map((d) =>
                                                                                                <>
                                                                                                    <div className={`${classes.collectionCardHome}`} id={`home${d.Id}`} onClick={() => AddHomeCollectionClass(d.Id)}>
                                                                                                        {
                                                                                                            (d.Pincode === "" || d.Pincode === "0") && d.HouseNo === "" && d.Address === "" ?
                                                                                                                <>
                                                                                                                    <p>{d.District},{d.State}</p>
                                                                                                                </> :
                                                                                                                (d.Pincode === "" || d.Pincode === "0") && d.HouseNo === "" ?
                                                                                                                    <>
                                                                                                                        <p>{d.Address} <br />{d.District},{d.State}</p>
                                                                                                                    </> :
                                                                                                                    (d.Pincode === "" || d.Pincode === "0") && d.Address === "" ?
                                                                                                                        <>
                                                                                                                            <p>{d.HouseNo}, <br />{d.District},{d.State}</p>
                                                                                                                        </> :
                                                                                                                        d.Address === "" && d.HouseNo === "" ?
                                                                                                                            <>
                                                                                                                                <p>{d.District},{d.State} &ndash; {d.Pincode}</p>
                                                                                                                            </> :
                                                                                                                            (d.Pincode === "" || d.Pincode === "0") ?
                                                                                                                                <>
                                                                                                                                    <p>{d.HouseNo}/{d.Address} <br />{d.District},{d.State}</p>
                                                                                                                                </> :
                                                                                                                                d.Address === "" ?
                                                                                                                                    <>
                                                                                                                                        <p>{d.HouseNo} <br />{d.District},{d.State} &ndash; {d.Pincode}</p>
                                                                                                                                    </> :
                                                                                                                                    d.HouseNo === "" ?
                                                                                                                                        <>
                                                                                                                                            <p>{d.Address} <br />{d.District},{d.State} &ndash; {d.Pincode}</p>
                                                                                                                                        </> :
                                                                                                                                        <>
                                                                                                                                            <p>{d.HouseNo}/{d.Address} <br />{d.District},{d.State} &ndash; {d.Pincode}</p>
                                                                                                                                        </>
                                                                                                        }
                                                                                                        {
                                                                                                            d.DeleteFlag === "0" ?
                                                                                                                <>
                                                                                                                    <div className='row align-items-center'>
                                                                                                                        <div className='col-auto'>
                                                                                                                            <a className={classes.actionBtn} onClick={() => RemoveCartAddress(d.Id)}><i className='fa fa-trash'></i> Remove</a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </> :
                                                                                                                null
                                                                                                        }
                                                                                                        <div className={classes.selectFlag}></div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    </Carousel>
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                    </> :
                                                                    null
                                                                }
                                                                {visitCollection &&
                                                                    cartcentreaddress != [] ?
                                                                    <>
                                                                        {
                                                                            <Row className='mt-4'>
                                                                                <Col lg={12}>
                                                                                    <Carousel responsive={responsive}>
                                                                                        {
                                                                                            cartcentreaddress.map((d) => <>
                                                                                                <div className={`${classes.collectionCard}`} id={`centre${d.Id}`} onClick={() => AddCentreCollectionClass(d.Id)}>
                                                                                                    <h2>{d.CentreName}</h2>
                                                                                                    {d.Pincode === "" || d.Pincode === "0" ?
                                                                                                        <>
                                                                                                            <p>{d.Address1}, {d.StateName}</p>
                                                                                                        </>
                                                                                                        :
                                                                                                        <p>{d.Address1}, {d.StateName} &ndash; {d.PinCode}</p>
                                                                                                    }
                                                                                                    <div className={classes.selectFlag}></div>
                                                                                                </div>
                                                                                            </>
                                                                                            )
                                                                                        }
                                                                                    </Carousel>
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                    </> :
                                                                    null
                                                                }
                                                            </Card.Body>
                                                            <Card.Footer>
                                                                <Row className='align-items-center'>
                                                                    <Col xs={7} className='text-muted text-left'>
                                                                    </Col>
                                                                    <Col xs={5} className="text-right">
                                                                        <Button variant="primary" onClick={selectTimeHandler}>Continue</Button>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Footer>
                                                        </>
                                                    }
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} className='text-left align-items-center justify-content-between'>
                                                <Card className={`${classes.cardBorderd} ${classes.mt20} ${classes.mb20}`}>
                                                    <Card.Header className={classes.bgTransparent}>
                                                        <Row className='align-items-center'>
                                                            <Col md={10}>
                                                                <b>3. Select Date & Time Slot</b> <br />
                                                                <small className='text-muted'>Please select Select Date & Time Slot</small>
                                                            </Col>
                                                            {
                                                                selectedittime &&
                                                                <Col md={2} className='text-right'>
                                                                    <a className={classes.actionBtn}><i className='fa fa-edit'></i> Edit</a>
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </Card.Header>
                                                    {selectTime &&
                                                        <>
                                                            <Card.Body>
                                                                <Row className='mt-4'>
                                                                    <Col md={6}>
                                                                        <Form>
                                                                            <Form.Group className="mb-3 calandersec">
                                                                                <Form.Label>Select date<span className='text-red'>*</span></Form.Label>
                                                                                <DatePicker selected={selectedslotDate} minDate={startDate} showMonthDropdown={true} showYearDropdown={true} dateFormat="yyyy-MM-dd" className='form-control'
                                                                                    onChange={handleSlotDateChange} ref={calendarRef2}
                                                                                />
                                                                                <span className='calandericon1' onClick={() => { calendarRef2.current.setOpen(true) }}><i className="fa fa-calendar" aria-hidden="true"></i></span>
                                                                            </Form.Group>
                                                                        </Form>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Form>
                                                                            <Form.Group className="mb-3">
                                                                                <Form.Label>Select time<span className='text-red'>*</span></Form.Label>
                                                                                <Form.Control as="select" name="time" value={datetimeformik.values.time} ref={dtime} onChange={datetimeformik.handleChange} onBlur={datetimeformik.handleBlur}
                                                                                    onKeyPress={(e) => DisableKeyPress(e)} onChangeCapture={(e) => RequestTime(e)}>
                                                                                    <option value="">---Select---</option>
                                                                                    {slottiming}
                                                                                </Form.Control>
                                                                                {datetimeformik.touched.time && datetimeformik.errors.time ? (<div className='text-required'>{datetimeformik.errors.time}</div>) : null}
                                                                            </Form.Group>
                                                                        </Form>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                            <Card.Footer>
                                                                <Row className='align-items-center'>
                                                                    <Col md={7} className='text-muted text-left'>
                                                                        Request for: <br />
                                                                        <span className={classes.txtDate}>{finaldatetime}</span>
                                                                    </Col>
                                                                    <Col md={5} className="text-right mt-4 mt-md-0">
                                                                        <Button variant="primary" onClick={datetimeformik.handleSubmit}>Review Booking</Button>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Footer>
                                                        </>
                                                    }
                                                </Card>
                                            </Col>
                                        </Row>
                                    </section>
                                </>
                                :
                                null
                        }
                        <Row>
                            {
                                reviewstatus === true ?
                                    <>
                                        <Col lg={8} md={7}>
                                            <Order PatientNames={allpatientname} ItemDetails={allitemdetails} Address={carthomeaddress} AddressId={addressdetailid} DateTime={finaldatetime} />
                                        </Col>
                                        <Col lg={4} md={5}>
                                            <CartSummary PatientNames={allpatientname} ItemDetails={allitemdetails} reviewstatus={(reviewstatus) => setReviewStatus(reviewstatus)} statevalues={(statevalues) => setStateValues(statevalues)} />
                                            {
                                                props.Status === "1" ? null :
                                                    <>
                                                        {
                                                            props.IsConfirmed === "1" ? null :
                                                                <>
                                                                    <Row>
                                                                        <Col lg={12} md={12} className="text-center mb-5 mt-3">
                                                                            <div>
                                                                                <Col md={12} className='mt-4 p-0'>
                                                                                    <Card>
                                                                                        <Card.Body>
                                                                                            <Form>
                                                                                                <Form.Group className="mb-3">
                                                                                                    {/* <Form.Control placeholder='Enter phlebo notes' as="textarea" ref={refPhleboNotes} rows={3} name="notes" defaultValue={cartnotes} onChange={(e) => SaveNotes(e.target.value)} /> */}
                                                                                                    <Form.Label><b>Phlebo notes </b></Form.Label>
                                                                                                    <textarea className="form-control" id="phleboNotes" name="notes" rows="3" onChange={(e) => SaveNotes(e.target.value)} defaultValue={cartnotes} ></textarea>
                                                                                                </Form.Group>
                                                                                            </Form>
                                                                                        </Card.Body>
                                                                                    </Card>
                                                                                </Col>
                                                                                {
                                                                                    isButtonLoading ?
                                                                                        <LoadingButton variantName='dark' cssName="" /> :
                                                                                        <>
                                                                                            <Button variant="dark" className='ml-2' type='submit' onClick={(e) => confirmBookingHandler(statevalues, "2")}>Confirm</Button>
                                                                                            <Col md={12} className='mt-4 p-0'>
                                                                                                <Card>
                                                                                                    <Card.Body>
                                                                                                        <Form>
                                                                                                            <Form.Group className="mb-3">
                                                                                                                <Form.Control placeholder='Enter cancellation reason' as="textarea" rows={3} name="cancelreason" ref={refcancelreason} />
                                                                                                            </Form.Group>
                                                                                                        </Form>
                                                                                                    </Card.Body>
                                                                                                </Card>
                                                                                            </Col>
                                                                                            <Button variant="primary" type='submit' onClick={(e) => cancelBookingHandler()}>Cancel</Button>
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                        }
                                                    </>
                                            }
                                            <OrderSummary PatientNames={allpatientname} ItemDetails={allitemdetails} />
                                        </Col>
                                    </> :
                                    null
                            }
                        </Row>
                    </>
            }
            {
                cityModal &&
                <Modal show={cityModal} onHide={openCitiesModal} size='l' centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Districts</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <DataTables
                                    keyField='DistrictId'
                                    tableData={viewcities}
                                    columns={popupcityColumns}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            }
            {
                packageitemModal &&
                <Modal show={packageitemModal} onHide={openPackageItemModal} size='l' centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Tests</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <DataTables
                                    keyField='InvestigationMasterId'
                                    tableData={filteredpackageitems}
                                    columns={popuppackageitemsColumns}
                                    pagination={paginationFactory(paginationOptions)}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            }
            {
                validationModal &&
                <Modal show={validationModal} onHide={openValidationItemModal} size='l' centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Duplicate</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <p>Below are the items which is included in package, So you need to remove items or the same package to continue :</p>
                                <p><b>{allvaliditems}</b></p>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}

export default Cart;

