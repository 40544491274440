import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import '../../src/index.scss';
import '../../src/pages/css/layout.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { configureFakeBackend, authHeader, handleResponse } from "../services/fack.backend";
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import * as serviceWorker from '../serviceWorker';

// ** Import custom components for redux **
import { Provider } from 'react-redux';
import store from '../store';

import { Auth0Provider } from '@auth0/auth0-react'
//*******Component *//
import Dashboard from '../pages/user/dashboard';
import Assignedcalls from '../pages/user/outbound/assignedcalls';
import Unassignedcalls from '../pages/user/outbound/unassignedcalls';
import AddAssignCalls from '../pages/user/outbound/addassigncalls';
import TestStatus from '../pages/admin/LabReport/TestStatus';
import ReportOverview from '../pages/admin/LabReport/ReportOverview';
import Notifications from '../pages/Inbound/Notifications';
import AddInboundcalls from '../pages/Inbound/addInboundcalls';
import InCompletedCalls from '../pages/Inbound/InCompletedCalls';
import AssignRoute from '../pages/Phlebo/AssignRoute';
import RouteInfo from '../pages/Phlebo/RouteInfo';
import InBoundAppointments from '../pages/Inbound/appointments';
import OutBoundAppointments from '../pages/admin/outbound/appointments';
import ShowAssignRoute from '../pages/Phlebo/ShowAssignedRoute';
import Appointments from '../pages/booking/Appointments';
import DirectAppointments  from '../pages/booking/DirectAppointments';
import SelfAnsweredCalls from '../pages/reports/SelfAnsweredCalls';
import SelfCallHistory  from '../pages/reports/SelfCallHistory';
import AssignPhlebo from '../pages/Phlebo/AssignPhlebo';
import Calldata from '../pages/searchdb/Calldata';
import ViewCallDataDetail from '../pages/searchdb/ViewCallDataDetail';
const Usernav = () => {
    return (

        <>
            {/* dashboard menu */}
            <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                return (<Redirect to={`${process.env.PUBLIC_URL}/user/dashboard`} />)
            }} />
            <Route exact path={`${process.env.PUBLIC_URL}/user/dashboard`} caseSensitive={false} component={Dashboard} />
            <Route exact path={`${process.env.PUBLIC_URL}/user/outbound/assignedcalls`} caseSensitive={false} component={Assignedcalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/user/outbound/unassignedcalls`} caseSensitive={false} component={Unassignedcalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/user/outbound/addassigncalls`} caseSensitive={false} component={AddAssignCalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/labreport/test-status`} caseSensitive={false} component={TestStatus} />
            <Route exact path={`${process.env.PUBLIC_URL}/lab-report/overview`} caseSensitive={false} component={ReportOverview} />
            <Route exact path={`${process.env.PUBLIC_URL}/inbound/notifications`} caseSensitive={false} component={Notifications} />
            <Route exact path={`${process.env.PUBLIC_URL}/inbound/addInboundcalls`} caseSensitive={false} component={AddInboundcalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/inbound/incompletedcalls`} caseSensitive={false} component={InCompletedCalls} />
            <Route exact path={`${process.env.PUBLIC_URL}/phlebo/assignroute`} caseSensitive={false} component={AssignRoute} />
            <Route exact path={`${process.env.PUBLIC_URL}/phlebo/routeinfo`} caseSensitive={false} component={RouteInfo} />
            <Route exact path={`${process.env.PUBLIC_URL}/inbound/appointments`} caseSensitive={false} component={InBoundAppointments} />
            <Route exact path={`${process.env.PUBLIC_URL}/admin/outbound/appointments`} caseSensitive={false} component={OutBoundAppointments} />
            <Route exact path={`${process.env.PUBLIC_URL}/phlebo/showassignedroute`} caseSensitive={false} component={ShowAssignRoute} />
            <Route exact path={`${process.env.PUBLIC_URL}/booking/appointments`} caseSensitive={false} component={Appointments} />
            <Route exact path={`${process.env.PUBLIC_URL}/booking/directAppointments`} caseSensitive={false} component={DirectAppointments} /> 
            <Route exact path={`${process.env.PUBLIC_URL}/reports/SelfAnsweredCalls`} caseSensitive={false} component={SelfAnsweredCalls} />  
            <Route exact path={`${process.env.PUBLIC_URL}/reports/SelfCallHistory`} caseSensitive={false} component={SelfCallHistory} /> 
            <Route exact path={`${process.env.PUBLIC_URL}/phlebo/assignphlebo`} caseSensitive={false} component={AssignPhlebo} /> 
            <Route exact path={`${process.env.PUBLIC_URL}/searchdb/calldata`} caseSensitive={false} component={Calldata} />  
            <Route exact path={`${process.env.PUBLIC_URL}/searchdb/viewcalldatadetail`} caseSensitive={false} component={ViewCallDataDetail} />  
        </>
    )



}

export default Usernav;