import React, { useState, useEffect,useRef } from 'react';
import SweetAlert from 'sweetalert2';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Breadcrumb from '../../commonComponent/common/breadcrumb';
import bookingImage from '../../../assets/images/booking.jpg';
import { useSelector, useDispatch } from 'react-redux'
import { useFormik, ErrorMessage } from 'formik';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import axios from 'axios';
import ConnectionInstance from '../../apisettings/ConnectionInstance';
import { useHistory } from 'react-router';
import DatePicker from "react-datepicker";
import LoadingButton from '../../commonComponent/Usecomponent/loadingbutton';
import { Spinner } from 'react-bootstrap';
import DataTable from '../../commonComponent/DataTable/DataTable';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolTip from '../../commonComponent/ToolTip';
import SampleQuotation from '../../commonComponent/common/SampleQuotation';
import NoRecordFounds from '../../commonComponent/DataTable/NoRecordFounds';
import EditToolTip from '../../UI/ToolTips/EditToolTip';

let departmentValid = false;
let DefaultdateField = '';
let GlBCallingId = localStorage.CallingId == undefined ? "" : localStorage.CallingId;
let UserAccLogin = localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser;
var glbRecords = [];
const AddAssignCalls = () => {
    const [CustomernameL, SetCustomernameL] = useState("");
    const [customernameUpdate, setcustomernameUpdate] = useState(false);
    const [CallingId, setCallingId] = useState(localStorage.CallingId == undefined ? "" : localStorage.CallingId);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [UserTypeId, setUserTypeId] = useState(localStorage.UserType == undefined ? "" : localStorage.UserType);
    const navigate = useHistory();
    const calendarRef = React.createRef();
    const timerRef = React.createRef();
    const [Sldate, setSldate] = useState("");
    const [Sltime, setSltime] = useState("");
    const [startDate, setstartDate] = useState(new Date());
    const [startDateTime, setstartDateTime] = useState(null);
    const [CallstatusDLL, SetCallstatusDLL] = useState(0);
    const [LeadSourceDDL, SetLeadSourceDDL] = useState(0);
    const [LeadServiceDDL, SetLeadServiceDDL] = useState(0);
    const [TeamMData, SetTeamMData] = useState(0);
    const [CallDepartmentDLL, SetCallDepartmentDLL] = useState(0);
    const [CallSummaryresult, setCallSummaryresult] = useState(null);
    const [Dbresult, setDbresult] = useState(null);
    const [ErrorPopUp, setErrorPopUp] = useState(0);
    const [ModalBodyHtml, SetModalBodyHtml] = useState("");
    const [errorModal, setErrorModal] = useState(false);
    const [IsLoadButton, setIsLoadButton] = useState(false);
    const [inputCustomerMob, setinputCustomerMob] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [popupTableData, setPopupTableData] = useState([]);
    const [testsModal, setTestsModal] = useState();
    const [visitsTableData, setVisitsTableData] = useState([]);
    const [patientName, setPatientName] = useState();
    const [patientAge, setPatientAge] = useState();
    const [visitNo, setVisitNo] = useState();
    const [crntPageNO, setCrntPageNO] = useState(0);
    // const [CrntPatientPageNO, setCrntPatientPageNO] = useState(0);
    const [displayTestsList, setDisplayTestsList] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isReportAvailable, setReportAvailable] = useState(false);
    const [reportUrl, setReportUrl] = useState();
    const [tableAppointmentData, settableAppointmentData] = useState([]);
    const [openmodal, setOpenModal] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [defaultDate, setDefaultDate] = useState("");
    const [slottiming, setSlotTiming] = useState([]);
    const [appid, setAppId] = useState("0");
    const [Users, setUsers] = useState([]);
    const [SelectDS, setSelectDS] = useState(null);
    const [Location, SetLocation] = useState([]);

    let dtime = useRef();
    let appointmentDateId = useRef();
    var finaltime = "";
    let ToTime = useRef();
    let Locid = useRef();
    let frmtime = useRef();

    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        GetSlotTiming(defaultValue); 
        asyncFunBindAddAssignedCallAdminId();
        asyncFunBindCallSummaryDetailById();
    }, []);

    useEffect(() => {
        if (Dbresult !== null) {
            setCrntPageNO(0);
            BindGrid(1, 10);
            SearchPatient();
        }
    }, [Dbresult]);

    const [visible, setVisible] = useState(false);
    const [Complainvisible, setComplainvisible] = useState(false);
    const [modify, setModify] = useState(false);
    const [sms, setSms] = useState(false);
    const [booking, setBooking] = useState(false);
    const [PBXfrom, setPBXfrom] = useState(localStorage.ExtenNo == undefined ? "" : localStorage.ExtenNo);
    const [PBXto, setPBXto] = useState("0");
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
    }
    const openModal = () => { setTestsModal(!testsModal); };
    const updateHandler = () => {
        setModal(!modal);
        SweetAlert.fire({ title: "Success!", text: "Data has been updated!", icon: "success" });
    }
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const paginationOptions = {
        sizePerPage: 10, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const popupActionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="Check Tests" placement="top" onClick={() => GetTestsList(rowData.VisitingId, rowData.VisitingCode, "1")} linkId="TestList" faName="fa fa-eye mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const testsActionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            {
                rowData.InvestigationStatus === "3" ?
                    <div>
                        <ToolTip title="Download Report" placement="top" onClick={() => openReport(rowData.ReportUrl)} linkId="ReportLink" faName="fa fa-download mr-2"></ToolTip>
                    </div>
                    : <div></div>
            }

        </React.Fragment>
    }
    const popupColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'OrderDate', text: 'Order Date', editable: false },
        { dataField: 'VisitingCode', text: 'Visit Id', editable: false },
        { dataField: 'actions', text: 'Actions', formatter: popupActionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '15%' }; } }
    ];
    const testsColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'InvestigationName', text: 'Test Name', editable: false },
        { dataField: 'PackageName', text: 'Package Name', editable: false },
        { dataField: 'SampleCollectionDate', text: 'Collection Date', editable: false },
        { dataField: 'SampleStatus', text: 'Status', editable: false },
        { dataField: 'actions', text: 'Actions', editable: false, formatter: testsActionFormatter, headerStyle: (colum, colIndex) => { return { width: '15%' }; } }
    ];
    const openReport = async (fileUrl) => { window.open(fileUrl, "_blank"); }
    const modifyHandler = () => {
        setModal(!modal);
        setModify(true);
        setSms(false);
        setBooking(false);
    }

    const smsHandler = () => {
        setModal(!modal);
        setModify(false);
        setSms(true);
        setBooking(false);
    }

    const bookHandler = () => {
        setModal(!modal);
        setModify(false);
        setcustomernameUpdate(false);
        setSms(false);
        setBooking(true);
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="Check Visits" placement="top" onClick={() => GetVisits(rowData)} linkId="View" faName="fa fa-eye mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }


    const paginationOptionNew = {
        sizePerPage: 10, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };

    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'PatientName', text: 'Name', editable: false },
        { dataField: 'Age', text: 'Age', editable: false },
        { dataField: 'Gender', text: 'Gender', editable: false },
        { dataField: 'MobileNo', text: 'Mobile', editable: false },
        { dataField: 'VisitType', text: 'VisitType', editable: false },
        { dataField: 'Centre', text: 'Centre', editable: false },
        { dataField: 'action', text: 'Action', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '11%' }; } }
    ];


   //Alok work Start
const RequestTime = (e) => {
    const value = e.target.options[e.target.selectedIndex].text;
    finaltime = value;
    if (finaltime === "---Select---") { finaltime = ""; }
  }
  const handleSlotDateChange = () => {
    GetSlotTiming(appointmentDateId.current.value); 
  }
  const GetSlotTiming = async (date) => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) { dd = '0' + dd; }
    if (mm < 10) { mm = '0' + mm; }
    var todaydate = yyyy + '-' + mm + '-' + dd;
    if (date === "") { SweetAlert.fire({ title: "Error", text: "Please select date", icon: "error" }); setSlotTiming([]); return false; }
    if (todaydate > date) { SweetAlert.fire({ title: "Error", text: "You cannot select past date slots", icon: "error" }); setSlotTiming([]); return false; }
    setIsLoading(true);
    let url = ConnectionInstance + 'Cart/getslottiming/' + '1' + '/' + LoginId + '/' + date
    await axios.get(url).then((response) => {
      const data = response.data;
      setIsLoading(false);
      if (data.Success) {
        setSlotTiming(data.Data.map((d) => <option key={d.SlotTimingId} value={d.SlotTimingId}>{d.FromTime} - {d.ToTime}</option>));
      }
      else { setSlotTiming=[] }
    }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
  }
  const openRequiredFieldModal = () => { setOpenModal(!openmodal); }

  const updateAppDateTime = async () => {
    if (appointmentDateId.current.value === "") { SweetAlert.fire("Please enter date"); return false; }
    else if (dtime.current.options[dtime.current.selectedIndex].value== "") { SweetAlert.fire("Please select time"); return false; }
    else
    {
      // var arr1 = [];
      // var arr2 = [];
      // var arr3 = [];
      // arr1 = TableData.filter((d) => d.AppointmentId === appid);
      // arr2 = TableData.filter((d) => d.AppointmentId !== appid);
      // arr1[0].AppointmentDate = selectedslotDateNew;
      // arr1[0].AppointmentTime = dtime.current.options[dtime.current.selectedIndex].text;
      // arr3 = [...arr1, ...arr2];
      // setTableData(arr3);
      setOpenModal(false);
      const requestOptions = {
        LoginId: localStorage.LoggedInUser,
        AppointmentId:appid,
        AppointmentDate: appointmentDateId.current.value,
        AppointmentTime: dtime.current.options[dtime.current.selectedIndex].value,
      };
      setIsLoading(true);
      let url = ConnectionInstance + 'Cart/UpdateAppointmentDate'
      await axios.post(url, requestOptions).then((response) => {
        const data = response.data;
        setIsLoading(false);
        if (data.Success) {
          SweetAlert.fire({
            text: "Successfully Appointment date & time updated.",
            icon: 'success',
            confirmButtonText: 'Ok',
            reverseButtons: true,
          }).then((result) => 
          {
            if (result.isConfirmed) {
                SearchPatient();
              setSelectDS(null);
              setTableData([]);             
              frmtime.current.value = "";
              ToTime.current.value = "";
              Locid.current.value = "0";
            //  setUsers([]);
             // SetLocation([]);
              window.location.reload(true);        
            }
        })
      }
        else {
          APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
        }
      }).catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          ErrorFunction(error.response.status);
        }
      });
  }

  }

const actionnewFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      {rowData.TotalPatients + ' | ' + '( ' + rowData.Age + ' )'}
    </React.Fragment>
  }
  const actionsFormatter = (cellData, rowData, rowIndex, extraData) => {
    // return <React.Fragment>
    //   {rowData.AppointmentDate != "" && rowData.AppointmentTime != "" ? rowData.AppointmentDate + ' | ' + rowData.AppointmentTime : rowData.AppointmentDate}
    // </React.Fragment>
    return <React.Fragment>
      <div>
        {rowData.AppointmentDate != "" && rowData.AppointmentTime != "" ? rowData.AppointmentDate + ' | ' + rowData.AppointmentTime : rowData.AppointmentDate}
        &nbsp; <EditToolTip title="Edit" onClick={() => editHandler(rowData)} />
      </div>
    </React.Fragment>
  }

  const editHandler = (rowData) => {
    setOpenModal(true);
    setAppId(rowData.AppointmentId);
  }
    const paginationOptionAppointment = {
        sizePerPage: 5, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };

    const columnsAppointment = [
        {
          dataField: '#',
          text: '#',
          formatter: idFormatter,
          headerStyle: (colum, colIndex) => {
            return { width: '9%' };
          }
        },
        {
          dataField: 'CustomerName',
          text: 'Customer Name'
        },
        {
          dataField: 'CustomerMobile',
          text: 'Customer Mobile'
        },
        {
          dataField: 'SourceCategory',
          text: 'Source Category'
        },
        {
          dataField: 'AppointmentTime',
          text: 'Appointment Date',
          formatter: actionsFormatter
        },
        {
          dataField: 'TotalPatients',
          text: 'Total Patients | Age',
          formatter: actionnewFormatter
        },
        {
          dataField: 'StatusName',
          text: 'Status',
        }
      ];

      const SearchPatient = async () => {
        try {
          let MobileNo = Dbresult.CustomerMobile == null ? "0" : Dbresult.CustomerMobile ;
         // alert(MobileNo);
            let url = ConnectionInstance + 'cart/getallappointmentsCrm/' + MobileNo
            await axios.get(url).then((response) => {
                const data = response.data.Data;
                if (response.data.Success === true) {
                    if (data !== null) {
                        var isexist = data.filter((d) => d.MobileNo === MobileNo);
                        if (isexist.length === 0) { settableAppointmentData([]); }
                        else { settableAppointmentData(data.filter((d) => d.MobileNo === MobileNo)); }
                    }
                    else { settableAppointmentData([]); }
                }
                else { settableAppointmentData([]); }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Duplicate", text: "User already exist", icon: "error" }) }
        else if (ErrorCode === "0") { SweetAlert.fire({ title: "Location not found", text: "Please select location", icon: "error" }) }
        else if (ErrorCode === "-1997") { SweetAlert.fire({ title: "Empty", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-1998") { SweetAlert.fire({ title: "Some value is missing", text: "Check server and db logs", icon: "error" }) }
        else if (ErrorCode === "-1999") { SweetAlert.fire({ title: "Runtime Exception", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
      }
      const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
      }
//Alok End


    // const dateArr=defaultValue1.split('-');

    // if(dateArr!=null && dateArr.length==3)
    // {
    //    const fromdate = dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
    //     console.log(dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0]);
    //     DefaultdateField=dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
    // }


    const [value, setValue] = useState('0');

    const changeHandler = (e) => {
        const s1 = e.target.value;

        if (e.target.value == '0') {


            formik.setFieldValue('CallStatus', '');
        }
        else {
            formik.setFieldValue('CallStatus', e.target.value);
            setValue(e.target.value);
            //alert(e.target.value);

        }
        if (s1 == "1") {
            setVisible(true);
            setComplainvisible(false);
            DefaultdateField = "";
            startDate.setDate(startDate.getDate());
            DefaultdateField = new Date(startDate).toISOString().split("T")[0];
            setSldate(DefaultdateField);
        }
        else {
            setVisible(false);
            DefaultdateField = "";
            setSltime("");
            setSldate("");

        }
        if (s1 == "4") {
            setComplainvisible(true);
            setVisible(false);
            DefaultdateField = "";
            setSltime("");
            setSldate("");
            asyncFuncCallCentreDepartmentDDL();

        }
        else {

            setComplainvisible(false);
        }
        // setValue(e.target.value);
        // //alert(e.target.value);
        // const s1 = e.target.value;
        //  if(s1=="1")
        //  {
        //     setVisible(true);
        //  }
        //  else{
        //     setVisible(false);  
        //  }
        // if (s1 === "1" || s1 === "3" || s1 === "4") {
        //     setVisible(true);
        // }
        // if (s1 === "2" || s1 === "5") {
        //     setVisible(false);
        // }
    }
    const handleChange = date => {
        date.setDate(date.getDate());
        DefaultdateField = "";
        DefaultdateField = new Date(date).toISOString().split("T")[0];
        setSldate(DefaultdateField);
        setstartDate(date);
    };

    const handleChangeTime = (date,) => {
        date.setDate(date.getDate());
        const DefaultdateFieldTime = new Date(date).toLocaleTimeString();
        setSltime(DefaultdateFieldTime);
        setstartDateTime(date);
    };
    const departmentOnBlurhandleBlur = (e) => {
        const s1 = e.target.value;
        if (e.target.value == '0') {

            departmentValid = true;
            formik.setFieldValue('CallDepartment', '0');
        }
        else {

            formik.setFieldValue('CallDepartment', e.target.value);
        }
    }
    const departmentHandler = (e) => {
        const s1 = e.target.value;
        if (e.target.value == '0') {

            departmentValid = true;
            formik.setFieldValue('CallDepartment', e.target.value);
        }
        else {
            departmentValid = false;
            formik.setFieldValue('CallDepartment', e.target.value);


        }

    }
    const LeadSourceHandler = (e) => {
        const val = e.target.value;
        formik.setFieldValue('LeadSource', val);
    }
    const LeadServiceHandler = (e) => {
        const val = e.target.value;
        formik.setFieldValue('LeadService', val);
    }
    async function asyncFunBindAddAssignedCallAdminId() {
        try {

            let url = ConnectionInstance + 'OutboundCalling/GetCallCentreAssignedAdminCallingId';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { CallingId: `${CallingId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        if (data.Data != null && data.Data.CustomerMobile != null) {
                            setPBXto(data.Data.CustomerMobile);
                        }
                        await setDbresult(data.Data);
                        localStorage.setItem("CustomerMobileNo", data.Data.CustomerMobile);
                    }
                }
                else {
                    if (GlBCallingId == '') {
                        if (UserAccLogin != "" && UserAccLogin != null)
                            window.location.assign(`${process.env.PUBLIC_URL}/admin/dashboard`);
                        else
                            window.location.assign(`${process.env.PUBLIC_URL}/login`);
                    }
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SetModalBodyHtml(Errtext);
                    setModal(!modal);
                    setErrorModal(true);
                    console.log('no record found');
                }

                //setUserData(data.map((option) =>(<option value={option.sNo}>{option.ename}</option>)));
                // do something with data
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            console.log(error);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);
            //alert(error);
        }
    }

    /********** Previous Interaction  ***********/

    async function asyncFunBindCallSummaryDetailById() {
        try {

            let url = ConnectionInstance + 'OutboundCalling/GetCallCentreCallSummaryCallId';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { CallingId: `${CallingId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        await setCallSummaryresult(data.Data);

                    }
                }
                else {
                    //     let Errtext = <p><div className='text-required'> no record found </div></p>;
                    //     SetModalBodyHtml(Errtext);
                    //     setModal(!modal);
                    //    setErrorModal(true);
                    console.log('no record found');
                }
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            console.log(error);
            let Errtext = "";
            Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);
            //alert(error);
        }
    }
    /********** Validations  ***********/
    const formik = useFormik({
        initialValues: {
            Notes: '',
            CallStatus: '',
            LeadSource: '',
            LeadService: '',
            TeamId: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            // email: Yup.string().email('Invalid email address').required('Required'),
            CallStatus: Yup.string().transform(v => v === '0' ? '' : v)
                .required('Call Status is Required'),
            LeadSource: Yup.string().transform(v => v === '0' ? '' : v)
                .required('Call Source is Required'),
            LeadService: Yup.string().transform(v => v === '0' ? '' : v)
                .required('Call Service is Required'),
            Notes: Yup.string().required('Notes is Required'),
        }),
        isSubmitting: true,
        onSubmit: async (values) => {

            //setModal(false);
            //setErrorModal(false);
            if (departmentValid == false) {
                try {

                    let url = ConnectionInstance + 'OutboundCalling/SetUpdateCallCentreCallInterActionDetails';

                    let options = {
                        method: 'POST',
                        url: url,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json;charset=UTF-8'
                        },
                        data: {
                            CallingId: `${CallingId}`, AddedBy: `${LoginId}`, FollowUpDate: `${Sldate}`, FollowUpTime: `${Sltime}`, Notes: values.Notes, CallStatusId: values.CallStatus, DeptId: `${values.CallDepartment}`,
                            LeadSourceId: `${values.LeadSource}`, LeadserviceId: `${values.LeadService}`, TeamId: `${values.TeamId}`
                        }
                    };

                    let response = await axios(options);
                    let responseOK = response && response.status == 200;
                    if (responseOK) {
                        let data = response.data;
                        // let data = await response.data;
                        if (data.Success == true && data.Data == "2000") {

                            SweetAlert.fire({ title: "Success!", text: "Update  and close call has been successfully!", icon: "success" });
                            setModal(false);
                            setErrorModal(false);
                            setModify(false);
                            localStorage.removeItem('CallingId');
                            navigate.push('/admin/outbound/assignedcalls');
                            formik.resetForm();

                        }
                        else {
                            if (data.ErrorList != null && data.ErrorList.length > 0) {
                                let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                                SetModalBodyHtml(Errtext);
                                setModal(!modal);
                                setModify(false);
                                setErrorModal(true);
                            }

                        }


                    }
                    else {
                        console.log('no record found');

                    }
                    // return data;
                } catch (error) {
                    console.log(error.message);
                    let Errtext = "";
                    Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                    SetModalBodyHtml(Errtext);
                    setModify(false);
                    setModal(!modal);
                    setErrorModal(true);
                }
            }

        },
    });
    useEffect(() => {
        asyncFuncCallStatusDDL();
        FuncLeadSourceDDL();
        FuncLeadServiceDDL();
        asyncFunCallTeamDDL();
    }, []);

    async function asyncFuncCallStatusDDL() {
        try {

            let url = ConnectionInstance + 'master/GetCallStatusDDList';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        SetCallstatusDLL(ds.map((option) => (<option value={option.CallStatusId}>{option.CallStatus}</option>)));
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                }

            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = "";
            Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);
            //alert(error);
        }
    }

    const FuncLeadSourceDDL = async () => {

        try {

            let url = ConnectionInstance + 'master/GetBindLeadSourceDDList';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        SetLeadSourceDDL(ds.map((option) => (<option value={option.SNO}>{option.Source}</option>)));
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                }

            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = "";
            Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);
        }
    }

    const FuncLeadServiceDDL = async () => {

        try {

            let url = ConnectionInstance + 'master/GetBindLeadServiceDDList';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        SetLeadServiceDDL(ds.map((option) => (<option value={option.SNO}>{option.LeadService}</option>)));
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                }

            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = "";
            Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);
        }
    }
    async function asyncFuncCallCentreDepartmentDDL() {
        try {

            let url = ConnectionInstance + 'master/GetCallCentredepartmentDDList';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;

                    if (ds != null && ds.length > 0) {
                        SetCallDepartmentDLL(ds.map((option) => (<option value={option.DeptId}>{option.Department}</option>)));
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SetModalBodyHtml(Errtext);
                    setModal(!modal);
                    setErrorModal(true);
                    console.log('no record found');
                }

            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = "";
            Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);
            //alert(error);
        }
    }
    async function asyncFunCallTeamDDL() {

        try {

            let url = ConnectionInstance + 'master/GetCallCentreTeamDDList';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        SetTeamMData(ds.map((option) => (<option value={option.TeamID}>{option.TeamName}</option>)));
                        setModal(false);
                        setErrorModal(false);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SetModalBodyHtml(Errtext);
                    setModal(!modal);
                    setErrorModal(true);

                    // modelpopUp(toggleModal,Errtext); 
                    console.log('no record found');
                }
            }
            else {

                let Errtext = <p><div className='text-required'> no record found </div></p>;

                SetModalBodyHtml(Errtext);
                setModal(!modal);
                setErrorModal(true);

                console.log('no record found');
            }

            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = "";
            Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);

        }

    }

    const errorHandler = () => {
        formik.handleSubmit();
        //formik.errors.CallDepartment="";
        // formik.setFieldTouched('CallDepartment',true);
        if (formik.values.CallStatus == "4") {

            if ((formik.values.CallDepartment == undefined || formik.values.CallDepartment == "" || formik.values.CallDepartment == "0")) {
                departmentValid = true;

            }
        }
        else {
            formik.setFieldValue('CallDepartment', '');
            departmentValid = false;
            // formik.values.errors.removeItem('CallDepartment');
            // formik.errors.removeItem('CallDepartment');
            //formik.setFieldTouched('CallDepartment',false);
            //formik.errors.CallDepartment="";
            //formik.setFieldTouched('CallDepartment',true);
        }
        if ((formik.touched.CallStatus && formik.errors.CallStatus) || (formik.touched.Notes && formik.errors.Notes) || (formik.touched.Notes == undefined && formik.values.Notes == "") || (formik.touched.CallStatus == undefined && formik.values.CallStatus == "")) {
            toggleModal();
            setErrorModal(true);
        }
        if (departmentValid == true) {
            toggleModal();
            setErrorModal(true);
        }


    }

    const PBXClicktoCallHandler = () => {
        asyncFunBindCallingEditmob();

    }
    async function asyncFunBindCallingEditmob() {
        if (Dbresult != null) {
            setPBXto(Dbresult.CustomerMobile);
            setModal(!modal);
            setModify(true);
            setcustomernameUpdate(false);
            setErrorModal(false);
        }

    }
    const changeCustMobHandle = (e) => {
        setPBXto(e.target.value);
    }
    const updateMobHandler = () => {
        setModal(!modal);
        if (PBXto == "" || PBXto.length < 10) {
            SweetAlert.fire({ title: "Incomplete Mobile", text: "Please enter correct mobile no.", icon: "warning" });
        }
        else if (PBXto.length > 10) {
            SweetAlert.fire({ title: "Incomplete Mobile", text: "Please enter 10 digit mobile no without +91 or 0 only.", icon: "warning" });
        }
        else {
            asyncFunCustomerMobileUpdate();

            // SweetAlert.fire({
            //     title: 'Are you sure?',
            //     text: "Mobile number start with zero !",
            //     icon: 'warning',
            //     showCancelButton: true,
            //     confirmButtonText: 'Ok',
            //     cancelButtonText: 'Cancel',
            //     reverseButtons: true
            // })
            //     .then((result) => {
            //         if (result.value && result.isConfirmed===true) {

            //             asyncFunCustomerMobileUpdate();

            //         }

            //     })
        }

    }

    async function asyncFunCustomerMobileUpdate() {
        try {

            setModal(false);
            setErrorModal(false);
            setErrorPopUp("");
            let url = ConnectionInstance + 'outboundcalling/SETUPDATEOutboundCallCustomerMobByID';

            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { CallingId: `${CallingId}`, CustomerMobile: `${PBXto}`, ModifiedBy: `${LoginId}` }
            };

            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true && data.Data == "2000") {
                    setModal(false);
                    asyncFunclicktocallpbx();
                    //SweetAlert.fire({ title: "Success!", text: "Data has been updated!", icon: "success" });
                    asyncFunBindAddAssignedCallAdminId();

                    setErrorModal(false);

                }
                else {
                    if (data.ErrorList != null && data.ErrorList.length > 0) {
                        let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                        setErrorPopUp(Errtext);
                        setModal(!modal);
                        setErrorModal(true);
                    }

                }


            }
            else {
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            setErrorPopUp(Errtext);
            setErrorModal(true);
        }
    }
    async function asyncFunclicktocallpbx() {
        try {
            setIsLoadButton(true);
            let url = ConnectionInstance + 'pbxcall/pbxclicktocalls';

            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { CallingId: `${CallingId}`, AddedBy: `${LoginId}`, PBXfrom: `${PBXfrom}`, PBXto: `0${PBXto}` }
            };

            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    setTimeout(() => {
                        setIsLoadButton(false);
                        SweetAlert.fire({ title: "Success!", text: "call connected has been successfully!", icon: "success" });
                    }, 3000);


                    setModal(false);
                    setErrorModal(false);

                }
                else {
                    setIsLoadButton(false);
                    if (data.ErrorList != null && data.ErrorList.length > 0) {
                        let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                        SetModalBodyHtml(Errtext);
                        setModal(!modal);
                        setErrorModal(true);
                    }

                }


            }
            else {
                setIsLoadButton(false);
                console.log('no record found');

            }
            // return data;
        } catch (error) {
            setIsLoadButton(false);
            console.log(error.message);
            let Errtext = "";
            Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SetModalBodyHtml(Errtext);
            setModal(!modal);
            setErrorModal(true);
        }
    }

    const GetVisits = async (rwData) => {
        console.log(rwData.PatientVisits);
        setPatientName(rwData.PatientName);
        setPatientAge(rwData.Age);
        if (rwData.PatientVisits.length > 0) {
            if (rwData.PatientVisits.length > 1) {
                setPopupTableData(rwData.PatientVisits); openModal();
                window.location.href = '/admin/outbound/addassigncalls#tag';
            }
            else { GetTestsList(rwData.PatientVisits[0].VisitingId, rwData.PatientVisits[0].VisitingCode, "1"); }
        }
        else {
            setDisplayTestsList(false);
            setPopupTableData([]); setTestsModal(false);
            SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
        }
        // setPopupTableData(rwData.PatientVisits); openModal();
    }

    const GetTestsList = async (id, vCode, callingId) => {
        setTestsModal(false);
        setVisitNo(vCode);
        setIsLoading(true);
        let url = ConnectionInstance + 'labreport/gettestslist/' + id + "/" + vCode + "/" + callingId + '/' + localStorage.LoggedInUser;
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data != null) {
                    var dataInfo = data.Data;
                    setReportUrl(dataInfo.ReportUrl);
                    if (dataInfo.ReportUrl !== "") { setReportAvailable(true); } else { setReportAvailable(false); }
                    setVisitsTableData(dataInfo.TestsList);
                    setDisplayTestsList(true);
                    window.location.href = '/admin/outbound/addassigncalls#tag';
                }
                setIsLoading(false);
            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const handleTestStatus = async () => {
        window.open(reportUrl, "_blank");
    }
    // const BindGrid = async (PageNo,PageSize) => {
    //     setTableData([]);
    //     let MobileNo;
    //     if (Dbresult == null) { return false; }
    //     else { MobileNo = Dbresult.CustomerMobile == null ? "0" : Dbresult.CustomerMobile }
    //     setIsLoading(true);
    //     // 9878355537,
    //     const Objpatient = {
    //         SearchText: MobileNo, 
    //         PageNo: `${PageNo}`,
    //         PageSize: `${PageSize}`,
    //         SearchType: `${1}`,
    //     };
    //     let url = ConnectionInstance + 'labreport/callcentresearchpatient';
    //     await axios.post(url,Objpatient).then((response) => {
    //         const data = response.data;
    //         if (data.Success) {
    //             if (data.Data != null) {
    //                let ds = data.Data;
    //                setTableData(ds.patientDs);
    //                 setDisplayTestsList(false);
    //                 setIsLoading(false);
    //             }
    //             else{
    //                 setTableData([]);
    //                 setIsLoading(false);
    //             }

    //         }
    //         else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); setTableData([]); }
    //     }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); setTableData([]); });
    // }




    const BindGrid = async () => {
        setTableData([]);
        let MobileNo;
        if (Dbresult == null) { return false; }
        else { MobileNo = Dbresult.CustomerMobile == null ? "0" : Dbresult.CustomerMobile }
        setIsLoading(true);
        let url = ConnectionInstance + "labreport/searchpatient/" + MobileNo + "/" + "1";
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data != null) {
                    glbRecords = data.Data;
                    setTableData(glbRecords);
                    setDisplayTestsList(false);
                }
                setIsLoading(false);
            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }

    const openDatepicker = () => this._calendar.setOpen(true);
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    const EditCustomerNameHandler = async () => {
        asyncCustomerNameHandler();

    }

    async function asyncCustomerNameHandler() {
        if (Dbresult != null) {
            SetCustomernameL(Dbresult.CustomerName);
            setModal(!modal);
            setcustomernameUpdate(true);
            setModify(false);
            setErrorModal(false);
        }
    }
    const updateCusNameHandler = () => {
        setModal(!modal);
        if (CustomernameL == "" || CustomernameL.length < 3) {
            SweetAlert.fire({ title: "Vaild Customer", text: "Please enter vaild customer name", icon: "warning" });
        }
        else {
            asyncFunCustomerNameUpdate();
        }
    }

    async function asyncFunCustomerNameUpdate() {
        try {

            setModal(false);
            setErrorModal(false);
            setErrorPopUp("");
            let url = ConnectionInstance + 'outboundcalling/SETUPDATECustomerNameDetails';

            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { CallingId: `${CallingId}`, CustomerName: `${CustomernameL}`, ModifiedBy: `${LoginId}` }
            };

            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true && data.Data == "2000") {
                    setModal(false);
                    SweetAlert.fire({ title: "Customer Detail", text: "Customer Name updated Successfully", icon: "info" });
                    asyncFunBindAddAssignedCallAdminId();
                    setErrorModal(false);

                }
                else {
                    if (data.ErrorList != null && data.ErrorList.length > 0) {
                        let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                        setErrorPopUp(Errtext);
                        setModal(!modal);
                        setErrorModal(true);
                    }

                }


            }
            else {
                console.log('no record found');
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            setErrorPopUp(Errtext);
            setErrorModal(true);
        }
    }
    const changeCustomerNameClick = async (e) => {
        const val = e.target.value;
        SetCustomernameL(val);
    }

    return (
        <>
            <Breadcrumb title="Assign Call" parent="Outbound Calls" />
            <div className='container-fluid'>


                <div className='row'>
                    <div className='col-md-8'>
                        <div className="card">
                            {Dbresult != null ? <div className="card-body">
                                <div className='row'>
                                    <div className='col-md-6 col-lg-3'>
                                        <div className="form-group">
                                            <label className="col-form-label"><b>Customer Name</b></label>
                                            <span className="form-control-plaintext" >{Dbresult.CustomerName == null ? 'N/A' : Dbresult.CustomerName} <EditToolTip title="Edit" onClick={EditCustomerNameHandler} /> </span>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-lg-3'>
                                        <div className="form-group">
                                            <label className="col-form-label"><b>Mobile</b></label>
                                            {/* <input className="form-control" type="text" value={Dbresult.CustomerMobile==null?'N/A':Dbresult.CustomerMobile}  /> */}
                                            {<span className="form-control-plaintext" >{Dbresult.CustomerMobile == null ? 'N/A' : Dbresult.CustomerMobile}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-lg-3'>
                                        <div className="form-group">
                                            <label className="col-form-label"><b>Source</b></label>
                                            <span className="form-control-plaintext" >{Dbresult.CASourceCategory == null ? 'N/A' : Dbresult.CASourceCategory}{' - '}{Dbresult.CASourceName == null ? 'N/A' : Dbresult.CASourceName}</span>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-lg-3'>
                                        <div className="form-group">
                                            <label className="col-form-label"><b>Purpose</b></label>

                                            <span className="form-control-plaintext" >
                                                {

                                                    (() => {
                                                        switch (Dbresult.CallTypeId) {
                                                            case "1":
                                                                return <>{'New Call'}{' - '}{Dbresult.MPurposeName == null ? 'N/A' : Dbresult.MPurposeName}</>;
                                                            case "2":
                                                                return <>{'Follow Up'}{' - '}{Dbresult.MPurposeName == null ? 'N/A' : Dbresult.MPurposeName}</>;
                                                            case "3":
                                                                return <>{'Complaint'}{' - '}{Dbresult.MPurposeName == null ? 'N/A' : Dbresult.MPurposeName}</>;
                                                            case "4":
                                                                return <>{Dbresult.CallStatus}{' - '}{Dbresult.MPurposeName == null ? 'N/A' : Dbresult.MPurposeName}</>;
                                                            default:
                                                                return null
                                                        }
                                                    })()

                                                }
                                                {/* {Dbresult.CallTypeId==1?'New Call':'Follow Up'}{' - '} {Dbresult.MPurposeName==null?'N/A':Dbresult.MPurposeName} */}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}

                            <div className="card-footer text-center">
                                {IsLoadButton ? <LoadingButton variantName='primary' cssName="mr-2" btntext={' Connecting...'} /> : <button className="btn btn-primary mr-1" onClick={PBXClicktoCallHandler}><i className='fa fa-headphones'></i> Click to Call</button>}
                                {/* <button className="btn btn-primary mr-1" onClick={PBXClicktoCallHandler}><i className='fa fa-headphones'></i> Click to Call</button> */}
                                {/* <button className="btn btn-secondary" onClick={modifyHandler}>Modify Personal Detail</button> */}
                            </div>
                        </div>

                        {/* <div className="card pageDivDisble">

                            <div className='card-header pb-0'>
                                <h5>Home Collection</h5>
                            </div>
                            <div className='pageUnderConstruction'>
                                <h1 >Under Development</h1>
                            </div>
                            <div className="card-body">

                                <div className='row justify-content-between'>
                                    <div className='col-md-6 col-lg-5'>
                                        <div className="form-group">
                                            <label className="col-form-label">Location</label>
                                            <input className="form-control" type="text" />
                                        </div>
                                        <div className="form-group text-center">
                                            <button className="btn btn-secondary btn-sm" type="button" onClick={bookHandler}>Book Appointment</button>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-lg-5'>
                                        <div className="form-group">
                                            <label className="col-form-label">Radiology Location</label>
                                            <input className="form-control" type="text" />
                                        </div>
                                        <div className="form-group text-center">
                                            <button className="btn btn-secondary btn-sm" type="button" onClick={bookHandler}>Book Appointment</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="card pageDivDisble"> */}
                        <div className='card-header pb-0'>
                            <h5>Report</h5>
                        </div>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header className='pb-0'>
                                        <Card.Title>Patient's List</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        {
                                            isLoading ?
                                                <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                <>
                                                    <DataTable
                                                        keyField='Id'
                                                        tableData={tableData}
                                                        columns={columns}
                                                        noDataIndication={NoRecordFounds}
                                                        pagination={paginationFactory(paginationOptionNew)}
                                                    />

                                                </>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        {displayTestsList ?
                            <Row id='tag'>
                                <Col md={12}>
                                    <Card>
                                        <Card.Header className='pb-0'>
                                            <Card.Title>Investigations's List</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className='row align-items-center'>
                                                <div className='col-md-10 order-2 order-md-1'>
                                                    <p><b>Name:</b>{patientName}, <b>Age:</b> {patientAge} Years, <b>Visit No:</b> {visitNo}</p>
                                                </div>
                                                {/* <div className='col-md-2 text-right order-1 order-md-2'>
                                                    {isReportAvailable ? <Button variant="secondary" onClick={handleTestStatus}>Open Report</Button> : null}
                                                </div> */}
                                            </div>
                                            {
                                                isLoading ?
                                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                    <>
                                                        <DataTable keyField='id' tableData={visitsTableData} columns={testsColumns}
                                                            noDataIndication={NoRecordFounds}
                                                            pagination={paginationFactory(paginationOptions)} />
                                                    </>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            : <Row></Row>
                        }
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header className='pb-0'>
                                        <Card.Title>Appointment List</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        {
                                            isLoading ?
                                                <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                <>
                                                    <DataTable
                                                        keyField='AppointmentId'
                                                        tableData={tableAppointmentData}
                                                        columns={columnsAppointment}
                                                        noDataIndication={NoRecordFounds}
                                                        pagination={paginationFactory(paginationOptionAppointment)}
                                                    />

                                                </>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        {/* <div className='pageUnderConstruction'>
                            <h1 >Under Development</h1>
                        </div> */}
                        {/* <div className="card-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead className='thead-light'>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Test Name</th>
                                                    <th>Package Name</th>
                                                    <th>Collection Date</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='text-center' colSpan="6">No Data Found</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* </div> */}
                        <SampleQuotation flag="adminoutbound" />
                        {/* <div className="card pageDivDisble">
                            <div className='card-header pb-0'>
                                <h5>Sample Quotation</h5>
                            </div>
                            <div className='pageUnderConstruction'>
                                <h1 >Under Development</h1>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-md-6 col-lg-4'>
                                        <div className="form-group">
                                            <label className="col-form-label">Patient Type</label>
                                            <select className="form-control">
                                                <option value="0">---Select---</option>
                                                <option value="1">Walk-in</option>
                                                <option value="2">Corporate</option>
                                                <option value="3">Government</option>
                                                <option value="4">Insurance</option>
                                                <option value="5">B2B</option>
                                                <option value="6">IPD</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-lg-4'>
                                        <div className="form-group">
                                            <label className="col-form-label">Rate Type</label>
                                            <select className="form-control">
                                                <option value="0">---Select---</option>
                                                <option value="1">Standard</option>
                                                <option value="2">Rate 2</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-12 col-lg-4'>
                                        <div className="form-group">
                                            <label className="col-form-label">Add Test</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-secondary" type="button"><i className="fa fa-plus"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}


                    </div>{/** en col-md-8 */}

                    <div className='col-md-4'>

                        <Row>
                            <Col md={12}>
                                <Card>
                                    <div className='card-header pb-0'>
                                        <h5>Call Summary</h5>
                                    </div>
                                    <Card.Body>
                                        <Form >
                                            <Form.Row className="justify-content-center">

                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label htmlFor="Notes">Notes<span className='text-required'>*</span></Form.Label>
                                                        <textarea className="form-control" id="Notes" name="Notes" rows="3" onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur} maxLength={300}
                                                            value={formik.values.Notes}></textarea>
                                                        {formik.touched.Notes && formik.errors.Notes ? (
                                                            <div className='text-required'>{formik.errors.Notes}</div>
                                                        ) : null}

                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="justify-content-center"> <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Call Service<span className='text-required'>*</span></Form.Label>
                                                    <Form.Control as="select" id="ldservice" name="ldservice"
                                                        onChange={LeadServiceHandler}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.LeadService}>
                                                        <option value='0'>---select---</option>
                                                        {LeadServiceDDL}
                                                    </Form.Control>

                                                    {formik.touched.LeadService && formik.errors.LeadService ? (
                                                        <div className='text-required'>{formik.errors.LeadService}</div>
                                                    ) : null}

                                                </Form.Group>
                                            </Col></Form.Row>
                                            <Form.Row className="justify-content-center"> <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Call Source<span className='text-required'>*</span></Form.Label>
                                                    <Form.Control as="select" id="lsource" name="lsource"
                                                        onChange={LeadSourceHandler}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.LeadSource}>
                                                        <option value='0'>---select---</option>
                                                        {LeadSourceDDL}
                                                    </Form.Control>

                                                    {formik.touched.LeadSource && formik.errors.LeadSource ? (
                                                        <div className='text-required'>{formik.errors.LeadSource}</div>
                                                    ) : null}

                                                </Form.Group>
                                            </Col></Form.Row>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Call Status<span className='text-required'>*</span></Form.Label>
                                                        <Form.Control as="select" id="CallStatus" name="CallStatus"
                                                            onChange={changeHandler}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.CallStatus}>
                                                            <option value='0'>---select---</option>
                                                            {CallstatusDLL}
                                                        </Form.Control>

                                                        {formik.touched.CallStatus && formik.errors.CallStatus ? (
                                                            <div className='text-required'>{formik.errors.CallStatus}</div>
                                                        ) : null}

                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            {visible &&
                                                <>
                                                    {/* <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label className="col-form-label">Priorty</label>
                                                            <select className="form-control">
                                                                <option value="0">---Select---</option>
                                                                <option value="1">High</option>
                                                                <option value="2">Medium</option>
                                                                <option value="3">Low</option>
                                                            </select>
                                                        </div>
                                                    </div> */}
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <label className="col-form-label">Follow Up Date</label>
                                                            {/* <input className="form-control digits" type="datetime-local"  defaultValue={DefaultdateField} onChange={SelectDateHandler} /> */}
                                                            {<div className='dateIcon'>
                                                                <DatePicker className="form-control digits" selected={startDate} onChange={handleChange} dateFormat="dd-MM-yyyy" ref={calendarRef} />
                                                                <i className='fa fa-calendar' onClick={() => { calendarRef.current.setOpen(true) }}></i>
                                                            </div>}
                                                            {/* { <input className="form-control digits" type="date"  defaultValue={DefaultdateField} onChange={SelectDateHandler} /> } */}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <label className="col-form-label">Preferred  Time</label>
                                                            {/* <input className="form-control digits" type="datetime-local"  defaultValue={DefaultdateField} onChange={SelectDateHandler} /> */}
                                                            {/* { <input className="form-control digits" type="time"  onChange={SelecttimeHandler} /> } */}
                                                            {<div className='dateIcon'>
                                                                <DatePicker className="form-control digits" selected={startDateTime} onChange={handleChangeTime} showTimeSelect
                                                                    showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm aa" ref={timerRef} />
                                                                <i className='fa fa-clock-o' onClick={() => { timerRef.current.setOpen(true) }}></i>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            {Complainvisible && <>
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Department</Form.Label>
                                                        <Form.Control as="select" id="CallDepartment" name="CallDepartment"
                                                            onChange={departmentHandler}
                                                            onBlur={departmentOnBlurhandleBlur}
                                                            value={formik.values.CallDepartment}>
                                                            <option value='0'>---select---</option>
                                                            {CallDepartmentDLL}
                                                        </Form.Control>

                                                        {departmentValid == true ? (
                                                            <div className='text-required'>Department is Required</div>
                                                        ) : null}

                                                    </Form.Group>
                                                </Col>
                                            </>

                                            }
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Re-assign Team </Form.Label>
                                                        <Form.Control as="select" id="TeamId" name="TeamId" onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.TeamId}  >
                                                            <option value='0'>---select---</option>
                                                            {TeamMData}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>  </Form.Row>
                                            <div className='row text-center'>
                                                <div className="col-md-12">
                                                    <button className="btn btn-primary btn-sm" type="button" disabled={formik.isSubmitting} onClick={errorHandler} >Update and Close Call</button>
                                                    {/* <button class="btn btn-primary btn-sm mr-1" type="button" onClick={smsHandler}>Send SMS</button>
                                                <button class="btn btn-secondary btn-sm" type="button">Submit</button> */}
                                                </div>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>



                        <div className='row txtPrivousInteractionStep2'>
                            <div className='col-md-12'>
                                <div className='card'>
                                    <div className='card-header pb-0'>
                                        <h5>Previous Interactions</h5>
                                    </div>
                                    <div className="card-body">
                                        {CallSummaryresult != null && CallSummaryresult.length > 0 ?
                                            CallSummaryresult.map((tb, index) => {
                                                return (
                                                    <>


                                                        <div className="row">
                                                            <div className="col-md-6 col-6"><strong>{tb.UserName}</strong></div>
                                                            {/* <div className="col-md-6 col-6 text-right text-muted">{tb.CFollowUpDate}</div> */}

                                                            <div className="col-md-12 m-t-5">
                                                                <p className='mb-0'><b>Call Service:</b>{tb.LeadService == '' ? 'N/A' : tb.LeadService}</p>
                                                                <p className='mb-0'><b>Call Source:</b>{tb.LeadSource == '' ? 'N/A' : tb.LeadSource}</p>
                                                                <p className='mb-0'><b>Call Status:</b>{tb.CallStatus == '' ? 'N/A' : tb.CallStatus}</p>
                                                                <p className='mb-0'><em><b>Notes:</b>{tb.Notes}</em></p>
                                                                <p className='mb-0'><b>Activity Date:</b>{tb.AddedOn}</p>
                                                                {/* <p className='mb-0 text-success'><b>Solution:</b> Report has been collected</p> */}
                                                            </div>
                                                            {(tb.CFollowUpDate != "") ? <div className="col-md-12 m-t-5">

                                                                <p className='mb-0'><b>Follow Up Date:</b>{tb.CFollowUpDate != null && tb.CFollowUpTime != null ? tb.CFollowUpDate + ' | ' + tb.CFollowUpTime : tb.CFollowUpDate}</p>
                                                                {/* <p className='mb-0 text-success'><b>Solution:</b> Report has been collected</p> */}
                                                            </div> : null}



                                                        </div>
                                                        <hr />

                                                    </>)
                                            })

                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>



            <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                {modify ?
                    <>
                        <ModalHeader toggle={toggleModal}>Edit Customer Mobile Detail</ModalHeader>
                        <ModalBody>
                            <form>
                                {/* <div className="form-group">
                                    <label className="col-form-label">Customer Name:</label>
                                    <input className="form-control" type="text" value="Manish" disabled />
                                </div> */}
                                <div className="form-group">
                                    <label className="col-form-label">Mobile:</label>

                                </div> <input className="form-control" type="text" id='txtcustmobile' value={PBXto} onChange={changeCustMobHandle} name='txtcustmobile' />

                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button color="primary" onClick={updateMobHandler}>Update and Call</Button>
                        </ModalFooter>
                    </>
                    : null}

                {sms ?
                    <>
                        <ModalHeader toggle={toggleModal}>Send SMS</ModalHeader>
                        <ModalBody>
                            <form>
                                <div className="form-group">
                                    <label className="col-form-label">Message:</label>
                                    <textarea className="form-control" rows="5"></textarea>
                                </div>
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button color="primary" onClick={toggleModal}>Send SMS</Button>
                        </ModalFooter>
                    </>
                    : null}

                {booking ?
                    <>
                        <ModalHeader toggle={toggleModal}>Book Appointment</ModalHeader>
                        <ModalBody>
                            <p>
                                <img className="img-fluid" src={bookingImage} alt="" />
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button color="primary" onClick={toggleModal}>Submit</Button>
                        </ModalFooter>
                    </>
                    : null}
                {customernameUpdate ?
                    <>
                        <ModalHeader toggle={toggleModal}>Edit Customer Detail</ModalHeader>
                        <ModalBody>
                            <form>
                                {/* <div className="form-group">
                                <label className="col-form-label">Customer Name:</label>
                                <input className="form-control" type="text" value="Manish" disabled />
                            </div> */}
                                <div className="form-group">
                                    <label className="col-form-label">Customer Name:</label>

                                </div> <input className="form-control" type="text" id='txtcustname' name='txtcustname'
                                    value={CustomernameL} onChange={changeCustomerNameClick} />
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button color="primary" onClick={updateCusNameHandler}>Update</Button>

                        </ModalFooter>
                    </>
                    : null}

            </Modal>

            {errorModal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>

                        {ModalBodyHtml === null || ModalBodyHtml === '' ?

                            (<><p>  {formik.touched.Notes && formik.errors.Notes ? (
                                <div className='text-required'>{formik.errors.Notes}</div>
                            ) : null}</p>
                                <p>
                                    {formik.touched.LeadService && formik.errors.LeadService ? (
                                        <div className='text-required'>{formik.errors.LeadService}</div>
                                    ) : null}
                                </p>
                                <p>
                                    {formik.touched.LeadSource && formik.errors.LeadSource ? (
                                        <div className='text-required'>{formik.errors.LeadSource}</div>
                                    ) : null}
                                </p>
                                <p>
                                    {formik.touched.CallStatus && formik.errors.CallStatus ? (
                                        <div className='text-required'>{formik.errors.CallStatus}</div>
                                    ) : null}
                                </p>

                            </>) : ModalBodyHtml}
                        <p>
                            {departmentValid == true ? (
                                <div className='text-required'>Department is Required</div>
                            ) : null}
                        </p>


                        {/* <p>
                            {formik.touched.callType && formik.errors.callType ? (
                                <div className='text-required'>{formik.errors.callType}</div>
                            ) : null}
                        </p>
                        <p>
                            {formik.touched.purpose && formik.errors.purpose ? (
                                <div className='text-required'>{formik.errors.purpose}</div>
                            ) : null}
                        </p> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>

                </Modal>
            }
            {testsModal &&
                <Modal isOpen={testsModal} toggle={openModal} centered={true} size="lg">
                    <ModalHeader toggle={openModal}>Investigations List</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <DataTable keyField='PlabId' tableData={popupTableData} columns={popupColumns} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            }
             {
          openmodal &&
          <Modal isOpen={openmodal} centered={true} size="lg">
            <ModalHeader> Appointment</ModalHeader>
            <ModalBody>
              <Form.Row>
                <Col md={6}>
                  <Form.Group className="mb-3 calandersec">
                     <Form.Label>Date</Form.Label>
                      <Form.Control type="date" name="date" ref={appointmentDateId} defaultValue={defaultDate}  onChange={handleSlotDateChange} />
                   </Form.Group>                 
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Select time<span className='text-red'>*</span></Form.Label>
                    <Form.Control as="select" name="time" ref={dtime} onChangeCapture={(e) => RequestTime(e)}>
                      <option value="">---Select---</option>
                      {slottiming}
                    </Form.Control>                 
                  </Form.Group>
                </Col>
                <Col md={12} xs={11}>
                  <Form.Group className='m-t-15'>
                    <Form.Label className='icRight'>
                      <Button variant='secondary' className="mr-2" onClick={openRequiredFieldModal}>Cancel</Button>
                      {
                        isButtonLoading ?
                          <LoadingButton variantName='primary' cssName="" /> :
                          <Button variant='primary' type='submit' id="btnUpdate" onClick={updateAppDateTime}>Update</Button>
                      }
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ModalBody>
          </Modal>
        }
        </>
    )
}

export default AddAssignCalls;