import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Card, Button } from "react-bootstrap";
import classes from './CartSummary.module.css';

const Order = (props) => {
    const [itemcount, setItemCount] = useState([]);
    useEffect(() => {
        const Items = props.ItemDetails;
        const Patients = props.PatientNames;
        var countarr = [];
        var totalcount = 0;
        for (var key in Patients) {
            countarr.push({
                PatientId: Patients[key].PatientId,
                Count: 0
            })
            totalcount = 0;
            for (var keyy in Items) {
                if (Patients[key].PatientId === Items[keyy].PatientId) {
                    totalcount = parseInt(totalcount + 1);
                    countarr[key].Count = parseInt(totalcount);
                }
            }
        }
        setItemCount(countarr);
    }, [])
    return (
        <>
            <Card className={classes.cardBorderd}>
                <Card.Header className={classes.bgTransparent}>
                    <Row className='align-items-left'>
                        <Col xs={12}>
                            <h2 className='headingstyle3'>Review Booking</h2>
                        </Col>

                    </Row>
                </Card.Header>
                <Card.Body className='pt-0'>
                    <Row>
                        <Col xs={12}>
                            <Card.Text>
                                {
                                    props.PatientNames == [] ? null :
                                        <>
                                            {
                                                props.PatientNames.map((e, indexx) =>
                                                    <>
                                                        <small className='text-muted textfullwidth mt-3 mb-2' key={indexx}><span className={classes.textDark}><b className='text-patientName'>{e.PatientName}</b>,</span> <span className='ageGender'>{e.Age} {e.Gender}</span>
                                                            {
                                                                itemcount.length > 0 ?
                                                                    <>
                                                                        {
                                                                            itemcount.map((f, indexxx) =>
                                                                                (e.PatientId === f.PatientId) ?
                                                                                    <>
                                                                                        &nbsp;(x{f.Count} {f.Count === 1 ? "item" : "items"})
                                                                                        {/* <span className={classes.offPrice} style={{ float: 'right' }}>x{f.Count} {f.Count === 1 ? "test" : "tests"}</span> */}
                                                                                    </> :
                                                                                    null
                                                                            )
                                                                        }
                                                                    </>
                                                                    : null
                                                            }
                                                        </small>
                                                        {
                                                            props.ItemDetails === [] ? null :
                                                                props.ItemDetails.map((d, index) =>
                                                                    (e.PatientId === d.PatientId) ?
                                                                        <p className={classes.orderpara} key={index}>
                                                                            <span className={classes.textDark}>{d.ItemMasterName}</span>
                                                                            <span className={classes.salePrice}> &#8377;{d.DiscountPrice}</span>
                                                                            {
                                                                                d.ActualPrice === d.DiscountPrice ? null :
                                                                                    <span className={classes.comparePrice}> &#8377;{d.ActualPrice}</span>
                                                                            }
                                                                            {
                                                                                d.OffPercent === "0" ? null :
                                                                                    <span className={classes.offPrice}> &#8377;{d.OffPercent}% Off</span>
                                                                            }
                                                                            {
                                                                                (d.HomeCollectionAvailable === "0" || d.HomeCollectionAvailable === "false" || d.HomeCollectionAvailable === false || d.HomeCollectionAvailable === "False") ?
                                                                                    <span style={{ color: '#999999', fontSize: '14px' }}> (Centre visit required)</span>
                                                                                    : null
                                                                            }
                                                                        </p> :
                                                                        null
                                                                )
                                                        }
                                                    </>
                                                )
                                            }
                                        </>
                                }
                            </Card.Text>
                        </Col>
                    </Row>
                    <Row className='border-bottom pb-3 mt-5'>
                        <Col md={12}>
                            <span className={classes.textDark}><b className='ft18'>Address</b></span><br />
                            {
                                props.AddressId === 0 ? null :
                                    <>
                                        {
                                            props.Address.map((d) => d.Id === props.AddressId ?
                                                (d.Pincode === "" || d.Pincode === "0") && d.HouseNo === "" && d.Address === "" ?
                                                    <>
                                                        <small className='text-muted'>{d.District},{d.State}</small>
                                                    </> :
                                                    (d.Pincode === "" || d.Pincode === "0") && d.HouseNo === "" ?
                                                        <>
                                                            <small className='text-muted'>{d.Address}, {d.District},{d.State}</small>
                                                        </> :
                                                        (d.Pincode === "" || d.Pincode === "0") && d.Address === "" ?
                                                            <>
                                                                <small className='text-muted'>{d.HouseNo}, {d.District},{d.State}</small>
                                                            </> :
                                                            d.Address === "" && d.HouseNo === "" ?
                                                                <>
                                                                    <small className='text-muted'>{d.District},{d.State} &ndash; {d.Pincode}</small>
                                                                </> :
                                                                (d.Pincode === "" || d.Pincode === "0") ?
                                                                    <>
                                                                        <small className='text-muted'>{d.HouseNo}/{d.Address}, {d.District},{d.State}</small>
                                                                    </> :
                                                                    d.Address === "" ?
                                                                        <>
                                                                            <small className='text-muted'>{d.HouseNo}, {d.District},{d.State} &ndash; {d.Pincode}</small>
                                                                        </> :
                                                                        d.HouseNo === "" ?
                                                                            <>
                                                                                <small className='text-muted'>{d.Address}, {d.District},{d.State} &ndash; {d.Pincode}</small>
                                                                            </> :
                                                                            <>
                                                                                <small className='text-muted'>{d.HouseNo}/{d.Address}, {d.District},{d.State} &ndash; {d.Pincode}</small>
                                                                            </>
                                                :
                                                null
                                            )
                                        }
                                    </>
                            }
                        </Col>
                    </Row>
                    <Row className='pb-3 pt-3'>
                        <Col md={12}>
                            <span className={classes.textDark}><b className='ft18'>Time Slot</b></span><br />
                            <small className='text-muted'>{props.DateTime}</small>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export default Order;