import React, { Fragment, useState,useEffect,useRef,useCallback} from 'react'
import CallToolTip from '../../UI/ToolTips/CallToolTip';
import Breadcrumb from '../common/breadcrumb';
import SweetAlert from 'sweetalert2';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import ConnectionInstance from '../../apisettings/ConnectionInstance';
import PusherKey from '../../apisettings/PusherKey';
import { useHistory } from 'react-router';
 import Pusher from 'pusher-js';
 import { debounce } from 'lodash';
//  import toast, { Toaster } from 'react-hot-toast';
  import 'react-toastify/dist/ReactToastify.css';
  import { ToastContainer, toast } from 'react-toastify';
let GlbCustCallid="";
const InboundCallNotifications = () => {
    const [showA, setShowA] = useState(true);
    const notify = () => toast('Here is your toast.');
    const[LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const[UserTypeId, setUserTypeId] = useState(localStorage.UserType == undefined ? "" : localStorage.UserType);
    const [CallExtensionId, setCallExtensionId] = useState(localStorage.ExtenNo == undefined ? "" : localStorage.ExtenNo);
       const navigate=new useHistory();
       const[ErrorPopUp, setErrorPopUp]=useState(0);
        const[ModalBodyHtml,SetModalBodyHtml]=useState("");
        const[hitSubmitting,SethitSubmitting]=useState(false);
       const[PageHelper,SetPageHelper]=useState({
           PageNo:'1',
           PageSize:'10',
           TotalRecord:0,
            dsAssignedCallDlists:[]
        
            });
            const [errorModal, setErrorModal] = useState(false);
            const [modal, setModal] = useState();
            const toggleModal = () => {
                setModal(!modal)
            }

            useEffect(() => {

                asyncFunBindInboundnotifcation(1,10); 
            }, []);
   
            useEffect(() => {
                
                const id4 = setInterval(() => {
                   
                    asyncFunBindInboundnotifcation(1,10);
                    console.log('Inboundnotifcation');
                 
                },  25* 1000);
                return () => clearInterval(id4);
        
            }, []);
   
       const AddCallHandler = async (Call_id,callfrom,callto) => {
        //    localStorage.removeItem('Call_id');
        //    localStorage.setItem('Call_id', Call_id);
           try {
   
               let url=ConnectionInstance+ 'InboundCall/InboundCallAddFirst';
   
               let options = {
                   method: 'POST',
                   url: url,
                   headers: {
                       'Accept': 'application/json',
                       'Content-Type': 'application/json;charset=UTF-8'
                   },
                 data:{Call_id: `${Call_id}`,Callfrom:`${callfrom}`,Callto:`${callto}`,  AddedBy: `${LoginId}`}
               };
   
               let response = await axios(options);
                   let responseOK = response && response.status == 200;
                   if (responseOK) {
                       let data = response.data;
                       // let data = await response.data;
                       if(data.Success==true && data.Data!="-1")
                       { 
                        localStorage.removeItem('CallingId');
                        localStorage.setItem('CallingId', data.Data);
                           setModal(false);
                           setErrorModal(false);
                           navigate.push(`/inbound/addInboundcalls`);
                       }
                       else{
                            if(data.ErrorList!=null && data.ErrorList.length>0)
                            {
                               let Errtext=<p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                               SetModalBodyHtml(Errtext);
                                setModal(!modal);
                                setErrorModal(true);
                            }
   
                       }
   
   
                   }
                   else{
                       console.log('no record found');
   
                   }
                // return data;
               } catch (error) {
                   console.log(error.message);
                   let Errtext="";
                       Errtext =<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                   SetModalBodyHtml(Errtext);
                   setModal(!modal);
                   setErrorModal(true);
                }
          
       }
   
       const InboundNotificationHandler = () => {
           navigate.push(`/inbound/notifications`);
       }
       async function asyncFunBindInboundnotifcation(PageNo, PageSize) {
       
        try {
     
            let url=ConnectionInstance+ 'YeastarAPI/GetYesStarNotifcationDetailByExtDetail';
                let options = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    data: { CallExtensionId: `${CallExtensionId}`,PageNo: `${PageNo}`, PageSize: `${PageSize}` }
                };
                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
    
                    // let data = await response.data;
                    if (data.Success == true) {
                        let ds = data.Data;
                        if (ds != null) {
    
                            await SetPageHelper({
                                ...PageHelper,
                                PageNo:Number(PageNo),
                                PageSize: Number(PageSize),
                                TotalRecord: Number(ds.TotalRecord),
                                dsInboundnotifiCallDlists: ds.objYeastarAPINotificationsList == null ? [] : ds.objYeastarAPINotificationsList
                            });
    
    
                        }
                    }
                    else {
                        let Errtext = <p><div className='text-required'> no record found </div></p>;
                        setErrorPopUp(Errtext);
                        setErrorModal(true);
                        console.log('no record found');
                    }
    
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    setErrorPopUp(Errtext);
                    setErrorModal(true);
                    console.log('no record found');
                }
                // return data;
            } catch (error) {
                console.log(error);
                let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                setErrorPopUp(Errtext);
                setErrorModal(true);
                //alert(error);
            }
    }
   
//     Pusher.logToConsole = true;
//  const pusher = new Pusher(PusherKey, {
//         cluster: 'ap2'
//       });

//     const channel = pusher.subscribe('my-channel');
//     channel.bind('my-event', function(data) {
//         if(data.message){
//         let Dsmsg=data.message.Data.filter((item)=>{
//    return item.callto==CallExtensionId;
//         });
//          console.log(JSON.stringify(Dsmsg));
//         //SetPusherMsg(data.message);
//         //SweetAlert.fire(JSON.stringify(data.message)); 
//         //toast('Here is your toast.');
//          if(Dsmsg!=null && Dsmsg.length>0)
//          {
//             channel.unbind();
//             channel.unbind_global();
//             SethitSubmitting(true);
//         let CustIdTost =`call${parseInt(Dsmsg[0].call_id)+'pPK1'}`;
//             notifyAlert(Dsmsg, CustIdTost);
            
//             // toast.custom((t) => (
//             //     <div
//             //       className={`${
//             //         t.visible ? 'animate-enter' : 'animate-leave'
//             //       } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
//             //     >
//             //       <div className="table-responsive">
//             //                <table className="table">
//             //                    <thead className="thead-light">
//             //                        <tr>
//             //                            <th scope="col">Customer Mobile</th>
//             //                            <th scope="col">CallingOn</th>
//             //                            <th scope="col" className='text-center'>Action</th>
//             //                        </tr>
//             //                    </thead>
//             //                    <tbody>
//             //                        { Dsmsg!=null?Dsmsg.map((tb,index) => {
//             //                            return (
//             //                                <>
//             //                                    <tr key={tb.call_id}>
//             //                                        <td>{tb.callfrom}</td>
//             //                                        <td>{tb.AddedOn}</td>
//             //                                      <td className='text-center'>
//             //                                            <CallToolTip title="Read Notification" placement="top" id={`call${parseInt(tb.Call_id)+'pR'+parseInt(index+1)}`} onClick={() => AddCallHandler(tb.call_id,tb.callfrom,tb.callto)} />
//             //                                        </td>
//             //                                    </tr>
//             //                                </>
//             //                            )
//             //                        }):<tr><td colSpan={7} style={{ textAlign: 'center', color: 'red' }}>
//             //                        <h5>No record Found</h5>
//             //                        </td></tr>}
                             
           
//             //                    </tbody>
//             //                </table>
//             //            </div>
//             //       <div className="flex border-l border-gray-200">
//             //         <button
//             //           onClick={() => toast.dismiss(t.id)}
//             //           className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
//             //         >
//             //           Close
//             //         </button>
//             //       </div>
//             //     </div>
//             //   ));
              
           
//          }
//         }
       
//     //alert(JSON.stringify(data.message));
//     },   channel.unbind(),
//     channel.unbind_global());
// const notifyAlert=(Dsmsg, CustIdTost)=>{
//     if(!toast.isActive(CustIdTost))
//     {
//         toast(<><div className="table-responsive">
//         <table className="table">
//             <thead className="thead-light">
//                 <tr>
//                     <th scope="col">Customer Mobile</th>
//                     <th scope="col">CallingOn</th>
//                     <th scope="col" className='text-center'>Action</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 { Dsmsg!=null?Dsmsg.map((tb,index) => {
//                     return (
//                         <>
//                             <tr key={tb.call_id}>
//                                 <td>{tb.callfrom}</td>
//                                 <td>{tb.AddedOn}</td>
//                               <td className='text-center'>
//                                     <CallToolTip title="Read Notification" placement="top" id={`call${parseInt(tb.Call_id)+'pR'+parseInt(index+1)}`} onClick={() => AddCallHandler(tb.call_id,tb.callfrom,tb.callto)} />
//                                 </td>
//                             </tr>
//                         </>
//                     )
//                 }):<tr><td colSpan={7} style={{ textAlign: 'center', color: 'red' }}>
//                 <h5>No record Found</h5>
//                 </td></tr>}
          
    
//             </tbody>
//         </table>
//     </div></>, {
//             position: "top-center",
//             autoClose: 10000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             className:'txtwdthcls',
//             toastId:CustIdTost
//             });
//     }
//     }

       return (
           <>
          
         
        
               <div className="table-responsive">
                   <table className="table">
                       <thead className="thead-light">
                           <tr>
                               <th scope="col">#</th>
                               <th scope="col">Customer Mobile</th>
                               <th scope="col">Customer Name</th>
                               <th scope="col">Call Queue</th>
                               <th scope="col">Call Detail</th>
                               {/* <th scope="col">Call Duration</th>
                               <th scope="col">Call Started At</th>
                               <th scope="col">Call Ended At</th> */}
                               <th scope="col" className='text-center'>Action</th>
                           </tr>
                       </thead>
                       <tbody>
                           {

                            (PageHelper.dsInboundnotifiCallDlists==null ||  PageHelper.dsInboundnotifiCallDlists==undefined ||  PageHelper.dsInboundnotifiCallDlists=='')
                            ?<tr><td colSpan={7} style={{ textAlign: 'center', color: 'red' }}>
                            <h5>No record Found</h5>
                            </td></tr>:PageHelper.dsInboundnotifiCallDlists.map((tb,index) => {
                                return (
                                    <>
                                        <tr key={tb.call_id}>
                                            <th>{parseInt(index+1)}</th>
                                            <td>{tb.callfrom}</td>
                                            <td>{tb.CustomerName}</td>
                                            <td><p>
                                <span className='grdH3'><strong>Queue: {(tb.FlowName!="")?(tb.FlowName):'N/A'}</strong></span><br/>
     <span className='grdH3'>IVR: {(tb.IVR!="")?(tb.IVR):'N/A'}</span><br/>
     <span className='grdH3'>FLOW: {(tb.Flow!="")?(tb.Flow):'N/A'}</span>
      </p></td>
      <td><p>
                                <span className='grdH3'><strong>Call Duration: {(tb.CallDuration!="")?(tb.CallDuration):'N/A'}</strong></span><br/>
     <span className='grdH3'>Call Started At: {(tb.CallStartedAt!="")?(tb.CallStartedAt):'N/A'}</span><br/>
     <span className='grdH3'>Call Ended At: {(tb.AddedOn!="")?(tb.AddedOn):''}</span>
      </p></td>
                                            {/* <td>{tb.CallDuration}</td>
                                            <td>{tb.CallStartedAt}</td>
                                            <td>{tb.AddedOn}</td> */}
                                          <td className='text-center'>
                                                <CallToolTip title="Read Notification" placement="top" id={`call${parseInt(tb.call_id)+'pR'+parseInt(index+1)}`} onClick={() => AddCallHandler(tb.call_id,tb.callfrom,tb.callto)} />
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                     
   
                       </tbody>
                   </table>
               </div>
              { (PageHelper.dsInboundnotifiCallDlists==null ||  PageHelper.dsInboundnotifiCallDlists==undefined ||  PageHelper.dsInboundnotifiCallDlists=='')
                            ?null: <p className='text-center mt15'>
                            <button className='btn btn-primary' onClick={PageHelper.TotalRecord=='0'?'javascript:void(0);': InboundNotificationHandler}>View All</button>
                        </p>} 
              
               {
                   errorModal===true?
                    (<Modal isOpen={modal} toggle={toggleModal} centered={true}>
                       <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                       <ModalBody>  
                           {ModalBodyHtml}              
                       </ModalBody>
                       <ModalFooter>
                           <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                           <Button variant="primary" onClick={toggleModal}>Ok</Button>
                       </ModalFooter>
                       
                   </Modal>)
                       : null
               } 
           </>
           
       )
   }
export default InboundCallNotifications;