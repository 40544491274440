import React, { useState, useEffect, useContext, useRef } from 'react'
import Breadcrumb from '../commonComponent/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col,Button, Card, Form } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DataTable from '../commonComponent/DataTable/DataTable';
import axios from 'axios';
import ConnectionInstance from '../apisettings/ConnectionInstance';
import AppointmentNewOrder from '../commonComponent/Phlebo/AppointmentNewOrder';
import { GlobalInfo } from '../../components/context/context'
import { Typeahead } from 'react-bootstrap-typeahead';
import InputGroup from 'react-bootstrap/InputGroup';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import LoadingButton from '../commonComponent/Usecomponent/loadingbutton';
import Signup from '../commonComponent/Phlebo/Signup';
const DirectAppointments = () => {
    const[CCLoading,SetCCLoading]=useState(false);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [table, setTable] = useState([]);
    const [appointmentid, setAppointmentId] = useState("0");
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [centerid, setCenterId] = useState("0");
    const [userexistflag, setUserExistFlag] = useState("0");
    const [showdetails, setShowDetails] = useState(false);
    const [AddPatientshow, setAddPatientshow] = useState(false);
    const [hidebackbutton, setHideBackButton] = useState(false);
    const { dispatchUserEvent } = useContext(GlobalInfo);
    const [status, setStatus] = useState("0");
    const [isconfirmed, setIsConfirmed] = useState("0");
    const [moboruhidoptions, setMobOrUhidOptions] = useState([]);
    const [holdmobvalue, setHoldMobValue] = useState("");
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [defaultDate, setDefaultDate] = useState("");
    const [slottiming, setSlotTiming] = useState([]);
    const [IsLoadButton, setIsLoadButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [SelectDS, setSelectDS] = useState(null);
    const [NewAppointmentuserexistflag, setNewAppointmentuserexistflag] = useState("");
    let moboruhid = useRef();
    let btnnew = useRef();
    const [openmodal, setOpenModal] = useState(false);
    const [appid, setAppId] = useState("0");

    let dtime = useRef();
    let appointmentDateId = useRef();
    var finaltime = "";
    let ToTime = useRef();
    let Locid = useRef();
    let frmtime = useRef();
    useEffect(() => {
        ClearStoredState();
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        GetSlotTiming(defaultValue); 
        const pageAccessedByReload = (
            (window.performance.navigation && window.performance.navigation.type === 1) ||
            window.performance
                .getEntriesByType('navigation')
                .map((nav) => nav.type)
                .includes('reload')
        );
        if (pageAccessedByReload === true) {
            ClearStoredState();
            localStorage.removeItem("samplerid");
            localStorage.removeItem("sampledid");
            localStorage.removeItem("samplecid");
            localStorage.removeItem("sampleitemmasterdetails");
        }
        return () => {
            ClearStoredState();
            localStorage.removeItem("samplerid");
            localStorage.removeItem("sampledid");
            localStorage.removeItem("samplecid");
            localStorage.removeItem("sampleitemmasterdetails");
        }
    }, []);

    
   
const RequestTime = (e) => {
    const value = e.target.options[e.target.selectedIndex].text;
    finaltime = value;
    if (finaltime === "---Select---") { finaltime = ""; }
  }
  const handleSlotDateChange = () => {
    GetSlotTiming(appointmentDateId.current.value); 
  }
  const GetSlotTiming = async (date) => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) { dd = '0' + dd; }
    if (mm < 10) { mm = '0' + mm; }
    var todaydate = yyyy + '-' + mm + '-' + dd;
    if (date === "") { SweetAlert.fire({ title: "Error", text: "Please select date", icon: "error" }); setSlotTiming([]); return false; }
    if (todaydate > date) { SweetAlert.fire({ title: "Error", text: "You cannot select past date slots", icon: "error" }); setSlotTiming([]); return false; }
    setIsLoading(true);
    let url = ConnectionInstance + 'Cart/getslottiming/' + '1' + '/' + LoginId + '/' + date
    await axios.get(url).then((response) => {
      const data = response.data;
      setIsLoading(false);
      if (data.Success) {
        setSlotTiming(data.Data.map((d) => <option key={d.SlotTimingId} value={d.SlotTimingId}>{d.FromTime} - {d.ToTime}</option>));
      }
      else { setSlotTiming=[] }
    }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
  }
  const openRequiredFieldModal = () => { setOpenModal(!openmodal); }

  const updateAppDateTime = async () => {
    if (appointmentDateId.current.value === "") { SweetAlert.fire("Please enter date"); return false; }
    else if (dtime.current.options[dtime.current.selectedIndex].value== "") { SweetAlert.fire("Please select time"); return false; }
    else
    {
      setOpenModal(false);
      const requestOptions = {
        LoginId: localStorage.LoggedInUser,
        AppointmentId:appid,
        AppointmentDate: appointmentDateId.current.value,
        AppointmentTime: dtime.current.options[dtime.current.selectedIndex].value,
      };
      setIsLoading(true);
      let url = ConnectionInstance + 'Cart/UpdateAppointmentDate'
      await axios.post(url, requestOptions).then((response) => {
        const data = response.data;
        setIsLoading(false);
        if (data.Success) {
          SweetAlert.fire({
            text: "Successfully Appointment date & time updated.",
            icon: 'success',
            confirmButtonText: 'Ok',
            reverseButtons: true,
          }).then((result) => 
          {
            if (result.isConfirmed) {
                SearchPatient(holdmobvalue);
              setSelectDS(null);         
            //   frmtime.current.value = "";
            //   ToTime.current.value = "";
            //   Locid.current.value = "0";
            //  setUsers([]);
             // SetLocation([]);
            
              //window.location.reload(true);        
            }
        })
      }
        else {
          APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
        }
      }).catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          ErrorFunction(error.response.status);
        }
      });
  }

  }

  const APIError = async (ErrorCode, ErrorMsg) => {
    if (ErrorCode === "-1") { SweetAlert.fire({ title: "Duplicate", text: "User already exist", icon: "error" }) }
    else if (ErrorCode === "0") { SweetAlert.fire({ title: "Location not found", text: "Please select location", icon: "error" }) }
    else if (ErrorCode === "-1997") { SweetAlert.fire({ title: "Empty", text: "No record found", icon: "error" }) }
    else if (ErrorCode === "-1998") { SweetAlert.fire({ title: "Some value is missing", text: "Check server and db logs", icon: "error" }) }
    else if (ErrorCode === "-1999") { SweetAlert.fire({ title: "Runtime Exception", text: ErrorMsg, icon: "error" }) }
    else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
  }
  const AxiosError = async (ErrorCode) => {
    if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
    else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
    else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
    else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
    else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
    else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
  }

    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    // const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
    //     return <React.Fragment>
    //         <ToolTip title="Details" placement="top" id={`view${rowData.AppointmentId}`} onClick={() =>
    //             GetWebsiteAppointmentDetails(rowData.AppointmentId, rowData.Age, rowData.Gender, rowData.CentreId, rowData.UserId, rowData.MobileNo, rowData.Status, rowData.IsConfirmedByCallCentre)} />
    //         {
    //             rowData.Status === "0" || rowData.Status === "False" ? null :
    //                 <DetailsToolTip title={`Your phlebo name: ${rowData.PhleboName}, Your phlebo will arrive to you at : ${rowData.RouteDate} | ${rowData.RouteStartTime} - ${rowData.RouteEndTime}`} placement="top" id={`show${rowData.AppointmentId}`} />
    //         }
    //     </React.Fragment>
    // }
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };

    const actionnewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
          {rowData.TotalPatients + ' | ' + '( ' + rowData.Age + ' )'}
        </React.Fragment>
      }
      const actionsFormatter = (cellData, rowData, rowIndex, extraData) => {
        // return <React.Fragment>
        //   {rowData.AppointmentDate != "" && rowData.AppointmentTime != "" ? rowData.AppointmentDate + ' | ' + rowData.AppointmentTime : rowData.AppointmentDate}
        // </React.Fragment>
        return <React.Fragment>
          <div>
            {rowData.AppointmentDate != "" && rowData.AppointmentTime != "" ? rowData.AppointmentDate + ' | ' + rowData.AppointmentTime : rowData.AppointmentDate}
            &nbsp; <EditToolTip title="Edit" onClick={() => editHandler(rowData)} />
          </div>
        </React.Fragment>
      }
      const editHandler = (rowData) => {
        setOpenModal(true);
        setAppId(rowData.AppointmentId);
      }
    const columns = [
        {
            dataField: '#',
            text: '#',
            formatter: idFormatter,
            headerStyle: (colum, colIndex) => {
              return { width: '9%' };
            }
          },
          {
            dataField: 'CustomerName',
            text: 'Customer Name'
          },
          {
            dataField: 'CustomerMobile',
            text: 'Customer Mobile'
          },
          {
            dataField: 'SourceCategory',
            text: 'Source Category'
          },
          {
            dataField: 'AppointmentTime',
            text: 'Appointment Date',
            formatter: actionsFormatter
          },
          {
            dataField: 'TotalPatients',
            text: 'Total Patients | Age',
            formatter: actionnewFormatter
          },
          {
            dataField: 'StatusName',
            text: 'Status',
          }    ];
    const GetWebsiteAppointmentDetails = async (AppointmentId, Age, Gender, CentreId, UserId, MobileNo, Status, IsConfirmedByCallCentre) => {
        localStorage.removeItem("samplerid");
        localStorage.removeItem("sampledid");
        localStorage.removeItem("samplecid");
        localStorage.removeItem("sampleitemmasterdetails");
        setUserExistFlag("1");
        setAppointmentId(AppointmentId);
        setAge(Age);
        setGender(Gender);
        setCenterId(CentreId);
        if (Status === "0" || Status === "False") { setStatus("0"); }
        else { setStatus("1"); }
        if (IsConfirmedByCallCentre === "0" || IsConfirmedByCallCentre === "False") { setIsConfirmed("0"); }
        else { setIsConfirmed("1"); }
        localStorage.setItem("lid", UserId);
        dispatchUserEvent('ADD_LOGINDATA', { loginid: UserId });
    }
    const ClearStoredState = () => {
        setAppointmentId("0");
        setAge("");
        setGender("");
        setCenterId("0");
        setTable([]);
        setMobOrUhidOptions([]);
        setShowDetails(false);
        dispatchUserEvent('REMOVE_LOGINDATA', { loginid: '0' });
        dispatchUserEvent('REMOVE_CENTREID', { centreid: '0' });
        dispatchUserEvent('REMOVE_DISTRICTID', { districtid: '0' });
        dispatchUserEvent('REMOVE_REGIONID', { regionid: '0' });
        dispatchUserEvent('REMOVE_ITEMMASTERDETAILS', { itemmasterdetails: [] });
        dispatchUserEvent('REMOVE_CARTSUMMARY', { cartsummary: [] });
        localStorage.setItem('loginid', '0');
        localStorage.setItem('centreid', '0');
        localStorage.setItem('districtid', '0');
        localStorage.setItem('regionid', '0');
        localStorage.setItem('itemmasterdetails', []);
        localStorage.setItem('cartsummary', []);
        localStorage.removeItem('lid');
        localStorage.removeItem('cid');
        localStorage.removeItem('did');
        localStorage.removeItem('rid');
        localStorage.removeItem('itemmasterdetails');
        localStorage.removeItem("appdate");
        localStorage.removeItem("apptime");
        localStorage.removeItem("appaddress");
        localStorage.removeItem("apptimetext");
        localStorage.removeItem("addedmoreitems");
        localStorage.removeItem("RateTempId");
    }
    const openSignUp = async () => {
        localStorage.setItem('UPageURL', "/booking/directAppointments");
        localStorage.setItem('PageIdentify', "7");
        setStatus("0");
        setCenterId("0");
        setAppointmentId("-1");
        setUserExistFlag("");
        setNewAppointmentuserexistflag("0");
        localStorage.setItem("samplecentreid","0");
    }
    const ClickSearchPatient = async () => {
        if (moboruhid.current.value.length != "10") SweetAlert.fire('Patient mobile must be exactly 10 characters');
    }
    // const SearchPatient = async (MobileNo) => {
    //     try {
    //         let url = ConnectionInstance + 'master/getallwebsiteappointments/'
    //         await axios.get(url).then((response) => {
    //             const data = response.data.Data;
    //             if (response.data.Success === true) {
    //                 if (data !== null) {
    //                     var isexist = data.filter((d) => d.MobileNo === MobileNo);
    //                     if (isexist.length === 0) { setTable([]); }
    //                     else { setTable(data.filter((d) => d.MobileNo === MobileNo)); }
    //                 }
    //                 else { setTable([]); }
    //             }
    //             else { setTable([]); }
    //         }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    //     }
    //     catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    // }
    const SearchPatient = async (MobileNo) => {
        try {
            SetCCLoading(true);
            let url = ConnectionInstance + 'cart/getallappointmentsCrm/' + MobileNo;
            await axios.get(url).then((response) => {
                const data = response.data.Data;
                SetCCLoading(false);
                if (response.data.Success === true) {
                    if (data !== null) {
                        var isexist = data.filter((d) => d.MobileNo === MobileNo);
                        if (isexist.length === 0) { setTable([]); }
                        else { setTable(data.filter((d) => d.MobileNo === MobileNo)); }
                    }
                    else { setTable([]); }
                }
                else { setTable([]); }
            }).catch(function (error) { if (error.response) {
                SetCCLoading(false);
                ErrorFunction(error.response.status); } });
        }
        catch (error) {
            SetCCLoading(false);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const SearchPatientByMobNo = async (e) => {
        if (e.target.value.length == "10") {
            try {
                let url = ConnectionInstance + 'master/getsearchpatientbymobno/' +  e.target.value
                await axios.get(url).then((response) => {
                    const data = response.data.Data; 
                    if (response.data.Success === true) {
                        if (data !== null) {
                            var isexist = data.filter((d) => d.MobileNo === e.target.value);
                            setHoldMobValue(e.target.value);
                            if (isexist.length === 0) {
                                setAddPatientshow(true);
                                setShowDetails(false); setMobOrUhidOptions([]); SweetAlert.fire("No patient exist"); }
                            else {
                                setAddPatientshow(false);
                                setShowDetails(true);
                                setMobOrUhidOptions(data.filter((d) => d.MobileNo === e.target.value));
                                //btnnew.current.focus();
                                setTimeout(() => {
                                    moboruhid.current.focus();
                                }, 100);
                                SearchPatient(e.target.value);
                            }
                            moboruhid.current.clear();
                        }
                        else { 
                            setHoldMobValue(e.target.value);
                            setAddPatientshow(true);
                            setShowDetails(false); setMobOrUhidOptions([]); moboruhid.current.clear(); SweetAlert.fire("No patient exist"); }
                    }
                    else { setAddPatientshow(true);  setHoldMobValue(e.target.value); setShowDetails(false); setMobOrUhidOptions([]); moboruhid.current.clear(); SweetAlert.fire("No patient exist"); }
                }).catch(function (error) { if (error.response) { 
                    ErrorFunction(error.response.status); } });
            }
            catch (error) {
                SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
            e.preventDefault();
        }
 
    }
    

    const BindNewUserOrder = async (UserId, Age, Gender) => {
        setStatus("0");
        localStorage.setItem("lid", UserId);
        localStorage.setItem('PatientId', UserId);
        dispatchUserEvent('ADD_LOGINDATA', { loginid: UserId });
        setAge(Age);
        setGender(Gender);
        setCenterId("0");
        setHideBackButton(false);
        setAppointmentId("-1");
        setUserExistFlag("0");
        setNewAppointmentuserexistflag("0");
    }
    const BindNewUserOrderButton = async () => {
        localStorage.setItem('UPageURL', "/booking/directAppointments");
        localStorage.setItem('PageIdentify', "7");
        setStatus("0");
        localStorage.setItem("lid", moboruhidoptions[0].UserId);
        localStorage.setItem('PatientId', moboruhidoptions[0].UserId);
        dispatchUserEvent('ADD_LOGINDATA', { loginid: moboruhidoptions[0].UserId });
        setAge(moboruhidoptions[0].Age);
        setGender(moboruhidoptions[0].Gender);
        setCenterId("0");
        setHideBackButton(false);
        setAppointmentId("-1");
        setUserExistFlag("0");
        setNewAppointmentuserexistflag("1");
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") {
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        }
        else if (ErrorCode == "401") {
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        }
        else if (ErrorCode == "403") {
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        }
        else if (ErrorCode == "404") {
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        }
        else if (ErrorCode == "500") {
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        }
        else if (ErrorCode == "503") {
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
        }
    }
    return (
        <>
          <div id="cover-spin"  style={{display: CCLoading ? 'block' : 'none'}}></div> 
            <Container fluid>
                <Row className='align-items-center pt-2 pb-2'>
                    <Col md={10}>
                        <Breadcrumb title="Direct Appointments" parent="Appointments" />
                    </Col>
                    {
                        appointmentid === "0" ? null :
                            <>
                                {
                                    hidebackbutton === false ?
                                        <>
                                            <Col md={2} className='text-right'>
                                                <Button variant='secondary' type='submit' onClick={() => ClearStoredState()}>Back</Button>
                                            </Col>
                                        </> : null
                                }
                            </>
                    }
                </Row>
            </Container>
            <Container fluid>
                {
                    appointmentid === "0" ?
                        <>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <Form.Row className='justify-content-center align-items-center'>
                                                <Col className='col-auto adPatientColLeft'>
                                                    <Form.Group className='mb-0'>
                                                        <InputGroup id='inpt'>
                                                            <Typeahead
                                                                id="mobuhid-typeahead"
                                                                labelKey="PatientUHID"
                                                                multiple={false}
                                                                options={moboruhidoptions}
                                                                ref={moboruhid}
                                                                placeholder="Search by patient mobile"
                                                                onInputChange={(text, e) => { SearchPatientByMobNo(e, text); }}
                                                                onChange={(text) => { JSON.stringify(text) == "[]" ? BindNewUserOrder("0", "", "") : BindNewUserOrder(text[0].UserId, text[0].Age, text[0].Gender) }}
                                                            />
                                                            <InputGroup.Append>
                                                                <Button variant="primary" onClick={ClickSearchPatient}><i className='fa fa-search'></i></Button>
                                                            </InputGroup.Append>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                            {AddPatientshow &&<Col className='col-auto adPatientColRight'>
                                                    <Form.Group className='mb-0'>
                                                        <Button variant='primary' ref={btnnew} type='submit' onClick={openSignUp}>Add New Patient</Button>
                                                    </Form.Group>
                                                </Col>}
                                                
                                            </Form.Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            {showdetails &&
                                <>
                                    <Row>
                                        <Col md={12}>
                                            <Card>
                                                <Card.Header className='pb-0'>
                                                    <Form.Row>
                                                        <Col md={6}>
                                                            <Card.Title>Appointment List</Card.Title>
                                                        </Col>
                                                        <Col md={6} className='mt-4 mt-md-0' style={{ textAlign: 'right' }}>
                                                            <Form.Group>
                                                                <Button variant='primary' type='submit' onClick={BindNewUserOrderButton}>New Appointment</Button>
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Row>
                                                </Card.Header>
                                                <Card.Body>
                                                    <DataTable
                                                        keyField='AppointmentId'
                                                        tableData={table}
                                                        columns={columns}
                                                        noDataIndication={NoRecordFounds}
                                                        pagination={paginationFactory(paginationOptions)}
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </>
                        :
                        <>
                            {
                                userexistflag === "" ?
                                        <Signup appointmentid={(appointmentid) => setAppointmentId(appointmentid)}
                                        age={(age) => setAge(age)}
                                        gender={(gender) => setGender(gender)}
                                        centerid={(centerid) => setCenterId(centerid)}
                                        mobileNo={holdmobvalue}
                                        userexistflag={(userexistflag) => setUserExistFlag(userexistflag)}
                                        hidebackbutton={(hidebackbutton) => setHideBackButton(hidebackbutton)} />
                                    :
                                    <AppointmentNewOrder AppointmentId={appointmentid} Age={age} Gender={gender} CenterId={centerid} UserExistFlag={userexistflag} Status="0"  NewAppointmentuserexistflag={NewAppointmentuserexistflag}/>
                                    // <AppointmentDetails AppointmentId={appointmentid} Age={age} Gender={gender} CenterId={centerid} UserExistFlag={userexistflag} Status={status} IsConfirmed={isconfirmed} />
                            }
                        </>
                }
                
            </Container>

            {
          openmodal &&
          <Modal isOpen={openmodal} centered={true} size="lg">
            <ModalHeader> Appointment</ModalHeader>
            <ModalBody>
              <Form.Row>
                <Col md={6}>
                  <Form.Group className="mb-3 calandersec">
                     <Form.Label>Date</Form.Label>
                      <Form.Control type="date" name="date" ref={appointmentDateId} defaultValue={defaultDate}  onChange={handleSlotDateChange} />
                   </Form.Group>                 
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Select time<span className='text-red'>*</span></Form.Label>
                    <Form.Control as="select" name="time" ref={dtime} onChangeCapture={(e) => RequestTime(e)}>
                      <option value="">---Select---</option>
                      {slottiming}
                    </Form.Control>                 
                  </Form.Group>
                </Col>
                <Col md={12} xs={11}>
                  <Form.Group className='m-t-15'>
                    <Form.Label className='icRight'>
                      <Button variant='secondary' className="mr-2" onClick={openRequiredFieldModal}>Cancel</Button>
                      {
                        isButtonLoading ?
                          <LoadingButton variantName='primary' cssName="" /> :
                          <Button variant='primary' type='submit' id="btnUpdate" onClick={updateAppDateTime}>Update</Button>
                      }
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ModalBody>
          </Modal>
        }
        </>
    )
}

export default DirectAppointments;