import React, { useState, Fragment,useEffect } from 'react';
import logo from '../../../assets/images/logo-atulaya.png';
import UserMenu from './userMenu';
import CallToolTip from '../../../pages/UI/ToolTips/CallToolTip';
//import SearchHeader from './searchHeader';
import { Link } from 'react-router-dom';
import { AlignLeft, MoreHorizontal, Settings } from 'react-feather';
import SweetAlert from 'sweetalert2';
import ConnectionInstance from '../../../pages/apisettings/ConnectionInstance';
import axios from 'axios';
import { Container, Row, Col, Card, Form} from 'react-bootstrap';
import Select from 'react-select';
import PusherKey from '../../../pages/apisettings/PusherKey';
import {PUSHERENV} from '../../../pages/apisettings/PusherKey';
import { useHistory } from 'react-router';
 import Pusher from 'pusher-js';
 import { debounce } from 'lodash';
//  import toast, { Toaster } from 'react-hot-toast';
  import 'react-toastify/dist/ReactToastify.css';
  import { ToastContainer, toast } from 'react-toastify';
  import {YeastarAPI } from '../../../pages/apisettings/GlobalConstant';
let GlobalModuleId="0";

let GlbCustCallid="";
const Header = () => {
  
  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [headerbar, setHeaderbar] = useState(true);
  const[UserType,setUserType]=useState(localStorage.UserType == undefined ? "" : localStorage.UserType);
  const navigate=new useHistory();
  const[LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
  const[UserAllowModule,SetUserAllowModule]=useState(localStorage.UserAllowModule==undefined?"":JSON.parse(localStorage.getItem('UserAllowModule')));
  const[SelectLocationUser,SetSelectLocationUser] = useState(localStorage.SelectionOptonLoc==undefined?"":JSON.parse(localStorage.getItem('SelectionOptonLoc')));
  const[UserGroupLocationDDL,SetUserGroupLocationDDL]=useState(null);
  const[UserTypeId, setUserTypeId] = useState(localStorage.UserType == undefined ? "" : localStorage.UserType);
  const [CallExtensionId, setCallExtensionId] = useState(localStorage.ExtenNo == undefined ? "" : localStorage.ExtenNo);
  const[ErrorPopUp, setErrorPopUp]=useState(0);
  const[ModalBodyHtml,SetModalBodyHtml]=useState("");
  const[hitSubmitting,SethitSubmitting]=useState(false);
      const [errorModal, setErrorModal] = useState(false);
      const [modal, setModal] = useState();
      const toggleModal = () => {
          setModal(!modal)
      }
  const openCloseSidebar = () => {
    var element = document.querySelector(".page-main-header");
    var classexist = element.classList.contains('open');
    if (classexist === true) {
      document.querySelector(".page-main-header").classList.remove('open');
      document.querySelector(".page-sidebar").classList.remove('open');
    }
    else {
      document.querySelector(".page-main-header").classList.add('open');
      document.querySelector(".page-sidebar").classList.add('open');
    }
  }
 useEffect(() => {
 const mediaQuery = window.matchMedia('(max-width: 1024px)')
 if (mediaQuery.matches) {
      document.querySelector(".page-main-header").classList.add('open');
      document.querySelector(".page-sidebar").classList.add('open');
      }
  FuncModuleList();
  FuncUserLocationMappingList();
 }, []);

 useEffect(() => {
   if(PUSHERENV!='DEV')
   {
    Pusher.logToConsole = true;

    const pusher = new Pusher(PusherKey, {
      cluster: "ap2",
    });
  
    const channel = pusher.subscribe("my-channel");
  
    channel.bind(
      "my-event",
      function (data) {
        if(data.message &&(CallExtensionId!='' && CallExtensionId!=null)){
          let Dsmsg=data.message.Data.filter((item)=>{
     return item.callto==CallExtensionId;
          });
          if(Dsmsg!=null && Dsmsg.length>0)
          {
             channel.unbind();
             channel.unbind_global();
             SethitSubmitting(true);
         let CustIdTost =`call${parseInt(Dsmsg[0].call_id)+'pPK1'}`;
             notifyAlert(Dsmsg, CustIdTost);
          }
        }
      },
      channel.unbind()
      //   channel.unbind_global()
    );
    return () => {
      pusher.unsubscribe("my-channel");
      pusher.disconnect();
      // pusher.unsubscribe('channel_name2')
    };
   }
 
  //channel.bind('my-event');
}, []);
  // const openCloseSidebar = () => {
  //   if (sidebar) {
  //     setSidebar(!sidebar)
  //     document.querySelector(".page-main-header").classList.add('open');
  //     document.querySelector(".page-sidebar").classList.add('open');
  //   } else {
  //     setSidebar(!sidebar)
  //     document.querySelector(".page-main-header").classList.remove('open');
  //     document.querySelector(".page-sidebar").classList.remove('open');
  //   }
  // }
  const[FModuleURL,SetFModuleURL]=useState(null);

  function showRightSidebar() {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar)
      document.querySelector(".right-sidebar").classList.add('show');
    } else {
      setRightSidebar(!rightSidebar)
      document.querySelector(".right-sidebar").classList.remove('show');
    }
  }

  const ChangeHandler=(e)=>{
    GlobalModuleId=e.target.value;
     //window.location.assign(`${process.env.PUBLIC_URL}`);
     
    FuncModuleChangeFirst(); 
 }
  const   FuncModuleChangeFirst=async () => {
    try {

        let url=ConnectionInstance+ 'Users/SETUpdateUserModuleChangeAllowStatus';

        let options = {
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
          data:{ UserId:`${LoginId}`, ModuleId: `${GlobalModuleId}`,AddedBy: `${LoginId}`, ModLogId:"0" }
        };

        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if(data.Success==true)
                {

                       const FModuleURLFetch=FModuleURL.filter((item)=>{
                        return item.Mid==GlobalModuleId
                                 });
                                  if(FModuleURLFetch!=null && FModuleURLFetch.length>0)
                                  {
                                      const ReqURL=FModuleURLFetch[0].ModuleURL;
                                      window.location.assign(`${ReqURL}?Loginkey=`+data.Data); 
                                      //window.location.assign(`http://localhost:3001?key=`+data.Data); 
                                      
                                  }          
                  
                }
                else{
                     if(data.ErrorList!=null && data.ErrorList.length>0)
                     {
                           let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                            SweetAlert.fire({ title: "Error", text: data.ErrorList[0].errorMsg, icon: "error" });
                     }
                }
            }
            else{
                console.log('no record found');

            }
        } catch (error) {
            console.log(error.message);
            SweetAlert.fire({ title: "Error", text:'You may not be connected to a network or Unable to connect to a server', icon: "error" });
         }
  }

  const   FuncModuleList=async () => {
    try {

        let url=ConnectionInstance+ 'master/getBindModuledetails';

        let options = {
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
          data:{  }
        };

        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if(data.Success==true)
                {

                   if(data.Data!=null && data.Data.length>0)
                   {
                    SetFModuleURL(data.Data);
                   }
                }
                else{
                     if(data.ErrorList!=null && data.ErrorList.length>0)
                     {
                           let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                            SweetAlert.fire({ title: "Error", text: data.ErrorList[0].errorMsg, icon: "error" });
                     }
                }
            }
            else{
                console.log('no record found');

            }
        } catch (error) {
            console.log(error.message);
            SweetAlert.fire({ title: "Error", text:'You may not be connected to a network or Unable to connect to a server', icon: "error" });
         }
  }
  const   FuncUserLocationMappingList=async () => {
    try {
      let url=ConnectionInstance+ 'Users/GetUserAccessGroupLocationDDL';

      let options = {
          method: 'POST',
          url: url,
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
          },
        data:{ UserId:`${LoginId}`, ModuleId: `${"1"}` }
      };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let ds= response.data;
               
                // let data = await response.data;
                if(ds.Success==true)
                {  
                   if(ds.Data!=null && ds.Data.length>0)
                   {
                    const TypeOptionDs=[];
                      
                    ds.Data.map((option) =>{
                        const TypeOptions={
                            'UserLocld':option.UserLocld,
                            'label':option.CustUserLocation,
                            'value':option.UserLocld,
                             'ModuleId':option.ModuleId,
                            'AccessGroupName':option.AccessGroupName,
                             'IsDefaultGroup':option.IsDefaultGroup,
                             'PageAccessGroupId':option.PageAccessGroupId,
                            'disabled':false
                        }
                     return TypeOptionDs.push(TypeOptions);
                        });
                    
                        SetUserGroupLocationDDL(TypeOptionDs); 
                   }
                }
                else{
                     if(ds.Data.ErrorList!=null && ds.Data.ErrorList.length>0)
                     {
                           let Errtext = <p><div className='text-required'>{ds.Data.ErrorList[0].errorMsg} </div></p>;
                            SweetAlert.fire({ title: "Error", text: ds.Data.ErrorList[0].errorMsg, icon: "error" });
                     }
                }
            }
            else{
                console.log('no record found');

            }
        } catch (error) {
            console.log(error.message);
            SweetAlert.fire({ title: "Error", text:'You may not be connected to a network or Unable to connect to a server', icon: "error" });
         }
  }

  const UserAccessSelectionHandler=(options)=>{
   
    SetSelectLocationUser(options);
    localStorage.removeItem("SelectionOptonLoc");
    localStorage.setItem("SelectionOptonLoc", JSON.stringify(options));
   if(options!=null)
   {
  let ModuleId=options.ModuleId;
  let UserId=LoginId;
  let UserLocld=options.UserLocld;
  let PageAccessGroupId=options.PageAccessGroupId;
  
  BinduserAccessGroupLocations(ModuleId,UserId,UserLocld,PageAccessGroupId);

   }
  }  
  async  function  BinduserAccessGroupLocations(ModuleId,UserId,UserLocld,PageAccessGroupId){

    try {
        if (PageAccessGroupId) {

            let url = ConnectionInstance+ 'Users/GetMoveLocationAccessGroupDetail';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { ModuleId: `${ModuleId}` ,UserId:`${UserId}`, UserLocld:`${UserLocld}`,PageAccessGroupId:`${PageAccessGroupId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                      localStorage.removeItem("UserGroups");
                      localStorage.removeItem("UserSubGroups");
                        localStorage.setItem("UserGroups", JSON.stringify(ds.objUserAllowGroupsDetailsList));
                        localStorage.setItem("UserSubGroups", JSON.stringify(ds.objUserSubAllowGroupsList));

                        window.location.assign(`${process.env.PUBLIC_URL}/dashboard`);
                    }
                    else{
                      window.location.assign(`${process.env.PUBLIC_URL}/dashboard`);
                    }
                }
                else {
                  window.location.assign(`${process.env.PUBLIC_URL}/dashboard`);
                   
                }

            }
            else {
              window.location.assign(`${process.env.PUBLIC_URL}/dashboard`);
            }
        } 

    } catch (error) {
      window.location.assign(`${process.env.PUBLIC_URL}/dashboard`);
        console.log(error.message);      
    }
}
const AddCallHandler = async (Call_id,callfrom,callto) => {
  //    localStorage.removeItem('Call_id');
  //    localStorage.setItem('Call_id', Call_id);
     try {

         let url=ConnectionInstance+ 'InboundCall/InboundCallAddFirst';

         let options = {
             method: 'POST',
             url: url,
             headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json;charset=UTF-8'
             },
           data:{Call_id: `${Call_id}`,Callfrom:`${callfrom}`,Callto:`${callto}`,  AddedBy: `${LoginId}`}
         };

         let response = await axios(options);
             let responseOK = response && response.status == 200;
             if (responseOK) {
                 let data = response.data;
                 // let data = await response.data;
                 if(data.Success==true && data.Data!="-1")
                 { 
                  localStorage.removeItem('CallingId');
                  localStorage.setItem('CallingId', data.Data);
                     setModal(false);
                     setErrorModal(false);
                     navigate.push(`/inbound/addInboundcalls`);
                 }
                 else{
                      if(data.ErrorList!=null && data.ErrorList.length>0)
                      {
                         let Errtext=<p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                         SetModalBodyHtml(Errtext);
                          setModal(!modal);
                          setErrorModal(true);
                      }

                 }


             }
             else{
                 console.log('no record found');

             }
          // return data;
         } catch (error) {
             console.log(error.message);
             let Errtext="";
                 Errtext =<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
             SetModalBodyHtml(Errtext);
             setModal(!modal);
             setErrorModal(true);
          }
    
 }

const notifyAlert=(Dsmsg, CustIdTost)=>{
   if(!toast.isActive(CustIdTost))
   {
       toast(<><div className="table-responsive">
       <table className="table">
           <thead className="thead-light">
               <tr>
                   <th scope="col">Customer Mobile</th>
                   <th scope="col">Customer Name</th>
                   <th scope="col">Call Queue</th>
                    <th scope="col">Call Detail</th>
                   <th scope="col" className='text-center'>Action</th>
               </tr>
           </thead>
           <tbody>
               { Dsmsg!=null?Dsmsg.map((tb,index) => {
                   return (
                       <>
                           <tr key={tb.call_id}>
                               <td>{tb.callfrom}</td>
                               <td>{tb.CustomerName}</td>
                               <td><p>
                                <span className='grdH3'><strong>Queue: {(tb.FlowName!="")?(tb.FlowName):'N/A'}</strong></span><br/>
     <span className='grdH3'>IVR: {(tb.IVR!="")?(tb.IVR):'N/A'}</span><br/>
     <span className='grdH3'>FLOW: {(tb.Flow!="")?(tb.Flow):'N/A'}</span>
      </p></td>
      <td><p>
                                <span className='grdH3'><strong>Call Duration: {(tb.CallDuration!="")?(tb.CallDuration):'N/A'}</strong></span><br/>
     <span className='grdH3'>Call Started At: {(tb.CallStartedAt!="")?(tb.CallStartedAt):'N/A'}</span><br/>
     <span className='grdH3'>Call Ended At: {(tb.AddedOn!="")?(tb.AddedOn):''}</span>
      </p></td>
                             <td className='text-center'>
                                   <CallToolTip title="Read Notification" placement="top" id={`call${parseInt(tb.Call_id)+'pR'+parseInt(index+1)}`} onClick={() => AddCallHandler(tb.call_id,tb.callfrom,tb.callto)} />
                               </td>
                           </tr>
                       </>
                   )
               }):<tr><td colSpan={7} style={{ textAlign: 'center', color: 'red' }}>
               <h5>No record Found</h5>
               </td></tr>}
         
   
           </tbody>
       </table>
   </div></>, {
           position: "top-center",
           autoClose: 10000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           className:'txtwdthcls',
           toastId:CustIdTost
           });
   }
   }
  
  return (
    <Fragment>
      <div className="page-main-header">
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none">

          {UserType=='3'? <div className="logo-wrapper">
              <Link to={`${process.env.PUBLIC_URL}/user/dashboard`}>
                <img className="img-fluid" src={logo} alt="" />
                <div className='logoTagLine'>Imaging <span>&amp;</span> Laboratories</div>
              </Link>
            </div>:   <div className="logo-wrapper">
              <Link to={`${process.env.PUBLIC_URL}/admin/dashboard`}>
                <img className="img-fluid" src={logo} alt="" />
                <div className='logoTagLine'>Imaging <span>&amp;</span> Laboratories</div>
              </Link>
            </div>}
           
          </div>
          <div className="mobile-sidebar d-block">
            <div className="media-body text-right switch-sm">
              <label className="switch">
                <a href="javascript:void(0)" onClick={() => openCloseSidebar()}>
                  <AlignLeft />
                </a>
              </label>
            </div>
          </div>
          <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? '' : 'open'}`}>
            <li className='text-left' style={{width:500 }}  >
             
             {UserGroupLocationDDL!=null && UserGroupLocationDDL.length>0?<Select  
            
           //  defaultValue={UserGroupLocationDDL[1]}
     onChange={UserAccessSelectionHandler}
     options={UserGroupLocationDDL}
   value={SelectLocationUser}
            />:null}
           
           
             {/* <SearchHeader /> */}
           </li>
              {UserAllowModule!=null && UserAllowModule!='' && UserAllowModule.length>0?
                     <li className='text-center'> <Form.Control as="select" id="userModule" name="userModule"
                      onChange={ChangeHandler} > 
                    {UserAllowModule.map((option) => (
                    
                    <option value={option.ModuleId}  selected={option.ModuleId=="1"?true:false} >{option.ModuleName}</option>))}       
                     </Form.Control></li>:null
                    }

              {/* <li>
                <a href="#javascript" onClick={showRightSidebar}>
                  <Settings />
                 
                </a>
              </li> */}
              <UserMenu />
            </ul>
            <div className="d-lg-none mobile-toggle pull-right" onClick={() => setHeaderbar(!headerbar)}><MoreHorizontal /></div>
          </div>
          {/* <script id="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-airplay m-0"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1">
                </path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName"></div>
              </div>
            </div>
          </script> */}
          <script id="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">{"Your search turned up 0 results. This most likely means the backend is down, yikes!"}</div>
          </script>
        </div>
      </div>
    </Fragment>
  )
};
export default Header;